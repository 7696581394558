import { Route, Transporter } from '../../../clients';
import { convertDepartureSetStatusToText } from '../table-definitions';
import { ROUTE_PROGRAM_TYPE_TO_LABEL } from '../table-definitions/utilities';

export function parseFilteringText(filteringText: string, separator: string): string[] {
  return filteringText
    .split(separator)
    .map((text) => text.trim())
    .filter((text) => text.length > 0);
}

export function routeContainsOrderId(route: Route, orderId: string): boolean {
  const orderIds = route.actions?.map((action) => action.transportRequestDetails?.orderId).filter((orderId) => orderId !== null);
  if (orderIds) {
    return orderIds.includes(orderId);
  }
  return false;
}

export function routeContainsTrId(route: Route, trId: string): boolean {
  const trIds = route.actions?.map((action) => action.transportRequestDetails?.transportRequestId).filter((transportRequestId) => transportRequestId !== null);
  if (trIds) {
    return trIds.includes(trId);
  }
  return false;
}

export function routeContainsTrackingId(route: Route, trackingId: string): boolean {
  const trackingIds = route.actions?.map((action) => action.transportRequestDetails?.scannableId).filter((scannableId) => scannableId !== null);
  if (trackingIds) {
    return trackingIds.includes(trackingId);
  }
  return false;
}

export function routeContainsDepartureSet(route: Route, departureSetStatus: 'Planned' | 'Batching' | 'Batched' | string): boolean {
  const status = departureSetStatus.toLowerCase();
  if (['planned', 'batching', 'batched'].includes(status) && route.departureSets) {
    for (let i = 0; i < route.departureSets.length; i++) {
      if (convertDepartureSetStatusToText(route.departureSets[i].status).toLowerCase() === status) {
        return true;
      }
    }
  }
  return false;
}

export function transporterMatchesReadyToWorkSearch(transporter: Transporter, text: string): boolean {
  const status = text.toLocaleLowerCase();
  return (status === 'readytowork' && transporter.readyToWork) || (status === '!readytowork' && !transporter.readyToWork);
}

export function transporterGroupContainsTransporterId(transporters: Transporter[], transporterId: string): boolean {
  return !!transporters.find((transporter) => transporter.transporterId === transporterId);
}

export function routesContainsRouteName(routes: Route[], routeName: string) {
  return !!routes.find((route) => route.routeName?.toLowerCase() === routeName.toLowerCase());
}

export function routesContainsRouteId(routes: Route[], routeId: string) {
  return !!routes.find((route) => route.routeId === routeId);
}

export function routesContainsTrId(routes: Route[], trId: string) {
  return !!routes.find((route) => routeContainsTrId(route, trId));
}

export function routesContainsOrderId(routes: Route[], orderId: string) {
  return !!routes.find((route) => routeContainsOrderId(route, orderId));
}

export function routesContainsTrackingId(routes: Route[], trackingId: string) {
  return !!routes.find((route) => routeContainsTrackingId(route, trackingId));
}

export function routesContainsProviderType(routes: Route[], providerType: string) {
  return !!routes.find((route) => route.recommendedProviderType?.toLowerCase() === providerType.toLowerCase());
}

export function routesContainsRouteProgramType(routes: Route[], routeProgramType: string) {
  return !!routes.find((route) => (route.routeProgramType ? ROUTE_PROGRAM_TYPE_TO_LABEL[route.routeProgramType].toLowerCase() === routeProgramType.toLowerCase() : false));
}
