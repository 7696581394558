import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import ufraaVizClient, { RouteSnapshot } from '../../clients';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import { RouteSnapshotSummary } from './route-snapshot-summary';
import { RouteSnapshotDetails } from './route-snapshot-details';
import { getTimezone } from './helper';
import { saveAs } from 'file-saver';

interface Props {
  readonly routeSnapshotId: string;
}

interface State {
  /**
   * undefined indicates the snapshot is still loading
   * null indicates the snapshot doesn't exist.
   */
  readonly routeSnapshot?: RouteSnapshot | null;
}

export class RouteSnapshotPage extends React.Component<Props, State> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.updateBreadcrumbItems([
      {
        text: 'Route Snapshot',
        // todo: implement and update to '/route-snapshot' page.
        href: '/service-areas',
      },
      {
        text: this.props.routeSnapshotId,
        href: `/route-snapshot/${encodeURIComponent(this.props.routeSnapshotId)}`,
      },
    ]);

    try {
      const resp = await ufraaVizClient.getArtifact<RouteSnapshot>({
        artifactId: this.props.routeSnapshotId,
        artifactType: 'SNAPSHOT_ROUTES',
      });
      this.setState({
        routeSnapshot: resp.artifact,
      });
      const timezone = getTimezone(resp.artifact);
      if (timezone) {
        this.context.updateStationTimezone(timezone);
      }
    } catch (err) {
      this.context.addNotification({
        header: `RouteSnapshot Not Found`,
        content: `Unable to find the requested route snapshot ${this.props.routeSnapshotId}.`,
        type: 'error',
      });
      this.setState({ routeSnapshot: null });
    }
  }

  renderHeader() {
    return (
      <Header
        variant="h1"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              disabled={!this.state.routeSnapshot}
              onClick={() => {
                this.downloadData(this.state.routeSnapshot, `${this.props.routeSnapshotId}.json`);
              }}
            >
              Download Snapshot
            </Button>
          </SpaceBetween>
        }
      >
        Route Snapshot
      </Header>
    );
  }

  downloadData(object: any, filename: string) {
    const blob = new Blob([JSON.stringify(object, null, 2)], { type: 'application/json' });
    saveAs(blob, filename);
  }

  render() {
    return (
      <SpaceBetween direction="vertical" size="m">
        {this.renderHeader()}
        <RouteSnapshotSummary routeSnapshotId={this.props.routeSnapshotId} routeSnapshot={this.state.routeSnapshot} />
        <RouteSnapshotDetails routeSnapshotId={this.props.routeSnapshotId} routeSnapshot={this.state.routeSnapshot} />
      </SpaceBetween>
    );
  }
}
