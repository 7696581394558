import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import Button from '@amzn/awsui-components-react/polaris/button';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Container from '@amzn/awsui-components-react/polaris/container';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import Select, { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import DatePicker from '@amzn/awsui-components-react/polaris/date-picker';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Box from '@amzn/awsui-components-react/polaris/box';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import ProgressAlert from '../../shared-components/progress-alert';
import ufraaVizClient, { Cycle, ProgressStatus, ServiceAreaSnapshot, ServiceAreaSummary } from '../../clients';
import { timezoneManager } from '../../utilities';
import { downloadSCCInput } from './utilities';

interface Props {
  readonly dispatchDate?: string | null;
  readonly serviceAreaId?: string | null;
  readonly onDispatchDateChange: (date: string) => void;
  readonly onServiceAreaIdChange: (serviceAreaId: string) => void;
}

interface State {
  readonly isLoadingServiceAreas: boolean;
  readonly filteredServiceAreas?: ReadonlyArray<ServiceAreaSummary>;
  readonly isLoadingServiceAreaSnapshot: boolean;
  readonly serviceAreaSnapshot?: ServiceAreaSnapshot;
  readonly isLoadingStagingItems: boolean;
  readonly progresses?: ReadonlyArray<ProgressStatus>;

  readonly dispatchDate: string;
  readonly serviceAreaId?: string;
  readonly cycleId?: string;
}

const DISPATCH_DATE_FORMAT = 'YYYY-MM-DD';

export class AmzlStagingRoutesPage extends React.Component<Props, State> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  private serviceAreaSummaries?: ReadonlyArray<ServiceAreaSummary>;

  constructor(props: Props) {
    super(props);
    const localDispatchDate = timezoneManager.convertTimestampToString(new Date(), { tz: timezoneManager.getUserTimezone(), format: DISPATCH_DATE_FORMAT });
    this.state = {
      dispatchDate: typeof this.props.dispatchDate === 'string' ? this.props.dispatchDate : localDispatchDate,
      serviceAreaId: typeof this.props.serviceAreaId === 'string' ? this.props.serviceAreaId : undefined,
      isLoadingServiceAreas: false,
      isLoadingServiceAreaSnapshot: false,
      isLoadingStagingItems: false,
    };
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.setTools(undefined);
    this.context.updateBreadcrumbItems([{ text: 'AMZL Staging Routes', href: '/amzl-staging-routes' }]);
    this.loadServiceAreaSummaries();
    if (typeof this.props.serviceAreaId === 'string') {
      await this.loadServiceAreaSnapshot(this.props.serviceAreaId);
    }
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.dispatchDate !== this.props.dispatchDate) {
      const localDispatchDate = timezoneManager.convertTimestampToString(new Date(), { tz: timezoneManager.getUserTimezone(), format: DISPATCH_DATE_FORMAT });
      this.setState({ dispatchDate: typeof this.props.dispatchDate === 'string' ? this.props.dispatchDate : localDispatchDate });
    }

    if (prevProps.serviceAreaId !== this.props.serviceAreaId) {
      this.setState({ serviceAreaId: typeof this.props.serviceAreaId === 'string' ? this.props.serviceAreaId : undefined });
      if (typeof this.props.serviceAreaId === 'string') {
        await this.loadServiceAreaSnapshot(this.props.serviceAreaId);
      }
    }
  }

  private async loadServiceAreaSummaries() {
    try {
      this.setState({ isLoadingServiceAreas: true });
      this.serviceAreaSummaries = await ufraaVizClient.getServiceAreaSummaries();
    } catch (err: any) {
      this.context.addNotification({
        type: 'error',
        header: 'Failed to load service area summaries',
        content: err.message,
      });
    }
    this.setState({ isLoadingServiceAreas: false });
  }

  private async loadServiceAreaSnapshot(serviceAreaId: string) {
    try {
      this.setState({
        isLoadingServiceAreaSnapshot: true,
        serviceAreaSnapshot: undefined,
        progresses: undefined,
        cycleId: undefined,
      });
      const artifact = await ufraaVizClient.getLatestArtifact<ServiceAreaSnapshot>({
        scope: serviceAreaId,
        artifactType: 'SNAPSHOT_SERVICE_AREA',
      });
      this.setState({ serviceAreaSnapshot: artifact.artifact });
    } catch (err: any) {
      this.context.addNotification({
        type: 'error',
        header: 'Failed to load service area snapshot',
        content: err.message,
      });
    }
    this.setState({ isLoadingServiceAreaSnapshot: false });
  }

  private async downloadAmzlRouteStagingItems(serviceAreaId: string, cycleId: string, dispatchDate: string, timezone: string, filename: string) {
    try {
      this.setState({ isLoadingStagingItems: true, progresses: undefined });
      const resp = await ufraaVizClient.listAmzlRouteStagingItems({
        serviceAreaId: serviceAreaId,
        cycleId: cycleId,
        dispatchDate: dispatchDate,
      });

      if (resp.routeStagingItems.length > 0) {
        downloadSCCInput(resp.routeStagingItems, timezone, filename);
      }

      this.setState({ progresses: resp.progressStatuses });
    } catch (err: any) {
      this.context.addNotification({
        type: 'error',
        header: 'Failed to load routing staging response',
        content: err.message,
      });
    }
    this.setState({ isLoadingStagingItems: false });
  }

  render() {
    return (
      <SpaceBetween direction="vertical" size="m">
        {this.renderUserInputs()}
        {typeof this.state.serviceAreaId === 'string' ? this.renderStagingRoutesDownload() : null}
      </SpaceBetween>
    );
  }

  private renderUserInputs() {
    const options: ReadonlyArray<{ readonly value: string } & SelectProps.Option> | undefined = this.state.filteredServiceAreas?.map((serviceArea) => this.convertServiceAreaToOption(serviceArea));

    let selectedOption = options?.find((o) => o.value === this.state.serviceAreaId);

    if (selectedOption === undefined) {
      const serviceArea = this.serviceAreaSummaries?.find((sa) => sa.serviceAreaId === this.state.serviceAreaId);
      if (serviceArea !== undefined) {
        selectedOption = this.convertServiceAreaToOption(serviceArea);
      }
    }

    return (
      <Container header={this.renderHeader()}>
        <SpaceBetween direction="vertical" size="s">
          <FormField label="Dispatch date">
            <DatePicker
              placeholder="YYYY/MM/DD"
              value={this.state.dispatchDate}
              onChange={({ detail }) => {
                this.props.onDispatchDateChange(detail.value);
              }}
              openCalendarAriaLabel={(selectedDate) => 'Choose dispatch date'}
              nextMonthAriaLabel="Next month"
              todayAriaLabel="Today"
              previousMonthAriaLabel="Previous month"
            />
          </FormField>
          <FormField label="Service area">
            <Select
              options={options ?? []}
              placeholder="Choose a service area"
              filteringType={'manual'}
              filteringPlaceholder={'Enter 2 letters to start search'}
              onLoadItems={(evt) => {
                this.setState({
                  filteredServiceAreas: this.filterServiceAreas(evt.detail.filteringText),
                });
              }}
              statusType={this.state.isLoadingServiceAreas ? 'loading' : 'finished'}
              selectedOption={selectedOption ?? null}
              onChange={(evt) => {
                // if service area changed, we should also reset the selected cycle
                this.setState({ cycleId: undefined });
                this.props.onServiceAreaIdChange(evt.detail.selectedOption.value as string);
              }}
            />
          </FormField>
        </SpaceBetween>
      </Container>
    );
  }

  private renderHeader() {
    return (
      <Header variant="h2" description="Download AMZL Staging Routes SCC CSV input">
        AMZL Staging Routes
      </Header>
    );
  }

  private renderStagingRoutesDownload() {
    return (
      <Container
        header={
          <Header variant="h2" description="Download SCC Input for selected cycle">
            SCC Input
          </Header>
        }
      >
        {this.renderStagingRoutesContent()}
      </Container>
    );
  }

  private renderStagingRoutesContent() {
    if (this.state.serviceAreaSnapshot === undefined) {
      return (
        <Box textAlign="center">
          <StatusIndicator type="loading">Loading ...</StatusIndicator>
        </Box>
      );
    } else {
      /**
       * Cycle is a loosely defined concept. LmCycleAuthorityService (CAS) provides the definition of a cycle, which only includes a name and an Id (UUID).
       * There is no authority defines the cycle time window, station associates follow their de facto ops clock for each cycle. For example, CYCLE_1 comes
       * before CYCLE_2, comes before CYCLE_3. Here, we sort cycle by cycle name to fix the displaying order.
       */
      const options: ReadonlyArray<{ readonly value: string } & SelectProps.Option> | undefined = this.state.serviceAreaSnapshot.cycles
        ?.map((cycle) => this.convertCycleToOption(cycle))
        .sort((c1, c2) => c1.label.localeCompare(c2.label));

      const selectedOption = options?.find((o) => o.value === this.state.cycleId);

      return (
        <SpaceBetween direction="vertical" size="s">
          <FormField label="Cycle" secondaryControl={this.renderDownloadButton()}>
            <Select
              filteringPlaceholder="Select a cycle"
              options={options ?? []}
              placeholder="Choose a cycle"
              selectedOption={selectedOption ?? null}
              onChange={(evt) => {
                this.setState({ cycleId: evt.detail.selectedOption.value! });
              }}
            />
          </FormField>
          {this.renderProgresses()}
        </SpaceBetween>
      );
    }
  }

  private renderDownloadButton() {
    return (
      <Button
        variant="primary"
        disabled={this.state.cycleId === undefined || this.state.isLoadingStagingItems}
        onClick={async () => {
          const cycleId = this.state.cycleId;
          const serviceAreaId = this.state.serviceAreaSnapshot?.serviceAreaId;
          const timezone = this.state.serviceAreaSnapshot?.timezone;
          const dispatchDate = this.state.dispatchDate;
          if (typeof cycleId === 'string' && typeof serviceAreaId === 'string' && typeof dispatchDate === 'string' && typeof timezone === 'string') {
            const cycleName = this.state.serviceAreaSnapshot?.cycles?.find((cycle) => cycle.cycleId === cycleId)?.cycleName;
            const filename = `${this.state.serviceAreaSnapshot?.defaultStationCode}-${cycleName}`;
            await this.downloadAmzlRouteStagingItems(serviceAreaId, cycleId, dispatchDate, timezone, filename);
          }
        }}
      >
        Download
      </Button>
    );
  }

  private renderProgresses() {
    if (this.state.isLoadingStagingItems) {
      return (
        <Box textAlign="center">
          <StatusIndicator type="loading">Loading ...</StatusIndicator>
        </Box>
      );
    } else if (this.state.progresses !== undefined && this.state.progresses.length === 0) {
      return (
        <FormField>
          <Alert type="info">No route available in the selected cycle.</Alert>
        </FormField>
      );
    } else {
      return (
        <SpaceBetween direction="vertical" size="s">
          {this.state.progresses?.map((progress, index) => {
            return (
              <FormField>
                <ProgressAlert progress={progress} key={index} itemIdsHeader="Route Ids" />
              </FormField>
            );
          })}
        </SpaceBetween>
      );
    }
  }

  private filterServiceAreas(filteringText: string): ReadonlyArray<ServiceAreaSummary> | undefined {
    filteringText = filteringText.trim().toLowerCase();
    if (filteringText.length > 1 && this.serviceAreaSummaries != undefined) {
      return this.serviceAreaSummaries.filter((sa) => sa.stationCode.toLowerCase().includes(filteringText));
    }
    return undefined;
  }

  private convertServiceAreaToOption(serviceArea: ServiceAreaSummary) {
    return {
      value: serviceArea.serviceAreaId,
      label: serviceArea.stationCode,
      description: `${serviceArea.stationCode} - ${serviceArea.serviceAreaName} - ${serviceArea.serviceAreaId}`,
    };
  }

  private convertCycleToOption(cycle: Cycle) {
    return {
      value: cycle.cycleId,
      label: cycle.cycleName,
      description: `${cycle.cycleName} - ${cycle.cycleId}`,
    };
  }
}
