import React from 'react';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Tabs from '@amzn/awsui-components-react/polaris/tabs';
import Button from '@amzn/awsui-components-react/polaris/button';
import { GlobalContext } from '../../main-app/global-context';
import Markdown from '../../shared-components/markdown';
import PermissionTableMarkdownText from './permission-table.md';
import DemoImage from '../../shared-components/demo-image';

type ActiveTabId = 'changelog' | 'permission';

interface WelcomePageState {
  readonly activeTabId: ActiveTabId;
}

export class WelcomePage extends React.Component<{}, WelcomePageState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: {}) {
    super(props);
    // todo: we should move it back to changelog as the default thing to show in the welcome page.
    // as for now, let's just show the permission tab by default to explicitly tell users where to request permissions.
    this.state = { activeTabId: 'changelog' };
  }

  componentDidMount() {
    this.context.resetLayout();
  }

  private renderUserGuideLink() {
    return (
      <Link external externalIconAriaLabel="Opens in a new tab" href="https://quip-amazon.com/msUrACCFvGEp/UFRAA-Visualization-User-Guide">
        user guide
      </Link>
    );
  }

  private renderContactLink() {
    return (
      <Link external externalIconAriaLabel="Opens in a new tab" href="https://oncall.corp.amazon.com/#/view/flex-assignment/schedule">
        flex assignment team
      </Link>
    );
  }

  private renderMailListLink() {
    return (
      <Link external externalIconAriaLabel="Opens in a new tab" href="https://email-list.corp.amazon.com/email-list/expand-list/ufraaviz-interest">
        ufraaviz-interest
      </Link>
    );
  }

  render() {
    return (
      <SpaceBetween direction="vertical" size="l">
        <Container header={<Header variant="h1">Welcome</Header>}>
          <SpaceBetween direction="vertical" size="m">
            <Box>To find out how to navigate the visualization tool, please check out our {this.renderUserGuideLink()}.</Box>
            <Box>For questions or issues please contact the {this.renderContactLink()}.</Box>
            <Box>Subscribe {this.renderMailListLink()} to follow the latest news and updates.</Box>
          </SpaceBetween>
        </Container>

        {this.renderTabs()}
      </SpaceBetween>
    );
  }

  private renderTabs() {
    return (
      <Tabs
        tabs={[
          {
            id: 'changelog',
            label: 'Changelog',
            content: this.renderChangelog(),
          },
          {
            id: 'permission',
            label: 'Request permissions',
            content: this.renderRequestPermissions(),
          },
        ]}
        onChange={(evt) => {
          this.setState({ activeTabId: evt.detail.activeTabId as ActiveTabId });
        }}
        activeTabId={this.state.activeTabId}
      />
    );
  }

  private renderChangelog() {
    return (
      <Container header={<Header variant="h2">Changelog</Header>}>
        <SpaceBetween direction="vertical" size="m">
          {this.renderRouteStorePageCorrelationIdSearchChangeLog()}
          {this.renderNetworkHealthPageChangeLog()}
          {this.renderSupportRouteViewChangeLog()}
          {this.renderArtifactPageChangeLog()}
          {this.renderSupportDriaSnapshotChangeLog()}
          {this.renderAssignmentStatusChangeLog()}
        </SpaceBetween>
      </Container>
    );
  }

  private renderRequestPermissions() {
    return (
      <Container header={<Header variant="h2">Request Permissions</Header>}>
        <SpaceBetween direction="vertical" size="m">
          <Box>For individuals, use the SIM links below to request access to UFRAAViz resources. Permission requests are reviewed weekly.</Box>
          <Markdown markdownText={PermissionTableMarkdownText} />
          <Box>For teams or orgs, contact {this.renderContactLink()} to open a discussion.</Box>
        </SpaceBetween>
      </Container>
    );
  }

  private renderRouteStorePageCorrelationIdSearchChangeLog() {
    return (
      <Box>
        <Header>2023-12-08</Header>
        <Box>
          Effective 2023-12-08, ufraaviz <Link href="/route">route store page</Link> supports querying routes by correlationId and displaying system's aws account.
        </Box>
        <DemoImage imageAlt="Route store correlationId demo" imageLink="demo-assets/route-store-correlation-id-feature-intro.gif" />
      </Box>
    );
  }

  private renderNetworkHealthPageChangeLog() {
    return (
      <Box>
        <Header>2023-03-23</Header>
        <Box>
          Effective 2023-03-23, ufraaviz <Link href="/network-health">network health page</Link> is available. This page reports planning metrics collected at network and service area level. Features
          include live mode, exporting the visible table to CSV/JSON, a time range selector with a shareable URL, and selectable preferences such as columns and rows to display. Additional information
          can be found in the side info tab within the network health page.
        </Box>
      </Box>
    );
  }

  private renderSupportRouteViewChangeLog() {
    return (
      <Box>
        <Header>2023-03-17</Header>
        <Box>
          Effective 2023-03-17, ufraaviz <Link href="/route">route store visualization</Link> is generally available. The route page provides route search function and displays route's lifecycle,
          which can provide additional insights for routing, assignment and execution deep dives.
        </Box>
      </Box>
    );
  }

  private renderArtifactPageChangeLog() {
    return (
      <Box>
        <Header>2023-01-24</Header>
        <Box>
          Effective 2023-01-24, ufraaviz <Link href="/artifacts">artifact page</Link> is available. This page allows users to download artifacts, such as routing snapshots/plans, assignment plans,
          DRIA snapshots, etc., by providing the artifact Id, as well as redirecting users to supported renders of these artifacts.
        </Box>
      </Box>
    );
  }

  private renderSupportDriaSnapshotChangeLog() {
    return (
      <Box>
        <Header>2022-10-18</Header>
        <Box>
          Effective 2022-10-18, ufraaviz supported downloading additional DRIA snapshots. Users can also customize the visible artifact types by clicking the settings icon{' '}
          <Button iconName="settings" variant="inline-icon" /> located at the right of the service area summary.
        </Box>
      </Box>
    );
  }

  private renderAssignmentStatusChangeLog() {
    return (
      <Box>
        <Header>2022-08-31</Header>
        <Box>Effective 2022-08-31, we renamed two assignment statuses to give better intuition.</Box>
        <Box>
          <Box variant="span" fontWeight="bold">
            NoAssignment
          </Box>{' '}
          has been renamed to{' '}
          <Box variant="span" fontWeight="bold">
            Waiting
          </Box>{' '}
          to indicate the driver is still available and is waiting for assignment.
        </Box>
        <Box>
          <Box variant="span" fontWeight="bold">
            ToBeRelease
          </Box>{' '}
          has been renamed to{' '}
          <Box variant="span" fontWeight="bold">
            Discharged
          </Box>{' '}
          to indicate the driver is discharged, possibly due to no available route to assign.
        </Box>
      </Box>
    );
  }
}
