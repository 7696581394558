import { FlexRouteAssignmentPlannerInput, ServiceArea } from '../../../clients';

export function assertAssignmentPlannerInput(input: FlexRouteAssignmentPlannerInput): asserts input is FlexRouteAssignmentPlannerInput {
  _assert(input.serviceArea !== undefined, 'Invalid plannerInput.serviceArea');
  assertServiceArea(input.serviceArea);
}

function assertServiceArea(serviceArea: ServiceArea): asserts serviceArea is ServiceArea {
  _assert(typeof serviceArea.serviceAreaId === 'string', 'Invalid serviceArea.serviceAreaId');
  _assert(typeof serviceArea.defaultStationCode === 'string', 'Invalid serviceArea.defaultStationCode');
  _assert(typeof serviceArea.serviceAreaName === 'string', 'Invalid serviceArea.serviceAreaName');
  _assert(typeof serviceArea.timeZoneId === 'string', 'Invalid serviceArea.timeZoneId');
}

function _assert(valid: boolean, message: string) {
  if (!valid) {
    throw new Error(message);
  }
}
