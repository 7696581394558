import React, { useContext } from 'react';
import { useLocalizationContext } from '@amzn/react-arb-tools';
import { LocalizationContextBuilder } from '@amzn/arb-tools';
import { DEFAULT_LOCALE_KEY } from '../constants';
import ButtonDropdown, { ButtonDropdownProps } from '@amzn/awsui-components-react/polaris/button-dropdown';
import { GlobalContext, Locale } from './global-context';

const localeToLangauge: Record<Locale, string> = {
  'en-US': 'English',
  'ja-JP': '日本語',
};

const items: ReadonlyArray<ButtonDropdownProps.ItemOrGroup> = Object.keys(localeToLangauge).map((key) => {
  return {
    text: localeToLangauge[key as Locale],
    id: key,
  };
});

// React hooks cannot be defined within React classes
export const LanguagePicker = () => {
  const context = useContext(GlobalContext);
  const { setLocalizationContext } = useLocalizationContext();
  const localizationContextBuilder = new LocalizationContextBuilder().withDefaultLocale(DEFAULT_LOCALE_KEY);

  const handleChange = (evt: CustomEvent<{ id: string }>) => {
    const selectedLocale = evt.detail.id;
    context.updateLocale(selectedLocale as Locale);
    setLocalizationContext(localizationContextBuilder.withLocale(selectedLocale).build());
  };

  return (
    <ButtonDropdown items={items} onItemClick={handleChange} variant="normal">
      {localeToLangauge[context.locale as Locale]}
    </ButtonDropdown>
  );
};
