import * as React from 'react';
import moment from 'moment';
import lodash from 'lodash';

import Table, { TableProps } from '@amzn/awsui-components-react/polaris/table';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';

import TimeDisplay from '../../shared-components/time-display';
import { ExecutionPlanInformation, TrInformation, ActionInformation, UnplannedActionInformation } from './models';
import { timezoneManager, SHORT_FORMAT } from '../../utilities';
import { buildActionStatusString, displayTruncatedId, SelectionType, NOT_AVAILABLE } from './utilities';

interface ConstituentTrInfo {
  trId: string;
  lockStatus: string;
}

export interface TrTableItem {
  snapshotTime: number;

  orderId: string;
  orderTrs: ConstituentTrInfo[];

  routeId: string;
  routeDuration: number;
  routeOrders: string[];
  routeLockStatus: string;

  driverType: string;
  driverId: string; // just from lmdp not useful, should get from assignment (TODO)

  actions?: (ActionInformation | UnplannedActionInformation)[];
}

function nullRow(timestamp: number) {
  return {
    snapshotTime: timestamp,
    orderId: NOT_AVAILABLE,
    orderTrs: [],
    routeId: NOT_AVAILABLE,
    routeDuration: -99,
    routeOrders: [],
    routeLockStatus: NOT_AVAILABLE,
    driverType: NOT_AVAILABLE,
    driverId: NOT_AVAILABLE,
    actions: [],
  };
}

interface Props {
  rawInfo: ExecutionPlanInformation[];
  lookupId?: string;
  idType?: SelectionType;
}

interface State {}

export default class TrViewTable extends React.Component<Props, State> {
  items: TrTableItem[] = [];

  constructor(props: Props) {
    super(props);

    if (props.rawInfo.length >= 0 && props.lookupId !== undefined && props.idType !== undefined) {
      this.items = this.buildTableInfo(props.rawInfo, props.lookupId, props.idType);
    }
  }

  private buildTableInfo(rawInfo: ExecutionPlanInformation[], lookupId: string, idType: string): TrTableItem[] {
    return lodash
      .sortBy(rawInfo, (plan) => plan.timestamp)
      .map((plan: ExecutionPlanInformation) => {
        let trInfo: TrInformation | undefined;
        let orderId: string | undefined;

        if (idType === 'TR') {
          trInfo = plan.trs.get(lookupId);
          orderId = trInfo?.orderId;
        } else if (idType === 'ORDER') {
          orderId = lookupId;
        }

        if (orderId === undefined) {
          return nullRow(plan.timestamp);
        }

        const orderInfo = plan.orders.get(orderId);
        if (orderInfo === undefined) {
          return nullRow(plan.timestamp);
        }

        // const orderTrs = Array.from(orderInfo?.constituentTrs);
        const orderTrs = (Array.from(orderInfo?.constituentTrs) ?? []).map((trId) => {
          const actionSet = plan.trs.get(trId)?.relatedActions ?? new Set();
          const actionInfo = Array.from(actionSet).map((actionId) => plan.actions.get(actionId));
          return {
            trId: trId,
            lockStatus: buildActionStatusString(actionInfo),
          };
        });

        const routeId = orderInfo?.routeId ?? 'N/A';
        const route = plan.routes.get(routeId);

        const driverType = route?.driverType ?? 'N/A';
        const driverId = route?.deliveryAssociate ?? 'no driver';
        const routeDuration = route?.duration ?? 0;
        const routeOrders = Array.from(route?.constituentOrders ?? []);
        const routeLockStatus = route?.lockStatus ?? 'UNKNOWN';

        let trActions: undefined | (ActionInformation | UnplannedActionInformation)[];
        if (trInfo !== undefined) {
          trActions = new Array();
          Array.from(trInfo.relatedActions).forEach((actionId) => {
            const action = plan.actions.get(actionId);
            if (action !== undefined) {
              trActions?.push(action);
            }
          });
        }

        return {
          actions: trActions,
          snapshotTime: plan.timestamp,
          orderId,
          orderTrs,
          routeDuration,
          routeId,
          routeLockStatus,
          routeOrders,
          driverType,
          driverId,
        };
      })
      .sort((row) => row.snapshotTime);
  }

  render() {
    const columns: TableProps.ColumnDefinition<TrTableItem>[] = [];

    columns.push({
      id: 'time',
      header: 'Time',
      cell: (row) => <TimeDisplay time={row.snapshotTime} format={SHORT_FORMAT} />,
    });

    if (this.props.idType === 'TR') {
      columns.push({
        id: 'order',
        header: 'Order Id',
        cell: (row) => <Box>{displayTruncatedId(row.orderId)}</Box>,
      });
    } else if (this.props.idType === 'ORDER') {
      columns.push({
        id: 'trs',
        header: 'Related TRs',
        cell: (row) => (
          <SpaceBetween direction="vertical" size="xxxs">
            {row.orderTrs.map((trInfo) => {
              return <Box key={trInfo.trId} variant="code">{`${trInfo.trId} ${trInfo.lockStatus}`}</Box>;
            })}
          </SpaceBetween>
        ),
      });
    }

    columns.push({
      id: 'route',
      header: 'Route ID & Duration',
      cell: (row) => {
        const minutes = moment.duration(row.routeDuration).asMinutes();
        const roundedMinutes = Math.round(minutes * 10) / 10;

        return (
          <SpaceBetween direction="vertical" size="xxs">
            <Box>{displayTruncatedId(row.routeId)}</Box>
            <SpaceBetween direction="horizontal" size="xxs">
              <Popover content={row.routeOrders.join('\n')} triggerType="text" dismissButton={false}>
                {`${row.routeOrders.length} orders`}
              </Popover>
              <Box>{`in ${roundedMinutes} mins`}</Box>
            </SpaceBetween>
          </SpaceBetween>
        );
      },
    });

    columns.push({
      id: 'driver',
      header: 'Driver Info',
      cell: (row) => (
        <SpaceBetween direction="vertical" size="xxs">
          <Box>{displayTruncatedId(row.driverType)}</Box>
          <Box>{displayTruncatedId(row.driverId)}</Box>
        </SpaceBetween>
      ),
    });

    if (this.props.idType === 'TR') {
      columns.push({
        id: 'action',
        header: 'Scheduled Actions',
        cell: (row) => {
          if (row.actions === undefined) {
            return NOT_AVAILABLE;
          }

          const twoCols = [{ colspan: { default: 5 } }, { colspan: { default: 7 } }];
          let subrows = new Array();
          row.actions.forEach((action) => {
            // this statement gets unplanned orders
            if ('category' in action) {
              subrows.push(<Box>{action.category}</Box>);
              return;
            }

            // rest are planned
            const actionName = action.actionType;
            const actionTime = timezoneManager.convertTimestampToString(action.scheduledActionTime, { format: 'HH:mm:ss' });

            subrows.push(
              <Grid key={actionName.concat(actionTime)} gridDefinition={twoCols}>
                <Box key={'action'} textAlign="right">
                  {actionName.toLocaleLowerCase()}
                </Box>
                <Box key={'time'} textAlign="left">
                  {actionTime}
                </Box>
              </Grid>,
            );
          });

          return (
            <SpaceBetween direction="vertical" size="xxs">
              {' '}
              {subrows}{' '}
            </SpaceBetween>
          );
        },
      });
    }

    if (this.props.idType === 'TR') {
      columns.push({
        id: 'lockstatus',
        header: 'Locking',
        cell: (row) => buildActionStatusString(row.actions ?? []),
      });
    }

    return (
      <Table
        columnDefinitions={columns}
        items={this.items ?? []}
        loadingText="Parsing execution plans..."
        empty={<StatusIndicator type="in-progress">{'No plans downloaded; please select a TR above.'}</StatusIndicator>}
        header={<Header description={this.props.lookupId}>{'Timeline'}</Header>}
      />
    );
  }
}
