import React from 'react';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import ButtonDropdown from '@amzn/awsui-components-react/polaris/button-dropdown';
import { GlobalContext } from '../../main-app/global-context';
import ufraaVizClient, { FlexRouteAssignmentPlannerInput, FlexRouteAssignmentPlan, ServiceAreaDetails, ArtifactType } from '../../clients';
import AssignmentPlanSummary from './assignment-plan-summary';
import AssignmentPlanDetails from './assignment-plan-details';
import { downloadJsonData } from '../../utilities';
import { buildAssignmentPlanUrl, buildURLSearchParamsFromLmRouteUserSearch } from '../../main-app/url-utils';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';

interface Props extends WithBundleProps {
  readonly plan?: FlexRouteAssignmentPlan | null;
  readonly plannerInput?: FlexRouteAssignmentPlannerInput | null;
  readonly serviceAreaDetails?: ServiceAreaDetails | null;
  readonly mode: 'live' | 'static' | 'drag-and-drop';

  readonly activeTab?: string;
  readonly onTabChange: (tab: string) => void;
  readonly filteringText?: string;
  readonly onFilteringTextChange: (text: string) => void;
  readonly isShadow?: boolean;
}

interface State {
  readonly previousPlannerInputId?: string;
  readonly nextPlannerInputId?: string;
  readonly inputArtifact: ArtifactType;
  readonly prefixHeader: string;
  readonly prefixLink: string;
}

class AssignmentPlanContent extends React.Component<Props, State> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      previousPlannerInputId: undefined,
      nextPlannerInputId: undefined,
      inputArtifact: this.props.isShadow ? 'SHADOW_FLEX_ROUTE_ASSIGNMENT_PLANNER_INPUT' : 'FLEX_ROUTE_ASSIGNMENT_PLANNER_INPUT',
      prefixHeader: this.props.isShadow ? 'Shadow ' : '',
      prefixLink: this.props.isShadow ? 'shadow-' : '',
    };
  }

  async componentDidUpdate(prevProps: Props, prevState: State) {
    const previousPlannerInputId = prevProps.plannerInput ? prevProps.plannerInput.plannerInputId : undefined;
    const currentPlannerInputId = this.props.plannerInput ? this.props.plannerInput.plannerInputId : undefined;

    if (this.props.mode === 'static' && previousPlannerInputId !== currentPlannerInputId) {
      await this.resetPreviousAndNextPlannerInputIds();
    }
  }

  async resetPreviousAndNextPlannerInputIds() {
    if (this.props.plannerInput) {
      const serviceAreaId = this.props.plannerInput.serviceArea.serviceAreaId;
      const plannerInputGenerationTime = new Date(this.props.plannerInput.serviceArea.lastUpdated).getTime();

      // find the previous and next planner input ids, assume they are generated within 30 minutes radius of the current planner input generation time.
      const plannertInputMetadataList = await ufraaVizClient.listArtifactMetadata({
        scope: serviceAreaId,
        artifactType: this.state.inputArtifact,
        startTime: plannerInputGenerationTime - 30 * 60_000,
        endTime: plannerInputGenerationTime + 30 * 60_000,
        timeUnits: 'MILLISECONDS',
      });

      const index = plannertInputMetadataList.findIndex((metadata) => metadata.artifactId === this.props.plannerInput?.plannerInputId);
      if (index !== -1) {
        const previousPlannerInputId = index > 0 ? plannertInputMetadataList[index - 1].artifactId : undefined;
        const nextPlannerInputId = index + 1 < plannertInputMetadataList.length ? plannertInputMetadataList[index + 1].artifactId : undefined;
        this.setState({
          previousPlannerInputId,
          nextPlannerInputId,
        });
      }
    }
  }

  render() {
    return (
      <SpaceBetween direction="vertical" size="m">
        {this.renderHeader()}
        <AssignmentPlanSummary serviceAreaDetails={this.props.serviceAreaDetails} plan={this.props.plan} plannerInput={this.props.plannerInput} />
        <AssignmentPlanDetails
          plan={this.props.plan}
          plannerInput={this.props.plannerInput}
          activeTab={this.props.activeTab}
          onFilteringTextChange={this.props.onFilteringTextChange}
          onTabChange={this.props.onTabChange}
          filteringText={this.props.filteringText}
          onRouteClick={(routeId, version) => {
            const query = buildURLSearchParamsFromLmRouteUserSearch({
              type: 'routeId',
              text: routeId,
              version: version.toString(),
            });

            const queryString = new URLSearchParams(query).toString();
            this.context.onGoto(`/route?${queryString}`);
          }}
        />
      </SpaceBetween>
    );
  }

  private renderHeader() {
    return (
      <Header
        variant="h1"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            {this.renderHeaderActions()}
          </SpaceBetween>
        }
      >
        {this.state.prefixHeader}
        {this.props.bundle.getMessage('ASSIGNMENT_PLAN_CONTENT:PAGE_HEADER')}
      </Header>
    );
  }

  private renderHeaderActions() {
    switch (this.props.mode) {
      case 'live':
        return this.renderLiveModeHeaderActions();
      case 'static':
        return this.renderStaticModeHeaderActions();
      default:
        return null;
    }
  }

  private renderLiveModeHeaderActions() {
    return (
      <React.Fragment>
        {this.renderDownloadPlannerInputBtn()}
        {this.renderDownloadPlanBtn()}
        {this.renderOpenPlanInANewTabBtn()}
      </React.Fragment>
    );
  }

  private renderStaticModeHeaderActions() {
    return (
      <React.Fragment>
        {this.renderDownloadPlannerInputBtn()}
        {this.renderDownloadPlanBtn()}
        {this.renderPreviousPlanBtn()}
        {this.renderNextPlanBtn()}
        {this.renderMoreBtn()}
      </React.Fragment>
    );
  }

  private renderDownloadPlannerInputBtn() {
    return (
      <Button disabled={!this.props.plannerInput} onClick={() => downloadJsonData(this.props.plannerInput, `${this.props.plannerInput?.plannerInputId}.json`)}>
        {this.props.bundle.getMessage('ASSIGNMENT_PLAN_CONTENT:DOWNLOAD_PLANNER_INPUT')}
      </Button>
    );
  }

  private renderDownloadPlanBtn() {
    return (
      <Button disabled={!this.props.plan} onClick={() => downloadJsonData(this.props.plan, `${this.props.plan?.planId}.json`)}>
        {this.props.bundle.getMessage('ASSIGNMENT_PLAN_CONTENT:DOWNLOAD_PLAN')}
      </Button>
    );
  }

  private renderPreviousPlanBtn() {
    const previousPlannerInputId = this.state.previousPlannerInputId;
    const link = buildAssignmentPlanUrl(previousPlannerInputId ?? '', this.props.activeTab, this.props.filteringText, undefined, this.state.prefixLink);
    return (
      <Button
        disabled={!previousPlannerInputId}
        href={link}
        onClick={(evt) => {
          evt.preventDefault();
          this.context.onGoto(link);
          this.setState({
            previousPlannerInputId: undefined,
            nextPlannerInputId: undefined,
          });
        }}
      >
        {this.props.bundle.getMessage('ASSIGNMENT_PLAN_CONTENT:PREVIOUS_PLAN')}
      </Button>
    );
  }

  private renderNextPlanBtn() {
    const nextPlannerInputId = this.state.nextPlannerInputId;
    const link = buildAssignmentPlanUrl(nextPlannerInputId ?? '', this.props.activeTab, this.props.filteringText, undefined, this.state.prefixLink);
    return (
      <Button
        disabled={!nextPlannerInputId}
        href={link}
        onClick={(evt) => {
          evt.preventDefault();
          this.context.onGoto(link);
          this.setState({
            previousPlannerInputId: undefined,
            nextPlannerInputId: undefined,
          });
        }}
      >
        {this.props.bundle.getMessage('ASSIGNMENT_PLAN_CONTENT:NEXT_PLAN')}
      </Button>
    );
  }

  private renderOpenPlanInANewTabBtn() {
    const plannerInputId = this.props.plannerInput?.plannerInputId;
    return (
      <Button
        disabled={!this.props.plannerInput}
        target="_blank"
        href={`/${this.state.prefixLink}assignment-plan/${encodeURIComponent(plannerInputId ? plannerInputId : '')}`}
        iconAlign="right"
        iconName="external"
      >
        {this.props.bundle.getMessage('ASSIGNMENT_PLAN_CONTENT:NEW_TAB')}
      </Button>
    );
  }

  private renderMoreBtn() {
    let serviceAreaId = this.props.serviceAreaDetails?.serviceAreaId;
    serviceAreaId = serviceAreaId ? serviceAreaId : this.props.plannerInput?.serviceArea.serviceAreaId;
    const liveLink = `/${this.state.prefixLink}assignment-plan/live?serviceAreaId=${serviceAreaId}`;

    return (
      <ButtonDropdown
        items={[
          { text: this.props.bundle.getMessage('ASSIGNMENT_PLAN_CONTENT:LIVE'), id: 'live', disabled: !serviceAreaId, href: liveLink },
          { text: this.props.bundle.getMessage('ASSIGNMENT_PLAN_CONTENT:DRAG_AND_DROP'), id: 'drag-and-drop', href: '/assignment-plan/drag-and-drop' },
        ]}
        onItemClick={(evt) => {
          evt.preventDefault();
          switch (evt.detail.id) {
            case 'live':
              this.context.onGoto(liveLink);
              break;
            case 'drag-and-drop':
              this.context.onGoto(`/assignment-plan/drag-and-drop`);
              break;
          }
        }}
      >
        {this.props.bundle.getMessage('ASSIGNMENT_PLAN_CONTENT:MORE')}
      </ButtonDropdown>
    );
  }
}

export default withBundle('AssignmentPlanContent')(AssignmentPlanContent);
