import React from 'react';
import CopyText from '../shared-components/copy-text';

export class RenderTextHelper {
  renderNullableText(text: string | null | undefined, default_: string): string {
    if (text) {
      return text;
    }
    return default_;
  }

  renderCopyableText(text: string | null | undefined, default_: string) {
    if (text) {
      return <CopyText copyText={text} />;
    }
    return default_;
  }
}
