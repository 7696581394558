import React from 'react';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Box from '@amzn/awsui-components-react/polaris/box';
import Hotspot from '@amzn/awsui-components-react/polaris/hotspot';
import Button from '@amzn/awsui-components-react/polaris/button';
import CopyText from '../../shared-components/copy-text';
import Link from '@amzn/awsui-components-react/polaris/link';
import { LmRoute } from '../../clients';
import { timezoneManager } from '../../utilities';
import { download } from './utils';
import { deploymentStageToRouteIqDomain, martketplaceToRouteIqRegion } from './constants';
import { config } from '../../utilities';
import { NameValue } from './models';

export interface RouteSummaryProps {
  readonly route: LmRoute;
}

export class RouteSummary extends React.Component<RouteSummaryProps, {}> {
  constructor(props: RouteSummaryProps) {
    super(props);
  }

  componentDidMount() {}

  private header() {
    const description = `${this.props.route.routeId} (version ${this.props.route.version})`;
    return (
      <Header
        variant="h2"
        description={description}
        actions={
          <Hotspot side="left" hotspotId="route-download-btn">
            <Button
              onClick={(evt) => {
                download(this.props.route, `${this.props.route.routeId}-v${this.props.route.version}.json`);
              }}
            >
              Download
            </Button>
          </Hotspot>
        }
      >
        Route
      </Header>
    );
  }

  private renderNameValue(item: NameValue) {
    switch (item.type) {
      case 'copyable':
        return <CopyText copyText={item.value} />;
      case 'redirect':
        return (
          <Link href={item.value} external={true}>
            Link
          </Link>
        );
      default:
        return item.value;
    }
  }

  private content() {
    const nameValues: NameValue[] = [];
    const stationCode = this.props.route.metadata['STATION_CODE'];
    const countryCode = this.props.route.metadata['COUNTRY_CODE'];
    if (stationCode) {
      const value = countryCode ? `${stationCode} (${countryCode})` : stationCode;
      nameValues.push({
        name: 'Station',
        value: value,
      });
    }

    const serviceAreaId = this.props.route.metadata['SERVICE_AREA_ID'];
    if (serviceAreaId) {
      nameValues.push({
        name: 'Service Area Id',
        value: serviceAreaId,
        type: 'copyable',
      });
    }

    const stationTimezone = this.props.route.metadata['STATION_TIMEZONE'];
    if (stationTimezone) {
      nameValues.push({
        name: 'Station Timezone',
        value: stationTimezone,
      });
      timezoneManager.setStationTimezone(stationTimezone);
    }

    const operatingEntity = this.props.route.metadata['OPERATING_ENTITY']; // GSF, SSD, AMZL
    if (operatingEntity) {
      nameValues.push({
        name: 'Operating entity',
        value: operatingEntity,
      });
    }

    const dispatchDate = this.props.route.metadata['DISPATCH_DATE'];
    if (dispatchDate) {
      nameValues.push({
        name: 'Dispatch Date',
        value: dispatchDate,
      });
    }

    const routeName = this.props.route.metadata['ROUTE_NAME']; // SSD route has a route code, e.g. CX4
    if (operatingEntity !== 'GSF') {
      // GSF route name is not useful.
      nameValues.push({
        name: 'Route Name',
        value: routeName ?? '-',
      });
    }
    const correlationId = this.props.route.metadata['CORRELATION_ID']; // client side id, useful during debug
    if (correlationId && operatingEntity !== 'GSF') {
      nameValues.push({
        name: 'CorrelationId',
        value: correlationId,
        type: 'copyable',
      });
    }
    const routeGroup = this.props.route.metadata['ROUTE_GROUP']; // AMZL specific
    if (operatingEntity === 'AMZL') {
      nameValues.push({
        name: 'Route Group',
        value: routeGroup ?? '-',
        type: 'copyable',
      });
    }

    const operations = this.props.route.operations;

    const departureSets = operations?.departureSets;
    if (Array.isArray(departureSets)) {
      nameValues.push({
        name: 'Departure Sets',
        value: departureSets.length.toString(),
      });
    } else {
      nameValues.push({
        name: 'Departure Sets',
        value: 'N/A',
      });
    }

    const assignment = operations?.assignment;
    const groupType = assignment?.transporterGroup?.groupType; //  DSP vs. Flex.
    if (assignment && groupType) {
      const transporterId = operations?.assignment?.transporterId;
      const type = groupType === 'FLEX' ? 'Flex' : groupType;
      const driverInfo = transporterId ? `${type} ${transporterId}` : type;
      nameValues.push({
        name: 'Driver',
        value: driverInfo,
      });
    } else {
      nameValues.push({
        name: 'Driver',
        value: 'N/A',
      });
    }

    if (operatingEntity === 'SSD') {
      nameValues.push({
        name: 'RouteIQ',
        value: `${deploymentStageToRouteIqDomain[config.stage]}/?region=${martketplaceToRouteIqRegion[config.marketplace]}&routes=${this.props.route.routeId}`,
        type: 'redirect',
      });
    }

    return (
      <ColumnLayout columns={4} variant="text-grid">
        {nameValues.map((item, index) => {
          return (
            <Box key={index}>
              <Box margin={{ bottom: 'xxxs' }} color="text-label">
                {item.name}
              </Box>
              <Box margin={{ bottom: 'xxxs' }}>{this.renderNameValue(item)}</Box>
            </Box>
          );
        })}
      </ColumnLayout>
    );
  }

  render() {
    return (
      <Container
        header={
          <Hotspot side="left" hotspotId="route-summary">
            {this.header()}
          </Hotspot>
        }
      >
        {this.content()}
      </Container>
    );
  }
}
