import { ALLOW_LIST_ARTIFACT_TYPES, IMPLEMENTED_ARTIFACT_TYPES_TO_HREF } from './constants';
import { ArtifactSearch, ArtifactSearchType, RawArtifactSearch } from './models';

/**
 * Builds empty artifact search, invoked upon the component constructor.
 * @param searchType desired search type
 * @returns artifact search
 */
export function buildEmptyArtifactSearch(searchType: ArtifactSearchType): ArtifactSearch {
  return {
    searchType: searchType,
    text: '',
  };
}

/**
 * Builds artifact search from raw artifact search
 * @param rawArtifactSearch raw artifact search from query params
 * @returns artifact search
 */
export function buildArtifactSearchFromRawArtifactSearch(rawArtifactSearch: RawArtifactSearch): ArtifactSearch {
  return {
    text: rawArtifactSearch.text,
    artifactType: rawArtifactSearch.artifactType,
    searchType: rawArtifactSearch.searchType,
  };
}

/**
 * Builds description for specific artifacts if getting latest artifact by scope
 * @param artifactSearch current artifact search
 * @returns if searching by scope description; otherwise null
 */
export function getDescriptionFromArtifactSearch(artifactSearch: ArtifactSearch): string | null {
  if (artifactSearch.searchType === 'artifactId') {
    return null;
  }
  switch (artifactSearch.artifactType) {
    case 'SERVICE_AREA_ALLOW_LIST': {
      return `Enter one of the following: ${ALLOW_LIST_ARTIFACT_TYPES.join(', ')}.`;
    }
    case 'DRIA_WORK_CADENCE_CONFIG': {
      return 'DRIA Work Cadence Config does not require scope.';
    }
    default:
      return '';
  }
}

/**
 * Creates href based on artifact search so users can open the supported renders of artifacts
 * @param artifactSearch artifact search
 * @returns href
 */
export function createHref(artifactSearch: ArtifactSearch): string {
  if (artifactSearch.searchType !== 'artifactId') {
    return '';
  }

  const artifactId = artifactSearch.text;
  const artifactType = artifactSearch.artifactType;

  // TODO: add more cases as more artifacts are implemented
  switch (artifactType) {
    case 'FLEX_ROUTE_ASSIGNMENT_PLAN': {
      return `${IMPLEMENTED_ARTIFACT_TYPES_TO_HREF[artifactType]}${encodeURIComponent(artifactId)}ner-input`;
    }
    case 'EXECUTION_SNAPSHOT':
    case 'EXECUTION_PLAN':
    case 'FLEX_ROUTE_ASSIGNMENT_PLANNER_INPUT':
      return `${IMPLEMENTED_ARTIFACT_TYPES_TO_HREF[artifactType]}${encodeURIComponent(artifactId)}`;
    default: {
      // will not reach default case since user must select an option afterwards
      return '';
    }
  }
}

/**
 * Validate user input text before downloading the artifact.
 *
 * @param artifactSearch current artifact search
 * @returns true if valid, otherwise, return the error message
 */
export function validateTextInput(artifactSearch: ArtifactSearch): string | true {
  /**
   * Validate text input. First we validate empty string (invalid unless getting DRIA Work Cadence Config by scope).
   * If not empty string then validate for scope search that SERVICE_AREA_ALLOW_LIST includes the allow list types.
   */
  if (artifactSearch.searchType === 'artifactId') {
    if (artifactSearch.text === '') {
      return 'Artifact Id cannot be empty.';
    }
  } else if (artifactSearch.searchType === 'scope') {
    if (artifactSearch.text === '' && artifactSearch.artifactType !== 'DRIA_WORK_CADENCE_CONFIG') {
      return 'Scope cannot be empty.';
    } else if (artifactSearch.artifactType === 'SERVICE_AREA_ALLOW_LIST' && !ALLOW_LIST_ARTIFACT_TYPES.includes(artifactSearch.text)) {
      return `Scope must be one of the following: ${ALLOW_LIST_ARTIFACT_TYPES.join(', ')}.`;
    }
  }

  return true;
}
