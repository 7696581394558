import React from 'react';
import { useBundle } from '@amzn/react-arb-tools';
import { useHistory } from 'react-router';
import SideNavigation, { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';

export const Navigation = () => {
  const history = useHistory();
  const [bundle, isBundleLoading] = useBundle('SideNavigation');

  if (!isBundleLoading) {
    const navHeader = { text: 'UFRAA Visualization', href: '/' };
    const navItems: SideNavigationProps.Item[] = [
      {
        text: 'UFRAA Visualization',
        type: 'section',
        items: [
          {
            type: 'link',
            text: bundle.getMessage('SIDE_NAVIGATION:SERVICE_AREAS'),
            href: '/service-areas',
          },
          {
            type: 'link',
            text: bundle.getMessage('SIDE_NAVIGATION:ROUTE'),
            href: '/route',
          },
          {
            type: 'link',
            text: bundle.getMessage('SIDE_NAVIGATION:ARTIFACTS'),
            href: '/artifacts',
          },
          {
            type: 'link',
            text: bundle.getMessage('SIDE_NAVIGATION:NETWORK_HEALTH'),
            href: '/network-health',
          },
          {
            type: 'link',
            text: bundle.getMessage('SIDE_NAVIGATION:ORDER_TIMELINE'),
            href: '/order-timeline',
          },
        ],
      },
      {
        text: bundle.getMessage('SIDE_NAVIGATION:OPS_TOOLS'),
        type: 'section',
        items: [
          {
            type: 'link',
            text: bundle.getMessage('SIDE_NAVIGATION:AMZL_STAGING_ROUTES'),
            href: '/amzl-staging-routes',
          },
        ],
      },
      {
        text: bundle.getMessage('SIDE_NAVIGATION:DEV_TOOLS'),
        type: 'section',
        items: [
          {
            type: 'link',
            text: bundle.getMessage('SIDE_NAVIGATION:JSON_TABLE'),
            href: '/json',
          },
          {
            type: 'link',
            text: bundle.getMessage('SIDE_NAVIGATION:SERVICE_AREA_CONVERSION'),
            href: '/service-area-conversion',
          },
          {
            type: 'link',
            text: bundle.getMessage('SIDE_NAVIGATION:ASSIGNMENT_DRAG_AND_DROP'),
            href: '/assignment-plan/drag-and-drop',
          },
        ],
      },
    ];

    return (
      <SideNavigation
        items={navItems}
        header={navHeader}
        activeHref="#/"
        onFollow={(evt) => {
          evt.preventDefault();
          history.push(evt.detail.href);
        }}
      />
    );
  } else {
    return <Spinner />;
  }
};
