import { JsonVisualizationProfile } from './models';
import jsonata from 'jsonata';

interface AssertionResult {
  pass: boolean;
  profileNameErrorMessage?: string;
  keyPathErrorMessage?: string;
  columnIdToNameErrorMessage: {
    [columnId: string]: string;
  };
  columnIdToPathErrorMessage: {
    [columnId: string]: string;
  };
}

export function assertJsonVisualizationProfile(profile: JsonVisualizationProfile): Readonly<AssertionResult> {
  const result: AssertionResult = {
    pass: true,
    columnIdToNameErrorMessage: {},
    columnIdToPathErrorMessage: {},
  };
  if (profile.profileName.trim().length === 0) {
    result.pass = false;
    result.profileNameErrorMessage = `Can't have empty profile name.`;
  }

  if (profile.keyPath.trim().length === 0) {
    result.pass = false;
    result.keyPathErrorMessage = `Can't have empty keyPath.`;
  } else {
    try {
      jsonata(profile.keyPath);
    } catch (err: any) {
      result.pass = false;
      result.keyPathErrorMessage = `Path is not a valid JSONata path.`;
    }
  }

  profile.columnDefinitions.forEach((column) => {
    if (column.columnName.trim().length === 0) {
      result.pass = false;
      result.columnIdToNameErrorMessage[column.columnId] = `Column name can't be empty.`;
    }

    if (column.path.trim().length === 0) {
      result.pass = false;
      result.columnIdToPathErrorMessage[column.columnId] = `Column path can't be empty.`;
    } else {
      try {
        jsonata(column.path);
      } catch (err: any) {
        result.pass = false;
        result.columnIdToPathErrorMessage[column.columnId] = `Column path is not a valid JSONata path.`;
      }
    }
  });

  return result;
}
