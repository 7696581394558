export * from './midway-token-retriever';
export * from './midway-cognito-auth-client-builder';
export * from './timezone-manager';
export * from './unit-conversion-helper';
export * from './formatter';
export * from './clipboard';
export * from './apply-theme';
export * from './download-json-data';
export * from './drop-json-file';
export * from './export-csv-data';

import { WebsiteConfigAccessor } from './website-config-accessor';
import { EnvironmentAccessor } from './environment-accessor';
import { LOCAL_TESTING_DOMAIN, stageConfigurations } from '../constants';
import { StageConfiguration } from '../models';
import { TimezoneManager } from './timezone-manager';
import { PreferencesStore } from './preferences-store';
import { MidwayIdentityCredentialProvider } from '@amzn/midway-identity-credential-provider';
import { UnitConversionHelper } from './unit-conversion-helper';
import { RenderTextHelper } from './render-text-helper';

export const configAccessor = new WebsiteConfigAccessor(stageConfigurations);

export const environment = new EnvironmentAccessor().getEnvironment();

let _config: StageConfiguration;

if (environment.domain === LOCAL_TESTING_DOMAIN) {
  if (environment.identifier === undefined) {
    throw new Error(`Running in development mode, but cannot find stage identifier. Do you forget to setup $${EnvironmentAccessor.IDENTIFIER_ENV_KEY} env variable?`);
  }
  _config = configAccessor.getStageConfigurationById(environment.identifier);
} else {
  _config = configAccessor.getStageConfigurationByDomain(environment.domain);
}

export const credentials = new MidwayIdentityCredentialProvider(
  {
    IdentityPoolId: _config.identityPoolId,
  },
  {
    region: _config.awsRegion,
  },
);

console.log(_config);

export const config = _config;

export const timezoneManager = new TimezoneManager();

export const preferencesStore = new PreferencesStore();

export const unitConversionHelper = new UnitConversionHelper();

export const renderHelper = new RenderTextHelper();
