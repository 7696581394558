import React from 'react';
import { Alert } from '@amzn/awsui-components-react/polaris';
import { GlobalContext } from '../../main-app/global-context';

interface Props {
  readonly path: string;
}

export class NotFoundPage extends React.Component<Props, {}> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  componentDidMount() {
    this.context.resetLayout();
  }

  render() {
    return (
      <div>
        <Alert type="warning" header="404">
          The page "{this.props.path}" can't be found.
        </Alert>
      </div>
    );
  }
}
