import {
  FlexRouteAssignmentPlan,
  FlexRouteAssignmentPlannerInput,
  Transporter,
  Route,
  FdpAssignment,
  UnAssignedTransporterReason,
  UnAssignedRouteReason,
  DispatchPlannerType,
  FdpAssignmentStatus,
  Block,
} from '../../../clients';
import { AssignmentConverter } from './assignment-converter';

// FdpAssignmentConverter for grocery
export class FdpAssignmentConverter implements AssignmentConverter<FdpAssignment> {
  readonly type: DispatchPlannerType;

  constructor() {
    this.type = 'FLEX_DISPATCH_PLANNER';
  }

  private getAssignmentStatus(route: Route): FdpAssignmentStatus {
    if (route.statuses?.includes('TRANSPORTER_WORK_ASSIGNED')) {
      return 'Assigned';
    } else if (route.statuses?.includes('TRANSPORTER_ACCEPTED') || route.statuses?.includes('TRANSPORTER_OFFER_ACCEPTED')) {
      return 'Accepted';
    } else if (route.statuses?.includes('TRANSPORTER_ACCEPT_PENDING') || route.statuses?.includes('TRANSPORTER_OFFER_PENDING')) {
      return 'Offered';
    }
    return 'Open';
  }

  /**
   * Convert IOAssignment to the UI assignment model.
   *
   * The IOAssignment is obtained from plan.instantOfferAssignments
   *
   * @param ioAssignment mapping between IO transporter Id to the route object.
   * @param transporterIdToTransporter
   */
  private convertIOAssignment(ioAssignment: Record<string, Route>, transporterIdToTransporter: Map<string, Transporter>): FdpAssignment[] {
    const transporterIds = Object.keys(ioAssignment);

    const assignments: FdpAssignment[] = [];
    for (let transporterId of transporterIds) {
      const route = ioAssignment[transporterId];
      const rejectedTransporterIds = route.rejectedTransporterIds;
      const transporter = transporterIdToTransporter.get(transporterId);
      assignments.push({
        route: route,
        routeExtras: {},
        transporter: transporter,
        transporterExtras: {
          stemInDurationInSeconds: this.findStemInDuration(route, transporter),
        },
        assignmentStatus: this.getAssignmentStatus(ioAssignment[transporterId]),
        rejectedTransporterIds: rejectedTransporterIds ? Array.from(rejectedTransporterIds) : [],
      });
    }
    return assignments;
  }

  /**
   * Convert FDP block assignment to the UI assignment model.
   * @param blockAssignment FDP block assignment, should contain one a single route.
   * @param transporterIdToTransporter
   */
  private convertBlockAssignment(blockAssignment: Record<string, Route[]>, transporterIdToTransporter: Map<string, Transporter>): FdpAssignment[] {
    const transporterIds = Object.keys(blockAssignment);

    const assignments: FdpAssignment[] = [];
    for (let transporterId of transporterIds) {
      if (blockAssignment[transporterId]?.length === 1) {
        const route = blockAssignment[transporterId][0];
        const rejectedTransporterIds = route.rejectedTransporterIds;
        const transporter = transporterIdToTransporter.get(transporterId);
        assignments.push({
          route: route,
          routeExtras: {},
          transporter: transporter,
          transporterExtras: {
            stemInDurationInSeconds: this.findStemInDuration(route, transporter),
          },
          assignmentStatus: this.getAssignmentStatus(route),
          rejectedTransporterIds: rejectedTransporterIds ? Array.from(rejectedTransporterIds) : [],
        });
      } else {
        throw new Error(`Invalid FDP block assignment on transporter ${transporterId}, it was planned ${blockAssignment[transporterId]?.length} routes but expected one.`);
      }
    }
    return assignments;
  }

  private findStemInDuration(route: Route, transporter?: Transporter): number | undefined {
    if (transporter?.addressIdToStemInDuration) {
      const firstPickupAddressId = route.actions?.find((action) => action.type === 'ADD')?.locationAddressId;
      if (typeof firstPickupAddressId === 'string') {
        return transporter.addressIdToStemInDuration[firstPickupAddressId];
      }
    }
  }
  /**
   * Convert FDP overflow assignment to the UI assignment model
   *
   */
  private convertOverflowAssignments(overflowAssignments: Record<string, Block> | undefined, transporterIdToTransporter: Map<string, Transporter>, routes: Route[]): FdpAssignment[] {
    const assignments: FdpAssignment[] = [];
    if (overflowAssignments) {
      const transporterIds = Object.keys(overflowAssignments);
      const routeIdToRoute: Record<string, Route> = {};
      routes.forEach((route) => (routeIdToRoute[route.routeId] = route));

      for (let transporterId of transporterIds) {
        const block = overflowAssignments[transporterId];
        const route = routeIdToRoute[block.routeId];
        const rejectedTransporterIds = route.rejectedTransporterIds;
        const transporter = transporterIdToTransporter.get(transporterId);
        assignments.push({
          route: route,
          block: block,
          transporter: transporter,
          assignmentStatus: this.getAssignmentStatus(route),
          rejectedTransporterIds: rejectedTransporterIds ? Array.from(rejectedTransporterIds) : [],
        });
      }
    }
    return assignments;
  }

  private convertFilteredOutAssignedRoutes(transporterIdToTransporter: Map<string, Transporter>, routes: Route[]): FdpAssignment[] {
    const assignments: FdpAssignment[] = [];
    for (let route of routes) {
      const rejectedTransporterIds = route.rejectedTransporterIds;
      const transporter = typeof route.assignedTransporter === 'string' ? transporterIdToTransporter.get(route.assignedTransporter) : undefined;
      const assignmentStatus = this.getAssignmentStatus(route);
      assignments.push({
        route: route,
        transporter: transporter,
        assignmentStatus: assignmentStatus,
        rejectedTransporterIds: rejectedTransporterIds ? Array.from(rejectedTransporterIds) : [],
      });
    }

    return assignments;
  }

  /**
   * Convert an array of transporters without assignment information to the UI assignment model along with a reason code.
   * @param transporters an array of transporters who don't have assignment information.
   * @param reason A reason code.
   */
  private convertNoAssignmentTransporters(transporters: Transporter[], reason: UnAssignedTransporterReason): FdpAssignment[] {
    const assignments: FdpAssignment[] = [];
    for (let transporter of transporters) {
      assignments.push({
        transporter: transporter,
        transporterExtras: {},
        transporterUnAssignedReason: reason,
      });
    }
    return assignments;
  }

  /**
   * Convert a route without assignment information to the UI assignment model along with a reason code.
   * @param route the route without assignment information
   * @param reason the reason code.
   * @returns
   */
  private convertNoAssignmentRoute(route: Route, reason: UnAssignedRouteReason): FdpAssignment {
    let assignmentStatus: FdpAssignmentStatus = 'Unknown';
    switch (reason) {
      case 'Infeasible':
        assignmentStatus = 'Infeasible';
        break;
      default:
        assignmentStatus = 'Unknown';
    }
    return {
      route,
      routeExtras: {},
      routeUnAssignedReason: reason,
      assignmentStatus: assignmentStatus,
    };
  }

  convert(plannerInput: FlexRouteAssignmentPlannerInput, plan: FlexRouteAssignmentPlan): FdpAssignment[] {
    let assignments: FdpAssignment[] = [];
    const routes = plannerInput.routes;
    const cycleIdToCyleName: Map<string, string> = new Map();
    // build a transporterId to transporter mapping
    const transporterIdToIOTransporter: Map<string, Transporter> = new Map();
    const transporterIdToBlockTransporter: Map<string, Transporter> = new Map();
    const transporterIdToOverflowTransporter: Map<string, Transporter> = new Map();
    for (let transporter of plannerInput.transporters) {
      const cycleId = transporter.cycle?.cycleId;
      const cycleName = transporter.cycle?.cycleName;
      if (typeof cycleId === 'string' && typeof cycleName === 'string') {
        cycleIdToCyleName.set(cycleId, cycleName);
      }

      if (transporter.type === 'BLOCK') {
        // a transporter object represents a schedule, and a block drvier may have multiple schedules,
        // the UI assumes assignment algorithm always use the nearest schedule.
        const existingTransporter = transporterIdToBlockTransporter.get(transporter.transporterId);

        if (existingTransporter && existingTransporter.shiftStartTime && transporter.shiftStartTime && existingTransporter.shiftStartTime < transporter.shiftStartTime) {
          // ignore the transporter/schedule since it's in the future compared with the exisiting transporter.
          continue;
        }

        transporterIdToBlockTransporter.set(transporter.transporterId, transporter);
      } else if (transporter.type === 'IO') {
        transporterIdToIOTransporter.set(transporter.transporterId, transporter);
      } else if (transporter.type === 'OVERFLOW') {
        transporterIdToOverflowTransporter.set(transporter.transporterId, transporter);
      } else {
        console.warn(`Unknown transporter type ${transporter.type}, transporter id ${transporter.transporterId}`);
      }
    }

    // convert instant offer assignments
    assignments = assignments.concat(this.convertIOAssignment(plan.instantOfferAssignments, transporterIdToIOTransporter));

    // convert fungible assignments
    assignments = assignments.concat(this.convertBlockAssignment(plan.fungibleBlockAssignments, transporterIdToBlockTransporter));

    // convert overflow assignments
    assignments = assignments.concat(this.convertOverflowAssignments(plan.overflowAssignments, transporterIdToOverflowTransporter, routes));

    // convert unassigned route to internal assignment mode, the transporterGroup is undefined in this case.
    if (plan.unAssignedRoutes) {
      for (let unassignedRoute of plan.unAssignedRoutes) {
        assignments.push(this.convertNoAssignmentRoute(unassignedRoute.route, unassignedRoute.reason));
      }
    }

    // add fitlered out assignments from planner input to assignment results
    if (plannerInput.preProcessingFilteredOutRoutes?.ASSIGNED && plannerInput.preProcessingFilteredOutTransporters?.ASSIGNED) {
      const routes = plannerInput.preProcessingFilteredOutRoutes?.ASSIGNED;
      const filteredTransporterIdToTransporterMap: Map<string, Transporter> = new Map();
      for (let transporter of plannerInput.preProcessingFilteredOutTransporters.ASSIGNED) {
        filteredTransporterIdToTransporterMap.set(transporter.transporterId, transporter);
      }
      assignments = assignments.concat(this.convertFilteredOutAssignedRoutes(filteredTransporterIdToTransporterMap, routes));
    }

    // add transportersToBeReleased to the assignment result
    if (plan.transportersToBeReleased) {
      assignments = assignments.concat(this.convertNoAssignmentTransporters(plan.transportersToBeReleased, 'Discharged'));
    }

    // add transportersWithAutoAssignmentNotEnabled to the assignment result
    if (plan.transportersWithAutoAssignmentNotEnabled) {
      assignments = assignments.concat(this.convertNoAssignmentTransporters(plan.transportersWithAutoAssignmentNotEnabled, 'AutoAssignDisabled'));
    }

    // add transportersWithoutAssignment to the assignment result
    if (plan.transportersWithoutAssignment) {
      assignments = assignments.concat(this.convertNoAssignmentTransporters(plan.transportersWithoutAssignment, 'Waiting'));
    }

    return assignments;
  }
}
