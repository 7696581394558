import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import '@amzn/awsui-global-styles/polaris.css';
import './custom-theme.less';
import reportWebVitals from './reportWebVitals';
import Root from './main-app';
import { DEFAULT_TIMEZONE_PREFERENCE, preferencesStore, timezoneManager, applyTheme, credentials } from './utilities';
import { applyMode, Mode, applyDensity, Density } from '@amzn/awsui-global-styles';
import { DEFAULT_LOCALE_KEY, DEFAULT_UI_DENSITY, DEFAULT_UI_MODE, DEFAULT_UI_THEME, LOCALE_KEY, PREFERRED_TIMEZONE_KEY, UI_DENSITY_KEY, UI_MODE_KEY, UI_THEME_KEY } from './constants';
import Box from '@amzn/awsui-components-react/polaris/box';
import { MbmProvider } from '@amzn/react-arb-tools';
import { getMbmOptions } from './mbm';

// initialize website states
applyMode('light' === preferencesStore.get(UI_MODE_KEY, DEFAULT_UI_MODE) ? Mode.Light : Mode.Dark);
applyDensity('comfortable' === preferencesStore.get(UI_DENSITY_KEY, DEFAULT_UI_DENSITY) ? Density.Comfortable : Density.Compact);
applyTheme(preferencesStore.get(UI_THEME_KEY, DEFAULT_UI_THEME));

timezoneManager.setTimezonePreference(preferencesStore.get(PREFERRED_TIMEZONE_KEY, DEFAULT_TIMEZONE_PREFERENCE));

// define MessageBundlerManager (mbm) options for MbmProvider, as MbmProvider allows us to react to localization
// recommended to use mbm options as it makes component reactive to localization context changes
// https://w.amazon.com/bin/view/I18N-Eng/Products/AmazonResourceBundle/JS_UserGuide/#HARBinReact
const mbmOptions = getMbmOptions(preferencesStore.get(LOCALE_KEY, DEFAULT_LOCALE_KEY));

credentials.refresh((err) => {
  if (err !== undefined) {
    ReactDOM.render(<Box>Login required</Box>, document.getElementById('root'));
  } else {
    ReactDOM.render(
      <React.StrictMode>
        <BrowserRouter>
          <MbmProvider {...mbmOptions}>
            <Root />
          </MbmProvider>
        </BrowserRouter>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
