import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Input from '@amzn/awsui-components-react/polaris/input';
import Select from '@amzn/awsui-components-react/polaris/select';
import { JsonVisualizationDataType, JsonVisualizationProfile, JsonVisualizationTableColumnDefinition } from './models';
import { assertJsonVisualizationProfile } from './assertion';
import { customAlphabet } from 'nanoid';
const columnIdGenerator = customAlphabet('1234567890abcdef', 10);

interface Props {
  readonly profile?: JsonVisualizationProfile;
  readonly onUpsert: (profile: JsonVisualizationProfile) => Promise<void>;
  readonly onDismiss: () => void;
}

interface State {
  readonly profile: JsonVisualizationProfile;
  readonly mode: 'create' | 'update';
  readonly isUpserting: boolean;
  readonly profileNameErrorMessage?: string;
  readonly keyPathErrorMessage?: string;
  readonly columnIdToNameErrorMessage: {
    [columnId: string]: string;
  };
  readonly columnIdToPathErrorMessage: {
    [columnId: string]: string;
  };
}

const COLUMN_DATA_TYPE_OPTIONS: { value: JsonVisualizationDataType; label: string; description?: string; disabled?: boolean }[] = [
  { value: 'string', label: 'String' },
  { value: 'numeric', label: 'Number' },
  { value: 'timestamp', label: 'Timestamp', description: 'Format examples: 1681863911716, 1681863911, 2023-04-19T00:25:31.346Z' },
  { value: 'geocode', label: 'Geocode', description: 'Format examples: [47.62, -122.206], {lat: 47.64, long: -122.206}, {latitude: 47.64, longitude: -122.206}' },
  { disabled: true, value: 'object', label: 'Object (Under construction 🚧)', description: 'Any JSON blob' },
  { value: 'url', label: 'URL', description: 'Example: https://example.com/path' },
];

/**
 * Wrap DepartureSet component in a modal (a.k.a popup)
 */
export class JsonVisualizationProfileModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isUpserting: false,
      columnIdToNameErrorMessage: {},
      columnIdToPathErrorMessage: {},
      profile: this.props.profile ?? {
        profileId: columnIdGenerator(),
        profileName: '',
        columnDefinitions: [
          {
            columnId: columnIdGenerator(),
            columnName: '',
            path: '',
            dataType: 'string',
          },
        ],
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        keyPath: '',
      },
      mode: this.props.profile === undefined ? 'create' : 'update',
    };
  }

  render() {
    return (
      <Modal
        onDismiss={() => this.props.onDismiss()}
        header={<Header variant="h3">{this.state.mode === 'create' ? 'Create Profile' : 'Update Profile'}</Header>}
        footer={this.renderFooter()}
        visible={true}
        closeAriaLabel="Close modal"
        size="large"
      >
        <SpaceBetween direction="vertical" size="m">
          <FormField label="Profile name" errorText={this.state.profileNameErrorMessage}>
            <Input
              value={this.state.profile.profileName}
              onChange={(evt) => {
                this.setState({
                  profile: {
                    ...this.state.profile,
                    profileName: evt.detail.value,
                  },
                });
              }}
            />
          </FormField>
          <FormField label="Key path" errorText={this.state.keyPathErrorMessage}>
            <Input
              value={this.state.profile.keyPath}
              onChange={(evt) => {
                this.setState({
                  profile: {
                    ...this.state.profile,
                    keyPath: evt.detail.value,
                  },
                });
              }}
            />
          </FormField>
          {this.state.profile.columnDefinitions.map((col, index) => {
            return this.renderColumnInput(col, index);
          })}

          <Button onClick={() => this.addNewColumn()}>Add new column</Button>
        </SpaceBetween>
      </Modal>
    );
  }

  private renderFooter() {
    return (
      <Box float="right">
        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="link" onClick={() => this.props.onDismiss()} disabled={this.state.isUpserting}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={this.state.isUpserting}
            onClick={async () => {
              const assertions = assertJsonVisualizationProfile(this.state.profile);
              this.setState({
                profileNameErrorMessage: assertions.profileNameErrorMessage,
                keyPathErrorMessage: assertions.keyPathErrorMessage,
                columnIdToNameErrorMessage: assertions.columnIdToNameErrorMessage,
                columnIdToPathErrorMessage: assertions.columnIdToPathErrorMessage,
              });

              if (assertions.pass) {
                this.props.onUpsert({
                  ...this.state.profile,
                  updatedAt: new Date().toISOString(),
                });
              }
            }}
          >
            {this.state.mode === 'create' ? 'Create' : 'Update'}
          </Button>
        </SpaceBetween>
      </Box>
    );
  }

  private renderColumnInput(column: JsonVisualizationTableColumnDefinition, columnIndex: number) {
    const selectedDataType = COLUMN_DATA_TYPE_OPTIONS.find((s) => s.value === column.dataType);
    return (
      <React.Fragment key={columnIndex}>
        <FormField
          label={`Column ${columnIndex + 1} - Name`}
          errorText={this.state.columnIdToNameErrorMessage[column.columnId]}
          secondaryControl={<Button onClick={() => this.deleteColumn(column.columnId)}>Delete</Button>}
        >
          <Input value={column.columnName} onChange={(evt) => this.updateColumnName(evt.detail.value, column.columnId)} />
        </FormField>
        <FormField label={`Column ${columnIndex + 1} - Path`} errorText={this.state.columnIdToPathErrorMessage[column.columnId]}>
          <Input value={column.path} onChange={(evt) => this.updateColumnPath(evt.detail.value, column.columnId)} />
        </FormField>
        <FormField label={`Column ${columnIndex + 1} - Data type`}>
          <Select
            options={COLUMN_DATA_TYPE_OPTIONS}
            selectedOption={selectedDataType ?? null}
            onChange={(evt) => {
              this.updateColumnDataType(evt.detail.selectedOption.value as JsonVisualizationDataType, column.columnId);
            }}
          ></Select>
        </FormField>
      </React.Fragment>
    );
  }

  private updateColumnName(name: string, columnId: string) {
    const columnIndex = this.state.profile.columnDefinitions.findIndex((c) => c.columnId === columnId);
    if (columnIndex >= 0) {
      const newColumn: JsonVisualizationTableColumnDefinition = {
        ...this.state.profile.columnDefinitions[columnIndex],
        columnName: name,
      };
      const newColumns: JsonVisualizationTableColumnDefinition[] = Array.from(this.state.profile.columnDefinitions);
      newColumns[columnIndex] = newColumn;
      const newProfit: JsonVisualizationProfile = {
        ...this.state.profile,
        columnDefinitions: newColumns,
        updatedAt: new Date().toISOString(),
      };
      this.setState({ profile: newProfit });
    }
  }

  private deleteColumn(columnId: string) {
    const newColumns = this.state.profile.columnDefinitions.filter((c) => c.columnId !== columnId);
    const newProfit: JsonVisualizationProfile = {
      ...this.state.profile,
      columnDefinitions: newColumns,
      updatedAt: new Date().toISOString(),
    };
    this.setState({ profile: newProfit });
  }

  private updateColumnPath(path: string, columnId: string) {
    const columnIndex = this.state.profile.columnDefinitions.findIndex((c) => c.columnId === columnId);
    if (columnIndex >= 0) {
      const newColumn: JsonVisualizationTableColumnDefinition = {
        ...this.state.profile.columnDefinitions[columnIndex],
        path: path,
      };
      const newColumns: JsonVisualizationTableColumnDefinition[] = Array.from(this.state.profile.columnDefinitions);
      newColumns[columnIndex] = newColumn;
      const newProfit: JsonVisualizationProfile = {
        ...this.state.profile,
        columnDefinitions: newColumns,
        updatedAt: new Date().toISOString(),
      };
      this.setState({ profile: newProfit });
    }
  }

  private updateColumnDataType(type: JsonVisualizationDataType, columnId: string) {
    const columnIndex = this.state.profile.columnDefinitions.findIndex((c) => c.columnId === columnId);
    if (columnIndex >= 0) {
      const newColumn: JsonVisualizationTableColumnDefinition = {
        ...this.state.profile.columnDefinitions[columnIndex],
        dataType: type,
      };
      const newColumns: JsonVisualizationTableColumnDefinition[] = Array.from(this.state.profile.columnDefinitions);
      newColumns[columnIndex] = newColumn;
      const newProfit: JsonVisualizationProfile = {
        ...this.state.profile,
        columnDefinitions: newColumns,
        updatedAt: new Date().toISOString(),
      };
      this.setState({ profile: newProfit });
    }
  }

  private addNewColumn() {
    const newColumns: JsonVisualizationTableColumnDefinition[] = Array.from(this.state.profile.columnDefinitions);
    newColumns.push({
      columnId: columnIdGenerator(),
      columnName: '',
      path: '',
      dataType: 'string',
    });

    const newProfit: JsonVisualizationProfile = {
      ...this.state.profile,
      columnDefinitions: newColumns,
    };
    this.setState({ profile: newProfit });
  }
}
