import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import ufraaVizClient, { FlexRouteAssignmentPlannerInput, FlexRouteAssignmentPlan, ServiceAreaDetails, ArtifactType } from '../../clients';
import AssignmentPlanContent from './assignment-plan-content';
import { timezoneManager } from '../../utilities';
import { DEFAULT_TITLE } from '../../constants';
import { buildAssignmentPermissionWarningNotification } from './utilities';

interface Props {
  readonly plannerInputId: string;
  readonly activeTab?: string;
  readonly onTabChange: (tab: string) => void;
  readonly filteringText?: string;
  readonly onFilteringTextChange: (text: string) => void;
  readonly isShadow?: boolean;
}

interface State {
  /**
   * undefined indicates the plan is still loading
   * null indicates the plan doesn't exist.
   */
  readonly plan?: FlexRouteAssignmentPlan | null;
  readonly plannerInput?: FlexRouteAssignmentPlannerInput | null;
  readonly serviceAreaDetails?: ServiceAreaDetails | null;
  readonly inputArtifact: ArtifactType;
  readonly planArtifact: ArtifactType;
  readonly prefixLink: string;
}

export class StaticAssignmentPlanPage extends React.Component<Props, State> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      plan: undefined,
      plannerInput: undefined,
      serviceAreaDetails: undefined,
      inputArtifact: this.props.isShadow ? 'SHADOW_FLEX_ROUTE_ASSIGNMENT_PLANNER_INPUT' : 'FLEX_ROUTE_ASSIGNMENT_PLANNER_INPUT',
      planArtifact: this.props.isShadow ? 'SHADOW_FLEX_ROUTE_ASSIGNMENT_PLAN' : 'FLEX_ROUTE_ASSIGNMENT_PLAN',
      prefixLink: this.props.isShadow ? 'shadow-' : '',
    };
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.updateBreadcrumbItems([
      {
        text: this.props.plannerInputId,
        href: `/${this.state.prefixLink}assignment-plan/${encodeURIComponent(this.props.plannerInputId)}`,
      },
    ]);

    await this.loadData(this.props.plannerInputId);

    try {
      const permissions = await ufraaVizClient.getUserPermissions();
      if (!permissions.includes('ASSIGNMENT_ACCESS')) {
        this.context.addNotification(buildAssignmentPermissionWarningNotification());
      }
    } catch (err: any) {
      this.context.addNotification({
        header: 'Error',
        content: 'Unable to load user permission.',
        type: 'warning',
      });
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.plannerInputId !== prevProps.plannerInputId) {
      await this.loadData(this.props.plannerInputId);
    }
  }

  /**
   * Load data from the backend, including the planner input, plan, and the service area details.
   * It's not an error if we cannot find the plan because there is a chance that the plan is not generated yet.
   * But if we cannot find the planner input, then it must be an exception due to either the invalid user input
   * or error in the assignment planning system. (a.k.a lord commander)
   * @param plannerInputId
   */
  async loadData(plannerInputId: string): Promise<void> {
    let plannerInput: FlexRouteAssignmentPlannerInput;
    let plan: FlexRouteAssignmentPlan | undefined = undefined;
    let serviceAreaDetails: ServiceAreaDetails | undefined = undefined;

    const serviceAreaId = plannerInputId.split('/')[0];

    try {
      const artifacts = await ufraaVizClient.getArtifactsFromReferences<FlexRouteAssignmentPlannerInput>({
        artifactIdentifiers: [
          {
            artifactId: plannerInputId,
            artifactType: this.state.inputArtifact,
          },
        ],
      });

      if (artifacts.length !== 1) {
        this.context.addNotification({
          header: artifacts.length === 0 ? 'Planner Input Not Found' : 'Unexpected response from backend',
          content: artifacts.length === 0 ? `Unable to find the requested planner input, looks like you have an invalid url.` : `Requested one artifact but backend responded ${artifacts.length}.`,
          type: 'warning',
        });
        return;
      }

      plannerInput = artifacts[0].artifact;

      document.title = this.formatTitle(plannerInput);
    } catch (err: any) {
      // we have nothing to do if the planner input is not found.
      this.setState({ plan: null, plannerInput: null, serviceAreaDetails: null });
      if (err?.response?.status === 404) {
        this.context.addNotification({
          header: `Planner Input Not Found`,
          content: `Unable to find the requested planner input, looks like you have an invalid url.`,
          type: 'warning',
        });
      } else {
        this.context.addNotification({
          header: `Planner Input Not Found`,
          // data contains the exception message.
          content: err?.response?.data,
          type: 'error',
        });
        console.error(`Cannot find planner input due to ${err?.response?.data}`);
      }
      return;
    }

    this.context.updateStationTimezone(plannerInput.serviceArea.timeZoneId);
    this.context.updateBreadcrumbItems([
      {
        text: `${plannerInput.serviceArea.serviceAreaName}`,
        href: `/service-areas/${plannerInput.serviceArea.serviceAreaId}`,
      },
      {
        text: this.props.plannerInputId,
        href: `/${this.state.prefixLink}assignment-plan/${encodeURIComponent(this.props.plannerInputId)}`,
      },
    ]);

    try {
      const artifacts = await ufraaVizClient.getArtifactsFromReferences<FlexRouteAssignmentPlan>({
        artifactIdentifiers: [
          {
            artifactId: plannerInput.planId,
            artifactType: this.state.planArtifact,
          },
        ],
      });

      if (artifacts.length !== 1) {
        this.context.addNotification({
          header: artifacts.length === 0 ? 'Plan Not Found' : 'Unexpected response from backend',
          content: artifacts.length === 0 ? `Unable to find the requested plan, please refresh later.` : `Requested one artifact but backend responded ${artifacts.length}.`,
          type: 'warning',
        });
        return;
      }

      plan = artifacts[0].artifact;
    } catch (err: any) {
      if (err?.response?.status === 404) {
        this.context.addNotification({
          header: 'Plan Not Found',
          content: 'Unable to find assigment plan, please refresh later.',
          type: 'warning',
        });
      } else {
        this.context.addNotification({
          header: `Plan Not Found`,
          // data contains the exception message.
          content: err?.response?.data,
          type: 'error',
        });
        console.error(`Cannot find assignment plan due to ${err?.response?.data}`);
      }
    }

    try {
      serviceAreaDetails = await ufraaVizClient.getServiceAreaDetails(plannerInput.serviceArea.serviceAreaId);
    } catch (err: any) {
      this.context.addNotification({
        header: `Cannot found service area`,
        content: `Unable to find the service area details ${plannerInput.serviceArea.serviceAreaId} due to ${err?.response?.data}`,
        type: 'error',
      });
    }

    this.setState({
      plannerInput: plannerInput,
      plan: plan === undefined ? null : plan,
      serviceAreaDetails: serviceAreaDetails === undefined ? null : serviceAreaDetails,
    });
  }

  private formatTitle(plannerInput: FlexRouteAssignmentPlannerInput): string {
    return `${plannerInput.serviceArea.defaultStationCode} @ ${timezoneManager.convertTimestampToString(plannerInput.serviceArea.lastUpdated, {
      format: 'HH:mm z',
    })}`;
  }

  // cleanup
  componentWillUnmount() {
    this.context.updateStationTimezone(undefined);
    document.title = DEFAULT_TITLE;
  }

  render() {
    return (
      <AssignmentPlanContent
        mode="static"
        plannerInput={this.state.plannerInput}
        plan={this.state.plan}
        onFilteringTextChange={this.props.onFilteringTextChange}
        onTabChange={this.props.onTabChange}
        serviceAreaDetails={this.state.serviceAreaDetails}
        activeTab={this.props.activeTab}
        filteringText={this.props.filteringText}
        isShadow={this.props.isShadow}
      />
    );
  }
}
