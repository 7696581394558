import * as React from 'react';
import { MidwayTokenRetriever, TimezonePreference, DEFAULT_TIMEZONE_PREFERENCE, timezoneManager, preferencesStore, applyTheme } from '../utilities';
import { GlobalContext, GlobalContextType, BreadcrumbItem, Notification, ToolsType, Tutorial, Locale } from './global-context';
import Header from './header';
import App from './app';
import shortid from 'shortid';
import { applyMode, Mode, applyDensity, Density } from '@amzn/awsui-global-styles';
import { useHistory } from 'react-router';
import {
  COMPLETED_TUTORIALS,
  DEFAULT_NAVIGATION_STATUS,
  DEFAULT_UI_DENSITY,
  DEFAULT_UI_MODE,
  DEFAULT_UI_THEME,
  NAVIGATION_STATUS_KEY,
  PREFERRED_TIMEZONE_KEY,
  UI_DENSITY_KEY,
  UI_MODE_KEY,
  UI_THEME_KEY,
  DEFAULT_LOCALE_KEY,
  LOCALE_KEY,
} from '../constants';
import { NavigationStatus } from '../models';

interface State {
  readonly username?: string;
  readonly context: GlobalContextType;
}

interface Props {
  readonly onGoto: (url: string) => void;
}

class Root_ extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const updateLocale = (locale: Locale) => {
      preferencesStore.set(LOCALE_KEY, locale as string);
      this.setState((state) => ({
        context: {
          ...state.context,
          locale: locale,
        },
      }));
    };

    const updateUIMode = (uiMode: 'light' | 'dark') => {
      applyMode(uiMode === 'light' ? Mode.Light : Mode.Dark);
      preferencesStore.set(UI_MODE_KEY, uiMode);
      this.setState((state) => ({
        context: {
          ...state.context,
          uiMode: uiMode,
        },
      }));
    };

    const updateTheme = (theme: 'regular' | 'visual-refresh') => {
      applyTheme(theme);
      preferencesStore.set(UI_THEME_KEY, theme);
      this.setState((state) => ({
        context: {
          ...state.context,
          theme: theme,
        },
      }));
    };

    const updateDensity = (density: 'compact' | 'comfortable') => {
      applyDensity(density === 'comfortable' ? Density.Comfortable : Density.Compact);
      preferencesStore.set(UI_DENSITY_KEY, density);
      this.setState((state) => ({
        context: {
          ...state.context,
          density: density,
        },
      }));
    };

    const updateNavgiationOpen = (open: boolean) => {
      preferencesStore.set<NavigationStatus>(NAVIGATION_STATUS_KEY, { open: open });
      this.setState((state) => ({
        context: {
          ...state.context,
          navigationOpen: open,
        },
      }));
    };

    const updateBreadcrumbItems = (items: BreadcrumbItem[]) => {
      this.setState((state) => ({
        context: {
          ...state.context,
          breadcrumbItems: items,
        },
      }));
    };

    const addNotification = (notification: Notification) => {
      this.setState((state) => {
        const currentNotifications = Array.from(state.context.notifications);
        if (notification.notificationId === undefined) {
          notification.notificationId = shortid.generate();
        }
        currentNotifications.push(notification);

        return {
          context: {
            ...state.context,
            notifications: currentNotifications,
          },
        };
      });
    };

    const removeNotification = (notificationId: string) => {
      this.setState((state) => {
        const filteredNotifications = Array.from(state.context.notifications.filter((notification) => notification.notificationId !== notificationId));
        return {
          context: {
            ...state.context,
            notifications: filteredNotifications,
          },
        };
      });
    };

    const openTools = () => {
      this.setState((state) => ({
        context: {
          ...state.context,
          toolsOpen: true,
        },
      }));
    };

    const closeTools = () => {
      this.setState((state) => ({
        context: {
          ...state.context,
          toolsOpen: false,
        },
      }));
    };

    const setTools = (tools?: ToolsType) => {
      this.setState((state) => ({
        context: {
          ...state.context,
          tools: tools,
        },
      }));
    };

    const setTutorial = (tutorial?: Tutorial) => {
      // update tutorial complete.
      const completedTutorials = preferencesStore.get<string[]>(COMPLETED_TUTORIALS, []);
      this.setState((state) => ({
        context: {
          ...state.context,
          tutorial: tutorial,
          tutorialComplete: tutorial !== undefined ? completedTutorials.includes(tutorial) : true,
        },
      }));
    };

    const openTutorial = () => {
      this.setState((state) => ({
        context: {
          ...state.context,
          tutorialOpen: true,
        },
      }));
    };

    const closeTutorial = () => {
      // update tutorial complete.
      const tutorial = this.state.context.tutorial;
      if (tutorial !== undefined) {
        const completedTutorials = preferencesStore.get<string[]>(COMPLETED_TUTORIALS, []);
        if (!completedTutorials.includes(tutorial)) {
          completedTutorials.push(tutorial);
          preferencesStore.set<string[]>(COMPLETED_TUTORIALS, completedTutorials);
        }
      }
      this.setState((state) => ({
        context: {
          ...state.context,
          tutorialOpen: false,
          tutorialComplete: true,
        },
      }));
    };

    const updateTimezonePreference = (timezonePreference: TimezonePreference) => {
      timezoneManager.setTimezonePreference(timezonePreference);
      preferencesStore.set(PREFERRED_TIMEZONE_KEY, timezonePreference);

      this.setState((state) => ({
        context: {
          ...state.context,
          timezonePreference: timezonePreference,
        },
      }));
    };

    const updateStationTimezone = (timezone?: string) => {
      timezoneManager.setStationTimezone(timezone);

      this.setState((state) => {
        const context = { ...state.context };
        context.stationTimezone = timezone;
        return { context };
      });
    };

    // clean notification, breadcrumb, and info
    const resetLayout = () => {
      this.setState((state) => ({
        context: {
          ...state.context,
          notifications: [],
          breadcrumbItems: [],
          tools: undefined,
          tutorial: undefined,
        },
      }));
    };

    const updateLiveModeEffectEnabled = (enabled: boolean) => {
      this.setState((state) => ({
        context: {
          ...state.context,
          liveModeEffectEnabled: enabled,
        },
      }));
    };

    this.state = {
      context: {
        locale: preferencesStore.get(LOCALE_KEY, DEFAULT_LOCALE_KEY),
        updateLocale: updateLocale,

        uiMode: preferencesStore.get(UI_MODE_KEY, DEFAULT_UI_MODE),
        updateUIMode: updateUIMode,

        theme: preferencesStore.get(UI_THEME_KEY, DEFAULT_UI_THEME),
        updateTheme: updateTheme,

        density: preferencesStore.get(UI_DENSITY_KEY, DEFAULT_UI_DENSITY),
        updateDensity: updateDensity,

        navigationOpen: preferencesStore.get<NavigationStatus>(NAVIGATION_STATUS_KEY, DEFAULT_NAVIGATION_STATUS).open ?? true,
        updateNavigationOpen: updateNavgiationOpen,

        breadcrumbItems: [],
        updateBreadcrumbItems: updateBreadcrumbItems,

        notifications: [],
        addNotification: addNotification,
        removeNotification: removeNotification,

        tools: undefined,
        toolsOpen: false,
        tutorialComplete: true,
        openTools: openTools,
        closeTools: closeTools,
        setTools: setTools,

        tutorial: undefined,
        setTutorial: setTutorial,
        tutorialOpen: false,
        openTutorial: openTutorial,
        closeTutorial: closeTutorial,

        timezonePreference: preferencesStore.get(PREFERRED_TIMEZONE_KEY, DEFAULT_TIMEZONE_PREFERENCE),
        updateTimezonePreference: updateTimezonePreference,

        stationTimezone: undefined,
        updateStationTimezone: updateStationTimezone,

        resetLayout: resetLayout,
        onGoto: (url: string) => this.props.onGoto(url),

        liveModeEffectEnabled: false,
        updateLiveModeEffectEnabled: updateLiveModeEffectEnabled,
      },
    };
  }

  async componentDidMount() {
    const username = await MidwayTokenRetriever.getUsername();
    this.setState((state) => ({
      username: username,
      context: {
        ...state.context,
        username: username,
      },
    }));
  }

  render() {
    return (
      <div>
        <GlobalContext.Provider value={this.state.context}>
          <div id="b">
            <div id="header-placeholder"></div>
            {/* div #b and #h are used by AWS Polaris AppLayout */}
            <div id="h" role="navigation">
              <Header username={this.state.username} />
            </div>
            <App />
          </div>
        </GlobalContext.Provider>
      </div>
    );
  }
}

export function Root() {
  const history = useHistory();
  return <Root_ onGoto={(url: string) => history.push(url)} />;
}
