import React from 'react';
import { DepartureSet, DepartureSetType, FdpAssignment } from '../../../clients';
import { timezoneManager, formatDuration } from '../../../utilities';
import Link, { LinkProps } from '@amzn/awsui-components-react/polaris/link';
import Box from '@amzn/awsui-components-react/polaris/box';
import { AssignmentTableDefinitionProps, AssignmentColumnDefinition } from './models';
import { convertNullable } from './utilities';
import { NOT_AVAILABLE, TIME_FORMAT } from './constants';
import CopyText from '../../../shared-components/copy-text';

/**
 * Compare two departureSets.
 *
 * A route can have multiple departure sets, the function helps display them in a consistent order.
 * @param d1
 * @param d2
 * @returns
 */
function departureSetComparator(d1: DepartureSet, d2: DepartureSet): number {
  return d1.departureSetId.localeCompare(d2.departureSetId);
}

export function convertDepartureSetStatusToText(departureSetStatus: DepartureSetType[]): string {
  if (departureSetStatus.includes('STAGING_COMPLETED') || departureSetStatus.includes('BATCHING_COMPLETED')) {
    return 'Batched';
  } else if (departureSetStatus.includes('PICK_STARTED') || departureSetStatus.includes('BATCHING_STARTED')) {
    return 'Batching';
  } else if (departureSetStatus.includes('DEPARTURE_WINDOW_PLANNED')) {
    return 'Planned';
  } else {
    return NOT_AVAILABLE;
  }
}

export function buildFdpTableDefinition(props: AssignmentTableDefinitionProps): AssignmentColumnDefinition<FdpAssignment>[] {
  return [
    {
      id: 'routeId',
      header: 'Route Id Prefix',
      cell: (item: FdpAssignment) => {
        if (item.route) {
          return (
            <Box>
              <Link
                onFollow={(evt: CustomEvent<LinkProps.FollowDetail>) => {
                  evt.preventDefault();
                  if (item.route) {
                    props.onRouteClick(item.route);
                  }
                }}
              >
                {item.route.routeId.substring(0, 13)}
              </Link>
            </Box>
          );
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
    {
      id: 'dispatchWindowStartTime',
      header: 'Dispatch Start',
      removable: true,
      cell: (item: FdpAssignment) => {
        if (item.route && item.route.dispatchWindow) {
          return <Box>{timezoneManager.convertTimestampToString(item.route.dispatchWindow.start, { format: TIME_FORMAT })}</Box>;
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
    {
      id: 'dispatchWindowEndTime',
      header: 'Dispatch End',
      removable: true,
      cell: (item: FdpAssignment) => {
        if (item.route && item.route.dispatchWindow) {
          return <Box>{timezoneManager.convertTimestampToString(item.route.dispatchWindow.end, { format: TIME_FORMAT })}</Box>;
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
    {
      id: 'departureSetStatus',
      header: 'UTR Status',
      removable: true,
      cell: (item: FdpAssignment) => {
        if (item.route && item.route.departureSets) {
          return item.route.departureSets.sort(departureSetComparator).map((departureSet, index) => {
            return (
              <Box key={index}>
                <Link
                  onFollow={(evt: CustomEvent<LinkProps.FollowDetail>) => {
                    evt.preventDefault();
                    if (props.onDepartureSetStatusClick) {
                      props.onDepartureSetStatusClick(departureSet);
                    }
                  }}
                >
                  {convertDepartureSetStatusToText(departureSet.status)}
                </Link>
              </Box>
            );
          });
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
    {
      id: 'departureSetUpdatedTime',
      header: 'UTR Updated Time',
      removable: true,
      cell: (item: FdpAssignment) => {
        if (item.route && item.route.departureSets) {
          return item.route.departureSets.sort(departureSetComparator).map((departureSet, index) => {
            return <Box key={index}>{departureSet.pickStartTime ? timezoneManager.convertTimestampToString(departureSet.pickStartTime, { format: TIME_FORMAT }) : NOT_AVAILABLE}</Box>;
          });
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
    {
      id: 'offerWindowStartTime',
      header: 'Offer Window Start',
      removable: true,
      cell: (item: FdpAssignment) => {
        if (item.route?.offerWindow) {
          return <Box>{timezoneManager.convertTimestampToString(item.route.offerWindow.start)}</Box>;
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
    {
      id: 'offerWindowEndTime',
      header: 'Offer Window End',
      removable: true,
      cell: (item: FdpAssignment) => {
        if (item.route?.offerWindow) {
          return <Box>{timezoneManager.convertTimestampToString(item.route.offerWindow.end)}</Box>;
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
    {
      id: 'duration',
      header: 'Duration',
      removable: true,
      cell: (item: FdpAssignment) => {
        return item.route?.routeDuration ? <Box>{formatDuration(item.route.routeDuration)}</Box> : NOT_AVAILABLE;
      },
    },
    {
      id: 'rejectionCount',
      header: 'Rejection Count',
      removable: true,
      cell: (item: FdpAssignment) => {
        if (item.route) {
          const transporterIds = item.rejectedTransporterIds ? item.rejectedTransporterIds : [];
          return (
            <Box>
              <Link
                onFollow={(evt: CustomEvent<LinkProps.FollowDetail>) => {
                  evt.preventDefault();
                  if (props.onRejectionCountClick) {
                    props.onRejectionCountClick(transporterIds);
                  }
                }}
              >
                {transporterIds.length}
              </Link>
            </Box>
          );
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
    {
      id: 'assignmentStatus',
      header: 'Assignment Status',
      removable: false,
      cell: (item: FdpAssignment) => {
        let assignmentStatus: string | undefined = item.assignmentStatus;
        assignmentStatus = assignmentStatus ? assignmentStatus : item.transporterUnAssignedReason;
        return <Box>{convertNullable(NOT_AVAILABLE, assignmentStatus)}</Box>;
      },
    },
    {
      id: 'transporterId',
      header: 'Transporter Id',
      removable: true,
      cell: (item: FdpAssignment) => {
        const text = convertNullable(NOT_AVAILABLE, item.transporter?.transporterId);
        return text === NOT_AVAILABLE ? text : <CopyText copyText={text} />;
      },
    },
    {
      id: 'transporterType',
      header: 'Transporter Type',
      removable: true,
      cell: (item: FdpAssignment) => convertNullable(NOT_AVAILABLE, item.transporter?.type),
    },
    {
      id: 'stemInDuration',
      header: 'Stem In Duration',
      removable: true,
      cell: (item: FdpAssignment) => {
        if (typeof item.transporterExtras?.stemInDurationInSeconds === 'number') {
          return <Box>{formatDuration(item.transporterExtras?.stemInDurationInSeconds)}</Box>;
        } else {
          return <Box>{NOT_AVAILABLE}</Box>;
        }
      },
    },
    {
      id: 'serviceTypeId',
      header: 'Service Type Id',
      removable: true,
      cell: (item: FdpAssignment) => {
        const text = convertNullable(NOT_AVAILABLE, item.transporter?.serviceTypeId);
        return text === NOT_AVAILABLE ? text : <CopyText copyText={text} />;
      },
    },
    {
      id: 'readyToWork',
      header: 'Ready To Work',
      removable: true,
      cell: (item: FdpAssignment) => {
        const readyToWork = item.transporter?.readyToWork;
        return typeof readyToWork === 'boolean' ? `${readyToWork ? '👍' : '❌'}` : NOT_AVAILABLE;
      },
    },
    {
      id: 'driverShiftStart',
      header: 'Block Start',
      removable: true,
      cell: (item: FdpAssignment) => {
        const startTime = item.transporter?.shiftStartTime;
        return startTime ? timezoneManager.convertTimestampToString(startTime, { format: TIME_FORMAT }) : NOT_AVAILABLE;
      },
    },
    {
      id: 'driverShiftEnd',
      header: 'Block End',
      removable: true,
      cell: (item: FdpAssignment) => {
        const endTime = item.transporter?.shiftEndTime;
        return endTime ? timezoneManager.convertTimestampToString(endTime, { format: TIME_FORMAT }) : NOT_AVAILABLE;
      },
    },
    {
      id: 'candidateBlockDurations',
      header: 'Candidate Block Duration',
      removable: true,
      cell: (item: FdpAssignment) => {
        return item.block?.durationInSeconds ? <Box>{formatDuration(item.block.durationInSeconds)}</Box> : NOT_AVAILABLE;
      },
    },
    {
      id: 'candiateBlockId',
      header: 'Candidate Block Id',
      removable: true,
      cell: (item: FdpAssignment) => {
        const text = convertNullable(NOT_AVAILABLE, item.transporter?.candidateBlockId);
        return text === NOT_AVAILABLE ? text : <CopyText copyText={text} />;
      },
      width: '180px', // since last column, need to enforce width
    },
  ];
}
