// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".H8RwIJQHuepWdOSII9JoJw\\=\\= h1,.H8RwIJQHuepWdOSII9JoJw\\=\\= h2,.H8RwIJQHuepWdOSII9JoJw\\=\\= h3,.H8RwIJQHuepWdOSII9JoJw\\=\\= h4,.H8RwIJQHuepWdOSII9JoJw\\=\\= h5,.H8RwIJQHuepWdOSII9JoJw\\=\\= h6{margin-top:24px;margin-bottom:16px;font-weight:600;line-height:1.25}.H8RwIJQHuepWdOSII9JoJw\\=\\= table{display:block;width:100%;overflow:auto;border-spacing:0;border-collapse:collapse;margin-top:0;margin-bottom:16px}.H8RwIJQHuepWdOSII9JoJw\\=\\= table th{font-weight:600}.H8RwIJQHuepWdOSII9JoJw\\=\\= table td,.H8RwIJQHuepWdOSII9JoJw\\=\\= table th{padding:6px 13px;border:1px solid #dfe2e5}.H8RwIJQHuepWdOSII9JoJw\\=\\= table tr{background-color:#fff;border-top:1px solid #c6cbd1}.H8RwIJQHuepWdOSII9JoJw\\=\\= table tr:nth-child(2n){background-color:#f6f8fa}", "",{"version":3,"sources":["webpack://./src/shared-components/markdown.module.scss"],"names":[],"mappings":"AACE,0LACE,eAAA,CACA,kBAAA,CACA,eAAA,CACA,gBAAA,CAEF,kCACE,aAAA,CACA,UAAA,CACA,aAAA,CACA,gBAAA,CACA,wBAAA,CACA,YAAA,CACA,kBAAA,CAGF,qCACE,eAAA,CAGF,0EAEE,gBAAA,CACA,wBAAA,CAGF,qCACE,qBAAA,CACA,4BAAA,CAEF,mDACE,wBAAA","sourcesContent":[".markdown-body {\n  h1, h2, h3, h4, h5, h6 {\n    margin-top: 24px;\n    margin-bottom: 16px;\n    font-weight: 600;\n    line-height: 1.25;\n  }\n  table {\n    display: block;\n    width: 100%;\n    overflow: auto;\n    border-spacing: 0;\n    border-collapse: collapse;\n    margin-top: 0;\n    margin-bottom: 16px;\n  }\n\n  table th {\n    font-weight: 600;\n  }\n\n  table td,\n  table th {\n    padding: 6px 13px;\n    border: 1px solid #dfe2e5;\n  }\n\n  table tr {\n    background-color: #fff;\n    border-top: 1px solid #c6cbd1;\n  }\n  table tr:nth-child(2n) {\n    background-color: #f6f8fa;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"markdown-body": "H8RwIJQHuepWdOSII9JoJw=="
};
export default ___CSS_LOADER_EXPORT___;
