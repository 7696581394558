import React from 'react';
import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import Link from '@amzn/awsui-components-react/polaris/link';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { NetworkHealthDetails } from '../../clients';
import { COLOR_RED, DEFAULT_SERVICE_AREAS_PER_PAGE, COLOR_YELLOW, COLUMN_IDS_TO_HEADERS, BASE_VISIBLE_COLUMN_IDS } from './constants';
import { renderNullableField, compareMetrics, saCodeSortingComparator, planIdSortingComparator, filterSelectedOptions, convertPlanTime } from './utilities';
import CopyText from '../../shared-components/copy-text';
import { GlobalContextType } from '../../main-app/global-context';
import { FilteringOptions } from '@amzn/awsui-collection-hooks';
import { NetworkHealthParams, UserPreferences } from './models';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';

/**
 * https://tiny.amazon.com/13prpyd8b/codeamazpackGSFTblobmainweba
 * Specific coloring rules are based off original GTW logic
 */
export const BASE_TABLE_COLUMN_DEFINITION: TableProps.ColumnDefinition<NetworkHealthDetails>[] = [
  {
    id: 'plan-time',
    header: COLUMN_IDS_TO_HEADERS['plan-time'],
    cell: (item) => convertPlanTime(item.executionPlanMetadata.creationTimeMillis, item.executionPlanMetadata.serviceAreaTimeZone),
    sortingComparator: (metricsA: NetworkHealthDetails, metricsB: NetworkHealthDetails) => metricsA.executionPlanMetadata.creationTimeMillis - metricsB.executionPlanMetadata.creationTimeMillis,
  },
  {
    id: 'routes',
    header: COLUMN_IDS_TO_HEADERS['routes'],
    cell: (item) => renderNullableField(item.networkHealthMetrics.routeCount),
    sortingComparator: (metricsA: NetworkHealthDetails, metricsB: NetworkHealthDetails) => compareMetrics(metricsA.networkHealthMetrics.routeCount, metricsB.networkHealthMetrics.routeCount),
  },
  {
    id: 'orders',
    header: COLUMN_IDS_TO_HEADERS['orders'],
    cell: (item) => renderNullableField(item.networkHealthMetrics.totalOrderCount),
    sortingComparator: (metricsA: NetworkHealthDetails, metricsB: NetworkHealthDetails) => compareMetrics(metricsA.networkHealthMetrics.totalOrderCount, metricsB.networkHealthMetrics.totalOrderCount),
  },
  {
    id: 'total-unassigned-order-count',
    header: COLUMN_IDS_TO_HEADERS['total-unassigned-order-count'],
    cell: (item) => renderNullableField(item.networkHealthMetrics.totalUnassignedOrderCount),
    sortingField: 'total-unassigned-order-count',
    sortingComparator: (metricsA: NetworkHealthDetails, metricsB: NetworkHealthDetails) =>
      compareMetrics(metricsA.networkHealthMetrics.totalUnassignedOrderCount, metricsB.networkHealthMetrics.totalUnassignedOrderCount),
  },
  {
    id: 'unplanned-orders',
    header: COLUMN_IDS_TO_HEADERS['unplanned-orders'],
    cell: (item) => {
      let color;
      let unplannedOrderCount = item.networkHealthMetrics.unplannedOrderCount;
      if (typeof unplannedOrderCount === 'number' && unplannedOrderCount >= 5) {
        color = COLOR_RED;
      }
      return renderNullableField(unplannedOrderCount, color);
    },
    sortingComparator: (metricsA: NetworkHealthDetails, metricsB: NetworkHealthDetails) =>
      compareMetrics(metricsA.networkHealthMetrics.unplannedOrderCount, metricsB.networkHealthMetrics.unplannedOrderCount),
  },
  {
    id: 'unassigned-tplus-10-order-count',
    header: COLUMN_IDS_TO_HEADERS['unassigned-tplus-10-order-count'],
    cell: (item) => renderNullableField(item.networkHealthMetrics.unassignedTPlus10OrderCount),
    sortingComparator: (metricsA: NetworkHealthDetails, metricsB: NetworkHealthDetails) =>
      compareMetrics(metricsA.networkHealthMetrics.unassignedTPlus10OrderCount, metricsB.networkHealthMetrics.unassignedTPlus10OrderCount),
  },
  {
    id: 'unassigned-tplus-20-order-count',
    header: COLUMN_IDS_TO_HEADERS['unassigned-tplus-20-order-count'],
    cell: (item) => renderNullableField(item.networkHealthMetrics.unassignedTPlus20OrderCount),
    sortingComparator: (metricsA: NetworkHealthDetails, metricsB: NetworkHealthDetails) =>
      compareMetrics(metricsA.networkHealthMetrics.unassignedTPlus20OrderCount, metricsB.networkHealthMetrics.unassignedTPlus20OrderCount),
  },
  {
    id: 'unassigned-tplus-30-order-count',
    header: COLUMN_IDS_TO_HEADERS['unassigned-tplus-30-order-count'],
    cell: (item) => {
      let color;
      let unassignedTPlus30OrderCount = item.networkHealthMetrics.unassignedTPlus30OrderCount;
      if (typeof unassignedTPlus30OrderCount === 'number' && unassignedTPlus30OrderCount >= 10) {
        color = COLOR_RED;
      } else if (typeof unassignedTPlus30OrderCount == 'number' && unassignedTPlus30OrderCount >= 5) {
        color = COLOR_YELLOW;
      }
      return renderNullableField(unassignedTPlus30OrderCount, color);
    },
    sortingComparator: (metricsA: NetworkHealthDetails, metricsB: NetworkHealthDetails) =>
      compareMetrics(metricsA.networkHealthMetrics.unassignedTPlus30OrderCount, metricsB.networkHealthMetrics.unassignedTPlus30OrderCount),
  },

  {
    id: 'assigned-block-driver-count',
    header: COLUMN_IDS_TO_HEADERS['assigned-block-driver-count'],
    cell: (item) => renderNullableField(item.networkHealthMetrics.assignedBlockDriverCount),
    sortingComparator: (metricsA: NetworkHealthDetails, metricsB: NetworkHealthDetails) =>
      compareMetrics(metricsA.networkHealthMetrics.assignedBlockDriverCount, metricsB.networkHealthMetrics.assignedBlockDriverCount),
  },
  {
    id: 'available-block-driver-count',
    header: COLUMN_IDS_TO_HEADERS['available-block-driver-count'],
    cell: (item) => {
      if (item.networkHealthMetrics.assignedBlockDriverCount === null) {
        return 'N/A';
      } else {
        return item.networkHealthMetrics.totalBlockDriverCount - item.networkHealthMetrics.assignedBlockDriverCount;
      }
    },
    sortingComparator: (metricsA: NetworkHealthDetails, metricsB: NetworkHealthDetails) =>
      compareMetrics(
        metricsA.networkHealthMetrics.totalBlockDriverCount - metricsA.networkHealthMetrics.assignedBlockDriverCount,
        metricsB.networkHealthMetrics.totalBlockDriverCount - metricsB.networkHealthMetrics.assignedBlockDriverCount,
      ),
  },
  {
    id: 'assigned-io-driver-count',
    header: COLUMN_IDS_TO_HEADERS['assigned-io-driver-count'],
    cell: (item) => renderNullableField(item.networkHealthMetrics.assignedIODriverCount),
    sortingComparator: (metricsA: NetworkHealthDetails, metricsB: NetworkHealthDetails) =>
      compareMetrics(metricsA.networkHealthMetrics.assignedIODriverCount, metricsB.networkHealthMetrics.assignedIODriverCount),
  },
  {
    id: 'unassigned-io-drivers-within-time-radius-count',
    header: COLUMN_IDS_TO_HEADERS['unassigned-io-drivers-within-time-radius-count'],
    cell: (item) => {
      let color;
      let unassignedIODriverWithinTimeRadiusCount = item.networkHealthMetrics.unassignedIODriverWithinTimeRadiusCount;
      let assignedIODriverCount = item.networkHealthMetrics.assignedIODriverCount;
      if (
        item.executionPlanMetadata.isIoEnabled === true &&
        unassignedIODriverWithinTimeRadiusCount !== null &&
        assignedIODriverCount !== null &&
        unassignedIODriverWithinTimeRadiusCount < 10 &&
        assignedIODriverCount + unassignedIODriverWithinTimeRadiusCount + item.networkHealthMetrics.totalBlockDriverCount < item.networkHealthMetrics.routeCount
      ) {
        color = COLOR_RED;
      }
      return renderNullableField(unassignedIODriverWithinTimeRadiusCount, color);
    },
    sortingComparator: (metricsA: NetworkHealthDetails, metricsB: NetworkHealthDetails) =>
      compareMetrics(metricsA.networkHealthMetrics.unassignedIODriverWithinTimeRadiusCount, metricsB.networkHealthMetrics.unassignedIODriverWithinTimeRadiusCount),
  },
];

export const ALL_AREAS_TABLE_DEFINITION = (context: GlobalContextType, networkHealthParams: NetworkHealthParams): TableProps.ColumnDefinition<NetworkHealthDetails>[] => {
  return [
    {
      id: 'station-code',
      header: COLUMN_IDS_TO_HEADERS['station-code'],
      cell: (item) => {
        let href: string;
        if (networkHealthParams.liveMode) {
          href = `/network-health/live?serviceAreaId=${item.executionPlanMetadata.serviceAreaId}`;
        } else {
          href = `/network-health/${item.executionPlanMetadata.serviceAreaId}${networkHealthParams.queryParams}`;
        }

        return (
          <Link
            href={href}
            onFollow={(evt) => {
              evt.preventDefault();
              if (typeof evt.detail.href === 'string') {
                context.onGoto(evt.detail.href);
              }
            }}
          >
            {item.executionPlanMetadata.stationCode}
          </Link>
        );
      },
      sortingComparator: saCodeSortingComparator,
    },
    ...BASE_TABLE_COLUMN_DEFINITION,
  ];
};

export const SINGLE_AREA_TABLE_DEFINITION: TableProps.ColumnDefinition<NetworkHealthDetails>[] = [
  {
    id: 'plan-id',
    header: COLUMN_IDS_TO_HEADERS['plan-id'],
    cell: (item) => {
      return <CopyText copyText={item.executionPlanMetadata.planId} />;
    },
    sortingComparator: planIdSortingComparator,
  },
  ...BASE_TABLE_COLUMN_DEFINITION,
];

export const VISIBLE_CONTENT_PREFERENCE: CollectionPreferencesProps.VisibleContentPreference = {
  title: 'Select visible metrics',
  options: [
    {
      label: 'Metrics',
      options: BASE_TABLE_COLUMN_DEFINITION.map(({ id, header }) => ({ id: id!, label: header!.valueOf().toString() })),
    },
  ],
};

export const ALL_AREAS_PREFERENCES: UserPreferences = {
  pageSize: DEFAULT_SERVICE_AREAS_PER_PAGE,
  visibleContent: ['station-code', ...BASE_VISIBLE_COLUMN_IDS],
};

export const SINGLE_AREA_PREFERENCES: UserPreferences = {
  pageSize: DEFAULT_SERVICE_AREAS_PER_PAGE,
  visibleContent: ['plan-id', ...BASE_VISIBLE_COLUMN_IDS],
};

export const ALL_AREAS_FILTERING_OPTIONS = (selectedOptions: ReadonlyArray<OptionDefinition>): FilteringOptions<NetworkHealthDetails> => {
  return {
    filteringFunction: (networkHealthDetails: NetworkHealthDetails, text: string) => {
      const lowerCaseText = text.toLowerCase();
      return networkHealthDetails.executionPlanMetadata.stationCode.toLowerCase().includes(lowerCaseText) && filterSelectedOptions(networkHealthDetails, selectedOptions);
    },
    fields: ['station-code'],
  };
};

export const SINGLE_AREA_FILTERING_OPTIONS = (): FilteringOptions<NetworkHealthDetails> => {
  return {
    filteringFunction: (networkHealthDetails: NetworkHealthDetails, text: string) => {
      const lowerCaseText = text.toLowerCase();
      return networkHealthDetails.executionPlanMetadata.planId.toLowerCase().includes(lowerCaseText);
    },
    fields: ['plan-id'],
  };
};
