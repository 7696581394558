import React, { useEffect, useState, useRef } from 'react';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Box from '@amzn/awsui-components-react/polaris/box';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import Hotspot from '@amzn/awsui-components-react/polaris/hotspot';
import { Stop } from './models';
import 'mapbox-gl/dist/mapbox-gl.css';
import lodash from 'lodash';
import { GeoCode } from '../../clients';
import { Map, Marker, Popup, MapRef, Source, Layer, LineLayer } from 'react-map-gl';
import { Signer } from 'aws-amplify';
import { credentials } from '../../utilities';
import { config } from '../../utilities';
import * as awsui from '@amzn/awsui-design-tokens';
import { FeatureCollection } from 'geojson';

export interface SequenceMapProps {
  readonly routeId: string;
  readonly version: number;
  readonly selectedStopId?: string;
  readonly onSelectedStopIdChange: (identifier: string | undefined) => void;
  readonly stops?: ReadonlyArray<Stop> | null;
}

interface MapState {
  readonly longitude: number;
  readonly latitude: number;
  readonly zoom: number;
}

// AWS re:Invent building, the hackathon page born there
const DEFAULT_STATE: MapState = {
  longitude: -122.3375455,
  latitude: 47.6167655,
  zoom: 13,
};

const PICK_UP_COLOR = awsui.colorChartsOrange400;
const DELIVERY_COLOR = awsui.colorChartsBlue1400;

const LINE_LAYER_PROPS: LineLayer = {
  id: 'lineLayer',
  type: 'line',
  source: 'route-data',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': 'rgba(7, 171, 236, 0.6)',
    'line-width': 5,
  },
};

/**
 * https://docs.mapbox.com/mapbox-gl-js/api/
 * https://code.amazon.com/packages/AWSWaypointWebApp/blobs/amzn-internal-fork/--/src/atomicui/pages/DemoPage/DemoPage.tsx
 */

function SequenceMapHeader() {
  return (
    <Hotspot side="left" hotspotId="route-map">
      <Header variant="h2">Map</Header>
    </Hotspot>
  );
}

export function SequenceMap(props: SequenceMapProps) {
  if (props.stops === null) {
    return (
      <Container header={SequenceMapHeader()}>
        <Box textAlign="center">
          <StatusIndicator type="info">Don't have route geocode.</StatusIndicator>
        </Box>
      </Container>
    );
  } else if (props.stops === undefined) {
    return (
      <Container header={SequenceMapHeader()}>
        <Box textAlign="center">
          <StatusIndicator type="loading">Loading...</StatusIndicator>
        </Box>
      </Container>
    );
  } else {
    if (props.stops.filter((stop) => stop.geocode).length === 0) {
      return (
        <Container header={SequenceMapHeader()}>
          <Alert header="Missing geolocation.">
            The route sequence doesn't associate with any geolocation data.
            {/* We are tracking the feature in the{' '}
            <Link href="https://sim.amazon.com/issues/FLXRT-2924" external={true}>
              SIM
            </Link> */}
          </Alert>
        </Container>
      );
    } else {
      return (
        <Container header={SequenceMapHeader()} disableContentPaddings={!!props.stops}>
          <SequenceMapContent stops={props.stops} selectedStopId={props.selectedStopId} onSelectedStopIdChange={props.onSelectedStopIdChange} />
        </Container>
      );
    }
  }
}

interface SequenceMapContentProps {
  readonly stops: ReadonlyArray<Stop>;
  readonly selectedStopId?: string;
  readonly onSelectedStopIdChange: (identifier: string | undefined) => void;
}

/**
 * We use react-map-gl library to render map, and use AmazonLocationService to get map data.
 * Examples https://visgl.github.io/react-map-gl/examples/controls
 */
function SequenceMapContent(props: SequenceMapContentProps) {
  const initialPos = calculateMapState(props.stops);

  const [selectedStop, setSelectedStop] = useState<Stop | undefined>(undefined);
  const mapViewRef = useRef<MapRef | null>(null);

  useEffect(() => {
    const stop = props.stops.find((stop) => stop.identifier === props.selectedStopId);
    setSelectedStop(stop);
    if (stop?.geocode) {
      mapViewRef?.current?.flyTo({ center: [stop.geocode.longitude, stop.geocode.latitude] });
    } else {
      const initialPos = calculateMapState(props.stops);
      mapViewRef?.current?.flyTo({ center: [initialPos.longitude, initialPos.latitude] });
    }
  }, [props.stops]);

  useEffect(() => {
    const stop = props.stops.find((stop) => stop.identifier === props.selectedStopId);
    setSelectedStop(stop);
    if (stop?.geocode) {
      mapViewRef?.current?.flyTo({ center: [stop.geocode.longitude, stop.geocode.latitude] });
    }
  }, [props.selectedStopId]);

  const transformRequest = (url: string, resourceType: string) => {
    if (resourceType === 'Style' && !url.includes('://')) {
      url = `https://maps.geo.${config.awsRegion}.amazonaws.com/maps/v0/maps/${url}/style-descriptor`;
    }

    if (url.includes('amazonaws.com')) {
      url = Signer.signUrl(url, {
        access_key: credentials.accessKeyId,
        secret_key: credentials.secretAccessKey,
        session_token: credentials.sessionToken,
      });
      return { url: url };
    }

    return { url: url };
  };

  const lineData: FeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };

  function addLine(start: GeoCode, end: GeoCode) {
    lineData.features.push({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: [
          [start.longitude, start.latitude],
          [end.longitude, end.latitude],
        ],
      },
    });
  }

  let previousGeocode: GeoCode;
  props.stops.forEach((stop) => {
    if (stop.geocode === null || stop.geocode === undefined) {
      return;
    }
    if (previousGeocode !== undefined) {
      addLine(previousGeocode, stop.geocode);
    }
    previousGeocode = stop.geocode;
  });

  return (
    <Map
      ref={mapViewRef}
      maxTileCacheSize={100}
      initialViewState={initialPos}
      // magic number, it makes the map has the same height as the 15-item table on the right.
      style={{ height: 668 }}
      mapStyle={config.mapStyle}
      maxZoom={16}
      minZoom={3}
      onError={(e) => console.error('map error', e)}
      transformRequest={transformRequest}
    >
      <Source id="polyLineLayer" type="geojson" data={lineData}>
        <Layer {...LINE_LAYER_PROPS} />
      </Source>
      {props.stops.map((stop, index) => {
        return stop.geocode ? (
          <React.Fragment key={index}>
            <Marker
              longitude={stop.geocode!.longitude}
              latitude={stop.geocode!.latitude}
              anchor="center"
              color={stop.taskType === 'PICKUP' ? PICK_UP_COLOR : DELIVERY_COLOR}
              onClick={(e) => {
                e.originalEvent.stopPropagation();
                setSelectedStop(stop);
                props.onSelectedStopIdChange(stop.identifier);
              }}
            />
          </React.Fragment>
        ) : (
          <Box key={index}></Box>
        );
      })}
      {selectedStop && selectedStop.geocode ? (
        <Popup
          longitude={selectedStop.geocode.longitude}
          latitude={selectedStop.geocode.latitude}
          anchor="top"
          closeButton={false}
          onClose={() => {
            setSelectedStop(undefined);
          }}
        >{`${selectedStop.identifier}. ${lodash.capitalize(selectedStop.taskType)} ${selectedStop.tasks.length} trs.`}</Popup>
      ) : null}
    </Map>
  );
}

function calculateMapState(stops: ReadonlyArray<Stop>): MapState {
  const geocodes = stops.filter((stop) => stop.geocode).map((stop) => stop.geocode as GeoCode);
  if (geocodes.length === 0) {
    return DEFAULT_STATE;
  } else {
    const latCenter = lodash.mean(geocodes.map((code) => code.latitude));
    const longCenter = lodash.mean(geocodes.map((code) => code.longitude));
    return {
      latitude: latCenter,
      longitude: longCenter,
      zoom: 11,
    };
  }
}
