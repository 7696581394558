import { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';
import { FdpAssignment } from '../../../clients';
import { parseFilteringText, routeContainsDepartureSet, routeContainsOrderId, routeContainsTrId, transporterMatchesReadyToWorkSearch } from './utilities';

/**
 * FDP filtering logic
 */

export const FDP_FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    key: 'orderId',
    groupValuesLabel: 'Order Id',
    propertyLabel: 'Order Id',
  },
];

export function fdpQuery(query: PropertyFilterProps.Query, assignments: FdpAssignment[]): FdpAssignment[] {
  return assignments;
}

/**
 * Text Filter functions
 * Compare the text with route Id, order Id, trId, assignment status, transporter id, and transporter type.
 */
function doesMatchAssignment(text: string, assignment: FdpAssignment): boolean {
  // text matches with route content
  if (assignment.route) {
    if (assignment.route.routeId === text || routeContainsOrderId(assignment.route, text) || routeContainsTrId(assignment.route, text) || routeContainsDepartureSet(assignment.route, text)) {
      return true;
    }
  }

  if (assignment.assignmentStatus && assignment.assignmentStatus.toLowerCase() === text.toLowerCase()) {
    return true;
  }

  if (assignment.transporterUnAssignedReason?.toLowerCase() === text.toLowerCase()) {
    return true;
  }

  if (assignment.rejectedTransporterIds && assignment.rejectedTransporterIds.includes(text)) {
    return true;
  }

  if (assignment.transporter) {
    if (assignment.transporter.transporterId === text || assignment.transporter.type.toLowerCase() === text.toLowerCase() || transporterMatchesReadyToWorkSearch(assignment.transporter, text)) {
      return true;
    }
  }
  return false;
}

function anyMatchAssignment(texts: string[], assignment: FdpAssignment): boolean {
  return !!texts.find((text) => doesMatchAssignment(text, assignment));
}

export const INITIAL_FDP_FILTERING_TEXT = 'Open, Offered, Accepted, Assigned';

export function fdpFilter(filteringText: string, assignments: FdpAssignment[]): FdpAssignment[] {
  const texts = parseFilteringText(filteringText, ',');
  if (texts.length === 0) {
    return Array.from(assignments);
  }
  return assignments.filter((assignment) => anyMatchAssignment(texts, assignment));
}
