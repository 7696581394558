import { LmRoute, RouteSequence, RouteChangeLog } from '../../../clients';
import { ROUTE_ID as vtn1BA5RouteId, timeline as vtn1BA5Timeline, routes, sequences, ROUTE_ID } from './vtn1-ba5';

/**
 * Note: because route store retention period is 3 months, and route is generated on 2023-03-01. To make sure
 * the tutorial keep working after the retention perid, we copy and store the route data into ufraaviz code.
 */

class TutorialDataStore {
  hasRoute(routeId: string): boolean {
    return routeId === ROUTE_ID;
  }

  async searchRoute(routeId: string, version?: number): Promise<LmRoute> {
    let route: LmRoute | undefined = undefined;
    if (routeId === vtn1BA5RouteId) {
      route = version === undefined ? routes[0] : routes.find((r) => r.version === version);
    }
    // todo: simulate latency
    if (route !== undefined) {
      return route;
    } else {
      throw new Error(`Route ${routeId} doesn't exist`);
    }
  }

  async searchRouteSequence(routeId: string, version?: number): Promise<RouteSequence> {
    let sequence: RouteSequence | undefined = undefined;
    if (routeId === vtn1BA5RouteId) {
      // the sequences array is a sorted array by version, and index 0 is version 2.
      sequence = version === undefined ? sequences[sequences.length - 1] : sequences[version - 2];
    }
    if (sequence !== undefined) {
      return sequence;
    } else {
      throw new Error(`Route sequence ${routeId} doesn't exist`);
    }
  }

  async searchRouteTimeline(routeId: string): Promise<RouteChangeLog[]> {
    if (routeId === vtn1BA5RouteId) {
      return vtn1BA5Timeline;
    } else {
      throw new Error(`Route timeline ${routeId} doesn't exist`);
    }
  }
}

export const tutorialDataStore = new TutorialDataStore();
