import React from 'react';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import { timezoneManager, PREFERRED_FORMAT } from '../utilities';

export interface TimeDisplayProps {
  readonly time: number;
  readonly format?: string;
  readonly relative?: boolean;
}

interface TimeDisplayState {}

export default class TimeDisplay extends React.Component<TimeDisplayProps, TimeDisplayState> {
  constructor(props: TimeDisplayProps) {
    super(props);
    this.setState({
      time: props.time,
      format: props.format ?? PREFERRED_FORMAT,
      relative: props.relative ?? false,
    });
  }

  render() {
    let primary = timezoneManager.convertTimestampToString(this.props.time, { format: this.props.format });
    let secondary = timezoneManager.convertTimestampToRelativeDuration(this.props.time);
    secondary = `about ${secondary} ago`;

    if (this.props.relative) {
      [primary, secondary] = [secondary, primary];
    }

    return (
      <Popover dismissButton={false} position="top" size="medium" triggerType="text" content={secondary}>
        {primary}
      </Popover>
    );
  }
}
