import React from 'react';
import { GlobalContext } from '../../../main-app/global-context';
import ufraaVizClient, { ArtifactMetadata } from '../../../clients';
import { timezoneManager } from '../../../utilities';
import Table, { TableProps } from '@amzn/awsui-components-react/polaris/table';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import TimeRangePicker from '../../../shared-components/time-range-picker';
import { DriaSnapshotTableProps, DriaSnapshotTableState } from './models';
import { QUERY_WINDOW_2_MS, DRIA_SNAPSHOT_TYPE_TO_MAX_QUERY_WINDOW } from './constants';

export class DriaSnapshotTable extends React.Component<DriaSnapshotTableProps, DriaSnapshotTableState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: DriaSnapshotTableProps) {
    super(props);
    this.state = {
      snapshotMetadata: undefined,
      selectedMetadata: undefined,
      isDownloading: false,
    };
  }

  async componentDidMount() {
    await this.loadSnapshotMetadata(this.props.startTime, this.props.endTime);
  }

  async loadSnapshotMetadata(startTime: number, endTime: number) {
    try {
      const snapshotMetadata = await ufraaVizClient.listArtifactMetadata({
        scope: this.props.serviceAreaId,
        artifactType: this.props.snapshotType,
        startTime,
        endTime,
        timeUnits: 'MILLISECONDS',
      });
      this.setState({ snapshotMetadata });
    } catch (err) {
      this.renderError(err);
      this.setState({ snapshotMetadata: [] });
    }
  }

  async downloadArtifact() {
    this.setState({ isDownloading: true });
    try {
      ufraaVizClient.downloadArtifactsChronologically(this.state.selectedMetadata);
    } catch (err) {
      this.renderError(err);
    } finally {
      this.setState({ isDownloading: false });
    }
  }

  private renderError(err: any) {
    this.context.addNotification({
      type: 'error',
      header: `${this.props.snapshotType} error`,
      content: typeof err?.response?.data === 'string' ? err?.response?.data : err?.message,
    });
  }

  private renderHeader() {
    return (
      <Header
        variant="h2"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="primary"
              disabled={this.state.selectedMetadata === undefined || this.state.selectedMetadata?.length === 0}
              loading={this.state.isDownloading}
              onClick={async () => await this.downloadArtifact()}
            >
              {this.props.bundle.getMessage('STATION_PAGE:DOWNLOAD')}
            </Button>
          </SpaceBetween>
        }
      >
        {this.props.tableName}
      </Header>
    );
  }

  renderDateRangePicker() {
    const queryWindowSize = DRIA_SNAPSHOT_TYPE_TO_MAX_QUERY_WINDOW[this.props.snapshotType];
    const queryWindowSizeInMs = QUERY_WINDOW_2_MS[queryWindowSize];

    return (
      <TimeRangePicker
        timezonePreference={timezoneManager.getInUseTimezonePreference()}
        utcOffset={timezoneManager.getUTCOffset()}
        startTime={this.props.startTime}
        endTime={this.props.endTime}
        onChange={async (startTime, endTime) => {
          this.props.onQueryWindowChange(startTime, endTime);
          this.setState({ selectedMetadata: undefined, snapshotMetadata: undefined });
          await this.loadSnapshotMetadata(startTime, endTime);
        }}
        isValidRange={(startTime, endTime) => {
          if (endTime - startTime > queryWindowSizeInMs) {
            return { valid: false, errorMessage: `Query window size cannot exceed ${queryWindowSize}.` };
          }
          return { valid: true };
        }}
      />
    );
  }

  render() {
    const tableDefintion: TableProps.ColumnDefinition<ArtifactMetadata>[] = [
      {
        id: 'snapshotGenerationTime',
        header: this.props.bundle.getMessage('STATION_PAGE:GENERATION_TIME'),
        cell: (item) => timezoneManager.convertTimestampToString(item.creationTime),
      },
      {
        id: 'snapshotId',
        header: this.props.bundle.getMessage('STATION_PAGE:ARTIFACT_ID'),
        // todo: uncomment the following to allow user to open the snapshot in ufraaviz.
        // cell: (item) => (
        //   <Link
        //     onFollow={(evt) => {
        //       // prevent the browser from sending requests to backend
        //       evt.preventDefault();
        //       this.props.onSnapshotClick(item.artifactId);
        //     }}
        //   >
        //     {item.artifactId}
        //   </Link>
        // ),

        cell: (item) => item.artifactId,
      },
    ];

    return (
      <Table<ArtifactMetadata>
        header={this.renderHeader()}
        columnDefinitions={tableDefintion}
        loading={this.state.snapshotMetadata === undefined}
        loadingText={this.props.bundle.getMessage('STATION_PAGE:LOADING_ARTIFACTS')}
        items={this.state.snapshotMetadata === undefined ? [] : this.state.snapshotMetadata}
        stickyHeader={false}
        filter={this.renderDateRangePicker()}
        selectionType="multi"
        onSelectionChange={(evt) => {
          this.setState({
            selectedMetadata: evt.detail.selectedItems,
          });
        }}
        selectedItems={this.state.selectedMetadata}
      />
    );
  }
}
