import React from 'react';
import DateRangePicker, { DateRangePickerProps } from '@amzn/awsui-components-react/polaris/date-range-picker';
import { TimezonePreference } from '../utilities';
import moment from 'moment';

export interface TimeRangePickerProps {
  timezonePreference: TimezonePreference;
  // utc offset in minutes, .e.g. PDT timezone -420, PST timezone -480
  utcOffset: number;
  // epoch timestamp in milliseconds
  startTime: number;
  // epoch timestamp in milliseconds
  endTime: number;

  isValidRange?: (startTime: number, endTime: number) => DateRangePickerProps.ValidRangeResult | DateRangePickerProps.InvalidRangeResult;
  onChange?: (startTime: number, endTime: number) => void;
}

interface TimeRangePickerState {}
export default class TimeRangePicker extends React.Component<TimeRangePickerProps, TimeRangePickerState> {
  generateI18nStrings() {
    let timezone: string = '';
    switch (this.props.timezonePreference) {
      case 'station_tz':
        timezone = 'Station';
        break;
      case 'user_tz':
        timezone = 'User';
        break;
      case 'utc_tz':
        timezone = 'UTC+0';
        break;
    }
    // reference: https://code.amazon.com/packages/AWS-UI-Examples/blobs/b6dee9bac1c06d1db55c59858ff2b2b53a6e07b4/--/src/react/table/table-date-filter.index.jsx#L262
    const dateRangeFilterI18nStrings: DateRangePickerProps.I18nStrings = {
      todayAriaLabel: 'Today',
      nextMonthAriaLabel: 'Next month',
      previousMonthAriaLabel: 'Previous month',
      customRelativeRangeDurationLabel: 'Duration',
      customRelativeRangeDurationPlaceholder: 'Enter duration',
      customRelativeRangeOptionLabel: 'Custom duration',
      customRelativeRangeOptionDescription: 'Set a custom range in the past',
      customRelativeRangeUnitLabel: 'Unit of time',
      formatUnit: (unit, value) => (value === 1 ? unit : `${unit}s`),
      formatRelativeRange: (range) => {
        const unit = range.amount === 1 ? range.unit : `${range.unit}s`;
        return `Last ${range.amount} ${unit}`;
      },
      dateTimeConstraintText: '',
      relativeModeTitle: 'Relative range',
      absoluteModeTitle: `Absolute range (${timezone} TZ)`,
      relativeRangeSelectionHeading: 'Choose a range',
      startDateLabel: 'Start date',
      endDateLabel: 'End date',
      startTimeLabel: 'Start time',
      endTimeLabel: 'End time',
      clearButtonLabel: 'Clear',
      cancelButtonLabel: 'Cancel',
      applyButtonLabel: 'Apply',
    };
    return dateRangeFilterI18nStrings;
  }

  // In ISO8601 format without timezone, e.g.: 2021-04-06T17:23:50
  renderTimeInBrowserLocalTimezone(timestamp: number): string {
    return moment(timestamp).format('YYYY-MM-DDTHH:mm:ss');
  }

  render() {
    return (
      <DateRangePicker
        i18nStrings={this.generateI18nStrings()}
        value={{
          startDate: this.renderTimeInBrowserLocalTimezone(this.props.startTime),
          endDate: this.renderTimeInBrowserLocalTimezone(this.props.endTime),
          type: 'absolute',
        }}
        timeInputFormat="hh:mm"
        onChange={(evt) => {
          const value = evt.detail.value;
          if (this.props.onChange !== undefined && value !== null) {
            if (value.type === 'absolute') {
              // convert to epoch timestamp
              const startTimestamp = new Date(value.startDate).getTime();
              const endTimestamp = new Date(value.endDate).getTime();
              return this.props.onChange(startTimestamp, endTimestamp);
            } else if (value.type === 'relative') {
              const endTimestamp = Date.now();
              const startTimestamp = endTimestamp - value.amount * timeUnitToMs(value.unit);
              return this.props.onChange(startTimestamp, endTimestamp);
            }
          }
        }}
        placeholder="Enter a query window"
        isValidRange={(value: DateRangePickerProps.Value | null) => {
          if (this.props.isValidRange !== undefined && value !== null) {
            if (value.type === 'absolute') {
              // convert to epoch timestamp
              // validRange value doesn't have the timezone proportion, which is different from onChange value.
              const startTimestamp = moment(value.startDate).utcOffset(this.props.utcOffset).toDate().getTime();
              const endTimestamp = moment(value.endDate).utcOffset(this.props.utcOffset).toDate().getTime();
              return this.props.isValidRange(startTimestamp, endTimestamp);
            } else if (value.type === 'relative') {
              const endTimestamp = Date.now();
              const startTimestamp = endTimestamp - value.amount * timeUnitToMs(value.unit);
              return this.props.isValidRange(startTimestamp, endTimestamp);
            }
          }
          return { valid: true };
        }}
        timeOffset={this.props.utcOffset}
        showClearButton={false}
        relativeOptions={[
          {
            type: 'relative',
            amount: 1,
            unit: 'hour',
            key: 'one-hour',
          },
          {
            type: 'relative',
            amount: 2,
            unit: 'hour',
            key: 'two-hour',
          },
        ]}
      />
    );
  }
}

function timeUnitToMs(timeUnit: DateRangePickerProps.TimeUnit): number {
  switch (timeUnit) {
    case 'second':
      return 1_000;
    case 'minute':
      return 60_000;
    case 'hour':
      return 3_600_000;
    case 'day':
      return 24 * timeUnitToMs('hour');
    case 'week':
      return 7 * timeUnitToMs('day');
    case 'month':
      return 31 * timeUnitToMs('day'); // assume each month has 31 days
    case 'year':
      return 365 * timeUnitToMs('day');
  }
}
