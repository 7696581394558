import { LocalizationContextBuilder } from '@amzn/arb-tools';
import arbManifest from '../build/translations/arbManifest';

/**
 * Gets MessageBundleManager (mbm) options based on locale string of user.
 * This function sets the locale preferred by user and a default fallback (en-US).
 * @param localeString locale string to set localization context i.e. en-US, ja-JP
 * @returns
 */
export function getMbmOptions(localeString: string) {
  // define localization context proivder
  const localizationContextBuilder = new LocalizationContextBuilder().withDefaultLocale('en-US');

  return {
    arbManifest,
    defaultLocalizationContext: localizationContextBuilder.withLocale(localeString).build(),
    resolveBundleUrl: (locale?: string) => {
      // create unique request as workaround to broswer/CDN caching translations
      return locale ? `/static/translations/${locale}?v=${Date.now()}` : undefined;
    },
  };
}
