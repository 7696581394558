import React from 'react';
import { timezoneManager } from '../utilities';

export interface ClockProps {
  readonly timezone: string;
}

export interface ClockState {
  time: string;
}

export default class Clock extends React.Component<ClockProps, ClockState> {
  private updateTimeHandle?: NodeJS.Timeout;

  constructor(props: ClockProps) {
    super(props);
    const initialTime = timezoneManager.convertTimestampToString(Date.now(), {
      tz: this.props.timezone,
    });

    this.state = { time: initialTime };
  }

  componentDidMount() {
    this.updateTimeHandle = setInterval(() => {
      this.setState({ time: timezoneManager.convertTimestampToString(Date.now(), { tz: this.props.timezone }) });
    }, 1000);
  }

  componentWillUnmount() {
    if (this.updateTimeHandle) {
      clearInterval(this.updateTimeHandle);
      this.updateTimeHandle = undefined;
    }
  }

  render() {
    return this.state.time;
  }
}
