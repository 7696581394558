import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import ufraaVizClient, { ArtifactMetadata } from '../../clients';
import { timezoneManager } from '../../utilities';
import Table, { TableProps } from '@amzn/awsui-components-react/polaris/table';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import TimeRangePicker from '../../shared-components/time-range-picker';
import { WithBundleProps } from '@amzn/react-arb-tools';

interface Props extends WithBundleProps {
  readonly serviceAreaId: string;
  readonly startTime: number;
  readonly endTime: number;
  readonly onQueryWindowChange: (startTime: number, endTime: number) => void;
}

interface State {
  readonly routeSnapshotMetadata?: ArtifactMetadata[];
  readonly selectedMetadata?: ArtifactMetadata[];
  readonly isDownloading?: boolean;
}

const MAXIMUM_QUERY_WINDOW_SIZE_IN_MS = 6 * 3600 * 1000;
const MAXIMUM_QUERY_WINDOW_SIZE_TEXT = '6 hours';

export default class RouteSnapshotTable extends React.Component<Props, State> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      routeSnapshotMetadata: undefined,
      selectedMetadata: undefined,
      isDownloading: false,
    };
  }

  async componentDidMount() {
    await this.loadRouteSnapshotMetadata(this.props.startTime, this.props.endTime);
  }

  async loadRouteSnapshotMetadata(startTime: number, endTime: number) {
    try {
      const routeSnapshotMetadata = await ufraaVizClient.listArtifactMetadata({
        scope: this.props.serviceAreaId,
        artifactType: 'SNAPSHOT_ROUTES',
        startTime,
        endTime,
        timeUnits: 'MILLISECONDS',
      });
      this.setState({
        routeSnapshotMetadata,
      });
    } catch (err) {
      this.renderError(err);
      this.setState({ routeSnapshotMetadata: [] });
    }
  }

  async downloadArtifact() {
    this.setState({ isDownloading: true });
    try {
      ufraaVizClient.downloadArtifactsChronologically(this.state.selectedMetadata);
    } catch (err) {
      this.renderError(err);
    } finally {
      this.setState({ isDownloading: false });
    }
  }

  renderError(err: any) {
    this.context.addNotification({
      type: 'error',
      header: 'Error',
      content: typeof err?.response?.data === 'string' ? err?.response?.data : err?.message,
    });
  }

  renderHeader() {
    return (
      <Header
        variant="h2"
        // todo: display the time window
        // counter={this.state.assignmentPlanMetadataList?.length.toString()}
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            {/* <Button iconAlign="right" iconName="external" disabled={this.state.selectedMetadata?.length !== 1}
              onClick={async ()=>{
                if (this.state.selectedMetadata?.length === 1) {
                  const url = await this.getSnapshotPresignedUrl(this.state.selectedMetadata[0].snapshotId);
                  window.open(url);
                }
              }}
            >Open</Button> */}

            <Button
              variant="primary"
              disabled={this.state.selectedMetadata === undefined || this.state.selectedMetadata?.length === 0}
              loading={this.state.isDownloading}
              onClick={async () => await this.downloadArtifact()}
            >
              {this.props.bundle.getMessage('STATION_PAGE:DOWNLOAD')}
            </Button>
          </SpaceBetween>
        }
      >
        {this.props.bundle.getMessage('STATION_PAGE:ROUTE_SNAPSHOTS')}
      </Header>
    );
  }

  renderDateRangePicker() {
    return (
      <TimeRangePicker
        timezonePreference={timezoneManager.getInUseTimezonePreference()}
        utcOffset={timezoneManager.getUTCOffset()}
        startTime={this.props.startTime}
        endTime={this.props.endTime}
        onChange={async (startTime, endTime) => {
          this.props.onQueryWindowChange(startTime, endTime);
          this.setState({ selectedMetadata: undefined, routeSnapshotMetadata: undefined });
          await this.loadRouteSnapshotMetadata(startTime, endTime);
        }}
        isValidRange={(startTime, endTime) => {
          if (endTime - startTime > MAXIMUM_QUERY_WINDOW_SIZE_IN_MS) {
            return { valid: false, errorMessage: `Query window size cannot exceed ${MAXIMUM_QUERY_WINDOW_SIZE_TEXT}.` };
          }
          return { valid: true };
        }}
      />
    );
  }

  render() {
    const tableDefinition: TableProps.ColumnDefinition<ArtifactMetadata>[] = [
      // todo: As for now, we only have the plan generation time, but it will be intuitive to have a planner input generation to display on the UI.
      // Update LordCommander to include a planner input generation time.
      {
        id: 'snapshotGenerationTime',
        header: this.props.bundle.getMessage('STATION_PAGE:GENERATION_TIME'),
        cell: (item) => timezoneManager.convertTimestampToString(item.creationTime),
      },
      {
        id: 'snapshotId',
        header: this.props.bundle.getMessage('STATION_PAGE:ARTIFACT_ID'),
        cell: (item) => (
          <Link
            href={`/route-snapshot/${encodeURIComponent(item.artifactId)}`}
            onFollow={(evt) => {
              // prevent browser from sending requests to backend. Doing in-browser navgiation.
              evt.preventDefault();
              if (typeof evt.detail.href === 'string') {
                this.context.onGoto(evt.detail.href);
              }
            }}
          >
            {item.artifactId}
          </Link>
        ),
      },
    ];

    return (
      <Table<ArtifactMetadata>
        header={this.renderHeader()}
        columnDefinitions={tableDefinition}
        loading={this.state.routeSnapshotMetadata === undefined}
        loadingText={this.props.bundle.getMessage('STATION_PAGE:LOADING_ARTIFACTS')}
        items={this.state.routeSnapshotMetadata === undefined ? [] : this.state.routeSnapshotMetadata}
        stickyHeader={false}
        filter={this.renderDateRangePicker()}
        selectionType="multi"
        onSelectionChange={(evt) => {
          this.setState({
            selectedMetadata: evt.detail.selectedItems,
          });
        }}
        selectedItems={this.state.selectedMetadata}
        // filter={this.renderFilter()}
        // pagination={this.renderPagination()}
      />
    );
  }
}
