import { routeSearchTypeToText } from '../constants';
import { UserSearch, UserSearchValidationMessages, ValidSearch } from '../models';

/**
 * compare two user search, return true if they are same
 * @param search1
 * @param search2
 */
export function compareUserSearch(search1: UserSearch | undefined, search2: UserSearch | undefined): boolean {
  if (search1 !== undefined && search2 !== undefined) {
    return (
      search1.text === search2.text &&
      search1.type === search2.type &&
      search1.version === search2.version &&
      search1.dispatchDate === search2.dispatchDate &&
      search1.serviceAreaId === search2.serviceAreaId
    );
  } else if (search1 === undefined && search2 === undefined) {
    return true;
  } else {
    return false;
  }
}

type Mutable<T> = {
  -readonly [P in keyof T]: T[P];
};

/**
 * Validate if the search is valid. The method is called right before user click the "Search" button. If the validation error messages are returned, they will
 * displayed under the input box.
 *
 * If search by routeId, then only text is needed.
 * If search by trackingId or trId, then text and serviceAreaId are needed.
 * If search by transporterId, then text, serviceAreaId, and dispatchDate are needed.
 * @param userSearch
 * @returns
 */
export function validateUserSearch(userSearch: UserSearch): UserSearchValidationMessages | undefined {
  const errorMessages: Mutable<UserSearchValidationMessages> = {};
  let hasError: boolean = false;

  // all search types need text.
  if (userSearch.text.trim().length === 0) {
    hasError = true;
    errorMessages.textErrorMessage = `${routeSearchTypeToText[userSearch.type]} can't be empty.`;
  }

  if (userSearch.type === 'routeId') {
    if (typeof userSearch.version === 'string' && userSearch.version.trim().length !== 0) {
      const version = Number(userSearch.version.trim());
      if (Number.isNaN(version) || version <= 0) {
        hasError = true;
        errorMessages.versionErrorMessage = 'Version needs to be a positive integer.';
      }
    }
  }

  if (userSearch.type === 'trackingId' || userSearch.type === 'trId' || userSearch.type === 'transporterId') {
    if (userSearch.serviceAreaId === undefined || userSearch.serviceAreaId.trim().length === 0) {
      hasError = true;
      errorMessages.searchAreaIdErrorMessage = "Service area Id can't be empty.";
    }
  }

  // todo: validate dispatch date format.

  return hasError ? errorMessages : undefined;
}

/**
 * Convert a user search to a valid search. User search can be invalid, for example, the user can input a wrong version either from the input box, or url.
 * Bad input in the input box is captured by the validateUserSearch, this method is mainly to capture bad input from url. The method is called right before
 * the frontend sends a request to the backend. The method throws exception is the user search is invalid, and the exception message will be displayed
 * in the UI
 * @param userSearch
 */
export function convertUserSearchToValidSearch(userSearch: UserSearch): ValidSearch {
  switch (userSearch.type) {
    case 'routeId': {
      let version: number | undefined = undefined;
      if (userSearch.version !== undefined && userSearch.version.trim().length > 0) {
        version = Number(userSearch.version.trim());
        if (Number.isNaN(version) || version <= 0) {
          throw new Error('version needs to be a positive integer');
        }
      }
      const routeId = userSearch.text.trim();
      if (routeId.length === 0) {
        throw new Error('missing routeId');
      }
      return {
        type: 'routeId',
        routeId: routeId,
        version: version,
      };
    }

    case 'correlationId': {
      const correlationId = userSearch.text.trim();
      if (correlationId.length === 0) {
        throw new Error('missing correlationId');
      }

      return {
        type: 'correlationId',
        correlationId: correlationId,
      };
    }

    case 'trId':
    case 'trackingId':
    case 'transporterId': {
      if (userSearch.text.trim().length === 0) {
        throw new Error(`missing ${userSearch.type}`);
      }

      if (userSearch.serviceAreaId === undefined || userSearch.serviceAreaId.trim().length === 0) {
        throw new Error('missing serviceAreaId');
      }

      let dispatchDate: string | undefined = undefined;
      if (typeof userSearch.dispatchDate === 'string' && userSearch.dispatchDate.trim().length > 0) {
        dispatchDate = userSearch.dispatchDate.trim();
      }

      return {
        type: userSearch.type,
        propertyId: userSearch.text.trim(),
        serviceAreaId: userSearch.serviceAreaId.trim(),
        dispatchDate: dispatchDate,
      };
    }
    default: {
      throw new Error(`Invalid search type ${userSearch.type}`);
    }
  }
}
