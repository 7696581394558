import React from 'react';
import { Order, TransportRequestDimensions } from '../../clients';
import Box from '@amzn/awsui-components-react/polaris/box';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Header from '@amzn/awsui-components-react/polaris/header';
import Table from '@amzn/awsui-components-react/polaris/table';
import { NOT_AVAILABLE } from './utilities/constants';

export interface OrderDetailProps {
  readonly orderDetails: Order;
}

export class OrderDetails extends React.Component<OrderDetailProps, {}> {
  render() {
    return Array.from(this.props.orderDetails.constituentTrs).map((tr, index) => {
      return <Box key={index}>{tr}</Box>;
    });
  }
}

export interface OrderDetailsModalProps extends OrderDetailProps {
  readonly onClose: () => void;
}

export class OrderDetailModal extends React.Component<OrderDetailsModalProps, {}> {
  render() {
    return (
      <Modal
        onDismiss={this.props.onClose}
        header={
          /* The package count is number of execution TRs in the order, so removing the placeholder TR */
          <Header variant="h3" description={`Package count: ${this.props.orderDetails.constituentTrs.size - 1}`}>
            More Details
          </Header>
        }
        visible={true}
        closeAriaLabel="Close modal"
        size="large"
      >
        <Table
          resizableColumns={true}
          columnDefinitions={[
            {
              id: 'trId',
              header: 'TRs in order',
              cell: (item) => <Box>{item.trId ?? NOT_AVAILABLE}</Box>,
              isRowHeader: true,
            },
            {
              id: 'volume',
              header: 'Volume (Cubic Ft.)',
              cell: (item) => <Box>{item.volume && item.volume.value ? item.volume.value.toFixed(2) : NOT_AVAILABLE}</Box>,
            },
            {
              id: 'weight',
              header: 'Weight (LB)',
              cell: (item) => <Box>{item.weight && item.weight.value ? item.weight.value.toFixed(2) : NOT_AVAILABLE}</Box>,
            },
            {
              id: 'dimensions',
              header: 'Dimensions (In.)',
              cell: (item) => <Box>{item.dimensions?.length?.toFixed(0) + ' x ' + item.dimensions?.width?.toFixed(0) + ' x ' + item.dimensions?.height?.toFixed(0) ?? NOT_AVAILABLE}</Box>,
            },
            {
              id: 'dimensionType',
              header: 'Dimension Type',
              cell: (item) => <Box>{item.dimensionType ?? NOT_AVAILABLE}</Box>,
            },
          ]}
          items={Object.values(this.props.orderDetails.trIdToTrDetails).map((obj: TransportRequestDimensions) => obj)}
          stickyHeader={true}
        />
      </Modal>
    );
  }
}
