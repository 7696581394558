import {
  FlexRouteAssignmentPlan,
  FlexRouteAssignmentPlannerInput,
  Transporter,
  Route,
  FungibleAssignment,
  DispatchPlannerType,
  UnAssignedRoute,
  UnAssignedTransporterReason,
  FungibleAssignmentStatus,
} from '../../../clients';
import { AssignmentConverter } from './assignment-converter';

export class FungibleAssignmentConverter implements AssignmentConverter<FungibleAssignment> {
  readonly type: DispatchPlannerType;

  constructor() {
    this.type = 'FUNGIBLE_ASSIGNMENT_ALGORITHM';
  }

  /**
   * The function has no effect for now as fungible algorithm doesn't support IO yet.
   *
   * Convert IOAssignment to the UI assignment model.
   *
   * The IOAssignment is obtained from plan.instantOfferAssignments
   *
   * @param ioAssignment mapping between IO transporter Id to the route object.
   * @param transporterGroupIdToTransporters
   */
  private convertIOAssignment(ioAssignment: Record<string, Route>, transporterGroupIdToTransporters: Map<string, Transporter[]>): FungibleAssignment[] {
    const transporterGroupIds = Object.keys(ioAssignment);

    const assignments: FungibleAssignment[] = [];
    for (let transporterGroupId of transporterGroupIds) {
      const transporters = transporterGroupIdToTransporters.get(transporterGroupId);
      assignments.push({
        route: [ioAssignment[transporterGroupId]],
        transporter: {
          transporterGroupId: transporterGroupId,
          transporters: transporters ? transporters : [],
        },
        assignmentStatus: 'Planned',
      });
    }
    return assignments;
  }

  /**
   * Convert Fungible block assignment to the UI assignment model.
   * @param blockAssignment Fungible block assignment
   * @param transporterGroupIdToTransporters
   */
  private convertBlockAssignment(blockAssignment: Record<string, Route[]>, transporterGroupIdToTransporters: Map<string, Transporter[]>): FungibleAssignment[] {
    const transporterGroupIds = Object.keys(blockAssignment);

    const assignments: FungibleAssignment[] = [];
    for (let transporterGroupId of transporterGroupIds) {
      const transporters = transporterGroupIdToTransporters.get(transporterGroupId);
      assignments.push({
        route: blockAssignment[transporterGroupId],
        transporter: {
          transporterGroupId: transporterGroupId,
          transporters: transporters ? transporters : [],
        },
        assignmentStatus: 'Planned',
      });
    }
    return assignments;
  }

  /**
   * Build the mapping between transporterGroupId to an array of transporters
   * @param transporterIdToTransporterGroupId
   * @param transporterIdToTransporter
   * @returns
   */
  private convertTransporterGroupIdToTransporterId(transporterIdToTransporterGroupId: Record<string, string>, transporterIdToTransporter: Map<string, Transporter>): Map<string, Transporter[]> {
    const transporterGroupIdToTransporterIds: Map<string, Set<string>> = new Map();
    const entries = Object.entries(transporterIdToTransporterGroupId);
    for (let [transporterId, transporterGroupId] of entries) {
      const transporterIds = transporterGroupIdToTransporterIds.get(transporterGroupId);
      if (transporterIds) {
        transporterIds.add(transporterId);
      } else {
        const newTransporterIds: Set<string> = new Set();
        newTransporterIds.add(transporterId);
        transporterGroupIdToTransporterIds.set(transporterGroupId, newTransporterIds);
      }
    }

    const transporterGroupIdToTransporters: Map<string, Transporter[]> = new Map();
    transporterGroupIdToTransporterIds.forEach((transporterIds, transporterGroupId) => {
      const transporters: Transporter[] = [];
      for (let transporterId of Array.from(transporterIds)) {
        const transporter = transporterIdToTransporter.get(transporterId);
        if (transporter) {
          transporters.push(transporter);
        }
      }
      transporterGroupIdToTransporters.set(transporterGroupId, transporters);
    });

    return transporterGroupIdToTransporters;
  }

  /**
   *
   * @param routes An array of assigned routes.
   * @param transporters An array of assigned transporters
   */
  private convertAssignedRoutes(routes: Route[], transporters: Transporter[]): FungibleAssignment[] {
    const transporterIdToTransporter: Map<string, Transporter> = new Map();

    transporters.forEach((transporter) => transporterIdToTransporter.set(transporter.transporterId, transporter));

    const results: FungibleAssignment[] = [];
    for (let route of routes) {
      const assignedTransporter = route.assignedTransporter ? transporterIdToTransporter.get(route.assignedTransporter) : undefined;
      if (assignedTransporter) {
        results.push({
          route: [route],
          transporter: {
            transporterGroupId:
              assignedTransporter.type === 'BLOCK'
                ? `${assignedTransporter.shiftStartTime}/${assignedTransporter.shiftEndTime}:${assignedTransporter.transporterId}`
                : assignedTransporter.transporterId,
            transporters: [assignedTransporter],
          },
          assignmentStatus: 'Assigned',
        });
      } else {
        results.push({
          route: [route],
          transporter: route.assignedTransporter ? route.assignedTransporter : '-',
          assignmentStatus: 'Assigned',
        });
      }
    }

    return results;
  }

  /**
   * convert unassigned routes
   * @param unassignedRoutes An array of unassigned routes, each unassigned route includes a route and a reason code.
   */
  private convertUnassignedRoutes(unassignedRoutes: UnAssignedRoute[]): FungibleAssignment[] {
    return unassignedRoutes.map((unassignedRoute) => ({
      route: [unassignedRoute.route],
      assignmentStatus: unassignedRoute.reason,
    }));
  }

  private convertUnassignedTransporters(transporters: Transporter[], reason: UnAssignedTransporterReason): FungibleAssignment[] {
    return transporters.map((transporter) => ({
      transporter: transporter.transporterId,
      transporterUnAssignedReason: reason,
    }));
  }

  private convertToFilteredOutRoutes(routes: Route[], status: FungibleAssignmentStatus): FungibleAssignment[] {
    return routes.map((route) => ({
      route: [route],
      assignmentStatus: status,
    }));
  }

  convert(plannerInput: FlexRouteAssignmentPlannerInput, plan: FlexRouteAssignmentPlan): FungibleAssignment[] {
    let assignments: FungibleAssignment[] = [];

    // build a transporterId to transporter mapping
    // todo: partition transporters into IO and block groups if fungible supports IO in the future.
    const transporterIdToTransporter: Map<string, Transporter> = new Map();
    for (let transporter of plannerInput.transporters) {
      transporterIdToTransporter.set(transporter.transporterId, transporter);
    }

    // build a mapping between transporterGroupId to its associated transporters
    const transporterGroupIdToTransporters = this.convertTransporterGroupIdToTransporterId(plan.transporterToTransporterGroupMap, transporterIdToTransporter);

    // convert instant offer assignments
    assignments = assignments.concat(this.convertIOAssignment(plan.instantOfferAssignments, transporterGroupIdToTransporters));

    // convert fungible assignments
    assignments = assignments.concat(this.convertBlockAssignment(plan.fungibleBlockAssignments, transporterGroupIdToTransporters));

    // find assigned route/transporter pairs..
    if (plannerInput.preProcessingFilteredOutRoutes && plannerInput.preProcessingFilteredOutTransporters) {
      const assignedRoutes = plannerInput.preProcessingFilteredOutRoutes['ASSIGNED'];
      const assignedTransporters = plannerInput.preProcessingFilteredOutTransporters['ASSIGNED'];
      if (assignedRoutes && assignedTransporters) {
        assignments = assignments.concat(this.convertAssignedRoutes(assignedRoutes, assignedTransporters));
      }
    }

    if (plannerInput.preProcessingFilteredOutRoutes) {
      if (plannerInput.preProcessingFilteredOutRoutes.UNSUPPORTED_PROVIDER_TYPE) {
        const filteredOutRoutes = plannerInput.preProcessingFilteredOutRoutes.UNSUPPORTED_PROVIDER_TYPE;
        assignments = assignments.concat(this.convertToFilteredOutRoutes(filteredOutRoutes, 'Unsupported'));
      }
    }

    // add transportersToBeReleased to the assignment result
    if (plan.transportersToBeReleased) {
      assignments = assignments.concat(this.convertUnassignedTransporters(plan.transportersToBeReleased, 'Discharged'));
    }

    // add transportersWithAutoAssignmentNotEnabled to the assignment result
    if (plan.transportersWithAutoAssignmentNotEnabled) {
      assignments = assignments.concat(this.convertUnassignedTransporters(plan.transportersWithAutoAssignmentNotEnabled, 'AutoAssignDisabled'));
    }

    // add transportersWithoutAssignment to the assignment result
    if (plan.transportersWithoutAssignment) {
      assignments = assignments.concat(this.convertUnassignedTransporters(plan.transportersWithoutAssignment, 'Waiting'));
    }

    assignments = assignments.concat(this.convertUnassignedRoutes(plan.unAssignedRoutes));

    return assignments;
  }
}
