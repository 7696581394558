import { Artifact, ExecutionPlan } from '../../clients';

import { ActionType } from '../../clients';
import * as models from './models';

const MS_MULTIPLIER = 1000;

interface ExternalIdMap {
  orderId: string;
  trId: models.TrId;
}

/*
 * This class parses artifacts into a series of related objects.
 * Rather than contain each other directly, as in the original artifact,
 *   the parsed objects point to each other by keys, which makes it possible to query and filter along different slices.
 * To see the parsed models, check the './models.ts' file.
 *
 * Currently the parser class works only with execution plans.
 * When extending to other plans it's probably appropiate to have a specialized class per artifact, plus this as a central parser class.
 * All the information should end in as small a set of "tables" as feasible.
 *
 * This parser iterates through many of the lists within the execution.
 * When it finds groups of information, say about a TR, it spins this off into a TR object, and adds it to the TR set.
 *
 * The primary "tables" in the setup ("actions", "trs", "orders", "routes") are all maps, from a string id to the relevant model.
 * If more info is found about an existing TR, it gets merged into the existing entry. This prevents duplication.
 */
export default class TimelineParser {
  // once the artifacts are loaded in, they stay for the object's lifetime
  // this makes it safe to cache intermediate results
  private executionPlans: Artifact<ExecutionPlan>[] = new Array<Artifact<ExecutionPlan>>();

  public parsedInfo: models.ExecutionPlanInformation[] = [];
  public readonly allTrs: Array<models.TrId> = [];
  public readonly allOrders: Array<models.OrderId> = [];

  public constructor(executionPlans: Artifact<ExecutionPlan>[]) {
    if (executionPlans.length === 0) {
      return;
    }

    this.executionPlans = executionPlans;
    this.parsedInfo = this.executionPlans.map((plan) => this.parseExecutionPlan(plan));

    this.allTrs = this.getTrListings();
    this.allOrders = this.getOrderListings();
  }

  private parseExecutionPlan(plan: any): models.ExecutionPlanInformation {
    const timestamp = this.parseSnapshotTime(plan);
    const actionStore: models.ActionMapping = new Map();
    const trStore: models.TrMapping = new Map();
    const orderStore: models.OrderMapping = new Map();
    const routeStore: models.RouteMapping = new Map();

    plan.artifact.plannedRoutes.forEach((route: any) => {
      const ordersForThisRoute: Set<string> = new Set<string>();
      let routeDuration: number = 0;

      route.actions.forEach((action: any) => {
        const actionDuration = action.seconds * MS_MULTIPLIER;
        routeDuration += actionDuration;

        const ids = TimelineParser.validateIdMapping(action.externalIdMap);
        if (ids === undefined) {
          return;
        }

        ordersForThisRoute.add(ids.orderId);

        const order = orderStore.get(ids.orderId) ?? { routeId: route.id, constituentTrs: new Set<models.TrId>() };
        order.constituentTrs.add(ids.trId);
        orderStore.set(ids.orderId, order);

        const tr = trStore.get(ids.trId) ?? { orderId: ids.orderId, relatedActions: new Set<models.ActionId>() };
        tr.relatedActions.add(action.id);
        trStore.set(ids.trId, tr);

        actionStore.set(action.id, {
          actionType: action.type.toUpperCase() as ActionType,
          duration: actionDuration,
          scheduledActionTime: action.scheduledTime * MS_MULTIPLIER,
          lockStatus: action.lockStatus,
        });
      });

      routeStore.set(route.id, {
        deliveryAssociate: route.deliveryAssociate,
        driverType: route.driverType,
        duration: routeDuration,
        lockStatus: route.locked ? 'Locked' : 'Unlocked',
        constituentOrders: ordersForThisRoute,
      });
    });

    plan.artifact.unplannedTasks.forEach((task: any) => {
      const ids = TimelineParser.validateIdMapping(task.externalIdMap);
      if (ids === undefined) {
        return;
      }

      const order = orderStore.get(ids.orderId) ?? { routeId: 'unplanned', constituentTrs: new Set<models.TrId>() };
      order.constituentTrs.add(ids.trId);
      orderStore.set(ids.orderId, order);

      const tr = trStore.get(ids.trId) ?? { orderId: ids.orderId, relatedActions: new Set<models.ActionId>() };
      tr.relatedActions.add(task.taskId);
      trStore.set(ids.trId, tr);

      actionStore.set(task.taskId, { category: task.category });
    });

    return { timestamp, actions: actionStore, trs: trStore, orders: orderStore, routes: routeStore };
  }

  private static validateIdMapping(candidate: any): ExternalIdMap | undefined {
    if (candidate === null || candidate === undefined) {
      return undefined;
    }

    if (candidate.ORDER_ID === undefined || candidate.TR_ID === undefined) {
      return undefined;
    }

    return { orderId: candidate.ORDER_ID, trId: candidate.TR_ID };
  }

  private getTrListings(): string[] {
    const trs = new Set<models.TrId>();
    this.parsedInfo.forEach((plan) => {
      plan.trs.forEach((_, key) => trs.add(key));
    });
    return Array.from(trs);
  }

  private getOrderListings(): string[] {
    const orders = new Set<models.OrderId>();
    this.parsedInfo.forEach((plan) => {
      plan.orders.forEach((_, key) => orders.add(key));
    });
    return Array.from(orders);
  }

  private parseSnapshotTime(plan: Artifact<ExecutionPlan>): number {
    return (plan.artifact as any).creationTime * MS_MULTIPLIER;
  }
}
