import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import { FlexRouteAssignmentPlannerInput, FlexRouteAssignmentPlan, ServiceAreaDetails } from '../../clients';
import AssignmentPlanContent from './assignment-plan-content';
import { handleJsonFileDropEvent } from '../../utilities';
import { DEFAULT_TITLE } from '../../constants';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import styles from './drag-and-drop-assignment-plan-page.module.scss';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import { assertAssignmentPlannerInput } from './utilities';

interface Props {
  readonly activeTab?: string;
  readonly onTabChange: (tab: string) => void;
  readonly filteringText?: string;
  readonly onFilteringTextChange: (text: string) => void;
}

interface State {
  /**
   * undefined indicates the plan is still loading
   * null indicates the plan doesn't exist.
   */
  readonly plan?: FlexRouteAssignmentPlan | null;
  readonly plannerInput?: FlexRouteAssignmentPlannerInput | null;
  readonly plannerInputDropZoneActive?: boolean;
  readonly plannerInputErrorMessage?: string;
  readonly plannerInputFilePath?: string;
  readonly planDropZoneActive?: boolean;
  readonly planErrorMessage?: string;
  readonly planFilePath?: string;
}

type Mutable<T> = {
  -readonly [P in keyof T]: T[P];
};

export class DragAndDropAssignmentPlanPage extends React.Component<Props, State> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      plan: undefined,
      plannerInput: undefined,
      plannerInputDropZoneActive: false,
      planDropZoneActive: false,
    };
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.updateBreadcrumbItems([
      {
        text: 'Drag and drop',
        href: `/assignment-plan/drag-and-drop`,
      },
    ]);
  }

  private updateContext(plannerInput: FlexRouteAssignmentPlannerInput) {
    this.context.updateStationTimezone(plannerInput.serviceArea.timeZoneId);
    this.context.updateBreadcrumbItems([
      {
        text: `${plannerInput.serviceArea.serviceAreaName}`,
        href: `/service-areas/${plannerInput.serviceArea.serviceAreaId}`,
      },
      {
        text: 'Drag and drop',
        href: `/assignment-plan/drag-and-drop`,
      },
    ]);
  }

  // cleanup
  componentWillUnmount() {
    this.context.updateStationTimezone(undefined);
    document.title = DEFAULT_TITLE;
  }

  render() {
    return (
      <SpaceBetween direction="vertical" size="m">
        {this.renderDragAndDrop()}
        {this.renderContent()}
      </SpaceBetween>
    );
  }

  private renderContent() {
    if (this.state.plannerInput === undefined && this.state.plan === undefined) {
      return null;
    }
    try {
      let serviceAreaDetails: ServiceAreaDetails | undefined = undefined;
      if (this.state.plannerInput) {
        serviceAreaDetails = {
          serviceAreaName: this.state.plannerInput.serviceArea.serviceAreaName,
          timeZone: this.state.plannerInput.serviceArea.timeZoneId,
          stationCode: this.state.plannerInput.serviceArea.defaultStationCode,
          serviceAreaId: this.state.plannerInput.serviceArea.serviceAreaId,
          programTypes: [],
        };
      }

      return (
        <AssignmentPlanContent
          mode="drag-and-drop"
          plannerInput={this.state.plannerInput}
          plan={this.state.plan}
          onFilteringTextChange={this.props.onFilteringTextChange}
          onTabChange={this.props.onTabChange}
          serviceAreaDetails={serviceAreaDetails}
          activeTab={this.props.activeTab}
          filteringText={this.props.filteringText}
        />
      );
    } catch (err: any) {
      return (
        <Box textAlign="center">
          <StatusIndicator type="error">Invalid input: {err?.message}</StatusIndicator>
        </Box>
      );
    }
  }

  private renderDragAndDrop() {
    return (
      <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
        {this.renderInputDropZone(
          this.state.plannerInputDropZoneActive ?? false,
          'plannerInputDropZoneActive',
          'Drop assignment planner input here.',
          this.state.plannerInputFilePath,
          this.state.plannerInputErrorMessage,
        )}
        {this.renderInputDropZone(this.state.planDropZoneActive ?? false, 'planDropZoneActive', 'Drop assignment plan here.', this.state.planFilePath, this.state.planErrorMessage)}
      </Grid>
    );
  }

  private renderInputDropZone(isActive: boolean, flagName: 'plannerInputDropZoneActive' | 'planDropZoneActive', promptMessage: string, filePath?: string, errorMessage?: string) {
    let classes = styles['drop-zone'];

    if (isActive) {
      classes = `${classes} ${styles['drop-zone-active']}`;
    }

    return (
      <div
        className={classes}
        onDragEnter={(e) => {
          e.stopPropagation();
          e.preventDefault();
          const obj: Partial<Mutable<State>> = {};
          obj[flagName] = true;
          this.setState(obj);
        }}
        onDragOver={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onDragLeave={(e) => {
          e.stopPropagation();
          e.preventDefault();
          const obj: Partial<Mutable<State>> = {};
          obj[flagName] = false;
          this.setState(obj);
        }}
        onDrop={async (e) => {
          e.stopPropagation();
          e.preventDefault();
          const obj: Partial<Mutable<State>> = {};
          obj[flagName] = false;
          this.setState(obj);
          this.processDataTransfer(flagName, e.dataTransfer);
        }}
      >
        <Box textAlign="center" variant="div">
          <Box>{promptMessage}</Box>
          {typeof filePath === 'string' ? (
            <Box fontSize="body-s" color="text-body-secondary">
              {filePath}
            </Box>
          ) : undefined}
          {typeof errorMessage === 'string' ? <StatusIndicator type="error">{errorMessage}</StatusIndicator> : undefined}
        </Box>
      </div>
    );
  }

  private async processDataTransfer(flagName: 'plannerInputDropZoneActive' | 'planDropZoneActive', dataTransfer: DataTransfer) {
    if (flagName === 'plannerInputDropZoneActive') {
      try {
        const input = await handleJsonFileDropEvent(dataTransfer);
        this.assertPlannerInput(input.data);
        this.setState({ plannerInput: input.data, plannerInputFilePath: input.filename, plannerInputErrorMessage: undefined });
        this.updateContext(input.data);
      } catch (err: any) {
        this.setState({ plannerInputErrorMessage: err.message ?? 'Error while processing planner input.' });
      }
    } else if (flagName === 'planDropZoneActive') {
      try {
        const plan = await handleJsonFileDropEvent(dataTransfer);
        this.assertPlan(plan.data);
        this.setState({ plan: plan.data, planFilePath: plan.filename, planErrorMessage: undefined });
      } catch (err: any) {
        this.setState({ planErrorMessage: err.message ?? 'Error while processing plan.' });
      }
    }
  }

  private assertPlannerInput(data: any): asserts data is FlexRouteAssignmentPlannerInput {
    assertAssignmentPlannerInput(data);
  }

  private assertPlan(data: any): asserts data is FlexRouteAssignmentPlan {
    // todo
  }
}
