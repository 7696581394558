import { FlexRouteAssignmentPlannerInput, FilterOutReason, DispatchPlannerType, AssignmentExecutor, AssignmentMode } from '../../../clients';

export function getAssignmentAlgorithm(plannerInput: FlexRouteAssignmentPlannerInput): DispatchPlannerType {
  return plannerInput.assignmentConfig.dispatchPlannerType ? plannerInput.assignmentConfig.dispatchPlannerType : 'FUNGIBLE_ASSIGNMENT_ALGORITHM';
}

export function getOperatingEntity(plannerInput: FlexRouteAssignmentPlannerInput): string | undefined {
  return plannerInput.serviceArea.operatingEntity;
}

export function convertFilterOutReason(filterOutReason: FilterOutReason): string {
  switch (filterOutReason) {
    case 'ASSIGNED':
      return 'Assigned';
    case 'AUTO_ASSIGN_NOT_ENABLED':
      return 'Auto Assign not enabled';
    case 'NO_SHOW':
      return 'No show';
    case 'OUTSIDE_PLANNING_HORIZON':
      return 'Out of planning horizon';
    case 'UNSUPPORTED_TRANSPORTER_TYPE':
      return 'Unsupported transporter type';
    case 'MULTIPLE_TRANSPORTER_SCHEDULE':
      return 'Mutliple transporter schedule';
    default:
      return filterOutReason;
  }
}

export const ALGORITHM_NAME: Record<DispatchPlannerType, string> = {
  SIMPLE_DISPATCH_PLANNER: 'Simple Dispatch Planner',
  FLEX_DISPATCH_PLANNER: 'Flex Dispatch Planner (FDP)',
  FUNGIBLE_ASSIGNMENT_ALGORITHM: 'Fungible Algorithm',
};

export const EXECUTOR_NAME: Record<AssignmentExecutor, string> = {
  CORE_PLAN_EXECUTOR: 'Plan Executor (PE)',
  ROUTE_DISPATCH_MANAGER: 'Route Dispatch Manager (RDM)',
};
