import React from 'react';
import { FungibleAssignment, TransportRequestDetails } from '../../../clients';
import { timezoneManager, formatDuration } from '../../../utilities';
import Box from '@amzn/awsui-components-react/polaris/box';
import Link from '@amzn/awsui-components-react/polaris/link';
import { AssignmentTableDefinitionProps, AssignmentColumnDefinition } from './models';
import { convertNullable, ROUTE_PROGRAM_TYPE_TO_LABEL } from './utilities';
import { NOT_AVAILABLE, TIME_FORMAT } from './constants';

/**
 * Base table definition is common to both fdp and fungible assignment algorithm. The base table display route information.
 * @param props
 */
export function buildFungibleTableDefinition(props: AssignmentTableDefinitionProps): AssignmentColumnDefinition<FungibleAssignment>[] {
  return [
    {
      id: 'routeName',
      width: 100,
      header: 'Route',
      cell: (item: FungibleAssignment) => {
        if (item.route) {
          return item.route.map((route, index) => {
            return (
              <Box key={index}>
                <Link
                  onFollow={(evt) => {
                    evt.preventDefault();
                    if (item.route) {
                      props.onRouteClick(route);
                    }
                  }}
                >
                  {route.routeName}
                </Link>
              </Box>
            );
          });
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
    {
      id: 'routeId',
      width: 100,
      header: 'Route Id',
      removable: true,
      cell: (item: FungibleAssignment) => {
        if (item.route) {
          return item.route.map((route, index) => {
            return <Box key={index}>{route.routeId}</Box>;
          });
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
    {
      id: 'packageCount',
      width: 100,
      header: 'Package Count',
      removable: true,
      cell: (item: FungibleAssignment) => {
        if (item.route) {
          return item.route.map((route, index) => {
            // only keep "remove" action to avoid duplicated trs.
            let trDetails = route.actions
              ?.filter((action) => action.type === 'REMOVE')
              .filter((action) => action.transportRequestDetails)
              .map((action) => action.transportRequestDetails) as TransportRequestDetails[] | undefined;

            trDetails = trDetails ? trDetails : [];
            return (
              <Box key={index}>
                <Link
                  onFollow={(evt) => {
                    evt.preventDefault();
                    if (props.onTrDetailsClick) {
                      props.onTrDetailsClick(trDetails as TransportRequestDetails[]);
                    }
                  }}
                >
                  {trDetails.length}
                </Link>
              </Box>
            );
          });
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
    {
      id: 'routeStagedStatus',
      width: 100,
      header: 'Staged',
      removable: true,
      cell: (item: FungibleAssignment) => {
        if (item.route) {
          return item.route.map((route, index) => {
            return <Box key={index}>{route.staged ? '👍' : '❌'}</Box>;
          });
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
    {
      id: 'recommendedProviderType',
      width: 100,
      header: 'Provider Target',
      removable: true,
      cell: (item: FungibleAssignment) => {
        if (item.route) {
          return item.route.map((route, index) => {
            let text = NOT_AVAILABLE;
            if (route.recommendedProviderType === 'FLEX') {
              text = 'Flex';
            } else if (typeof route.recommendedProviderType === 'string') {
              text = route.recommendedProviderType;
            }
            return <Box key={index}>{text}</Box>;
          });
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
    {
      id: 'routeProgramType',
      width: 100,
      header: 'Route Program Type',
      removable: true,
      cell: (item: FungibleAssignment) => {
        if (item.route) {
          return item.route.map((route, index) => {
            let text = NOT_AVAILABLE;
            if (typeof route.routeProgramType === 'string') {
              text = ROUTE_PROGRAM_TYPE_TO_LABEL[route.routeProgramType];
            }
            return <Box key={index}>{text}</Box>;
          });
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
    {
      id: 'dispatchWindowStartTime',
      header: 'Dispatch Start Time',
      removable: true,
      cell: (item: FungibleAssignment) => {
        if (item.route) {
          return item.route.map((route, index) => {
            return <Box key={index}>{route.dispatchWindow ? timezoneManager.convertTimestampToString(route.dispatchWindow.start, { format: TIME_FORMAT }) : NOT_AVAILABLE}</Box>;
          });
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
    {
      id: 'dispatchWindowEndTime',
      header: 'Dispatch End Time',
      removable: true,
      cell: (item: FungibleAssignment) => {
        if (item.route) {
          return item.route.map((route, index) => {
            return <Box key={index}>{route.dispatchWindow ? timezoneManager.convertTimestampToString(route.dispatchWindow.end, { format: TIME_FORMAT }) : NOT_AVAILABLE}</Box>;
          });
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
    {
      id: 'duration',
      header: 'Duration',
      removable: true,
      cell: (item: FungibleAssignment) => {
        if (item.route) {
          return item.route.map((route, index) => {
            return route.routeDuration ? <Box key={index}>{formatDuration(route.routeDuration)}</Box> : NOT_AVAILABLE;
          });
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
    {
      id: 'assignmentStatus',
      header: 'Assignment Status',
      removable: true,
      cell: (item: FungibleAssignment) => {
        let assignmentStatus: string | undefined = item.assignmentStatus;
        assignmentStatus = assignmentStatus ? assignmentStatus : item.transporterUnAssignedReason;
        return <Box>{convertNullable(NOT_AVAILABLE, assignmentStatus)}</Box>;
      },
    },
    {
      id: 'transporterGroupId',
      header: 'Transporter Group',
      removable: true,
      width: 300,
      cell: (item: FungibleAssignment) => {
        if (typeof item.transporter === 'object') {
          // transporterGroupId constructed by https://tiny.amazon.com/k325dxbg/FungibleTransporter
          const transporterGroupId = item.transporter?.transporterGroupId;
          // expected transporterGroupId format: 2021-08-11T01:15:00Z/2021-08-11T05:15:00Z:SCHEDULED
          const dateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/g;
          try {
            const blockShift = transporterGroupId.match(dateRegex);
            if (Array.isArray(blockShift) && blockShift.length === 2) {
              const segments = transporterGroupId.split(':');
              const isSchedule = segments[segments.length - 1] === 'SCHEDULED';
              const isNursery = segments[segments.length - 2] === 'NURSERY';

              const startTime = timezoneManager.convertTimestampToString(blockShift[0], { format: TIME_FORMAT });
              const endTime = timezoneManager.convertTimestampToString(blockShift[1], { format: TIME_FORMAT });
              return `${startTime} - ${endTime} ${isNursery ? 'Nursery ' : ''} 
              ${isSchedule ? 'schedules' : segments[segments.length - 1]}`;
            }
          } catch (err: any) {
            console.warn('Failed to parse fungible transporterGroupId', transporterGroupId, err.message);
          }

          return transporterGroupId;
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
    {
      id: 'transporterCount',
      header: 'Transporter Count',
      removable: true,
      cell: (item: FungibleAssignment) => convertNullable(NOT_AVAILABLE, typeof item.transporter === 'string' ? '1' : item.transporter?.transporters.length.toString()),
    },
    {
      id: 'transporters',
      header: 'Transporters',
      removable: true,
      cell: (item: FungibleAssignment) => {
        if (typeof item.transporter === 'string') {
          return <Box>{item.transporter}</Box>;
        } else if (typeof item.transporter === 'object') {
          const transporterGroup = item.transporter;
          const routeCount = item.route?.length ? item.route?.length : 1;
          const maximumDisplayableTransporters = Math.max(routeCount - 1, 1);
          const displayableTransporters = transporterGroup.transporters.slice(0, maximumDisplayableTransporters);

          if (displayableTransporters.length === transporterGroup.transporters.length) {
            return displayableTransporters.map((transporter, index) => {
              return <Box key={index}>{transporter.transporterId}</Box>;
            });
          } else {
            return (
              <React.Fragment>
                {displayableTransporters.map((transporter, index) => {
                  return <Box key={index}>{transporter.transporterId}</Box>;
                })}
                <Box textAlign="center">
                  <Link
                    onFollow={(evt) => {
                      evt.preventDefault();
                      if (props.onTransporterGroupClick) {
                        props.onTransporterGroupClick(transporterGroup);
                      }
                    }}
                  >
                    More
                  </Link>
                </Box>
              </React.Fragment>
            );
          }
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
  ];
}
