import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import ufraaVizClient, { ServiceAreaDetails, ExecutionSnapshot, ExecutionPlan, ExecutionPlanInfo } from '../../clients';
import { RoutingPlanContent } from './routing-plan-content';
import { buildRoutingPermissionWarningNotification } from './utilities/permission-warning-notification';
import { timezoneManager } from '../../utilities';
import { DEFAULT_TITLE } from '../../constants';
import { RouteDetailsConverter } from './utilities/helper';

interface Props {
  readonly snapshotId: string;
  readonly activeTab?: string;
  readonly onTabChange: (tab: string) => void;
  readonly filteringText?: string;
  readonly onFilteringTextChange: (text: string) => void;
}

interface State {
  readonly snapshot?: ExecutionSnapshot | null;
  readonly plan?: ExecutionPlan | null;
  readonly serviceAreaDetails?: ServiceAreaDetails | null;
  readonly planInfo: ExecutionPlanInfo | null;
}

export class StaticRoutingPlanPage extends React.Component<Props, State> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      snapshot: undefined,
      plan: undefined,
      serviceAreaDetails: undefined,
      planInfo: null,
    };
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.updateBreadcrumbItems([
      {
        text: this.props.snapshotId,
        href: `/routing-plan/${encodeURIComponent(this.props.snapshotId)}`,
      },
    ]);

    await this.loadData(this.props.snapshotId);

    try {
      const permissions = await ufraaVizClient.getUserPermissions();

      if (!permissions.includes('ROUTING_ARTIFACTS')) {
        this.context.addNotification(buildRoutingPermissionWarningNotification());
      }
    } catch (err: any) {
      this.context.addNotification({
        header: 'Error',
        content: 'Unable to load user permission.',
        type: 'warning',
      });
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.snapshotId !== prevProps.snapshotId) {
      await this.loadData(this.props.snapshotId);
    }
  }

  async loadData(snapshotId: string): Promise<void> {
    let snapshot: ExecutionSnapshot | undefined = undefined;
    let plan: ExecutionPlan | undefined = undefined;
    let serviceAreaDetails: ServiceAreaDetails | undefined = undefined;
    let planInfo: ExecutionPlanInfo | undefined = undefined;

    try {
      const snapshotResponse = await ufraaVizClient.getArtifactsFromReferences<ExecutionSnapshot>({
        artifactIdentifiers: [{ artifactId: snapshotId, artifactType: 'EXECUTION_SNAPSHOT' }],
      });
      snapshot = snapshotResponse[0].artifact;

      document.title = this.formatTitle(snapshot);
    } catch (err: any) {
      // we have nothing to do if the snapshot is not found.
      this.setState({ plan: null, snapshot: null, serviceAreaDetails: null, planInfo: null });
      if (err?.response?.status === 404) {
        this.context.addNotification({
          header: `Missing snapshot`,
          content: `Unable to find the requested snapshot ${snapshotId}.`,
          type: 'warning',
        });
      } else {
        this.context.addNotification({
          header: `Missing snapshot`,
          content: err?.response?.data,
          type: 'error',
        });
        console.error(`Cannot find snapshot due to ${err?.response?.data}`);
      }
      return;
    }
    this.context.updateStationTimezone(snapshot.serviceArea.timeZone);
    this.context.updateBreadcrumbItems([
      {
        text: `${snapshot.serviceArea.serviceAreaName}`,
        href: `/service-areas/${snapshot.serviceArea.serviceAreaId}`,
      },
      {
        text: this.props.snapshotId,
        href: `/routing-plan/${encodeURIComponent(this.props.snapshotId)}`,
      },
    ]);

    try {
      const planResponse = await ufraaVizClient.getArtifactsFromReferences<ExecutionPlan>({
        artifactIdentifiers: [{ artifactId: snapshotId, artifactType: 'EXECUTION_PLAN' }],
      });
      plan = planResponse[0].artifact;
    } catch (err: any) {
      if (err?.response?.status === 404) {
        this.context.addNotification({
          header: 'Plan Not Found',
          content: 'Unable to find routing plan, please refresh later.',
          type: 'warning',
        });
      } else {
        this.context.addNotification({
          header: `Plan Not Found`,
          content: err?.response?.data,
          type: 'error',
        });
        console.error(`Cannot find routing plan due to ${err?.response?.data}`);
      }
    }

    if (snapshot && plan) {
      planInfo = new RouteDetailsConverter().parseRoutingPlan(plan, snapshot);
    }

    try {
      serviceAreaDetails = await ufraaVizClient.getServiceAreaDetails(snapshot.serviceArea.serviceAreaId);
    } catch (err: any) {
      this.context.addNotification({
        header: `Cannot find service area`,
        content: `Unable to find the service area details ${snapshot.serviceArea.serviceAreaId} due to ${err?.response?.data}`,
        type: 'error',
      });
    }

    this.setState({
      snapshot: snapshot ?? null,
      plan: plan ?? null,
      serviceAreaDetails: serviceAreaDetails ?? null,
      planInfo: planInfo ?? null,
    });
  }

  // cleanup
  componentWillUnmount() {
    this.context.updateStationTimezone(undefined);
    document.title = DEFAULT_TITLE;
  }

  private formatTitle(snapshot: ExecutionSnapshot): string {
    return `${snapshot.serviceArea.defaultStationCode} @ ${timezoneManager.convertTimestampToString(snapshot.serviceArea.lastUpdated * 1000, {
      format: 'HH:mm z',
    })}`;
  }

  render() {
    return (
      <RoutingPlanContent
        liveMode={false}
        snapshot={this.state.snapshot}
        plan={this.state.plan}
        planInfo={this.state.planInfo}
        serviceAreaDetails={this.state.serviceAreaDetails}
        onTabChange={this.props.onTabChange}
        activeTab={this.props.activeTab}
      />
    );
  }
}
