import { FungibleAssignment } from '../../../clients';
import {
  parseFilteringText,
  routesContainsRouteName,
  routesContainsRouteId,
  transporterGroupContainsTransporterId,
  routesContainsTrId,
  routesContainsOrderId,
  routesContainsTrackingId,
  routesContainsProviderType,
  routesContainsRouteProgramType,
} from './utilities';

/**
 * Text Filter functions
 * Compare the text with route Id, order Id, trId, assignment status, transporter id, and transporter type.
 */
function doesMatchAssignment(text: string, assignment: FungibleAssignment): boolean {
  if (assignment.route) {
    // match routeId and routeCode
    if (routesContainsRouteId(assignment.route, text) || routesContainsRouteName(assignment.route, text)) {
      return true;
    }
    // match orderId, trId, trackingId
    if (routesContainsOrderId(assignment.route, text) || routesContainsTrId(assignment.route, text) || routesContainsTrackingId(assignment.route, text)) {
      return true;
    }
    // match provider type, DSP vs Flex
    if (routesContainsProviderType(assignment.route, text)) {
      return true;
    }
    // match route program type, PRISTINE_SSD, PRISTINE_GROCERY, COMMINGLED_SSD_GROCERY, UNKNOWN
    if (routesContainsRouteProgramType(assignment.route, text)) {
      return true;
    }
  }

  if (assignment.assignmentStatus?.toLowerCase() === text.toLowerCase()) {
    return true;
  }

  if (assignment.transporterUnAssignedReason?.toLowerCase() === text.toLowerCase()) {
    return true;
  }

  if (assignment.transporter) {
    if (typeof assignment.transporter === 'string' && assignment.transporter === text) {
      return true;
    } else if (typeof assignment.transporter === 'object' && transporterGroupContainsTransporterId(assignment.transporter.transporters, text)) {
      return true;
    }
  }
  return false;
}

function anyMatchAssignment(texts: string[], assignment: FungibleAssignment): boolean {
  return !!texts.find((text) => doesMatchAssignment(text, assignment));
}

export const INITIAL_FUNGIBLE_FILTERING_TEXT = 'Planned';

export function fungibleFilter(filteringText: string, assignments: FungibleAssignment[]): FungibleAssignment[] {
  const texts = parseFilteringText(filteringText, ',');
  if (texts.length === 0) {
    return Array.from(assignments);
  }
  return assignments.filter((assignment) => anyMatchAssignment(texts, assignment));
}
