import { ArtifactType } from './models';

/**
 * ToDo: remove the hard code compressed artifact type list, and get the info from backend ListArtifactTypeMetadata api.
 */
export const COMPRESSED_ARTIFACT_TYPES: ReadonlyArray<ArtifactType> = [
  'FLEX_DRIVER_NURSERY',
  'ROUTE_DRIVER_COST_SNAPSHOT',
  'SNAPSHOT_POPULARITY',
  'SNAPSHOT_PROVIDERS',
  'SNAPSHOT_RECENT_DRIVERS',
  'SNAPSHOT_REGION',
  'SNAPSHOT_ROUTE_DRIVER_AFFINITY',
  'SNAPSHOT_ROUTE_LABOR_ADJUSTMENT',
  'SNAPSHOT_TAPAS',
];
