import { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { DEFAULT_TIMEZONE_PREFERENCE, TimezonePreference } from '../utilities';
import * as React from 'react';

export interface BreadcrumbItem {
  readonly text: string;
  readonly href: string;
}

export interface Notification {
  notificationId?: string;
  readonly header: string;
  readonly content: string | React.ReactNode;
  readonly type: FlashbarProps.Type;
  readonly dismissible?: boolean;
}

export type ToolsType = 'lm-route-page' | 'network-health-page' | 'artifacts-page';
export type Tutorial = 'lm-route-page';

// Amazon follows BCP 47 language tag for locale identifier
// see https://w.amazon.com/bin/view/INTech/Panther/FAQs/#HSometimeslocalesuseahyphen2Csuchasen-GB.Othertimestheyuseanunderscore2Csuchasen_GB.Whichusageisrecommended3F
export type Locale = 'en-US' | 'ja-JP';

export interface GlobalContextType {
  readonly username?: string;

  readonly locale: Locale;
  readonly updateLocale: (locale: Locale) => void;

  readonly uiMode: 'light' | 'dark';
  readonly updateUIMode: (mode: 'light' | 'dark') => void;

  readonly theme: 'regular' | 'visual-refresh';
  readonly updateTheme: (theme: 'regular' | 'visual-refresh') => void;

  readonly density: 'compact' | 'comfortable';
  readonly updateDensity: (density: 'compact' | 'comfortable') => void;

  readonly navigationOpen: boolean;
  readonly updateNavigationOpen: (open: boolean) => void;

  readonly breadcrumbItems: BreadcrumbItem[];
  readonly updateBreadcrumbItems: (items: BreadcrumbItem[]) => void;

  readonly notifications: Notification[];
  readonly addNotification: (notification: Notification) => void;
  readonly removeNotification: (notificationId: string) => void;

  readonly tools?: ToolsType;
  readonly setTools: (tools?: ToolsType) => void;
  readonly toolsOpen: boolean;
  readonly openTools: () => void;
  readonly closeTools: () => void;

  readonly tutorial?: Tutorial;
  readonly setTutorial: (tutorial?: Tutorial) => void;
  readonly tutorialOpen: boolean;
  readonly tutorialComplete: boolean;
  readonly openTutorial: () => void;
  readonly closeTutorial: () => void;

  readonly timezonePreference: TimezonePreference;
  readonly updateTimezonePreference: (timezonePreference: TimezonePreference) => void;

  readonly stationTimezone?: string;
  readonly updateStationTimezone: (stationTimezone?: string) => void;

  readonly resetLayout: () => void;

  // in browser navigation handler
  readonly onGoto: (url: string) => void;

  readonly liveModeEffectEnabled: boolean;
  readonly updateLiveModeEffectEnabled: (enabled: boolean) => void;
}

export const GlobalContext = React.createContext<GlobalContextType>({
  uiMode: 'light',
  updateUIMode: () => {},

  locale: 'en-US',
  updateLocale: (locale: Locale) => {},

  theme: 'regular',
  updateTheme: (theme: 'regular' | 'visual-refresh') => {},

  density: 'comfortable',
  updateDensity: (density: 'compact' | 'comfortable') => {},

  navigationOpen: false,
  updateNavigationOpen: () => {},

  breadcrumbItems: [],
  updateBreadcrumbItems: () => {},

  notifications: [],
  addNotification: () => {},
  removeNotification: () => {},

  tools: undefined,
  toolsOpen: false,
  openTools: () => {},
  closeTools: () => {},
  setTools: () => {},

  tutorial: undefined,
  setTutorial: () => {},
  tutorialComplete: false,
  tutorialOpen: false,
  openTutorial: () => {},
  closeTutorial: () => {},

  timezonePreference: DEFAULT_TIMEZONE_PREFERENCE,
  updateTimezonePreference: () => {},

  stationTimezone: undefined,
  updateStationTimezone: () => {},

  resetLayout: () => {},
  onGoto: (url: string) => {},

  liveModeEffectEnabled: false,
  updateLiveModeEffectEnabled: () => {},
});
