import { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';
import { FdpAssignment } from '../../../clients';
import { parseFilteringText, routeContainsOrderId, routeContainsTrId, transporterMatchesReadyToWorkSearch } from './utilities';

/**
 * AMZL filtering logic
 */

export const AMZL_FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    key: 'orderId',
    groupValuesLabel: 'Order Id',
    propertyLabel: 'Order Id',
  },
];

export function amzlQuery(query: PropertyFilterProps.Query, assignments: FdpAssignment[]): FdpAssignment[] {
  return assignments;
}

/**
 * Text Filter functions
 * Compare the text with route Id, order Id, trId, assignment status, transporter id, and transporter type.
 */
function doesMatchAssignment(text: string, assignment: FdpAssignment): boolean {
  // text matches with route content
  const lowerCaseText = text.toLowerCase();
  if (assignment.route) {
    if (assignment.route.routeId === text || assignment.route.routeName?.toLowerCase() === lowerCaseText || routeContainsOrderId(assignment.route, text) || routeContainsTrId(assignment.route, text)) {
      return true;
    }
    if (assignment.routeExtras?.routeUserNotes?.toLowerCase().includes(text)) {
      return true;
    }
  }

  if (assignment.routeExtras?.cycleName?.toLowerCase() === lowerCaseText) {
    return true;
  }

  if (assignment.assignmentStatus && assignment.assignmentStatus.toLowerCase() === lowerCaseText) {
    return true;
  }

  if (assignment.transporterUnAssignedReason?.toLowerCase() === lowerCaseText) {
    return true;
  }

  if (assignment.rejectedTransporterIds && assignment.rejectedTransporterIds.includes(text)) {
    return true;
  }

  if (assignment.transporter) {
    if (
      assignment.transporter.transporterId === text ||
      assignment.transporter.cycle?.cycleName?.toLowerCase() === lowerCaseText ||
      assignment.transporter.type?.toLowerCase() === lowerCaseText ||
      assignment.transporterExtras?.transporterName?.toLowerCase().includes(text) ||
      assignment.transporterExtras?.transporterUserNotes?.toLowerCase().includes(text) ||
      transporterMatchesReadyToWorkSearch(assignment.transporter, text)
    ) {
      return true;
    }
  }
  return false;
}

function anyMatchAssignment(texts: string[], assignment: FdpAssignment): boolean {
  return !!texts.find((text) => doesMatchAssignment(text, assignment));
}

export const INITIAL_AMZL_FILTERING_TEXT = 'Planned, No Assignment, Unknown';

export function amzlFilter(filteringText: string, assignments: FdpAssignment[]): FdpAssignment[] {
  const texts = parseFilteringText(filteringText, ',');
  if (texts.length === 0) {
    return Array.from(assignments);
  }
  return assignments.filter((assignment) => anyMatchAssignment(texts, assignment));
}
