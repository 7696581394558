import React from 'react';
import { PartialRecord, FlexRouteAssignmentPlannerInput, FlexRouteAssignmentPlan, FilterOutReason, Transporter, ServiceAreaDetails, DispatchPlannerType, AssignmentMode } from '../../clients';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Container from '@amzn/awsui-components-react/polaris/container';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Box from '@amzn/awsui-components-react/polaris/box';
import Clock from '../../shared-components/clock';
import { timezoneManager, renderHelper } from '../../utilities';
import { convertFilterOutReason, ALGORITHM_NAME, EXECUTOR_NAME } from './utilities/helper';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';

interface Props extends WithBundleProps {
  readonly plannerInput?: FlexRouteAssignmentPlannerInput | null;
  readonly plan?: FlexRouteAssignmentPlan | null;
  readonly serviceAreaDetails?: ServiceAreaDetails | null;
}

export class AssignmentPlanSummary extends React.Component<Props, {}> {
  renderHeader() {
    return <Header variant="h2">{this.props.bundle.getMessage('ASSIGNMENT_PLAN_SUMMARY:SUMMARY_HEADER')}</Header>;
  }

  getFilterOutNumber(filterOutItems: PartialRecord<FilterOutReason, any[]> | null): PartialRecord<FilterOutReason, number> {
    const result: PartialRecord<FilterOutReason, number> = {};

    if (filterOutItems) {
      const keys = Object.keys(filterOutItems) as FilterOutReason[];
      for (let filterOutReason of keys) {
        result[filterOutReason] = filterOutItems[filterOutReason]?.length;
      }
    }
    return result;
  }

  renderItemWithFilterOutCounts(itemName: 'Input Routes' | 'Input Transporters', availableCount: number | string, filterOutCounts: PartialRecord<FilterOutReason, number>) {
    const keys = Object.keys(filterOutCounts) as FilterOutReason[];
    return (
      <Box>
        <Box margin={{ bottom: 'xxxs' }} color="text-label">
          {itemName}
        </Box>
        <Box margin={{ bottom: 'xxxs' }}>
          <SpaceBetween direction="horizontal" size="s">
            <Box>
              <Box fontWeight="bold">Available for planning</Box>
              {keys.map((key, index) => {
                return <Box key={index}>{convertFilterOutReason(key)}</Box>;
              })}
            </Box>
            <Box>
              <Box>{availableCount}</Box>
              {keys.map((key, index) => {
                return <Box key={index}>{filterOutCounts[key]}</Box>;
              })}
            </Box>
          </SpaceBetween>
        </Box>
      </Box>
    );
  }

  renderAssignmentMode(mode: AssignmentMode) {
    if (mode === 'ENABLED') {
      return <StatusIndicator type="success">Enabled</StatusIndicator>;
    } else if (mode === 'SHADOW_MODE') {
      return <StatusIndicator type="info">Shadow</StatusIndicator>;
    } else if (mode === 'DISABLED') {
      return (
        <StatusIndicator type="stopped" colorOverride="red">
          Disabled
        </StatusIndicator>
      );
    } else {
      // unknown mode
      return <StatusIndicator type="warning">{mode}</StatusIndicator>;
    }
  }

  renderContent(plannerInput: FlexRouteAssignmentPlannerInput, plan?: FlexRouteAssignmentPlan | null, serviceAreaDetails?: ServiceAreaDetails | null) {
    const algorithm: DispatchPlannerType = plannerInput.assignmentConfig.dispatchPlannerType ? plannerInput.assignmentConfig.dispatchPlannerType : 'FUNGIBLE_ASSIGNMENT_ALGORITHM';

    return (
      <ColumnLayout columns={4} variant="text-grid">
        <SpaceBetween size="m">
          <Box>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              {this.props.bundle.getMessage('ASSIGNMENT_PLAN_SUMMARY:SERVICE_AREA_NAME')}
            </Box>
            <Box margin={{ bottom: 'xxxs' }}>{renderHelper.renderNullableText(serviceAreaDetails?.serviceAreaName, 'N/A')}</Box>
          </Box>
          <Box>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              {this.props.bundle.getMessage('ASSIGNMENT_PLAN_SUMMARY:SERVICE_AREA_ID')}
            </Box>
            <Box margin={{ bottom: 'xxxs' }}>{renderHelper.renderCopyableText(serviceAreaDetails?.serviceAreaId, 'N/A')}</Box>
          </Box>
          <Box>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              {this.props.bundle.getMessage('ASSIGNMENT_PLAN_SUMMARY:STATION_TIMEZONE')}
            </Box>
            <Box margin={{ bottom: 'xxxs' }}>{renderHelper.renderNullableText(serviceAreaDetails?.timeZone, 'N/A')}</Box>
          </Box>
        </SpaceBetween>
        <SpaceBetween size="m">
          <Box>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              {this.props.bundle.getMessage('ASSIGNMENT_PLAN_SUMMARY:STATION_TIME')}
            </Box>
            <Box margin={{ bottom: 'xxxs' }}>{serviceAreaDetails?.timeZone ? <Clock timezone={serviceAreaDetails?.timeZone} /> : 'N/A'}</Box>
          </Box>
          <Box>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              {this.props.bundle.getMessage('ASSIGNMENT_PLAN_SUMMARY:PLANNER_INPUT_GENERATION_TIME')}
            </Box>
            <Box margin={{ bottom: 'xxxs' }}>{timezoneManager.convertTimestampToString(plannerInput.serviceArea.lastUpdated)}</Box>
          </Box>
          <Box>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              {this.props.bundle.getMessage('ASSIGNMENT_PLAN_SUMMARY:PLAN_GENERATION_TIME')}
            </Box>
            <Box margin={{ bottom: 'xxxs' }}>{plan ? timezoneManager.convertTimestampToString(plan.lastUpdated) : 'N/A'}</Box>
          </Box>
        </SpaceBetween>
        <SpaceBetween size="m">
          <Box>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              {this.props.bundle.getMessage('ASSIGNMENT_PLAN_SUMMARY:ALGORITHM')}
            </Box>
            <Box margin={{ bottom: 'xxxs' }}>{renderHelper.renderNullableText(ALGORITHM_NAME[algorithm], 'N/A')}</Box>
          </Box>
          <Box>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              {this.props.bundle.getMessage('ASSIGNMENT_PLAN_SUMMARY:EXECUTOR')}
            </Box>
            <Box margin={{ bottom: 'xxxs' }}>{renderHelper.renderNullableText(EXECUTOR_NAME[plannerInput.assignmentConfig.assignmentExecutor], 'N/A')}</Box>
          </Box>
          <Box>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              {this.props.bundle.getMessage('ASSIGNMENT_PLAN_SUMMARY:STATUS')}
            </Box>
            <Box margin={{ bottom: 'xxxs' }}>{this.renderAssignmentMode(plannerInput.assignmentConfig.assignmentMode)}</Box>
          </Box>
        </SpaceBetween>
        <SpaceBetween size="m">
          <Box>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              {this.props.bundle.getMessage('ASSIGNMENT_PLAN_SUMMARY:PLANNER_INPUT_ID')}
            </Box>
            <Box margin={{ bottom: 'xxxs' }}>{renderHelper.renderCopyableText(plannerInput.plannerInputId, 'N/A')}</Box>
          </Box>
          <Box>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              {this.props.bundle.getMessage('ASSIGNMENT_PLAN_SUMMARY:PLAN_ID')}
            </Box>
            <Box margin={{ bottom: 'xxxs' }}>{renderHelper.renderCopyableText(plannerInput.planId, 'N/A')}</Box>
          </Box>
        </SpaceBetween>
      </ColumnLayout>
    );
  }

  render() {
    return <Container header={this.renderHeader()}>{this.props.plannerInput ? this.renderContent(this.props.plannerInput, this.props.plan, this.props.serviceAreaDetails) : null}</Container>;
  }
}

function ioTransporterCount(transporters: Transporter[]): number {
  return transporters.filter((tp) => tp.type === 'IO').length;
}

function blockTransporterCount(transporters: Transporter[]): number {
  return transporters.filter((tp) => tp.type === 'BLOCK').length;
}

export default withBundle('AssignmentPlanSummary')(AssignmentPlanSummary);
