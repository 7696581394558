import React from 'react';
import { COMPONENT_ARTIFACT_TYPE, StationPageSettings } from '../models';
import { COMPONENT_ARTIFACT_TYPE_TO_BUNDLE_RESOURCE, SUPPORTED_COMPONENT_ARTIFACT_TYPES } from '../constants';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Box from '@amzn/awsui-components-react/polaris/box';
import Toggle from '@amzn/awsui-components-react/polaris/toggle';
import Button from '@amzn/awsui-components-react/polaris/button';
import { WithBundleProps } from '@amzn/react-arb-tools';

export interface StationPageSettingsModalProps extends WithBundleProps {
  readonly settings: StationPageSettings;
  readonly onCancel: () => void;
  readonly onSave: (settings: StationPageSettings) => void;
}

export interface StationPageSettingsModalState {
  readonly settings: StationPageSettings;
}

export class StationPageSettingsModal extends React.Component<StationPageSettingsModalProps, StationPageSettingsModalState> {
  constructor(props: StationPageSettingsModalProps) {
    super(props);
    this.state = {
      settings: this.props.settings,
    };
  }

  render() {
    return (
      <Modal header="Settings" onDismiss={() => this.props.onCancel()} footer={this.renderFooter()} visible={true} closeAriaLabel="Close settings">
        {this.renderModalContent()}
      </Modal>
    );
  }

  private renderModalContent() {
    return (
      <SpaceBetween direction="vertical" size="l">
        <Box>{this.props.bundle.getMessage('STATION_PAGE:PREFERENCES_DESCRIPTION')}</Box>
        <ColumnLayout columns={1} borders="horizontal">
          <Box variant="awsui-key-label">{this.props.bundle.getMessage('STATION_PAGE:ARTIFACT_TABLES')}</Box>
          {SUPPORTED_COMPONENT_ARTIFACT_TYPES.map((value, index) => {
            return this.renderKeyValueToggle(value, index);
          })}
        </ColumnLayout>
      </SpaceBetween>
    );
  }

  private renderKeyValueToggle(artifactType: COMPONENT_ARTIFACT_TYPE, index: number) {
    const checked = !this.state.settings.artifactHiddenList.includes(artifactType);
    return (
      <Box margin={{ left: 'm' }} key={index}>
        {this.props.bundle.getMessage(COMPONENT_ARTIFACT_TYPE_TO_BUNDLE_RESOURCE[artifactType])}
        <Box float="right">
          <Toggle
            checked={checked}
            onChange={(evt) => {
              if (evt.detail.checked && this.state.settings.artifactHiddenList.includes(artifactType)) {
                const newSettings = {
                  ...this.state.settings,
                  artifactHiddenList: this.state.settings.artifactHiddenList.filter((t) => t !== artifactType),
                };
                this.setState({ settings: newSettings });
              } else if (!evt.detail.checked && !this.state.settings.artifactHiddenList.includes(artifactType)) {
                const newArtifactHiddenList = Array.from(this.state.settings.artifactHiddenList);
                newArtifactHiddenList.push(artifactType);
                const newSettings = {
                  ...this.state.settings,
                  artifactHiddenList: newArtifactHiddenList,
                };
                this.setState({ settings: newSettings });
              }
            }}
          />
        </Box>
      </Box>
    );
  }

  private renderFooter() {
    return (
      <Box float="right">
        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="link" onClick={() => this.props.onCancel()}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => this.props.onSave(this.state.settings)}>
            Confirm
          </Button>
        </SpaceBetween>
      </Box>
    );
  }
}
