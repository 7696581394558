import { LmRoute, RouteSequence, RouteSequenceAction, RouteSnapshot, TaskSubject, InternalAction, InternalActionType, InternalRouteContainer } from '../../clients';

export function getTimezone(routeSnapshot: RouteSnapshot): string | undefined {
  for (let i = 0; i < routeSnapshot.routes.length; i++) {
    const timezone = routeSnapshot.routes[i]?.metadata?.STATION_TIMEZONE;
    if (timezone) {
      return timezone;
    }
  }
}

export type Comparator = (inputText: string, routeProperty: string) => boolean;

/**
 * Examine if the text matches any property of the route or route sequence. The function is used to implement the filtering functionality.
 * @param text
 */
export function matchRouteContainer(text: string, routeContainer: InternalRouteContainer, op: Comparator): boolean {
  if (op(text, routeContainer.route.routeId)) {
    return true;
  }
  if (routeContainer.route.sequence) {
    for (const departureSet of routeContainer.route.sequence.departureSets) {
      for (const taskSubject of departureSet.taskSubjects) {
        if (typeof taskSubject.trId === 'string' && op(text, taskSubject.trId)) {
          return true;
        }
      }
    }
  }

  //todo: support orderId
  return false;
}

export function filterRouteWithSequences(text: string, routeContainers: InternalRouteContainer[]): InternalRouteContainer[] {
  const textSegments = text
    .split(',')
    .map((segment) => segment.trim())
    .filter((segment) => segment.length > 0);

  if (textSegments.length === 0) {
    return routeContainers;
  }

  const result: InternalRouteContainer[] = [];
  for (let routeContainer of routeContainers) {
    for (let i = 0; i < textSegments.length; i++) {
      if (i === textSegments.length - 1) {
        // for the last search segment, it's matched as long as it's a prefix of route's property
        if (matchRouteContainer(textSegments[i], routeContainer, (t1, t2) => t2.startsWith(t1))) {
          result.push(routeContainer);
          break;
        }
      } else {
        if (matchRouteContainer(textSegments[i], routeContainer, (t1, t2) => t2 === t1)) {
          result.push(routeContainer);
          break;
        }
      }
    }
  }

  return result;
}

export function getInternalActionType(action: RouteSequenceAction): InternalActionType {
  if (action.actionType === 'EXECUTE') {
    if (action?.taskId?.endsWith('Add')) {
      return 'Pickup';
    } else if (action?.taskId?.endsWith('Remove')) {
      return 'Dropoff';
    } else if (action?.taskId?.startsWith('Start')) {
      return 'Start';
    } else {
      // fallback, it means the route is not from LMDP. For example, it can be a SSD route.
      return 'Execute';
    }
  } else if (action.actionType === 'TRANSIT') {
    return 'Transit';
  } else if (action.actionType === 'END') {
    return 'End';
  } else {
    return action.actionType;
  }
}

export function convertRouteSequenceToInternalActions(routeSequence: RouteSequence): InternalAction[] {
  const taskIdToTaskSubject: Map<string, TaskSubject> = new Map();
  routeSequence.taskSubjects.forEach((taskSubject) => {
    taskIdToTaskSubject.set(`${taskSubject.trId}Add`, taskSubject);
    taskIdToTaskSubject.set(`${taskSubject.trId}Remove`, taskSubject);
  });

  const internalActions: InternalAction[] = [];
  let lastInternalAction: InternalAction | undefined;

  for (const action of routeSequence.actions) {
    const actionType = getInternalActionType(action);
    if (lastInternalAction && lastInternalAction.type === actionType) {
      if (actionType === 'Pickup' || actionType === 'Dropoff') {
        if (action.duration !== 0) {
          lastInternalAction.duration = action.duration;
        }
        // merge taskSubject.
        const taskSubject = typeof action.taskId === 'string' ? taskIdToTaskSubject.get(action.taskId) : undefined;
        if (taskSubject) {
          lastInternalAction.taskSubjects.push(taskSubject);
        } else {
          throw new Error(`Cannot find taskSubject for task ${action.taskId}`);
        }
        continue;
      }
    }

    lastInternalAction = {
      type: actionType,
      taskSubjects: [],
      startTime: action.startTime,
      duration: action.duration,
    };

    if (actionType === 'Pickup' || actionType === 'Dropoff') {
      const taskSubject = typeof action.taskId === 'string' ? taskIdToTaskSubject.get(action.taskId) : undefined;
      if (taskSubject) {
        lastInternalAction.taskSubjects.push(taskSubject);
      } else {
        throw new Error(`Cannot find taskSubject for task ${action.taskId}`);
      }
    }

    internalActions.push(lastInternalAction);
  }

  return internalActions;
}

export function convertRouteSnapshotToInternalRouteContainers(routeSnapshot: RouteSnapshot): InternalRouteContainer[] {
  const routeContainers: InternalRouteContainer[] = [];
  for (let route of routeSnapshot.routes) {
    const sequence = routeSnapshot.routeIdToRouteSequenceMap[route.routeId];
    routeContainers.push({
      route: route,
      routeSequence: sequence,
      actions: sequence ? convertRouteSequenceToInternalActions(sequence) : undefined,
    });
  }

  return routeContainers;
}
