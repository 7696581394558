import React from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import BreadcrumbGroup from '@amzn/awsui-components-react/polaris/breadcrumb-group';
import Flashbar, { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { Route, Switch } from 'react-router';
import { GlobalContext } from './global-context';
import WelcomePage from '../components/welcome-page';
import NotFoundPage from '../components/not-found-page';
import OrderTimelinePage from '../components/order-timeline-page';
import ServiceAreaPage from '../components/service-area-page';
import StationPage from '../components/station-page';
import AmzlStagingRoutesPage from '../components/amzl-staging-routes-page';
import { DragAndDropAssignmentPlanPage, LiveAssignmentPlanPage, StaticAssignmentPlanPage } from '../components/assignment-plan-page';
import { StaticRoutingPlanPage } from '../components/routing-plan-page';
import AssignmentTimelinePage from '../components/assignment-timeline-page';
import RouteSnapshotPage from '../components/route-snapshot-page';
import LmRoutePage, { LmRoutePageTools } from '../components/lm-route-page';
import SearchPage from '../components/search-page';
import ArtifactSearchPage, { ArtifactSearchPageTools } from '../components/artifacts-page';
import JsonViewPage from '../components/json-view-page';
import {
  buildRoutingPlanUrl,
  buildAssignmentPlanUrl,
  buildLmRouteUserSearchFromURLSearchParams,
  buildURLSearchParamsFromLmRouteUserSearch,
  buildArtifactSearchFromURLSearchParams,
  buildURLSearchParamsFromArtifactSearch,
} from './url-utils';
import Box from '@amzn/awsui-components-react/polaris/box';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Button from '@amzn/awsui-components-react/polaris/button';
import AnnotationContext from '@amzn/awsui-components-react/polaris/annotation-context';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { I18nStrings, findTutorial } from '../tutorials';
import NetworkHealthPage, { NetworkHealthPageTools } from '../components/network-health-page';
import { NetworkHealthParams } from '../components/network-health-page/models';
import ServiceAreaConversion from '../components/service-area-conversion';
import { Navigation } from './navigation';

class Routes extends React.Component<{}, {}> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  render() {
    return (
      <Switch>
        <Route exact path="/" render={() => <WelcomePage />} />
        <Route exact path="/service-areas" render={() => <ServiceAreaPage />} />
        <Route exact path="/service-area-conversion" render={() => <ServiceAreaConversion />} />
        <Route exact path="/order-timeline" render={() => <OrderTimelinePage />} />
        <Route exact path="/json" render={() => <JsonViewPage />} />

        <Route
          path="/artifacts"
          render={(props) => {
            const params = new URLSearchParams(props.location.search);
            return (
              <ArtifactSearchPage
                rawArtifactSearch={buildArtifactSearchFromURLSearchParams(params)}
                onSubmit={(submit) => {
                  const query = buildURLSearchParamsFromArtifactSearch(submit);
                  const qs = new URLSearchParams(query);
                  const queryString = qs.toString();
                  if (queryString.length === 0) {
                    this.context.onGoto('/artifacts');
                  } else {
                    this.context.onGoto(`/artifacts?${queryString}`);
                  }
                }}
              />
            );
          }}
        />

        <Route
          path="/service-areas/:serviceAreaId"
          render={(props) => {
            const serviceAreaId = props.match.params['serviceAreaId'];
            return (
              <StationPage
                serviceAreaId={serviceAreaId}
                queryParams={props.location.search}
                onQueryParamsChange={(queryParams) => {
                  const newUrl = queryParams.length > 0 ? `/service-areas/${serviceAreaId}?${queryParams}` : `/service-areas/${serviceAreaId}`;
                  this.context.onGoto(newUrl);
                }}
              />
            );
          }}
        />

        <Route
          path="/routing-plan/:snapshotId"
          render={(props) => {
            const params = new URLSearchParams(props.location.search);
            let activeTab: string | undefined = undefined;
            let filteringText: string | undefined = undefined;
            try {
              activeTab = params.get('tab') !== null ? decodeURIComponent(params.get('tab')!) : undefined;
              filteringText = params.get('filtering') !== null ? decodeURIComponent(params.get('filtering')!) : undefined;
            } catch (err) {
              console.error('Failed to parse url', err);
            }

            if (props.match.params['snapshotId'] === 'live') {
              // todo: support live routing plan
              return <NotFoundPage path={props.location.pathname} />;
            } else {
              const snapshotId = decodeURIComponent(props.match.params['snapshotId']);
              const onTabChange = (tab: string) => {
                this.context.onGoto(buildRoutingPlanUrl(snapshotId, tab, filteringText));
              };
              const onFilteringTextChange = (text: string) => {
                this.context.onGoto(buildRoutingPlanUrl(snapshotId, activeTab, text));
              };

              return <StaticRoutingPlanPage snapshotId={snapshotId} onTabChange={onTabChange} onFilteringTextChange={onFilteringTextChange} activeTab={activeTab} filteringText={filteringText} />;
            }
          }}
        />

        <Route path="/assignment-plan/:plannerInputId" render={(props) => this.buildAssignmentPlanRoute(props, false)} />

        <Route path="/shadow-assignment-plan/:plannerInputId" render={(props) => this.buildAssignmentPlanRoute(props, true)} />

        <Route
          path="/assignment-timeline/:serviceAreaId"
          render={(props) => {
            // const params = new URLSearchParams(props.location.search);
            const serviceAreaId = props.match.params['serviceAreaId'];
            return <AssignmentTimelinePage serviceAreaId={serviceAreaId} />;
          }}
        />

        <Route
          path="/route-snapshot/:routesnapshotId"
          render={(props) => {
            const routeSnapshotId = decodeURIComponent(props.match.params['routesnapshotId']);
            return <RouteSnapshotPage routeSnapshotId={routeSnapshotId} />;
          }}
        />

        <Route exact path="/search" render={() => <SearchPage />} />

        <Route
          exact
          path="/network-health"
          render={(props) => {
            const networkHealthParams: NetworkHealthParams = {
              queryParams: props.location.search,
              isSingleServiceArea: false,
              liveMode: false,
            };
            return <NetworkHealthPage {...networkHealthParams} />;
          }}
        />

        <Route
          exact
          path="/network-health/:serviceAreaId"
          render={(props) => {
            let networkHealthParams: NetworkHealthParams = {
              serviceAreaId: props.match.params['serviceAreaId'],
              isSingleServiceArea: true,
              queryParams: props.location.search,
              liveMode: false,
            };

            if (props.match.params['serviceAreaId'] === 'live') {
              const params = new URLSearchParams(props.location.search);
              const serviceAreaId = params.get('serviceAreaId') ?? undefined;
              const isSingleServiceArea = typeof serviceAreaId === 'string';
              networkHealthParams = { ...networkHealthParams, isSingleServiceArea: isSingleServiceArea, serviceAreaId: serviceAreaId, liveMode: true };
            }

            return <NetworkHealthPage {...networkHealthParams} />;
          }}
        />

        <Route
          exact
          path="/route"
          render={(props) => {
            const params = new URLSearchParams(props.location.search);
            return (
              <LmRoutePage
                userSearch={buildLmRouteUserSearchFromURLSearchParams(params)}
                onUserSearchUpdate={(update) => {
                  const query = buildURLSearchParamsFromLmRouteUserSearch(update);

                  const qs = new URLSearchParams(query);
                  const queryString = qs.toString();
                  if (queryString.length === 0) {
                    this.context.onGoto('/route');
                  } else {
                    this.context.onGoto(`/route?${queryString}`);
                  }
                }}
              />
            );
          }}
        />

        <Route
          path="/amzl-staging-routes"
          render={(props) => {
            const params = new URLSearchParams(props.location.search);
            const dispatchDate = params.get('dispatchDate');
            const serviceAreaId = params.get('serviceAreaId');
            return (
              <AmzlStagingRoutesPage
                dispatchDate={dispatchDate}
                serviceAreaId={serviceAreaId}
                onDispatchDateChange={(date) => {
                  const query: { [key: string]: string } = { dispatchDate: date };
                  if (typeof serviceAreaId === 'string') {
                    query['serviceAreaId'] = serviceAreaId;
                  }
                  const qs = new URLSearchParams(query);
                  this.context.onGoto(`/amzl-staging-routes?${qs.toString()}`);
                }}
                onServiceAreaIdChange={(serviceAreaId) => {
                  const query: { [key: string]: string } = { serviceAreaId };
                  if (typeof dispatchDate === 'string') {
                    query['dispatchDate'] = dispatchDate;
                  }
                  const qs = new URLSearchParams(query);
                  this.context.onGoto(`/amzl-staging-routes?${qs.toString()}`);
                }}
              />
            );
          }}
        />

        <Route render={(props) => <NotFoundPage path={props.location.pathname} />} />
      </Switch>
    );
  }

  buildAssignmentPlanRoute(props: any, isShadow: boolean) {
    const params = new URLSearchParams(props.location.search);
    let activeTab: string | undefined = undefined;
    let filteringText: string | undefined = undefined;
    let shadowPrefix = isShadow ? 'shadow-' : '';
    try {
      activeTab = params.get('tab') !== null ? decodeURIComponent(params.get('tab')!) : undefined;
      filteringText = params.get('filtering') !== null ? decodeURIComponent(params.get('filtering')!) : undefined;
    } catch (err) {
      console.error('Failed to parse url', err);
    }

    if (props.match.params['plannerInputId'] === 'live') {
      const serviceAreaId = params.get('serviceAreaId');
      if (serviceAreaId) {
        const onTabChange = (tab: string) => {
          this.context.onGoto(buildAssignmentPlanUrl('live', tab, filteringText, serviceAreaId, shadowPrefix));
        };
        const onFilteringTextChange = (text: string) => {
          this.context.onGoto(buildAssignmentPlanUrl('live', activeTab, text, serviceAreaId, shadowPrefix));
        };

        return (
          <LiveAssignmentPlanPage
            serviceAreaId={serviceAreaId}
            onTabChange={onTabChange}
            onFilteringTextChange={onFilteringTextChange}
            activeTab={activeTab}
            filteringText={filteringText}
            isShadow={isShadow}
          />
        );
      }
      return <NotFoundPage path={props.location.pathname} />;
    } else if (props.match.params['plannerInputId'] === 'drag-and-drop') {
      const onTabChange = (tab: string) => {
        this.context.onGoto(buildAssignmentPlanUrl('drag-and-drop', tab, filteringText, undefined));
      };
      const onFilteringTextChange = (text: string) => {
        this.context.onGoto(buildAssignmentPlanUrl('drag-and-drop', activeTab, text, undefined));
      };

      return <DragAndDropAssignmentPlanPage onTabChange={onTabChange} onFilteringTextChange={onFilteringTextChange} activeTab={activeTab} filteringText={filteringText} />;
    } else {
      const plannerInputId = decodeURIComponent(props.match.params['plannerInputId']);
      const onTabChange = (tab: string) => {
        this.context.onGoto(buildAssignmentPlanUrl(plannerInputId, tab, filteringText, undefined, shadowPrefix));
      };
      const onFilteringTextChange = (text: string) => {
        this.context.onGoto(buildAssignmentPlanUrl(plannerInputId, activeTab, text, undefined, shadowPrefix));
      };

      return (
        <StaticAssignmentPlanPage
          plannerInputId={plannerInputId}
          onTabChange={onTabChange}
          onFilteringTextChange={onFilteringTextChange}
          activeTab={activeTab}
          filteringText={filteringText}
          isShadow={isShadow}
        />
      );
    }
  }
}

export default class App extends React.Component<{}, {}> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  async componentDidMount() {}

  render() {
    if (this.context.tutorialOpen && this.context.tutorial !== undefined) {
      const tutorial = findTutorial(this.context.tutorial);
      if (tutorial !== undefined) {
        return (
          <AnnotationContext
            currentTutorial={tutorial}
            i18nStrings={I18nStrings}
            onFinish={() => {
              this.context.closeTutorial();
            }}
            onStartTutorial={() => {}}
            onExitTutorial={() => {}}
          >
            {this.renderAppLayout()}
          </AnnotationContext>
        );
      }
    }

    return this.renderAppLayout();
  }

  private renderBreadcrumbs() {
    if (Array.isArray(this.context.breadcrumbItems)) {
      return (
        <BreadcrumbGroup
          items={this.context.breadcrumbItems}
          onFollow={(evt) => {
            // prevent browser from sending request to backend, instead, handle the request in browser (a.k.a in-browser navigation)
            evt.preventDefault();
            this.context.onGoto(evt.detail.href);
          }}
        />
      );
    }
  }

  private renderNotifications() {
    const items: FlashbarProps.MessageDefinition[] = [];
    for (let item of this.context.notifications) {
      items.push({
        ...item,
        dismissible: typeof item.dismissible === 'boolean' ? item.dismissible : true,
        onDismiss: () => {
          if (typeof item.notificationId === 'string') {
            this.context.removeNotification(item.notificationId);
          }
        },
      });
    }
    return <Flashbar items={items} />;
  }

  private renderTools() {
    switch (this.context.tools) {
      case 'lm-route-page':
        return <LmRoutePageTools />;
      case 'network-health-page':
        return <NetworkHealthPageTools />;
      case 'artifacts-page':
        return <ArtifactSearchPageTools />;
      default:
        <Box></Box>;
    }
  }

  private renderAppLayout() {
    return (
      <AppLayout
        contentType={'default'}
        navigation={<Navigation />}
        navigationOpen={this.context.navigationOpen}
        breadcrumbs={this.renderBreadcrumbs()}
        notifications={this.renderNotifications()}
        content={this.renderContent()}
        toolsOpen={this.context.toolsOpen}
        tools={this.renderTools()}
        onToolsChange={({ detail }) => {
          if (detail.open) {
            this.context.openTools();
          } else {
            this.context.closeTools();
          }
        }}
        onNavigationChange={(evt) => {
          this.context.updateNavigationOpen(evt.detail.open);
        }}
      />
    );
  }

  private renderContent() {
    if (this.context.tutorial !== undefined && !this.context.tutorialOpen && !this.context.tutorialComplete) {
      return (
        <SpaceBetween direction="vertical" size="l">
          <Alert
            type="info"
            action={
              <Button
                onClick={() => {
                  this.context.openTutorial();
                }}
              >
                Start tutorial
              </Button>
            }
          >
            A tutorial is available for the page.
          </Alert>
          <Routes />
        </SpaceBetween>
      );
    } else {
      return <Routes />;
    }
  }
}
