import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { GlobalContext } from '../../main-app/global-context';

/**
 * The info panel on the right side of a page, which is known as "tools" in polaris's jargons.
 */
export class LmRoutePageTools extends React.Component<{}, {}> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: {}) {
    super(props);
  }

  render() {
    return (
      <HelpPanel header={<Header variant="h2">Info</Header>}>
        <SpaceBetween direction="vertical" size="m">
          {this.renderStartTutorial()}
          {this.renderLearnMore()}
        </SpaceBetween>
      </HelpPanel>
    );
  }

  private renderStartTutorial() {
    return (
      <Box>
        <Header variant="h3">Tutorials</Header>
        <SpaceBetween direction="vertical" size="xs">
          <Button
            onClick={() => {
              this.context.setTutorial('lm-route-page');
              this.context.openTutorial();
            }}
          >
            Basic tutorial
          </Button>
        </SpaceBetween>
      </Box>
    );
  }

  private renderLearnMore() {
    return (
      <Box>
        <Header variant="h3">Learn more</Header>
        <SpaceBetween direction="vertical" size="xs">
          <Link external href="https://sim.amazon.com/issues/create?template=dc5a4062-cd98-4b02-bc3e-c33a3a8ce70f" target="_blank">
            Request permission
          </Link>
          <Link external href="https://quip-amazon.com/T6S3Av2vGFWP/Route-view-user-feedbacks" target="_blank">
            User feedbacks
          </Link>
          <Link external href="https://email-list.corp.amazon.com/email-list/expand-list/ufraaviz-interest" target="_blank">
            Subscribe to ufraaviz-interest
          </Link>
        </SpaceBetween>
      </Box>
    );
  }
}
