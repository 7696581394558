import { DeploymentStage, Marketplace } from '../../models';
import { UserGeneratedData } from './models';

export const ASSIGNMENT_PLAN_USER_GENERATED_DATA = 'ASSIGNMENT_PLAN_USER_GENERATED_DATA';
export const TRANSPORTER_ID = 'transporterId';
export const TRANSPORTER_NAME = 'transporterName';
export const TRANSPORTER_NOTES = 'transporterNotes';
export const ROUTE_NAME = 'routeName';
export const ROUTE_NOTES = 'routeNotes';

export const DEFAULT_USER_GENERATED_DATA: UserGeneratedData = {
  transportersCSV: [TRANSPORTER_ID, TRANSPORTER_NAME, TRANSPORTER_NOTES].join(', '),
  routesCSV: [ROUTE_NAME, ROUTE_NOTES].join(', '),
};

export const CSV_PARSER_OPTIONS = {
  columns: true, // Treat the first row as headers
  trim: true, // Trim leading/trailing whitespaces from values
  skip_records_with_error: true,
};

// https://w.amazon.com/bin/view/GSFRouting/Development/ConfigurationsManager/#HAppendixC-WebsiteURLs
export const UFRAAVIZ_TO_CONFIG_MANAGER_STAGE_SUFFIX: Readonly<Record<DeploymentStage, string>> = {
  devo: '-beta',
  beta: '-beta',
  gamma: '-gamma',
  prod: '',
};
