import jwtDecode from 'jwt-decode';
import axios from 'axios';

type QueryParameters = { [key: string]: string }; // tslint:disable-line:readonly-keyword

interface MidwayToken {
  iss: string;
  sub: string;
  aud: string;
  // expired at: epoch timestamp in second
  exp: number;
  // issued at: epoch timestamp in second
  iat: number;
}

export class MidwayTokenRetriever {
  public static username: string | undefined;
  private static token: string | undefined;

  static async getUsername(): Promise<string> {
    const token = await MidwayTokenRetriever.getTokenOrRedirect();
    return jwtDecode<MidwayToken>(token).sub;
  }

  static async getTokenOrRedirect(): Promise<string> {
    try {
      if (typeof MidwayTokenRetriever.token === 'string') {
        const expiredAt = jwtDecode<MidwayToken>(MidwayTokenRetriever.token).exp;
        if (expiredAt * 1000 > Date.now()) {
          return MidwayTokenRetriever.token;
        }
      }
    } catch (err) {
      console.error('Failed to validate token', err);
    }

    try {
      const response = await axios.get<string>(MidwayTokenRetriever.buildSSOUrl(), {
        withCredentials: true,
      });
      MidwayTokenRetriever.token = response.data;
      return response.data;
    } catch (err) {
      window.location.href = MidwayTokenRetriever.buildRedirectUrl();
      return '';
    }
  }

  private static buildRedirectUrl(): string {
    const queryParams: QueryParameters = {
      client_id: encodeURIComponent(window.location.host),
      redirect_uri: encodeURIComponent(window.location.href),
      response_type: 'id_token',
      scope: 'openid',
      nonce: MidwayTokenRetriever.generateNonce(),
    };

    return `https://midway-auth.amazon.com/login?next=/SSO/redirect${encodeURIComponent(MidwayTokenRetriever.buildQuery(queryParams))}`;
  }

  private static buildSSOUrl(): string {
    const queryParams: QueryParameters = {
      response_type: 'id_token',
      client_id: encodeURIComponent(window.location.host),
      redirect_uri: encodeURIComponent(window.location.href),
      scope: 'openid',
      nonce: MidwayTokenRetriever.generateNonce(),
    };

    return `https://midway-auth.amazon.com/SSO${MidwayTokenRetriever.buildQuery(queryParams)}`;
  }

  private static buildQuery(parameters: QueryParameters): string {
    return Object.keys(parameters).reduce((accumulator, key) => `${accumulator + key}=${parameters[key]}&`, '?');
  }

  private static generateNonce(): string {
    let nonce = '';
    const characterSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 64; i += 1) {
      nonce += characterSet.charAt(Math.floor(Math.random() * characterSet.length));
    }
    return nonce;
  }
}
