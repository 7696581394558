import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import Button from '@amzn/awsui-components-react/polaris/button';
import Box from '@amzn/awsui-components-react/polaris/box';
import Container from '@amzn/awsui-components-react/polaris/container';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import Select from '@amzn/awsui-components-react/polaris/select';
import Tabs from '@amzn/awsui-components-react/polaris/tabs';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import { JsonVisualizationProfile, JsonVisualizationTableColumnDefinition, Row } from './models';
import './json-view-page.scss';
import JSONViewer from '../../shared-components/json-viewer';
import { buildTableData, convertToBackendJsonVisualizationProfile, convertToJsonVisualizationProfile } from './convert';
import { JsonTable } from './json-table';
import { JsonVisualizationProfileModal } from './json-visualization-profile-modal';
import ufraaVizClient from '../../clients';
import { handleJsonFileDropEvent } from '../../utilities';

interface Props {}

interface State {
  readonly activeDropZone: boolean;
  readonly activeTabId: string;

  // the upload the file name or the artifact Id in case reading from dras.
  readonly userJsonDataIdentifier?: string;
  readonly readingUserJsonDataError?: string;
  readonly userJsonData?: any;
  readonly isReadingUserJsonData: boolean;
  readonly profiles?: ReadonlyArray<JsonVisualizationProfile>;
  readonly selectedProfileId?: string;

  readonly isParsing: boolean;
  readonly parsingUserJsonDataError?: string;
  readonly rows?: ReadonlyArray<Row>;

  readonly displayProfileModal: boolean;
  readonly profileInUpdate?: JsonVisualizationProfile;
}

export class JsonViewPage extends React.Component<Props, State> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      activeDropZone: false,
      isParsing: false,
      isReadingUserJsonData: false,
      activeTabId: 'table',
      displayProfileModal: false,
    };
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.updateBreadcrumbItems([{ text: 'JSON', href: '/json' }]);

    try {
      const profiles = await ufraaVizClient.listJsonVisualizationProfiles();
      this.setState({ profiles: profiles.map((p) => convertToJsonVisualizationProfile(p)) });
      if (this.state.selectedProfileId === undefined && profiles.length > 0) {
        // choose a default profile
        this.setState({ selectedProfileId: profiles[0].profileId });
      }
    } catch (err) {
      this.context.addNotification({
        dismissible: true,
        header: 'Error while retrieving profiles',
        content: (err as any).message,
        type: 'error',
      });
      return;
    }
  }

  render() {
    return (
      <div
        className="json-view-root"
        onDragEnter={(e) => {
          e.stopPropagation();
          e.preventDefault();
          this.setState({ activeDropZone: true });
        }}
        onDragOver={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {this.state.activeDropZone ? this.renderDropZone() : this.renderContent()}
      </div>
    );
  }

  private renderDropZone() {
    return (
      <div
        className="file-drop-zone"
        onDragLeave={(e) => {
          e.stopPropagation();
          e.preventDefault();
          this.setState({ activeDropZone: false });
        }}
        onDrop={async (e) => {
          e.stopPropagation();
          e.preventDefault();
          this.setState({ activeDropZone: false });
          try {
            const result = await handleJsonFileDropEvent(e.dataTransfer);
            this.setState({
              userJsonData: result.data,
              userJsonDataIdentifier: result.filename,
            });
            const profile = this.state.profiles?.find((profile) => profile.profileId === this.state.selectedProfileId);
            if (profile !== undefined) {
              await this.buildTableData(result.data, profile);
            }
          } catch (err: any) {
            this.setState({ readingUserJsonDataError: err.message });
          }
        }}
      >
        <Box textAlign="center" variant="div">
          Drop file here
        </Box>
      </div>
    );
  }
  private renderContent() {
    return (
      <React.Fragment>
        {this.renderProfileModal()}
        <SpaceBetween size="m" direction="vertical">
          {this.renderReadingUserJsonError()}
          {this.renderFileDropZonePrompt()}
          {this.renderMetadata()}
          {this.renderTabs()}
        </SpaceBetween>
      </React.Fragment>
    );
  }

  private renderProfileModal() {
    if (this.state.displayProfileModal) {
      return (
        <JsonVisualizationProfileModal
          profile={this.state.profileInUpdate}
          onDismiss={() => this.setState({ displayProfileModal: false, profileInUpdate: undefined })}
          onUpsert={async (profile) => {
            await ufraaVizClient.upsertJsonVisualizationProfile(convertToBackendJsonVisualizationProfile(profile));
            const profiles = await ufraaVizClient.listJsonVisualizationProfiles();
            this.setState({ displayProfileModal: false, profileInUpdate: undefined, profiles: profiles.map((p) => convertToJsonVisualizationProfile(p)) });
          }}
        />
      );
    }
  }

  private renderReadingUserJsonError() {
    if (typeof this.state.readingUserJsonDataError === 'string') {
      return (
        <Alert
          header="Failed to process the selected file"
          type="warning"
          dismissible={true}
          onDismiss={(evt) => {
            this.setState({ readingUserJsonDataError: undefined });
          }}
        >
          {this.state.readingUserJsonDataError}
        </Alert>
      );
    }
  }

  private renderFileDropZonePrompt() {
    return (
      <div className="file-drop-zone-prompt">
        <Box textAlign="center" variant="div">
          Drag and drop file here.
        </Box>
      </div>
    );
  }

  private renderMetadata() {
    return (
      <Container
        header={
          <Header variant="h2" actions={this.renderMetadataHeaderActions()}>
            Summary
          </Header>
        }
      >
        {this.renderMetadataContent()}
      </Container>
    );
  }

  private renderMetadataContent() {
    if (this.state.userJsonData === undefined) {
      return (
        <Box textAlign="center">
          <StatusIndicator type="info">To start with, select a profile and drop a JSON file to visualize.</StatusIndicator>
        </Box>
      );
    } else {
      return <Box>{this.state.userJsonDataIdentifier}</Box>;
    }
  }

  private renderMetadataHeaderActions() {
    let selectOptions: { value?: string; label: string; description?: string }[] | undefined = this.state.profiles?.map((profile) => ({
      value: profile.profileId,
      label: profile.profileName,
      description: profile.artifactType,
    }));

    const selectedOption = selectOptions?.find((o) => o.value === this.state.selectedProfileId);

    return (
      <SpaceBetween direction="horizontal" size="xxs">
        <Select
          selectedOption={selectedOption ?? null}
          options={selectOptions}
          onChange={async (evt) => {
            this.setState({ selectedProfileId: evt.detail.selectedOption.value });
            const profile = this.state.profiles?.find((p) => p.profileId === evt.detail.selectedOption.value);
            if (profile !== undefined && this.state.userJsonData) {
              await this.buildTableData(this.state.userJsonData, profile);
            }
          }}
        />
        <Button variant="primary" onClick={() => this.setState({ displayProfileModal: true })}>
          Create Profile
        </Button>
      </SpaceBetween>
    );
  }

  private renderTabs() {
    const data = this.state.userJsonData;
    const selectedProfile = this.state.profiles?.find((profile) => profile.profileId === this.state.selectedProfileId);
    const columnDefinitions = selectedProfile?.columnDefinitions;

    if (data !== undefined && columnDefinitions !== undefined && !this.state.isParsing) {
      return (
        <Tabs
          activeTabId={this.state.activeTabId}
          onChange={(evt) => {
            this.setState({ activeTabId: evt.detail.activeTabId });
          }}
          tabs={[
            {
              label: 'Table',
              id: 'table',
              content: this.renderJsonTable(columnDefinitions),
            },
            {
              label: 'JSON',
              id: 'json',
              content: this.renderOriginalJson(data),
            },
          ]}
        />
      );
    } else if (this.state.isParsing) {
      return (
        <Box textAlign="center">
          <StatusIndicator type="loading">Parsing...</StatusIndicator>
        </Box>
      );
    }
  }

  private renderJsonTable(columnDefinitions: ReadonlyArray<JsonVisualizationTableColumnDefinition>) {
    if (typeof this.state.parsingUserJsonDataError === 'string') {
      return (
        <Container header={<Header variant="h2">JSON Table</Header>}>
          <SpaceBetween direction="vertical" size="m">
            <Box textAlign="center">
              <StatusIndicator type="warning">{this.state.parsingUserJsonDataError}</StatusIndicator>
            </Box>
            <Box textAlign="center">
              <Button
                onClick={() => {
                  const profile = this.state.profiles?.find((profile) => profile.profileId === this.state.selectedProfileId);
                  this.setState({
                    displayProfileModal: true,
                    profileInUpdate: profile,
                  });
                }}
              >
                Update profile
              </Button>
              <Box variant="span" margin={{ left: 'm' }}></Box>
              <Button
                onClick={async () => {
                  const profile = this.state.profiles?.find((profile) => profile.profileId === this.state.selectedProfileId);
                  if (profile !== undefined) {
                    await ufraaVizClient.deleteJsonVisualizationProfile(profile.profileId);
                    this.setState({ selectedProfileId: undefined });
                    const profiles = await ufraaVizClient.listJsonVisualizationProfiles();
                    this.setState({ displayProfileModal: false, profileInUpdate: undefined, profiles: profiles.map((p) => convertToJsonVisualizationProfile(p)) });
                  }
                }}
              >
                Delete profile
              </Button>
            </Box>
          </SpaceBetween>
        </Container>
      );
    } else {
      return <JsonTable columns={columnDefinitions} rows={this.state.rows} />;
    }
  }

  private renderOriginalJson(data: any) {
    return (
      <Container header={<Header variant="h2">Original JSON</Header>}>
        <JSONViewer data={data} />
      </Container>
    );
  }

  private async buildTableData(data: any, profile: JsonVisualizationProfile) {
    this.setState({ isParsing: true });
    try {
      // even though the buildTableData is a async function, it's still blocking. To render the parsing loading,
      // sleep 50ms.
      await new Promise((resolve) => setTimeout(resolve, 50));
      const rows = await buildTableData(data, profile.keyPath, profile.columnDefinitions);
      this.setState({ rows: rows, parsingUserJsonDataError: undefined });
    } catch (err) {
      this.setState({
        rows: undefined,
        parsingUserJsonDataError: `Failed to parse input JSON with "${profile.profileName}" profile. Consider using a different profile or update the profile`,
      });
    } finally {
      this.setState({ isParsing: false });
    }
  }
}
