export const NOT_AVAILABLE = '-';
export const TIME_FORMAT = 'HH:mm:ss z';

export const ASSIGNMENT_COLUMN_TO_LABEL: Readonly<Record<string, string>> = {
  routeName: 'Route',
  routeNotes: 'Route Notes',
  routeCycle: 'Route Cycle',
  routeStartTime: 'Route Start Time',
  routeNurseryLevel: 'Route Service Type',
  dispatchWindowStartTime: 'Dispatch Start',
  dispatchWindowEndTime: 'Dispatch End',
  routeDuration: 'Route Duration',
  packageCount: 'Packages',
  rejectionCount: 'Rejections',
  assignmentStatus: 'Status',
  transporterId: 'Transporter ID',
  transporterName: 'Transporter Name',
  transporterNotes: 'Transporter Notes',
  transporterCycleId: 'Transporter Cycle',
  trasnsporterNurseryLevel: 'Transporter Service Type',
  transporterType: 'Transporter Type',
  readyToWork: 'Ready To Work',
  driverShiftStart: 'Transporter Start Time',
  driverShiftEnd: 'Transporter End Time',
  driverBlockLength: 'Transporter Block Length',
  adjustedDriverShiftStart: 'Adjusted Transporter Start Time',
  adjustedDriverShiftEnd: 'Adjusted Transporter End Time',
  adjustedBlockLength: 'Adjusted Transporter Block Length',
  affinityScore: 'Affinity Score',
};

// Mappings to AssignmentStatus resouce for translations
export const ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING: Readonly<Record<string, string>> = {
  routeName: 'ASSIGNMENT_STATUS:ROUTE_NAME',
  routeNotes: 'ASSIGNMENT_STATUS:ROUTE_NOTES',
  routeCycle: 'ASSIGNMENT_STATUS:ROUTE_CYCLE',
  routeStartTime: 'ASSIGNMENT_STATUS:ROUTE_START_TIME',
  routeNurseryLevel: 'ASSIGNMENT_STATUS:ROUTE_SERVICE_TYPE',
  dispatchWindowStartTime: 'ASSIGNMENT_STATUS:DISPATCH_WINDOW_START_TIME',
  dispatchWindowEndTime: 'ASSIGNMENT_STATUS:DISPATCH_WINDOW_END_TIME',
  routeDuration: 'ASSIGNMENT_STATUS:ROUTE_DURATION',
  packageCount: 'ASSIGNMENT_STATUS:PACKAGE_COUNT',
  rejectionCount: 'ASSIGNMENT_STATUS:REJECTION_COUNT',
  assignmentStatus: 'ASSIGNMENT_STATUS:ASSIGNMENT_STATUS',
  transporterId: 'ASSIGNMENT_STATUS:TRANSPORTER_ID',
  transporterName: 'ASSIGNMENT_STATUS:TRANSPORTER_NAME',
  transporterNotes: 'ASSIGNMENT_STATUS:TRANSPORTER_NOTES',
  transporterCycleId: 'ASSIGNMENT_STATUS:TRANSPORTER_CYCLE_ID',
  trasnsporterNurseryLevel: 'ASSIGNMENT_STATUS:TRANSPORTER_SERVICE_TYPE',
  transporterType: 'ASSIGNMENT_STATUS:TRANSPORTER_TYPE',
  readyToWork: 'ASSIGNMENT_STATUS:READY_TO_WORK',
  driverShiftStart: 'ASSIGNMENT_STATUS:DRIVER_SHIFT_START',
  driverShiftEnd: 'ASSIGNMENT_STATUS:DRIVER_SHIFT_END',
  driverBlockLength: 'ASSIGNMENT_STATUS:DRIVER_BLOCK_LENGTH',
  adjustedDriverShiftStart: 'ASSIGNMENT_STATUS:ADJUSTED_DRIVER_SHIFT_START',
  adjustedDriverShiftEnd: 'ASSIGNMENT_STATUS:ADJUSTED_DRIVER_SHIFT_END',
  adjustedBlockLength: 'ASSIGNMENT_STATUS:ADJUSTED_BLOCK_LENGTH',
  affinityScore: 'ASSIGNMENT_STATUS:AFFINITY_SCORE',
};
