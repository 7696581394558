import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import ufraaVizClient, { ArtifactMetadata, ArtifactType } from '../../clients';
import { timezoneManager } from '../../utilities';
import Table, { TableProps } from '@amzn/awsui-components-react/polaris/table';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import TimeRangePicker from '../../shared-components/time-range-picker';
import { WithBundleProps } from '@amzn/react-arb-tools';

export interface ArtifactTableProps extends WithBundleProps {
  readonly tableName: string;
  readonly serviceAreaId: string;
  readonly startTime: number;
  readonly endTime: number;
  readonly artifactType: ArtifactType;
  readonly onQueryWindowChange: (startTime: number, endTime: number) => void;
}

export interface ArtifactTableState {
  readonly artifactMetadata?: ArtifactMetadata[];
  readonly selectedMetadata?: ArtifactMetadata[];
  readonly isDownloading?: boolean;
}

const MAXIMUM_QUERY_WINDOW_SIZE_IN_MS = 6 * 3600 * 1000;
const MAXIMUM_QUERY_WINDOW_SIZE_TEXT = '6 hours';

export default class ArtifactTable extends React.Component<ArtifactTableProps, ArtifactTableState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: ArtifactTableProps) {
    super(props);
    this.state = {
      artifactMetadata: undefined,
      selectedMetadata: undefined,
      isDownloading: false,
    };
  }

  async componentDidMount() {
    await this.loadArtifactMetadata(this.props.startTime, this.props.endTime);
  }

  async loadArtifactMetadata(startTime: number, endTime: number) {
    try {
      const artifactMetadata = await ufraaVizClient.listArtifactMetadata({
        scope: this.props.serviceAreaId,
        artifactType: this.props.artifactType,
        startTime,
        endTime,
        timeUnits: 'MILLISECONDS',
      });
      this.setState({ artifactMetadata });
    } catch (err) {
      this.renderError(err);
      this.setState({ artifactMetadata: [] });
    }
  }

  async downloadArtifact() {
    this.setState({ isDownloading: true });
    try {
      await ufraaVizClient.downloadArtifactsChronologically(this.state.selectedMetadata);
    } catch (err) {
      this.renderError(err);
    } finally {
      this.setState({ isDownloading: false });
    }
  }

  renderError(err: any) {
    this.context.addNotification({
      type: 'error',
      header: `${this.props.artifactType} error`,
      content: typeof err?.response?.data === 'string' ? err?.response?.data : err?.message,
    });
  }

  renderHeader() {
    return (
      <Header
        variant="h2"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="primary"
              disabled={this.state.selectedMetadata === undefined || this.state.selectedMetadata?.length === 0}
              loading={this.state.isDownloading}
              onClick={async () => await this.downloadArtifact()}
            >
              {this.props.bundle.getMessage('STATION_PAGE:DOWNLOAD')}
            </Button>
          </SpaceBetween>
        }
      >
        {this.props.tableName}
      </Header>
    );
  }

  renderDateRangePicker() {
    return (
      <TimeRangePicker
        timezonePreference={timezoneManager.getInUseTimezonePreference()}
        utcOffset={timezoneManager.getUTCOffset()}
        startTime={this.props.startTime}
        endTime={this.props.endTime}
        onChange={async (startTime, endTime) => {
          this.props.onQueryWindowChange(startTime, endTime);
          this.setState({ selectedMetadata: undefined, artifactMetadata: undefined });
          await this.loadArtifactMetadata(startTime, endTime);
        }}
        isValidRange={(startTime, endTime) => {
          if (endTime - startTime > MAXIMUM_QUERY_WINDOW_SIZE_IN_MS) {
            return { valid: false, errorMessage: `Query window size cannot exceed ${MAXIMUM_QUERY_WINDOW_SIZE_TEXT}.` };
          }
          return { valid: true };
        }}
      />
    );
  }

  render() {
    const tableDefintion: TableProps.ColumnDefinition<ArtifactMetadata>[] = [
      {
        id: 'artifactGenerationTime',
        header: this.props.bundle.getMessage('STATION_PAGE:GENERATION_TIME'),
        cell: (item) => timezoneManager.convertTimestampToString(item.creationTime),
      },
      {
        id: 'artifactId',
        header: this.props.bundle.getMessage('STATION_PAGE:ARTIFACT_ID'),
        // TODO: implement modal/redirect to open specific artifact
        cell: (item) => item.artifactId,
      },
    ];

    return (
      <Table<ArtifactMetadata>
        header={this.renderHeader()}
        columnDefinitions={tableDefintion}
        loading={this.state.artifactMetadata === undefined}
        loadingText={this.props.bundle.getMessage('STATION_PAGE:LOADING_ARTIFACTS')}
        items={this.state.artifactMetadata === undefined ? [] : this.state.artifactMetadata}
        stickyHeader={false}
        filter={this.renderDateRangePicker()}
        selectionType="multi"
        onSelectionChange={(evt) => {
          this.setState({
            selectedMetadata: evt.detail.selectedItems,
          });
        }}
        selectedItems={this.state.selectedMetadata}
      />
    );
  }
}
