import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import ButtonDropdown, { ButtonDropdownProps } from '@amzn/awsui-components-react/polaris/button-dropdown';
import { saveCsvString, timezoneManager } from '../../utilities';
import { MessageBundle } from '@amzn/arb-tools';

export interface ExportAutoAssignCsvButtonProps<T> {
  // we pass in a message bundle instead of creating an HOC so this component can also re-use the translated column headers for the details component
  readonly bundle?: MessageBundle;
  readonly disabled: boolean;
  readonly hiddenColumnIds: ReadonlyArray<string>;
  readonly cycles: ReadonlyArray<string>;
  readonly stationCode: string;
  readonly planTime: string;

  // since cycles are specific to AMZL, we keep the selectedCycleName as optional
  // message bundle is also optional since translations are specific for AMZL JP as of now
  readonly buildAutoAssignCsv: (hiddenColumnIds: ReadonlyArray<string>, selectedCycleName?: string, bundle?: MessageBundle) => string;
}

export interface ExportAutoAssignCsvButtonState {
  readonly cycleItems: ReadonlyArray<ButtonDropdownProps.Item>;
}

export class ExportAutoAssignCsvButton<T> extends React.Component<ExportAutoAssignCsvButtonProps<T>, ExportAutoAssignCsvButtonState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: ExportAutoAssignCsvButtonProps<T>) {
    super(props);
    this.state = {
      cycleItems: this.createCycleItems(this.props.cycles),
    };
  }

  componentDidUpdate(prevProps: Readonly<ExportAutoAssignCsvButtonProps<T>>) {
    if (prevProps.cycles.length !== this.props.cycles.length) {
      this.setState({ cycleItems: this.createCycleItems(this.props.cycles) });
    }
  }

  private createCycleItems(cycles: ReadonlyArray<string>) {
    const cycleItems: ButtonDropdownProps.Item[] = [];
    cycles.forEach((cycle) => {
      cycleItems.push({
        id: cycle,
        text: cycle,
      } as ButtonDropdownProps.Item);
    });

    return cycleItems;
  }

  private createFilename(stationCode: string, planTime: string, cycleName: string): string {
    const dateString = timezoneManager.convertTimestampToString(planTime).split(':').join('-');
    return `${stationCode} ${dateString} ${cycleName}`;
  }

  render() {
    return (
      <ButtonDropdown
        disabled={this.props.disabled}
        items={this.state.cycleItems}
        onItemClick={(evt) => {
          const csvString = this.props.buildAutoAssignCsv(this.props.hiddenColumnIds, evt.detail.id, this.props.bundle);
          const filename = this.createFilename(this.props.stationCode, this.props.planTime, evt.detail.id);
          saveCsvString(csvString, filename);
        }}
      >
        {this.props.bundle ? this.props.bundle.getMessage('EXPORT_AUTO_ASSIGN_BUTTON:EXPORT_AS_CSV') : 'Export as CSV'}
      </ButtonDropdown>
    );
  }
}
