import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import { RouteSearch } from './route-search';
import { RouteSummary } from './route-summary';
import { RouteTimeline } from './route-timeline';
import { SequenceMap } from './sequence-map';
import { UserSearch, Stop, ValidSearch } from './models';
import ufraaVizClient, { LmRoute, RouteChangeLog, RouteSequence } from '../../clients';
import { aggregateSequence, compareUserSearch, convertUserSearchToValidSearch } from './utils';
import { RouteItinerary } from './route-itinerary';
import { RouteSelection } from './route-selection';
import { tutorialDataStore } from './tutorial-data';

interface Props {
  readonly userSearch?: UserSearch;
  readonly onUserSearchUpdate: (userSearch: UserSearch) => void;
}

interface State {
  readonly lmRoute?: LmRoute;
  readonly stops?: ReadonlyArray<Stop>;
  readonly lmRouteSequence?: RouteSequence;
  readonly lmRouteTimeline?: ReadonlyArray<RouteChangeLog>;
  // in case the user is searching route by dispatch date, the backend
  // may return multiple routes, and we will prompt a dialog to ask
  // the user to select one of the routes.
  readonly lmRouteOptions?: ReadonlyArray<LmRoute>;

  readonly isLoading: boolean;
  readonly selectedStopId?: string;
}

export class LmRoutePage extends React.Component<Props, State> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    this.context.resetLayout();
    this.context.updateBreadcrumbItems([
      {
        text: 'Route',
        href: '/route',
      },
    ]);

    this.context.setTools('lm-route-page');
    this.context.setTutorial('lm-route-page');

    if (this.props.userSearch) {
      this.handleUserSearch(this.props.userSearch);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!compareUserSearch(prevProps.userSearch, this.props.userSearch) && this.props.userSearch !== undefined) {
      // the previous search is different the current one, reload data and update state.
      this.handleUserSearch(this.props.userSearch);
      return;
    }
  }

  render() {
    return (
      <SpaceBetween direction="vertical" size="l">
        <RouteSearch
          userSearch={this.props.userSearch}
          onSearch={(search) => {
            if (compareUserSearch(search, this.props.userSearch)) {
              // if the user input search is same as the current search, it indicates the search doesn't change, and user just want to refresh the results.
              this.handleUserSearch(search);
            } else {
              // search changes, update the url. Data will be updated by componentDidUpdate
              this.props.onUserSearchUpdate(search);
            }
          }}
          isLoading={this.state.isLoading}
        />
        {this.renderMainContent()}
      </SpaceBetween>
    );
  }

  private renderMainContent() {
    if (this.state.lmRoute && this.state.lmRouteTimeline && this.state.stops && this.state.lmRouteSequence) {
      const routeId = this.state.lmRoute.routeId;
      const version = this.state.lmRoute.version;
      return (
        <React.Fragment>
          <RouteSummary route={this.state.lmRoute} />
          <RouteTimeline
            routeId={this.state.lmRoute.routeId}
            timeline={this.state.lmRouteTimeline}
            version={this.state.lmRoute.version}
            onSequenceClick={() => {}}
            onVersionClick={(version: number) => {
              this.props.onUserSearchUpdate({
                type: 'routeId',
                text: routeId,
                version: version.toString(),
              });
            }}
          />
          <Grid gridDefinition={[{ colspan: 5 }, { colspan: 7 }]}>
            <SequenceMap
              routeId={routeId}
              version={version}
              selectedStopId={this.state.selectedStopId}
              stops={this.state.stops}
              onSelectedStopIdChange={(identifier) => this.setState({ selectedStopId: identifier })}
            />
            <RouteItinerary
              routeId={this.state.lmRoute.routeId}
              stops={this.state.stops}
              version={this.state.lmRoute.version}
              sequence={this.state.lmRouteSequence}
              selectedStopId={this.state.selectedStopId}
              onSelectedStopIdChange={(identifier) => this.setState({ selectedStopId: identifier })}
            />
          </Grid>
        </React.Fragment>
      );
    } else if (this.state.lmRoute && this.state.lmRouteTimeline) {
      const routeId = this.state.lmRoute.routeId;
      return (
        <React.Fragment>
          <RouteSummary route={this.state.lmRoute} />
          <RouteTimeline
            routeId={this.state.lmRoute.routeId}
            timeline={this.state.lmRouteTimeline}
            version={this.state.lmRoute.version}
            onSequenceClick={() => {}}
            onVersionClick={(version: number) => {
              this.props.onUserSearchUpdate({
                type: 'routeId',
                text: routeId,
                version: version.toString(),
              });
            }}
          />
        </React.Fragment>
      );
    }
    if (this.state.lmRoute) {
      return (
        <React.Fragment>
          <RouteSummary route={this.state.lmRoute} />
        </React.Fragment>
      );
    } else if (this.state.lmRouteOptions) {
      return (
        <React.Fragment>
          <RouteSelection
            routes={this.state.lmRouteOptions}
            onRouteClick={(routeId) => {
              this.props.onUserSearchUpdate({
                type: 'routeId',
                text: routeId,
              });
            }}
          />
        </React.Fragment>
      );
    }
  }

  /**
   * Load data from backend and update states.
   * @param userSearch
   * @returns
   */
  private async handleUserSearch(userSearch: UserSearch) {
    if (userSearch.type !== 'routeId' || userSearch.text !== this.state.lmRoute?.routeId) {
      // cleanup if it's not switching route version
      this.setState({
        lmRoute: undefined,
        lmRouteTimeline: undefined,
        stops: undefined,
        lmRouteSequence: undefined,
        lmRouteOptions: undefined,
      });
    }

    /**
     * A user can input the search from the input boxes, or even from the browser url. Since there is no restriction on what the user inputs,
     * we need to sanitize the user input before sending to the backend. If the function detects invalid user inputs, the UI should promote
     * the warning message and discard the search request.
     */
    let validSearch: ValidSearch;
    try {
      validSearch = convertUserSearchToValidSearch(userSearch);
    } catch (err) {
      this.context.addNotification({
        dismissible: true,
        header: 'Error while retrieving routes',
        content: (err as any).message,
        type: 'error',
      });
      return;
    }
    let routes: LmRoute[];
    try {
      this.setState({ isLoading: true });
      if (validSearch.type === 'routeId' && tutorialDataStore.hasRoute(validSearch.routeId)) {
        routes = [await tutorialDataStore.searchRoute(validSearch.routeId, validSearch.version)];
      } else {
        routes = await this.searchRoutes(validSearch);
        routes = routes.sort((r1, r2) => {
          // sort routes by their last update at time.
          return r2.auditInfo.timestamp - r1.auditInfo.timestamp;
        });
      }
    } catch (err: any) {
      let message = err.message;
      if (err.response) {
        message = `${err.response.status} ${err.response.data}`;
      }
      this.context.addNotification({
        dismissible: true,
        header: 'Error while retrieving routes',
        content: message,
        type: 'error',
      });
      this.setState({ isLoading: false });
      return;
    }

    if (routes.length > 1) {
      // found multiple routes associated with the search, the UI will prompt the user to select one of the routes.
      this.setState({
        lmRouteOptions: routes,
        isLoading: false,
      });
    } else if (routes.length === 1) {
      const route = routes[0];
      let timeline: RouteChangeLog[];
      if (tutorialDataStore.hasRoute(route.routeId)) {
        timeline = await tutorialDataStore.searchRouteTimeline(route.routeId);
      } else {
        timeline = await ufraaVizClient.getLmRouteTimline(route.routeId);
      }

      try {
        let routeSequence: RouteSequence;
        if (tutorialDataStore.hasRoute(route.routeId)) {
          routeSequence = await tutorialDataStore.searchRouteSequence(route.routeId, route.version);
        } else {
          routeSequence = await ufraaVizClient.getLmRouteSequence(route.routeId, route.version);
        }

        this.setState({
          lmRoute: route,
          lmRouteTimeline: timeline,
          lmRouteSequence: routeSequence,
          stops: aggregateSequence(routeSequence),
          isLoading: false,
        });
      } catch (err: any) {
        // the route [version] doesn't have a sequence
        this.context.addNotification({
          dismissible: true,
          header: 'No Sequence',
          content: `Route ${route.routeId} (version ${route.version}) doesn't have a sequence yet.`,
          type: 'info',
        });
        this.setState({
          lmRoute: route,
          lmRouteTimeline: timeline,
          lmRouteSequence: undefined,
          isLoading: false,
        });
      }
    } else {
      this.setState({ isLoading: false });
      this.context.addNotification({
        dismissible: true,
        header: `Can't find route(s) with ${userSearch.type}=${userSearch.text}`,
        content: `The backend returns empty response with this search. Note if the route is closed or cancelled, you may not be able to search it by giving trId, trackingId or transporterId.`,
        type: 'warning',
      });
      return;
    }
  }

  private async searchRoutes(search: ValidSearch): Promise<LmRoute[]> {
    switch (search.type) {
      case 'routeId':
        {
          const route = await ufraaVizClient.getLmRoute(search.routeId, search.version);
          return [route];
        }
        break;
      case 'correlationId':
        {
          return await ufraaVizClient.getLmRoutesByCorrelationId(search.correlationId);
        }
        break;
      case 'trId':
      case 'trackingId':
      case 'transporterId': {
        return await ufraaVizClient.getLmRoutes(search.type, search.propertyId, search.serviceAreaId, search.dispatchDate);
      }
      default: {
        throw new Error(`search routes by ${(search as any).type} is not supported`);
      }
    }
  }
}
