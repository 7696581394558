import React from 'react';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Box from '@amzn/awsui-components-react/polaris/box';
import Toggle from '@amzn/awsui-components-react/polaris/toggle';
import Button from '@amzn/awsui-components-react/polaris/button';
import RadioGroup from '@amzn/awsui-components-react/polaris/radio-group';
import Input from '@amzn/awsui-components-react/polaris/input';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import { RouteTimelineColumnId, routeTimelineColumnIdToName } from './timeline-table-definition';

const OPTIONAL_COLUMNIDS: ReadonlyArray<RouteTimelineColumnId> = ['updateCategory', 'updateType'];

const TIME_FORMAT_OPTIONS: ReadonlyArray<{ value: RouteTimelineSettings['timeFormat']; label: string }> = [
  { value: 'HH:mm:ss z', label: '20:59:59 PST' },
  { value: 'YYYY-MM-DD HH:mm:ss z', label: '2020-01-01 20:59:59 PST' },
];

export interface RouteTimelineSettings {
  readonly hiddenColumnIds: ReadonlyArray<RouteTimelineColumnId>;
  readonly timeFormat: 'HH:mm:ss z' | 'YYYY-MM-DD HH:mm:ss z';
  readonly eventsPerPage: number;
}

export interface RouteTimelinePreferencesProps {
  readonly settings: RouteTimelineSettings;
  readonly visible: boolean;
  readonly onCancel: () => void;
  readonly onSave: (settings: RouteTimelineSettings) => void;
}

export interface RouteTimelinePreferencesState {
  readonly settings: RouteTimelineSettings;
  readonly eventsPerPage: string;
  readonly eventsPerPageErrorMessage?: string;
}

export class RouteTimelinePreferencesModal extends React.Component<RouteTimelinePreferencesProps, RouteTimelinePreferencesState> {
  constructor(props: RouteTimelinePreferencesProps) {
    super(props);
    this.state = {
      settings: this.props.settings,
      eventsPerPage: this.props.settings.eventsPerPage.toString(),
    };
  }

  componentDidUpdate(prevProps: Readonly<RouteTimelinePreferencesProps>) {
    if (this.props.settings !== prevProps.settings) {
      this.setState({ settings: this.props.settings });
    }
  }

  render() {
    return (
      <Modal header="Settings" onDismiss={() => this.props.onCancel()} footer={this.renderFooter()} visible={this.props.visible} closeAriaLabel="Close settings">
        {this.renderModalContent()}
      </Modal>
    );
  }

  private renderModalContent() {
    return (
      <ColumnLayout columns={2} borders="vertical">
        {this.renderCustomizations()}
        {this.renderVisibleColumns()}
      </ColumnLayout>
    );
  }

  private renderCustomizations() {
    return (
      <SpaceBetween direction="vertical" size="l">
        <Box>
          <Box>Time format</Box>
          <RadioGroup
            items={TIME_FORMAT_OPTIONS}
            value={this.state.settings.timeFormat}
            onChange={(evt) => {
              const newSettings: RouteTimelineSettings = {
                ...this.state.settings,
                timeFormat: evt.detail.value as RouteTimelineSettings['timeFormat'],
              };
              this.setState({ settings: newSettings });
            }}
          />
        </Box>
        <FormField label="Events per page" errorText={this.state.eventsPerPageErrorMessage}>
          <Input
            value={this.state.eventsPerPage}
            onChange={(evt) => {
              this.setState({ eventsPerPage: evt.detail.value });
            }}
          />
        </FormField>
      </SpaceBetween>
    );
  }

  private renderVisibleColumns() {
    return (
      <SpaceBetween direction="vertical" size="l">
        <Box>Select visible columns</Box>
        <ColumnLayout columns={1} borders="horizontal">
          {OPTIONAL_COLUMNIDS.map((columnId, index) => {
            return this.renderKeyValueToggle(columnId, index);
          })}
        </ColumnLayout>
      </SpaceBetween>
    );
  }

  private renderKeyValueToggle(columnId: RouteTimelineColumnId, index: number) {
    const checked = !this.state.settings.hiddenColumnIds.includes(columnId);
    return (
      <Box margin={{ left: 'm' }} key={index}>
        {routeTimelineColumnIdToName[columnId]}
        <Box float="right">
          <Toggle
            checked={checked}
            onChange={(evt) => {
              if (evt.detail.checked && this.state.settings.hiddenColumnIds.includes(columnId)) {
                const newSettings = {
                  ...this.state.settings,
                  hiddenColumnIds: this.state.settings.hiddenColumnIds.filter((t) => t !== columnId),
                };
                this.setState({ settings: newSettings });
              } else if (!evt.detail.checked && !this.state.settings.hiddenColumnIds.includes(columnId)) {
                const newHiddenColumnIds = Array.from(this.state.settings.hiddenColumnIds);
                newHiddenColumnIds.push(columnId);
                const newSettings = {
                  ...this.state.settings,
                  hiddenColumnIds: newHiddenColumnIds,
                };
                this.setState({ settings: newSettings });
              }
            }}
          />
        </Box>
      </Box>
    );
  }

  private renderFooter() {
    return (
      <Box float="right">
        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="link" onClick={() => this.props.onCancel()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              const text = this.state.eventsPerPage.trim();
              const num = Number(text);
              if (Number.isInteger(num) && num > 0 && num.toString() === text) {
                if (num > 100) {
                  this.setState({ eventsPerPageErrorMessage: 'Maximum 100 events per page.' });
                } else {
                  this.setState({ eventsPerPageErrorMessage: undefined });
                  this.props.onSave({
                    ...this.state.settings,
                    eventsPerPage: num,
                  });
                }
              } else {
                this.setState({ eventsPerPageErrorMessage: `${text} should be a valid positive integer.` });
              }
            }}
          >
            Confirm
          </Button>
        </SpaceBetween>
      </Box>
    );
  }
}
