import React from 'react';
import { FdpAssignment } from '../../../clients';
import { timezoneManager, formatDuration } from '../../../utilities';
import Link from '@amzn/awsui-components-react/polaris/link';
import Box from '@amzn/awsui-components-react/polaris/box';
import { AssignmentTableDefinitionProps, AssignmentColumnDefinition } from './models';
import { convertNullable } from './utilities';
import { NOT_AVAILABLE, TIME_FORMAT, ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING } from './constants';
import { config } from '../../../utilities';
import { ROUTE_NOTES, TRANSPORTER_ID, TRANSPORTER_NAME, TRANSPORTER_NOTES } from '../constants';

export function buildAmzlTableDefinition(props: AssignmentTableDefinitionProps): AssignmentColumnDefinition<FdpAssignment>[] {
  return [
    {
      id: 'routeName',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['routeName']),
      cell: (item: FdpAssignment) => {
        if (item.route) {
          return (
            <Box>
              <Link
                onFollow={(evt) => {
                  evt.preventDefault();
                  if (item.route) {
                    props.onRouteClick(item.route);
                  }
                }}
              >
                {AmzlTableRenderer.renderRouteName(item)}
              </Link>
            </Box>
          );
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
    {
      id: ROUTE_NOTES,
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING[ROUTE_NOTES]),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderRouteNotes(item);
      },
    },
    {
      id: 'routeCycle',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['routeCycle']),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderRouteCycle(item);
      },
    },
    {
      id: 'routeStartTime',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['routeStartTime']),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderRouteStartTime(item);
      },
    },
    {
      id: 'routeNurseryLevel',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['routeNurseryLevel']),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderRouteNurseryLevel(item);
      },
    },
    {
      id: 'dispatchWindowStartTime',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['dispatchWindowStartTime']),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderDispatchWindowStartTime(item);
      },
    },
    {
      id: 'dispatchWindowEndTime',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['dispatchWindowEndTime']),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderDispatchWindowEndTime(item);
      },
    },
    {
      id: 'routeDuration',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['routeDuration']),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderRouteDuration(item);
      },
    },
    {
      id: 'packageCount',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['packageCount']),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderPackageCount(item);
      },
    },
    {
      id: 'rejectionCount',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['rejectionCount']),
      removable: true,
      cell: (item: FdpAssignment) => {
        if (item.route) {
          const transporterIds = item.rejectedTransporterIds ?? [];
          return (
            <Box>
              <Link
                onFollow={(evt) => {
                  evt.preventDefault();
                  if (props.onRejectionCountClick) {
                    props.onRejectionCountClick(transporterIds);
                  }
                }}
              >
                {transporterIds.length}
              </Link>
            </Box>
          );
        } else {
          return NOT_AVAILABLE;
        }
      },
    },
    {
      id: 'assignmentStatus',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['assignmentStatus']),
      removable: false,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderAssignmentStatus(item);
      },
    },
    {
      id: TRANSPORTER_ID,
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING[TRANSPORTER_ID]),
      removable: true,
      cell: (item: FdpAssignment) => {
        const transporterId = AmzlTableRenderer.renderTransporterId(item);
        if (transporterId === NOT_AVAILABLE) {
          return transporterId;
        } else {
          const link = `${config.logisticsPortalURL}/amconsole/transporter/${transporterId}`;
          return (
            <Link external externalIconAriaLabel="Opens in a new tab" href={link}>
              {transporterId}
            </Link>
          );
        }
      },
    },
    {
      id: TRANSPORTER_NAME,
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING[TRANSPORTER_NAME]),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderTransporterName(item);
      },
    },
    {
      id: TRANSPORTER_NOTES,
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING[TRANSPORTER_NOTES]),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderTransporterNotes(item);
      },
    },
    {
      id: 'transporterCycleId',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['transporterCycleId']),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderTransporterCycleId(item);
      },
    },
    {
      id: 'trasnsporterNurseryLevel',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['trasnsporterNurseryLevel']),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderTransporterNurseryLevel(item);
      },
    },
    {
      id: 'transporterType',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['transporterType']),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderTransporterType(item);
      },
    },
    {
      id: 'readyToWork',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['readyToWork']),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderReadyToWork(item);
      },
    },
    {
      id: 'driverShiftStart',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['driverShiftStart']),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderDriverShiftStart(item);
      },
    },
    {
      id: 'driverShiftEnd',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['driverShiftEnd']),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderDriverShiftEnd(item);
      },
    },
    {
      id: 'driverBlockLength',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['driverBlockLength']),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderDriverBlockLength(item);
      },
    },
    {
      id: 'adjustedDriverShiftStart',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['adjustedDriverShiftStart']),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderAdjustedDriverShiftStart(item);
      },
    },
    {
      id: 'adjustedDriverShiftEnd',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['adjustedDriverShiftEnd']),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderAdjustedDriverShiftEnd(item);
      },
    },
    {
      id: 'adjustedBlockLength',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['adjustedBlockLength']),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderAdjustedBlockLength(item);
      },
    },
    {
      id: 'affinityScore',
      header: props.bundle!.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING['affinityScore']),
      removable: true,
      cell: (item: FdpAssignment) => {
        return AmzlTableRenderer.renderAffinityScore(item);
      },
    },
  ];
}

// given an FdpAssignment, renders specific table property
export class AmzlTableRenderer {
  static renderRouteName(item: FdpAssignment): string {
    return convertNullable(NOT_AVAILABLE, item.route?.routeName);
  }

  static renderRouteNotes(item: FdpAssignment): string {
    return item.routeExtras?.routeUserNotes ?? NOT_AVAILABLE;
  }

  static renderRouteCycle(item: FdpAssignment): string {
    return item.routeExtras?.cycleName ?? NOT_AVAILABLE;
  }

  static renderRouteStartTime(item: FdpAssignment): string {
    const routeStartTime = item.routeExtras?.routeStartTime;
    return typeof routeStartTime === 'string' ? timezoneManager.convertTimestampToString(routeStartTime, { format: TIME_FORMAT }) : NOT_AVAILABLE;
  }

  static renderRouteNurseryLevel(item: FdpAssignment): string {
    return item.routeExtras?.nurseryLevel ?? NOT_AVAILABLE;
  }

  static renderDispatchWindowStartTime(item: FdpAssignment): string {
    return item.route && item.route.dispatchWindow ? timezoneManager.convertTimestampToString(item.route.dispatchWindow?.start, { format: TIME_FORMAT }) : NOT_AVAILABLE;
  }

  static renderDispatchWindowEndTime(item: FdpAssignment): string {
    return item.route && item.route.dispatchWindow ? timezoneManager.convertTimestampToString(item.route.dispatchWindow?.end, { format: TIME_FORMAT }) : NOT_AVAILABLE;
  }

  static renderRouteDuration(item: FdpAssignment): string {
    const duration = item.route?.routeDuration;
    return typeof duration === 'number' ? formatDuration(duration) : NOT_AVAILABLE;
  }

  static renderPackageCount(item: FdpAssignment): string {
    return item.routeExtras?.packageCount?.toString() ?? NOT_AVAILABLE;
  }

  static renderAssignmentStatus(item: FdpAssignment): string {
    let assignmentStatus: string | undefined = item.assignmentStatus;
    assignmentStatus = assignmentStatus ? assignmentStatus : item.transporterUnAssignedReason;
    return convertNullable(NOT_AVAILABLE, assignmentStatus);
  }

  static renderTransporterId(item: FdpAssignment): string {
    return convertNullable(NOT_AVAILABLE, item.transporter?.transporterId);
  }

  static renderTransporterName(item: FdpAssignment): string {
    const transporterId = convertNullable(NOT_AVAILABLE, item.transporter?.transporterId);
    const transporterName = convertNullable(transporterId, item.transporterExtras?.transporterName);
    return transporterName;
  }

  static renderTransporterNotes(item: FdpAssignment): string {
    return convertNullable(NOT_AVAILABLE, item.transporterExtras?.transporterUserNotes);
  }

  static renderTransporterCycleId(item: FdpAssignment): string {
    return item.transporter?.cycle?.cycleName ?? NOT_AVAILABLE;
  }

  static renderTransporterNurseryLevel(item: FdpAssignment): string {
    return item.transporterExtras?.nurseryLevel ?? NOT_AVAILABLE;
  }

  static renderTransporterType(item: FdpAssignment): string {
    return convertNullable(NOT_AVAILABLE, item.transporter?.type);
  }

  static renderReadyToWork(item: FdpAssignment): string {
    const readyToWork = item.transporter?.readyToWork;
    return typeof readyToWork === 'boolean' ? `${readyToWork ? '👍' : '❌'}` : NOT_AVAILABLE;
  }

  static renderDriverShiftStart(item: FdpAssignment): string {
    const startTime = item.transporter?.originalShiftStartTime;
    return startTime ? timezoneManager.convertTimestampToString(startTime, { format: TIME_FORMAT }) : NOT_AVAILABLE;
  }

  static renderDriverShiftEnd(item: FdpAssignment): string {
    const endTime = item.transporter?.originalShiftEndTime;
    return endTime ? timezoneManager.convertTimestampToString(endTime, { format: TIME_FORMAT }) : NOT_AVAILABLE;
  }

  static renderDriverBlockLength(item: FdpAssignment): string {
    const blockDuration = item.transporterExtras?.transporterDuration;
    return blockDuration ? formatDuration(blockDuration) : NOT_AVAILABLE;
  }

  static renderAdjustedDriverShiftStart(item: FdpAssignment): string {
    const adjustedStartTime = item.transporter?.shiftStartTime;
    return adjustedStartTime ? timezoneManager.convertTimestampToString(adjustedStartTime, { format: TIME_FORMAT }) : NOT_AVAILABLE;
  }

  static renderAdjustedDriverShiftEnd(item: FdpAssignment): string {
    const adjustedEndTime = item.transporter?.shiftEndTime;
    return adjustedEndTime ? timezoneManager.convertTimestampToString(adjustedEndTime, { format: TIME_FORMAT }) : NOT_AVAILABLE;
  }

  static renderAdjustedBlockLength(item: FdpAssignment): string {
    const adjustedBlockDuration = item.transporterExtras?.adjustedTransporterDuration;
    return adjustedBlockDuration ? formatDuration(adjustedBlockDuration) : NOT_AVAILABLE;
  }

  static renderAffinityScore(item: FdpAssignment): string {
    return item.assignmentExtras?.affinityScore?.toFixed(2) ?? NOT_AVAILABLE;
  }
}
