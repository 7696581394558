import { AxiosInstance, AxiosStatic } from 'axios';
import {
  ServiceAreaSummary,
  ServiceAreaSummariesResponse,
  ServiceAreaDetails,
  ServiceAreaDetailsResponse,
  AssignmentMetadata,
  AssignmentMetadataRequest,
  ArtifactReferenceRequest,
  ArtifactReferenceResponse,
  ArtifactReference,
  ArtifactIdentifier,
  Artifact,
  ArtifactMetadata,
  ArtifactMetadataRequest,
  ArtifactMetadataResponse,
  AssignmentEventRequest,
  AssignmentEvent,
  LmRoutesResponse,
  NetworkHealthDetailsRequest,
  NetworkHealthDetailsResponse,
  ListJsonVisualizationProfilesResponse,
  LatestArtifactReferenceResponse,
  LatestArtifactReferenceRequest,
  ListAmzlRouteStagingItemsRequest,
  ListAmzlRouteStagingItemsResponse,
  ArtifactTypeMetadata,
  ArtifactTypeMetadataResponse,
  ArtifactType,
} from './models';
import lodash from 'lodash';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import {
  LmRoute,
  LmRouteResponse,
  LmRouteSequenceResponse,
  RouteSequence,
  LmRouteTimelineResponse,
  RouteChangeLog,
  Permission,
  PermissionResponse,
  COMPRESSED_ARTIFACT_TYPES,
  NetworkHealthDetails,
} from '.';
import { BackendJsonVisualizationProfile } from '../components/json-view-page/models';

// the backend APIs have a restriction on the query window, which cannot exceed 2 hours.
const MAXIMUM_QUERY_WINDOW_IN_SECONDS = 2 * 3600;
const MAXIMUM_ARTIFACT_REFERENCES_PER_REQUEST = 50;
const CONCURRENT_DOWNLOAD = 5;

interface Query {
  [key: string]: string;
}

interface TimeWindow {
  startTime: number;
  endTime: number;
}

export class UfraaVizClientImpl {
  private readonly httpClient: AxiosInstance;
  private readonly s3PresignedUrlHttpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance, s3PresignedUrlHttpClient: AxiosInstance) {
    this.httpClient = httpClient;
    this.s3PresignedUrlHttpClient = s3PresignedUrlHttpClient;
  }

  async getUserPermissions(): Promise<Permission[]> {
    const response = await this.httpClient.get<PermissionResponse>('/permissions');
    return response.data.permissions;
  }

  async getServiceAreaSummaries(): Promise<ServiceAreaSummary[]> {
    const resp = await this.httpClient.get<ServiceAreaSummariesResponse>('/service-area-summaries');

    return resp.data.serviceAreaSummaries;
  }

  async getServiceAreaDetails(serviceAreaId: string): Promise<ServiceAreaDetails> {
    const query: Query = { serviceAreaId };
    const resp = await this.httpClient.get<ServiceAreaDetailsResponse>(`/service-area-details?${new URLSearchParams(query).toString()}`);
    return resp.data.serviceAreaDetails;
  }

  async getLmRoute(routeId: string, version?: number): Promise<LmRoute> {
    const query: Query = { routeId };
    if (typeof version === 'number') {
      query['version'] = version.toString();
    }
    const resp = await this.httpClient.get<LmRouteResponse>(`/lm-route?${new URLSearchParams(query).toString()}`);
    return resp.data.route;
  }

  async getLmRoutesByCorrelationId(correlationId: string): Promise<LmRoute[]> {
    const query: Query = { correlationId };
    const resp = await this.httpClient.get<LmRoutesResponse>(`/lm-routes?${new URLSearchParams(query).toString()}`);
    return resp.data.routes;
  }

  async getLmRoutes(idType: 'trackingId' | 'trId' | 'transporterId', id: string, serviceAreaId: string, dispatchDate?: string): Promise<LmRoute[]> {
    const query: Query = {
      serviceAreaId: serviceAreaId,
    };
    query[idType] = id;

    if (typeof dispatchDate === 'string') {
      query['dispatchDate'] = dispatchDate;
    }
    const resp = await this.httpClient.get<LmRoutesResponse>(`/lm-routes?${new URLSearchParams(query).toString()}`);
    return resp.data.routes;
  }

  async getLmRouteSequence(routeId: string, version?: number): Promise<RouteSequence> {
    const query: Query = { routeId };
    if (typeof version === 'number') {
      query['version'] = version.toString();
    }
    const resp = await this.httpClient.get<LmRouteSequenceResponse>(`/lm-route-sequence?${new URLSearchParams(query).toString()}`);
    return resp.data.routeSequence;
  }

  async getLmRouteTimline(routeId: string): Promise<RouteChangeLog[]> {
    const query: Query = { routeId };
    const resp = await this.httpClient.get<LmRouteTimelineResponse>(`/lm-route-timeline?${new URLSearchParams(query).toString()}`);
    return resp.data.timeline;
  }

  async listJsonVisualizationProfiles(): Promise<BackendJsonVisualizationProfile[]> {
    const resp = await this.httpClient.get<ListJsonVisualizationProfilesResponse>('/json-viz-profiles');
    return resp.data.profiles;
  }

  async upsertJsonVisualizationProfile(profile: BackendJsonVisualizationProfile): Promise<void> {
    await this.httpClient.post<any>(
      '/json-viz-profile',
      {
        profile: profile,
      },
      {
        headers: {
          'content-type': 'application/json',
        },
      },
    );
  }

  async deleteJsonVisualizationProfile(profileId: string): Promise<void> {
    await this.httpClient.delete<ListJsonVisualizationProfilesResponse>('/json-viz-profile', { params: { profileId: profileId } });
  }

  async getArtifactReference(request: ArtifactReferenceRequest): Promise<ArtifactReference[]> {
    const partitionedRequests = lodash.chunk(request.artifactIdentifiers, MAXIMUM_ARTIFACT_REFERENCES_PER_REQUEST).map((artifactIdentifiers) => {
      const request: ArtifactReferenceRequest = {
        artifactIdentifiers,
      };
      return request;
    });

    let references: ArtifactReference[] = [];
    for (let i = 0; i < partitionedRequests.length; i++) {
      const response = await this.httpClient.post<ArtifactReferenceResponse>(`/artifact-reference`, partitionedRequests[i], {
        headers: {
          'content-type': 'application/json',
        },
      });
      references = references.concat(response.data.artifactReferences);
    }

    return references;
  }

  async getArtifactsFromReferences<T>(request: ArtifactReferenceRequest): Promise<Artifact<T>[]> {
    const references = await this.getArtifactReference(request);
    const referencesChunks = lodash.chunk(references, CONCURRENT_DOWNLOAD);

    const artifacts: Artifact<T>[] = [];
    for (let i = 0; i < referencesChunks.length; i++) {
      await Promise.all(
        referencesChunks[i].map(async (reference) => {
          const resp = await this.s3PresignedUrlHttpClient.get<T>(reference.reference, {
            responseType: COMPRESSED_ARTIFACT_TYPES.includes(reference.artifactType) ? 'arraybuffer' : 'json',
          });

          artifacts.push({
            artifactId: reference.artifactId,
            artifactType: reference.artifactType,
            artifact: resp.data,
          });
        }),
      );
    }

    return artifacts;
  }

  // This method is the same as the one above, but it reports on its progress while respecting batch size
  // TODO -- replace all use cases of getArtifactsFromReferences
  async *getArtifactsFromReferences_incrementally<T>(request: ArtifactReferenceRequest): AsyncGenerator<Artifact<T>[]> {
    const references = await this.getArtifactReference(request);
    const referencesChunks = lodash.chunk(references, CONCURRENT_DOWNLOAD);

    for (let i = 0; i < referencesChunks.length; i++) {
      const results: Artifact<T>[] = [];
      await Promise.all(
        referencesChunks[i].map(async (reference) => {
          const resp = await this.s3PresignedUrlHttpClient.get<T>(reference.reference, {
            responseType: COMPRESSED_ARTIFACT_TYPES.includes(reference.artifactType) ? 'arraybuffer' : 'json',
          });

          results.push({
            artifactId: reference.artifactId,
            artifactType: reference.artifactType,
            artifact: resp.data,
          });
        }),
      );

      yield results;
    }
  }

  async getSearchByQueryIdRequest(request: AssignmentEventRequest): Promise<AssignmentEvent[]> {
    const resp = await this.httpClient.get(`/search-assignment-events?requestString=${request.requestString}&searchType=${request.searchType}`);
    const results = resp.data.searchResults;

    const events: AssignmentEvent[] = [];

    for (let i = 0; i < results.length; i++) {
      const event = results[i];

      const assignmentEvent = {
        creationTime: event.creationtime,
        planId: event.planid,
        serviceAreaId: event.serviceareaid,
        routeId: event.routeid,
        transporterId: event.transporterid,
        transporterType: event.transportertype,
        offerWindowStart: event.offerwindowstart,
        offerWindowEnd: event.offerwindowend,
        dispatchStart: event.dispatchstart,
        dispatchEnd: event.dispatchend,
        blockStartTime: event.blockstarttime,
        blockEndTime: event.blockendtime,
        readyToWorkState: event.readytoworkstate,
        routeDuration: event.routeduration,
        trIds: event.trids,
        orderIds: event.orderids,
        groupIds: event.groupids,
        scannableIds: event.scannableids,
        reason: event.reason,
      };
      events.push(assignmentEvent);
    }

    // Will be replaced by proper column sorting on the table after the demo
    events.sort((a, b) => {
      return a.creationTime.localeCompare(b.creationTime);
    });
    return events;
  }

  async getArtifact<T>(request: ArtifactIdentifier): Promise<Artifact<T>> {
    const response = await this.httpClient.get<Artifact<T>>(`/artifact?artifactId=${request.artifactId}&artifactType=${request.artifactType}`);
    return response.data;
  }

  /**
   * Function calls ufraaviz backend to get latest artifact.
   *
   * ToDo: support uncompress in browser.
   */
  async getLatestArtifact<T>(request: LatestArtifactReferenceRequest): Promise<Artifact<T>> {
    const reference = await this.httpClient.get<LatestArtifactReferenceResponse>(`/latest-artifact-reference?scope=${request.scope}&artifactType=${request.artifactType}`);
    const resp = await this.s3PresignedUrlHttpClient.get<any>(reference.data.artifactReferences[0].reference, {
      responseType: COMPRESSED_ARTIFACT_TYPES.includes(request.artifactType) ? 'arraybuffer' : 'json',
    });

    /**
     * LatestArtifactReferenceResponse contains a list of references, however currently we only support searching 1 reference on frontend
     * Hence we index by 0 to get the singular reference.
     * TODO: support multiple aritfact downloads (i.e. user can toggle configs and snapshot to get latest at same time)
     */
    const artifact: Artifact<T> = {
      artifactId: reference.data.artifactReferences[0].artifactId,
      artifactType: reference.data.artifactReferences[0].artifactType,
      artifact: resp.data,
    };

    return artifact;
  }

  /**
   * Function calls ufraaviz backend to get latest artifact. Currently, only supports downloading a single artifact for the ufraaviz artifacts page.
   * @param LatestArtifactRequest request including scope and artifact type for backend to pull latest artifact reference
   * @param filename optional filename without any extension. Extension will be automatically appended.
   * @return Promise<void>
   */
  async downloadLatestArtifact(request: LatestArtifactReferenceRequest, filename?: string): Promise<void> {
    const artifact: Artifact<any> = await this.getLatestArtifact(request);

    const blob = COMPRESSED_ARTIFACT_TYPES.includes(artifact.artifactType) ? new Blob([artifact.artifact]) : new Blob([JSON.stringify(artifact.artifact, null, 2)]);
    const extension = COMPRESSED_ARTIFACT_TYPES.includes(artifact.artifactType) ? 'json.gzip' : 'json';
    // the default filename will replace / with _ to avoid creating directories.
    saveAs(blob, typeof filename === 'string' ? `${filename}.${extension}` : this.generateFilename(artifact, extension));
  }

  async listArtifactTypeMetadata(): Promise<ArtifactTypeMetadata[]> {
    const resp = await this.httpClient.get<ArtifactTypeMetadataResponse>('/artifact-types');
    return resp.data.artifactTypeMetadataList;
  }

  async listArtifactMetadata(request: ArtifactMetadataRequest): Promise<ArtifactMetadata[]> {
    let startTimeSeconds = request.startTime;
    let endTimeSeconds = request.endTime;

    if (request.timeUnits === 'MILLISECONDS') {
      startTimeSeconds = Math.round(startTimeSeconds / 1000);
      endTimeSeconds = Math.round(endTimeSeconds / 1000);
    }

    const requestWindows: Array<TimeWindow> = new Array();
    do {
      const nextWindowStart = lodash.last(requestWindows)?.endTime ?? startTimeSeconds;
      const nextWindowEnd = Math.min(nextWindowStart + MAXIMUM_QUERY_WINDOW_IN_SECONDS, endTimeSeconds);
      requestWindows.push({ startTime: nextWindowStart, endTime: nextWindowEnd });
    } while (lodash.last(requestWindows)!.endTime < endTimeSeconds);

    let result: ArtifactMetadata[] = [];
    for (let window of requestWindows) {
      const queryStem = `/artifact-metadata?scope=${request.scope}&artifactType=${request.artifactType}&startTime=${window.startTime}&endTime=${window.endTime}`;
      const resp = await this.httpClient.get<ArtifactMetadataResponse>(queryStem);
      result = result.concat(resp.data.artifactMetadataList);
    }

    return lodash.sortBy(result, (t) => t.creationTime);
  }

  async listAssignmentMetadata(
    request: AssignmentMetadataRequest,
    inputArtifact: ArtifactType = 'FLEX_ROUTE_ASSIGNMENT_PLANNER_INPUT',
    planArtifact: ArtifactType = 'FLEX_ROUTE_ASSIGNMENT_PLAN',
  ): Promise<AssignmentMetadata[]> {
    const plannerInputMetadataList: ArtifactMetadata[] = await this.listArtifactMetadata({
      scope: request.serviceAreaId,
      artifactType: inputArtifact,
      startTime: request.startTime,
      endTime: request.endTime,
      timeUnits: 'MILLISECONDS',
    });

    const planMetadataList: ArtifactMetadata[] = await this.listArtifactMetadata({
      scope: request.serviceAreaId,
      artifactType: planArtifact,
      startTime: request.startTime,
      endTime: request.endTime,
      timeUnits: 'MILLISECONDS',
    });

    const planIdToPlanMetadata: Map<string, ArtifactMetadata> = new Map();
    planMetadataList.forEach((metadata) => {
      planIdToPlanMetadata.set(metadata.artifactId, metadata);
    });

    const convertPlannerInputIdToPlanId = (plannerInputId: string) => {
      // a temporary solution for getting planId from plannerInputId.
      // todo: update LC to populate planId same as plannerInputId, it's also desired by Tyrion. (DRAS now supports to have the same artifactId for two different artifact types.)
      return plannerInputId.substring(0, plannerInputId.length - 'ner-input'.length);
    };

    return plannerInputMetadataList.map((plannerInputMetadata) => {
      const planId = convertPlannerInputIdToPlanId(plannerInputMetadata.artifactId);
      const planMetadata = planIdToPlanMetadata.get(planId);

      const assignmentMetadata: AssignmentMetadata = {
        serviceAreaId: request.serviceAreaId,
        plannerInputId: plannerInputMetadata.artifactId,
        plannerInputGenerationTime: plannerInputMetadata.creationTime,
        planId: planId,
        planGenerationTime: typeof planMetadata?.creationTime === 'string' ? planMetadata?.creationTime : null,
      };

      return assignmentMetadata;
    });
  }

  async getNetworkHealthDetailsList(request: NetworkHealthDetailsRequest): Promise<NetworkHealthDetails[]> {
    const startEpochTimeInSecond = Math.round(request.startTime / 1000);
    const endEpochTimeInSecond = Math.round(request.endTime / 1000);

    const query: Query = {
      startTime: startEpochTimeInSecond.toString(),
      endTime: endEpochTimeInSecond.toString(),
    };
    if (typeof request.serviceAreaId === 'string') {
      query['serviceAreaId'] = request.serviceAreaId;
    }

    const resp = await this.httpClient.get<NetworkHealthDetailsResponse>(`/network-health-details?${new URLSearchParams(query).toString()}`);
    return resp.data.networkHealthDetailsList;
  }

  async listAmzlRouteStagingItems(request: ListAmzlRouteStagingItemsRequest): Promise<ListAmzlRouteStagingItemsResponse> {
    const query: Query = { ...request };
    const resp = await this.httpClient.get<ListAmzlRouteStagingItemsResponse>(`/amzl-route-staging-items?${new URLSearchParams(query).toString()}`);
    return resp.data;
  }

  /**
   * Calls ufraaviz backend, gets presigned url(s), and then calls s3 to fetch the artifact contents.
   * If given a single artifact, will save to a single json file.
   * Multiple artifacts are batched in a zip file.
   * Handles compressesed artifacts as well, using gzip.
   * @param artifactIdentifiers list of artifact ids & types
   * @param filename optional filename without any extension. Extension will be automatically appended.
   */
  async downloadArtifacts(artifactIdentifiers: ArtifactIdentifier[]): Promise<void> {
    const results = await this.getArtifactsFromReferences<any>({ artifactIdentifiers });
    if (results.length === 0) {
      throw new Error('Found 0 artifacts to download.');
    }

    if (results.length === 1) {
      const [filename, blob] = this.getFileInfo(results[0]);
      saveAs(blob, filename);
    } else {
      const zip = new JSZip();
      results.forEach((artifact) => {
        const [defaultName, blob] = this.getFileInfo(artifact);
        zip.file(defaultName, blob);
      });

      const file = await zip.generateAsync({ type: 'blob' });
      const filename = this.generateBundleName(artifactIdentifiers);
      saveAs(file, filename);
    }
  }

  /**
   * Often we want to download several artifacts at once.
   * The sorting on the station page is locked to newest on the top, oldest on the bottom.
   * This sort ensures the zip file contains the artifacts in the same order.
   * Makes navigating to the correct artifacts intuitive.
   * @param metadata Information about the artifacts to download.
   */
  async downloadArtifactsChronologically(metadata?: ArtifactMetadata[]): Promise<void> {
    if (metadata === undefined || metadata.length === 0) {
      return;
    }
    const sortedMetadata = metadata
      .sort((metadata) => {
        return Date.parse(metadata.creationTime);
      })
      .reverse();
    const identifiers = sortedMetadata.map((metadata) => ({ artifactId: metadata.artifactId, artifactType: metadata.artifactType }));
    await this.downloadArtifacts(identifiers);
  }

  private getFileInfo(artifact: Artifact<any>): [string, Blob] {
    if (COMPRESSED_ARTIFACT_TYPES.includes(artifact.artifactType)) {
      return [this.generateFilename(artifact, 'json.gzip'), new Blob([artifact.artifact])];
    } else {
      return [this.generateFilename(artifact, 'json'), new Blob([JSON.stringify(artifact.artifact, null, 2)])];
    }
  }

  private generateBundleName(identifiers: ArtifactIdentifier[]): string {
    const everyType = identifiers.map((each) => each.artifactType);
    const uniqueTypes = Array.from(new Set(everyType));
    const labelString = uniqueTypes.sort().join('-').replaceAll(' ', '');

    return `${labelString}-BundleOf${identifiers.length}.zip`;
  }

  private generateFilename(artifact: Artifact<any>, ext: string): string {
    return `${artifact.artifactType}-${artifact.artifactId.replace(/\//g, '_')}.${ext}`;
  }
}
