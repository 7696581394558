import { StageConfiguration, NavigationStatus } from './models';

export const LOCALE_KEY = 'LOCALE';
export const DEFAULT_LOCALE_KEY = 'en-US';

export const UI_MODE_KEY = 'UI_MODE';
export const DEFAULT_UI_MODE = 'light';

export const UI_THEME_KEY = 'UI_THEME';
export const DEFAULT_UI_THEME = 'regular';

export const UI_DENSITY_KEY = 'UI_DENSITY';
export const DEFAULT_UI_DENSITY = 'comfortable';

export const PREFERRED_TIMEZONE_KEY = 'PREFERRED_TIMEZONE';
export const DEFAULT_TITLE = 'UFRAA Viz';

export const NAVIGATION_STATUS_KEY = 'NAVGIATION_STATUS';
export const DEFAULT_NAVIGATION_STATUS: NavigationStatus = { open: true };

export const COMPLETED_TUTORIALS = 'COMPLETED_TUTORIALS';

export const LOCAL_TESTING_DOMAIN = 'localhost.a2z.com';
export const UNITTEST_IDENTIFIER = 'unittest';
export const MOCK_SERVER_DOMAIN = 'http://mock-server-domain';

const LOGISTICS_PORTAL_URL = {
  NA: 'https://logistics.amazon.com',
  FE: 'https://logistics.amazon.com.au',
  EU: 'https://logistics.amazon.co.uk',
  IN: 'https://logistics.amazon.in',
};

export const stageConfigurations: StageConfiguration[] = [
  {
    id: typeof process.env['STAGE_ID'] === 'string' ? process.env['STAGE_ID'] : '',
    domain: typeof process.env['DOMAIN'] === 'string' ? process.env['DOMAIN'] : LOCAL_TESTING_DOMAIN,
    identityPoolId: typeof process.env['IDENTITY_POOL_ID'] === 'string' ? process.env['IDENTITY_POOL_ID'] : '',
    apiGatewayBaseURL: typeof process.env['API_GATEWAY_BASE_URL'] === 'string' ? process.env['API_GATEWAY_BASE_URL'] : '',
    awsRegion: typeof process.env['AWS_REGION'] === 'string' ? process.env['AWS_REGION'] : 'us-west-2',
    stage: 'devo',
    marketplace: 'NA',
    mapStyle: 'ufraaviz',
    logisticsPortalURL: LOGISTICS_PORTAL_URL['NA'],
  },
  {
    id: 'nqqin.remote',
    domain: 'viz-nqqin.ufraa.last-mile.amazon.dev',
    identityPoolId: 'us-west-2:27581339-a24a-4ca0-ab6c-a1567edf4935',
    apiGatewayBaseURL: 'https://zq6tmk95d4.execute-api.us-west-2.amazonaws.com/prod/',
    awsRegion: 'us-west-2',
    stage: 'beta',
    marketplace: 'NA',
    mapStyle: 'ufraaviz',
    logisticsPortalURL: LOGISTICS_PORTAL_URL['NA'],
  },
  {
    id: 'skunku.remote',
    domain: 'viz-skunku.ufraa.last-mile.amazon.dev',
    identityPoolId: 'us-west-2:c6b9d239-3c97-4f09-9136-beb8c7e5b34f',
    apiGatewayBaseURL: 'https://3a4x3h13z9.execute-api.us-west-2.amazonaws.com/prod/',
    awsRegion: 'us-west-2',
    stage: 'beta',
    marketplace: 'NA',
    mapStyle: 'ufraaviz',
    logisticsPortalURL: LOGISTICS_PORTAL_URL['NA'],
  },
  {
    id: 'kytolent.remote',
    domain: 'viz-kytolent.ufraa.last-mile.amazon.dev',
    identityPoolId: 'us-west-2:d5e0b8fa-f7e1-46b9-9c96-f872aa4b03cc',
    apiGatewayBaseURL: 'https://7i1r08cyge.execute-api.us-west-2.amazonaws.com/prod/',
    awsRegion: 'us-west-2',
    stage: 'beta',
    marketplace: 'NA',
    mapStyle: 'ufraaviz',
    logisticsPortalURL: LOGISTICS_PORTAL_URL['NA'],
  },
  {
    id: UNITTEST_IDENTIFIER,
    domain: 'localhost.a2z.com',
    identityPoolId: '',
    apiGatewayBaseURL: MOCK_SERVER_DOMAIN,
    awsRegion: 'us-west-2',
    stage: 'devo',
    marketplace: 'NA',
    mapStyle: 'ufraaviz',
    logisticsPortalURL: LOGISTICS_PORTAL_URL['NA'],
  },
  {
    id: 'beta.na',
    domain: 'viz-na-beta.ufraa.last-mile.amazon.dev',
    identityPoolId: 'us-west-2:27581339-a24a-4ca0-ab6c-a1567edf4935',
    apiGatewayBaseURL: 'https://zq6tmk95d4.execute-api.us-west-2.amazonaws.com/prod/',
    awsRegion: 'us-west-2',
    stage: 'beta',
    marketplace: 'NA',
    mapStyle: 'ufraaviz',
    logisticsPortalURL: LOGISTICS_PORTAL_URL['NA'],
  },
  {
    id: 'gamma.na',
    domain: 'viz-na-gamma.ufraa.last-mile.amazon.dev',
    identityPoolId: 'us-east-1:08910374-623e-4c2b-9453-c63e8df32087',
    apiGatewayBaseURL: 'https://cri4dmjceb.execute-api.us-east-1.amazonaws.com/prod/',
    awsRegion: 'us-east-1',
    stage: 'gamma',
    marketplace: 'NA',
    mapStyle: 'ufraaviz',
    logisticsPortalURL: LOGISTICS_PORTAL_URL['NA'],
  },
  {
    id: 'gamma.eu',
    domain: 'viz-eu-gamma.ufraa.last-mile.amazon.dev',
    identityPoolId: 'eu-west-1:4b522d79-7fd6-48ea-9f85-f14f319dc085',
    apiGatewayBaseURL: 'https://l9toevy29g.execute-api.eu-west-1.amazonaws.com/prod/',
    awsRegion: 'eu-west-1',
    stage: 'gamma',
    marketplace: 'EU',
    mapStyle: 'ufraaviz',
    logisticsPortalURL: LOGISTICS_PORTAL_URL['EU'],
  },
  {
    id: 'gamma.in',
    domain: 'viz-in-gamma.ufraa.last-mile.amazon.dev',
    identityPoolId: 'eu-west-1:de3cfe52-2a4a-4d78-852b-5d71a47d6b63',
    apiGatewayBaseURL: 'https://gq2u6ghlk0.execute-api.eu-west-1.amazonaws.com/prod/',
    awsRegion: 'eu-west-1',
    stage: 'gamma',
    marketplace: 'IN',
    mapStyle: 'ufraaviz',
    logisticsPortalURL: LOGISTICS_PORTAL_URL['IN'],
  },
  {
    id: 'gamma.fe',
    domain: 'viz-fe-gamma.ufraa.last-mile.amazon.dev',
    identityPoolId: 'us-west-2:c37903b3-6a16-45eb-bd8f-c8c2d00c10a0',
    apiGatewayBaseURL: 'https://y1dm70ierb.execute-api.us-west-2.amazonaws.com/prod/',
    awsRegion: 'us-west-2',
    stage: 'gamma',
    marketplace: 'FE',
    mapStyle: 'ufraaviz',
    logisticsPortalURL: LOGISTICS_PORTAL_URL['FE'],
  },
  {
    id: 'prod.na',
    domain: 'viz-na.ufraa.last-mile.amazon.dev',
    identityPoolId: 'us-east-1:1803873f-acd2-493e-9288-a2bc163a9785',
    apiGatewayBaseURL: 'https://w00i083ntb.execute-api.us-east-1.amazonaws.com/prod/',
    awsRegion: 'us-east-1',
    stage: 'prod',
    marketplace: 'NA',
    mapStyle: 'ufraaviz',
    logisticsPortalURL: LOGISTICS_PORTAL_URL['NA'],
  },
  {
    id: 'prod.eu',
    domain: 'viz-eu.ufraa.last-mile.amazon.dev',
    identityPoolId: 'eu-west-1:1eed7a33-c4c3-472d-83d5-19a090e2be90',
    apiGatewayBaseURL: 'https://a6oiouelq5.execute-api.eu-west-1.amazonaws.com/prod',
    awsRegion: 'eu-west-1',
    stage: 'prod',
    marketplace: 'EU',
    mapStyle: 'ufraaviz',
    logisticsPortalURL: LOGISTICS_PORTAL_URL['EU'],
  },
  {
    id: 'prod.in',
    domain: 'viz-in.ufraa.last-mile.amazon.dev',
    identityPoolId: 'eu-west-1:9c2d18eb-1bb4-493e-8df7-b94f5fa03b84',
    apiGatewayBaseURL: 'https://gh700zcdi9.execute-api.eu-west-1.amazonaws.com/prod/',
    awsRegion: 'eu-west-1',
    stage: 'prod',
    marketplace: 'IN',
    mapStyle: 'ufraaviz',
    logisticsPortalURL: LOGISTICS_PORTAL_URL['IN'],
  },
  {
    id: 'prod.fe',
    domain: 'viz-fe.ufraa.last-mile.amazon.dev',
    identityPoolId: 'us-west-2:0daa45b6-726e-47ae-b50c-01365bc364bb',
    apiGatewayBaseURL: 'https://fulv8diza1.execute-api.us-west-2.amazonaws.com/prod/',
    awsRegion: 'us-west-2',
    stage: 'prod',
    marketplace: 'FE',
    mapStyle: 'ufraaviz',
    logisticsPortalURL: LOGISTICS_PORTAL_URL['FE'],
  },
];
