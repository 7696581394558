import { Dimensions, Weight, TrVolume } from '../clients/routing-models';
import convert from 'convert-units';
import { DimensionUnitConstants, VolumeUnitConstants, WeightUnitConstants } from '../components/routing-plan-page/utilities/constants';

export class UnitConversionHelper {
  convertCubicVolumeToCuFt(volume: TrVolume | undefined): number | undefined {
    if (volume !== undefined) {
      if (volume.unit === VolumeUnitConstants.CU_FT) {
        return volume.value;
      } else if (volume.unit === VolumeUnitConstants.CU_IN) {
        return convert(volume.value).from('in3').to('ft3');
      } else if (volume.unit === VolumeUnitConstants.CU_CM) {
        return convert(volume.value).from('cm3').to('ft3');
      } else if (volume.unit === VolumeUnitConstants.CU_M) {
        return convert(volume.value).from('m3').to('ft3');
      }
    }
  }

  convertWeightToLb(weight: Weight | undefined): number | undefined {
    if (weight !== undefined) {
      if (weight.unit === WeightUnitConstants.KG) {
        return convert(weight.value).from('kg').to('lb');
      } else if (weight.unit === WeightUnitConstants.G) {
        return convert(weight.value).from('g').to('lb');
      } else if (weight.unit === WeightUnitConstants.LB) {
        return weight.value;
      }
    }
  }

  convertToInches(dimensions: Dimensions): Dimensions {
    if (dimensions.unit === DimensionUnitConstants.CM) {
      const lengthInIn = convert(dimensions.length).from('cm').to('in');
      const widthInIn = convert(dimensions.width).from('cm').to('in');
      const heightInIn = convert(dimensions.height).from('cm').to('in');
      return { length: lengthInIn, width: widthInIn, height: heightInIn, unit: dimensions.unit } as Dimensions;
    } else if (dimensions.unit === DimensionUnitConstants.M) {
      const lengthInIn = convert(dimensions.length).from('m').to('in');
      const widthInIn = convert(dimensions.width).from('m').to('in');
      const heightInIn = convert(dimensions.height).from('m').to('in');
      return { length: lengthInIn, width: widthInIn, height: heightInIn, unit: dimensions.unit } as Dimensions;
    } else if (dimensions.unit === DimensionUnitConstants.IN) {
      return dimensions;
    }
    return dimensions;
  }
}
