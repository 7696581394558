import React from 'react';
import { InternalAction, InternalRouteContainer, LmRoute, RouteSnapshot } from '../../clients';
import Header from '@amzn/awsui-components-react/polaris/header';
import { RouteActions } from './route-actions';
import Box from '@amzn/awsui-components-react/polaris/box';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import Cards from '@amzn/awsui-components-react/polaris/cards';
import { convertRouteSnapshotToInternalRouteContainers, filterRouteWithSequences } from './helper';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import { timezoneManager } from '../../utilities';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { OrderDetailsModal } from './order-details';

interface Props {
  readonly routeSnapshotId: string;
  readonly routeSnapshot?: RouteSnapshot | null;
}

interface State {
  readonly filteringText: string;
  readonly routeContainers?: InternalRouteContainer[];
  readonly filteredRouteContainers?: InternalRouteContainer[];
  readonly selectedActionToDisplay?: InternalAction;
}

export class RouteSnapshotDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      filteringText: '',
      routeContainers: undefined,
      filteredRouteContainers: undefined,
      selectedActionToDisplay: undefined,
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.routeSnapshot && !state.routeContainers) {
      const routeContainers = convertRouteSnapshotToInternalRouteContainers(props.routeSnapshot);
      return {
        routeContainers,
        filteredRouteContainers: filterRouteWithSequences(state.filteringText, routeContainers),
      };
    }
    return null;
  }

  renderEmpty() {
    if (this.props.routeSnapshot === null) {
      return (
        <Box textAlign="center">
          <StatusIndicator type="error">Cannot find route snapshot</StatusIndicator>
        </Box>
      );
    } else {
      return (
        <Box textAlign="center">
          <StatusIndicator type="info">No match routes</StatusIndicator>
        </Box>
      );
    }
  }

  renderHeader(count: number | undefined) {
    if (typeof count === 'number') {
      return (
        <Header variant="h2" counter={`(${count})`}>
          Route Details
        </Header>
      );
    } else {
      return <Header variant="h2">Route Details</Header>;
    }
  }

  renderFilter() {
    return (
      <TextFilter
        filteringText={this.state.filteringText}
        filteringPlaceholder="routeId, orderId, trId ..."
        onChange={(evt) => this.setState({ filteringText: evt.detail.filteringText })}
        onDelayedChange={(evt) => {
          this.setState((state) => {
            if (state.routeContainers) {
              return {
                filteredRouteContainers: filterRouteWithSequences(state.filteringText, state.routeContainers),
              };
            }
            return null;
          });
        }}
      />
    );
  }

  renderRouteInfo(route: LmRoute) {
    // todo: improve duration formatting to show in hh:mm format.
    if (route.sequence) {
      const startTime = timezoneManager.convertTimestampToString(route.sequence.startTime * 1000);
      const durationInMinute = Math.round(route.sequence.duration / 60);
      return `Start time: ${startTime}, duration: ${durationInMinute} minutes, departure set count: ${route.sequence.departureSets.length}`;
    } else {
      return `Empty route`;
    }
  }

  renderRouteStatus(route: LmRoute) {
    return route.status.join(', ');
  }

  renderAssignmentStatus(route: LmRoute) {
    if (route.operations?.assignment) {
      const assignment = route.operations.assignment;
      const transporterGroup = route.operations.assignment.transporterGroup;
      if (transporterGroup) {
        return `Route is assigned to transporter ${assignment.transporterId} (transporter group ${transporterGroup.groupType} / ${transporterGroup.groupId})`;
      } else {
        return `Route is assigned to transporter ${assignment.transporterId}`;
      }
    } else {
      return null;
    }
  }

  renderRoute(routeContainer: InternalRouteContainer) {
    return (
      <SpaceBetween direction="vertical" size="m">
        <Box>{this.renderRouteInfo(routeContainer.route)}</Box>
        <Box>{this.renderRouteStatus(routeContainer.route)}</Box>
        <Box>{this.renderAssignmentStatus(routeContainer.route)}</Box>
        <Box>{routeContainer.actions ? <RouteActions actions={routeContainer.actions} onTrIdClick={(action) => this.setState({ selectedActionToDisplay: action })} /> : null}</Box>
      </SpaceBetween>
    );
  }

  renderSelectedAction() {
    if (this.state.selectedActionToDisplay) {
      return (
        <OrderDetailsModal
          trIds={this.state.selectedActionToDisplay.taskSubjects.filter((subject) => typeof subject.trId === 'string').map((subject) => subject.trId!)}
          actionType={this.state.selectedActionToDisplay.type}
          onClose={() => {
            this.setState({ selectedActionToDisplay: undefined });
          }}
        />
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderSelectedAction()}
        <Cards<InternalRouteContainer>
          cardDefinition={{
            header: (item) => {
              return (
                <Box variant="p">
                  Route Id: {item.route.routeId}, version {item.route.version}
                </Box>
              );
            },
            sections: [
              {
                id: 'route',
                header: 'Route',
                content: (item) => this.renderRoute(item),
              },
            ],
          }}
          cardsPerRow={[{ cards: 1 }]}
          items={this.state.filteredRouteContainers ? this.state.filteredRouteContainers : []}
          loading={this.props.routeSnapshot === undefined}
          loadingText="Loading ..."
          empty={this.renderEmpty()}
          filter={this.renderFilter()}
          header={this.renderHeader(this.state.filteredRouteContainers ? this.state.filteredRouteContainers.length : undefined)}
        />
      </React.Fragment>
    );
  }
}
