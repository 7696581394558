var endpointMap = {
	en: {
		AssignmentPlanContent: "translation-en.json",
		AssignmentPlanDetails: "translation-en.json",
		AssignmentPlanSummary: "translation-en.json",
		AssignmentStatus: "translation-en.json",
		Preferences: "translation-en.json",
		ServiceAreaPage: "translation-en.json",
		SideNavigation: "translation-en.json",
		StationPage: "translation-en.json",
		TopNavigation: "translation-en.json",
		UserNotes: "translation-en.json"
	},
	"en-US": {
		AssignmentPlanContent: "translation-en.json",
		AssignmentPlanDetails: "translation-en.json",
		AssignmentPlanSummary: "translation-en.json",
		AssignmentStatus: "translation-en.json",
		Preferences: "translation-en.json",
		ServiceAreaPage: "translation-en.json",
		SideNavigation: "translation-en.json",
		StationPage: "translation-en.json",
		TopNavigation: "translation-en.json",
		UserNotes: "translation-en.json"
	},
	ja: {
		AssignmentPlanContent: "translation-ja.json",
		AssignmentPlanDetails: "translation-ja.json",
		AssignmentPlanSummary: "translation-ja.json",
		AssignmentStatus: "translation-ja.json",
		Preferences: "translation-ja.json",
		ServiceAreaPage: "translation-ja.json",
		SideNavigation: "translation-ja.json",
		StationPage: "translation-ja.json",
		TopNavigation: "translation-ja.json",
		UserNotes: "translation-ja.json"
	},
	"ja-JP": {
		AssignmentPlanContent: "translation-ja.json",
		AssignmentPlanDetails: "translation-ja.json",
		AssignmentPlanSummary: "translation-ja.json",
		AssignmentStatus: "translation-ja.json",
		Preferences: "translation-ja.json",
		ServiceAreaPage: "translation-ja.json",
		SideNavigation: "translation-ja.json",
		StationPage: "translation-ja.json",
		TopNavigation: "translation-ja.json",
		UserNotes: "translation-ja.json"
	},
	und: {
		AssignmentPlanContent: "translation.json",
		AssignmentPlanDetails: "translation.json",
		AssignmentPlanSummary: "translation.json",
		AssignmentStatus: "translation.json",
		Preferences: "translation.json",
		ServiceAreaPage: "translation.json",
		SideNavigation: "translation.json",
		StationPage: "translation.json",
		TopNavigation: "translation.json",
		UserNotes: "translation.json"
	}
};
var arbManifest = {
	endpointMap: endpointMap
};

export { arbManifest as default, endpointMap };
