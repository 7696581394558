import React from 'react';
import { ExecutionSnapshot, ExecutionPlan, ServiceAreaDetails, ExecutionPlanInfo } from '../../clients';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Container from '@amzn/awsui-components-react/polaris/container';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Box from '@amzn/awsui-components-react/polaris/box';
import Clock from '../../shared-components/clock';
import { timezoneManager, renderHelper } from '../../utilities';
import { NOT_AVAILABLE } from './utilities/constants';

interface Props {
  readonly snapshot?: ExecutionSnapshot | null;
  readonly plan?: ExecutionPlan | null;
  readonly serviceAreaDetails?: ServiceAreaDetails | null;
  readonly planInfo: ExecutionPlanInfo | null;
}

export class RoutingPlanSummary extends React.Component<Props, {}> {
  render() {
    return <Container header={this.renderHeader()}>{this.props.snapshot ? this.renderContent() : null}</Container>;
  }

  private renderHeader() {
    return <Header variant="h2">Routing Summary</Header>;
  }

  private renderContent() {
    return (
      <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween size="m">
          <Box>
            <Box color="text-label">Service Area Name</Box>
            <Box>{renderHelper.renderNullableText(this.props.snapshot?.serviceArea.defaultStationCode, NOT_AVAILABLE)}</Box>
          </Box>
          <Box>
            <Box color="text-label">Service Area Id</Box>
            <Box>{renderHelper.renderCopyableText(this.props.snapshot?.serviceArea.serviceAreaId, NOT_AVAILABLE)}</Box>
          </Box>
          <Box>
            <Box color="text-label">Station Timezone</Box>
            <Box>{this.props.serviceAreaDetails?.timeZone ?? NOT_AVAILABLE}</Box>
          </Box>
          <Box>
            <Box color="text-label">Station Lock Offset Minutes</Box>
            <Box>{this.props.snapshot?.serviceAreaConfig.defaultHorizonConfig?.lockOffsetMinutes}</Box>
          </Box>
        </SpaceBetween>
        <SpaceBetween size="m">
          <Box>
            <Box color="text-label">Station Time</Box>
            <Box>{this.props.serviceAreaDetails?.timeZone ? <Clock timezone={this.props.serviceAreaDetails?.timeZone} /> : NOT_AVAILABLE}</Box>
          </Box>
          <Box>
            <Box color="text-label">Snapshot Generation Time</Box>
            <Box>{this.props.snapshot?.time ? timezoneManager.convertTimestampToString(this.props.snapshot?.time * 1000) : NOT_AVAILABLE}</Box>
          </Box>
          <Box>
            <Box color="text-label">Plan Generation Time</Box>
            <Box>{this.props.plan ? timezoneManager.convertTimestampToString(this.props.plan.lastUpdated * 1000) : NOT_AVAILABLE}</Box>
          </Box>
          <Box>
            <Box color="text-label">Plan Id</Box>
            <Box>{renderHelper.renderCopyableText(this.props.snapshot?.id, NOT_AVAILABLE)}</Box>
          </Box>
        </SpaceBetween>
        <SpaceBetween size="m">
          <Box>
            <Box color="text-label">Number of overflow routes</Box>
            <Box>{this.props.planInfo !== null ? this.props.planInfo?.overflowRoutesCount : 0}</Box>
          </Box>
          <Box>
            <Box color="text-label">Number of planned routes</Box>
            <Box>{this.props.planInfo !== null ? this.props.planInfo?.plannedRoutes.length : 0}</Box>
          </Box>
          <Box>
            <Box color="text-label">Number of unplanned orders</Box>
            <Box>{this.props.planInfo?.unplannedOrders !== null ? this.props.planInfo?.unplannedOrders.length : 0}</Box>
          </Box>
        </SpaceBetween>
      </ColumnLayout>
    );
  }
}
