import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { DepartureSet as DepartureSet_ } from '../../clients';
import { timezoneManager } from '../../utilities';

export interface DepartureSetProps {
  readonly departureSet: DepartureSet_;
}

/**
 * Render departureSet
 */
export class DepartureSet extends React.Component<DepartureSetProps, {}> {
  render() {
    const updatedTime = this.props.departureSet.pickStartTime ? timezoneManager.convertTimestampToString(this.props.departureSet.pickStartTime) : 'N/A';
    return (
      <SpaceBetween direction="vertical" size="m">
        <Box>
          <Box>Status: {this.props.departureSet.status.join(', ')}</Box>
          <Box>Updated At: {updatedTime}</Box>
        </Box>
        <Box>
          {this.props.departureSet.transportRequestIds.map((trId, index) => {
            return <Box key={index}>{trId}</Box>;
          })}
        </Box>
      </SpaceBetween>
    );
  }
}

export interface DepartureSetModalProps extends DepartureSetProps {
  readonly onClose: () => void;
}

/**
 * Wrap DepartureSet component in a modal (a.k.a popup)
 */
export class DepartureSetModal extends React.Component<DepartureSetModalProps, {}> {
  render() {
    return (
      <Modal
        onDismiss={() => this.props.onClose()}
        header={
          <Header variant="h3" description={this.props.departureSet.departureSetId}>
            Departure Set
          </Header>
        }
        visible={true}
        closeAriaLabel="Close modal"
        size="medium"
      >
        <DepartureSet departureSet={this.props.departureSet} />
      </Modal>
    );
  }
}
