import * as models from '../../../clients/routing-models';
import { Artifact } from '../../../clients/models';
import { unitConversionHelper } from '../../../utilities';
import * as constants from '../utilities/constants';
import { GeoCode } from '../../../clients';
import { CapacityConstants, OUTSIDE_PLANNING_HORIZON, VolumeUnitConstants, WeightUnitConstants } from '../utilities/constants';

type Mutable<T> = {
  -readonly [P in keyof T]: T[P];
};

export class RouteDetailsConverter {
  driverInfoMapping: models.DeliveryAssociateMapping = new Map();
  providerDemandMapping: models.ProviderDemandMapping = new Map();
  routeExecutorMapping: Map<string, string> = new Map();

  parseRoutingPlan(plan: models.ExecutionPlan, snapshot: models.ExecutionSnapshot): models.ExecutionPlanInfo {
    const orderIdMaps: models.OrderIdMap = this.buildOrderIdMaps(snapshot);
    this.driverInfoMapping = this.getDeliveryAssociates(snapshot);
    this.providerDemandMapping = this.getProviderDemandMapping(snapshot);
    this.routeExecutorMapping = this.getRouteExecutorMapping(snapshot);
    const routeList: models.RouteList = this.buildRoutes(snapshot, plan, orderIdMaps);
    const unplannedOrders: models.UnplannedAction[] = this.getUnplannedOrders(plan, orderIdMaps.orderIDToOrderMap);

    const plannedRoutesWithIndex = routeList.plannedRoutes.map((route, index) => ({
      ...route,
      index: index + 1,
    }));

    const partiallyCompletedRoutesWithIndex = routeList.partiallyCompletedRoutes.map((route, index) => ({
      ...route,
      index: index + routeList.plannedRoutes.length + 1,
    }));

    return {
      timestamp: this.parseSnapshotTime(snapshot),
      overflowRoutesCount: routeList.plannedRoutes.filter((r: any) => r.isOverflow).length,
      plannedRoutes: plannedRoutesWithIndex,
      partiallyCompletedRoutes: partiallyCompletedRoutesWithIndex,
      unplannedOrders: unplannedOrders,
    };
  }

  private parseSnapshotTime(snapshot: models.ExecutionSnapshot): number {
    return (snapshot as any).time * constants.MS_MULTIPLIER;
  }

  private getDeliveryAssociates(snapshot: any): models.DeliveryAssociateMapping {
    const driverInfoMap: models.DeliveryAssociateMapping = new Map();
    snapshot.deliveryAssociates.forEach((driver: any) => {
      if (driver !== null && driver.name != null) {
        driverInfoMap.set(driver.name, this.createDriverInfo(driver, snapshot));
      }
    });
    return driverInfoMap;
  }

  private getProviderDemandMapping(snapshot: any): models.ProviderDemandMapping {
    const providerDemandMap: models.ProviderDemandMapping = new Map();
    snapshot.schedule.forEach((pd: any) => {
      if (pd.providerDemandId !== undefined) {
        const providerDemandInfo = { scheduleStart: pd.origin.time * constants.MS_MULTIPLIER, scheduleEnd: pd.terminus.time * constants.MS_MULTIPLIER, scheduleDuration: pd.durationInMinutes };
        providerDemandMap.set(pd.providerDemandId, providerDemandInfo);
      }
    });
    return providerDemandMap;
  }

  private getRouteExecutorMapping(snapshot: any): Map<string, string> {
    const routeExecutorMap: Map<string, string> = new Map();
    snapshot.routeExecutors.forEach((routeExecutor: any) => routeExecutorMap.set(routeExecutor.name, routeExecutor.vehicleType));
    return routeExecutorMap;
  }

  private createDriverInfo(deliveryAssociate: any, snapshot: any): models.Driver {
    const latitude = deliveryAssociate.origin!.location!.geocode!.latitude;
    const longitude = deliveryAssociate.origin!.location!.geocode!.longitude;
    const geocode: GeoCode = { latitude, longitude };

    const deliveryAssociateId: string = deliveryAssociate.name;
    const snapshotCreationTime: number = (snapshot as any).creationTime;
    const originCreationTime: number = deliveryAssociate.origin!.time!.time;
    const secondsSinceLocationUpdate: number = snapshotCreationTime - originCreationTime;

    const transporterType: string = deliveryAssociate.executor.includes(constants.INSTANT_OFFER_DRIVER_SUBSTRING) ? constants.TRANSPORTER_TYPE.INSTANT_OFFER : constants.TRANSPORTER_TYPE.BLOCK;

    return { originLocation: geocode, lastUpdated: secondsSinceLocationUpdate * constants.MS_MULTIPLIER, id: deliveryAssociateId, status: deliveryAssociate.status, type: transporterType };
  }

  private buildOrderIdMaps(snapshot: any): models.OrderIdMap {
    const orderStore: models.OrderMapping = new Map();
    const orderIdActionToGeocodeMap: models.OrderIDToGeocodeMapping = new Map();

    const trDetails = this.getTrDetails(snapshot);

    snapshot.allTasks.forEach((task: any) => {
      const externalIdMap: Map<string, string> = task.externalIdMap;
      const ids = RouteDetailsConverter.validateIdMapping(externalIdMap);
      if (ids === undefined) {
        return;
      }
      const orderId = ids?.orderId;
      const trId = ids?.trId;

      const orderIdPlusAction: string = orderId + task.demand[0].operation;

      //Geocodes added for displaying maps as part of phase 2
      if (orderIdActionToGeocodeMap.get(orderIdPlusAction) === undefined) {
        const latitude: number = task.locationWithAddress.geocode!.latitude;
        const longitude: number = task.locationWithAddress.geocode!.longitude;
        const geocode: GeoCode = { latitude, longitude };

        orderIdActionToGeocodeMap.set(orderIdPlusAction, geocode);
      }

      const existingOrder = orderStore.get(orderId);
      if (existingOrder === undefined || existingOrder === null) {
        const deliveryWindowStartTime: number = task.originalWindows[0].start * constants.MS_MULTIPLIER;
        const deliveryWindowEndTime: number = task.originalWindows[0].end * constants.MS_MULTIPLIER;
        const trIdToTrDetails: { [trId: string]: models.TransportRequestDimensions } = {};
        trIdToTrDetails[trId] = trDetails[trId];

        const order = {
          orderId: orderId,
          constituentTrs: new Set<models.TrId>(),
          deliveryWindowStart: deliveryWindowStartTime,
          deliveryWindowEnd: deliveryWindowEndTime,
          orderType: task.orderType,
          trIdToTrDetails: trIdToTrDetails,
        };
        order.constituentTrs.add(trId);
        orderStore.set(orderId, order);
      } else {
        existingOrder?.constituentTrs.add(trId);
        existingOrder.trIdToTrDetails[trId] = trDetails[trId];
      }
    });
    return { orderIDToOrderMap: orderStore, orderIdActionToGeocodeMap: orderIdActionToGeocodeMap };
  }

  private static validateIdMapping(candidate: any): models.ExternalIdMap | undefined {
    if (candidate === null || candidate === undefined || candidate.ORDER_ID === undefined || candidate.TR_ID === undefined) {
      return undefined;
    }

    return { orderId: candidate.ORDER_ID, trId: candidate.TR_ID };
  }

  private buildRoutes(snapshot: any, plan: any, orderIdMapping: any): models.RouteList {
    const plannedRoutes: models.RouteInfo[] = new Array();
    const partiallyCompletedRoutes: models.RouteInfo[] = new Array();

    plan.plannedRoutes.forEach((route: any) => {
      const planRoute: models.RouteInfo = this.buildRoute(route, snapshot, orderIdMapping);
      if (this.isPlannedRoute(planRoute)) {
        plannedRoutes.push(planRoute);
      } else {
        partiallyCompletedRoutes.push(planRoute);
      }
    });

    return { plannedRoutes: plannedRoutes, partiallyCompletedRoutes: partiallyCompletedRoutes };
  }

  private getTrDetails(snapshot: any): { [trId: string]: models.TransportRequestDimensions } {
    const trIdToTrDetailsMap: { [trId: string]: models.TransportRequestDimensions } = {};

    snapshot.allTransportRequests.forEach((tr: any) => {
      const trDimensions = tr.trDimensions;
      trIdToTrDetailsMap[tr.transportRequestId] = {
        trId: tr.transportRequestId,
        volume: { value: unitConversionHelper.convertCubicVolumeToCuFt(trDimensions.cubicVolume) || 0, unit: VolumeUnitConstants.CU_FT },
        weight: { value: unitConversionHelper.convertWeightToLb(trDimensions.weight) || 0, unit: WeightUnitConstants.LB },
        dimensions: unitConversionHelper.convertToInches(trDimensions.dimensions),
        dimensionType: trDimensions.dimensionType,
      };
    });

    return trIdToTrDetailsMap;
  }

  private isPlannedRoute(route: models.RouteInfo) {
    const orderIDsWithAddAction: Set<string> = new Set();
    const orderIDsWithRemoveAction: Set<string> = new Set();

    route.routeSteps.forEach((routeStep: any) => {
      routeStep.routeStepActions.forEach((routeStepAction: any) => {
        if (routeStepAction.actionType === constants.ACTION_TYPE.ADD) {
          orderIDsWithAddAction.add(routeStepAction.order.orderId);
        } else if (routeStepAction.actionType === constants.ACTION_TYPE.REMOVE) {
          orderIDsWithRemoveAction.add(routeStepAction.order.orderId);
        }
      });
    });

    return orderIDsWithAddAction.size === orderIDsWithRemoveAction.size;
  }

  private buildRoute(plannedRoute: any, snapshot: any, orderIdMapping: any): models.RouteInfo {
    const routeSteps: models.RouteStep[] = new Array();

    let driverInfo: models.Driver | undefined;

    if (plannedRoute.deliveryAssociate !== null) {
      driverInfo = this.driverInfoMapping.get(plannedRoute.deliveryAssociate);
    }

    const actions = plannedRoute.actions.filter((action: { type: string }) => action.type !== constants.ACTION_TYPE.START);

    const actionsList = this.splitActionsListOnTransitEvents(actions);

    const orderIdsInRoute: Set<string> = new Set();

    let index = 0;
    actionsList.forEach((subList: any) => {
      const geocode: GeoCode = this.getGeocodeFromFirstExecuteActionInList(subList, snapshot, orderIdMapping.orderIdActionToGeocodeMap);

      const routeStepActionValues: models.RouteStepActionsValues = this.buildRouteStepActions(subList, orderIdMapping.orderIDToOrderMap);
      routeStepActionValues.orderIdsInStep.forEach((orderId) => {
        orderIdsInRoute.add(orderId);
      });

      index += 1;
      routeSteps.push({
        totalTransitTime: routeStepActionValues.totalTransitTime,
        totalServiceTime: routeStepActionValues.totalServiceTime,
        geocode: geocode,
        routeStepActions: routeStepActionValues.routeStepActions,
        stepNumber: index,
      });
    });

    return {
      index: 0,
      transporterInfo: driverInfo,
      routeId: plannedRoute.id,
      driverType: plannedRoute.driverType,
      serviceTypeId: plannedRoute.serviceTypeId,
      vehicleType: this.routeExecutorMapping.get(plannedRoute.serviceTypeId),
      routeSteps: routeSteps,
      constituentOrders: orderIdsInRoute,
      routeTotalVolume: { value: this.getRouteTotalCapacity(orderIdsInRoute, orderIdMapping.orderIDToOrderMap, CapacityConstants.VOLUME), unit: VolumeUnitConstants.CU_FT },
      routeTotalWeight: { value: this.getRouteTotalCapacity(orderIdsInRoute, orderIdMapping.orderIDToOrderMap, CapacityConstants.WEIGHT), unit: WeightUnitConstants.LB },
      routeDuration: this.getRouteDurationSeconds(routeSteps),
      isOverflow: plannedRoute.id.includes(constants.TRANSPORTER_TYPE.OVERFLOW) ? true : false,
      lockStatus: routeSteps.some((step) => step.routeStepActions.some((action) => action.isLocked === false)) ? constants.LOCK_STATUS.UNLOCKED : constants.LOCK_STATUS.LOCKED,
      scheduleInfo: this.providerDemandMapping.get(plannedRoute.providerDemand),
    };
  }

  private getUnplannedOrders(plan: any, orderIdMap: any): models.UnplannedAction[] {
    const unplannedOrders: models.UnplannedAction[] = new Array();

    plan.unplannedTasks.forEach((task: any) => {
      const unplannedOrderId = task.externalIdMap.ORDER_ID;
      const existingOrder = unplannedOrders.filter((unplannedOrder) => unplannedOrder.orderId === unplannedOrderId);
      if (existingOrder.length === 0 && task.category !== OUTSIDE_PLANNING_HORIZON) {
        unplannedOrders.push({
          orderId: unplannedOrderId,
          category: task.category,
          deliveryWindowStart: orderIdMap.get(unplannedOrderId)?.deliveryWindowStart,
          deliveryWindowEnd: orderIdMap.get(unplannedOrderId)?.deliveryWindowEnd,
        });
      }
    });

    return unplannedOrders;
  }

  private getGeocodeFromFirstExecuteActionInList(actionList: any, snapshot: any, orderIdActionToGeocodeMap: any): GeoCode {
    const optionalNextExecuteAction = actionList.find((a: { type: string }) => a.type === constants.ACTION_TYPE.EXECUTE);
    let geocode: GeoCode;

    if (optionalNextExecuteAction !== undefined) {
      const firstAction = optionalNextExecuteAction;
      const serviceAction = firstAction.id.endsWith(constants.ACTION_TYPE.ADD) ? constants.ACTION_TYPE.ADD : constants.ACTION_TYPE.REMOVE;
      geocode = orderIdActionToGeocodeMap.get(firstAction.externalIdMap.get('ORDER_ID') + serviceAction);
    } else {
      const pickupLocation = snapshot.serviceArea.pickUpLocation;
      geocode = { latitude: pickupLocation.geocode.latitude, longitude: pickupLocation.geocode.longitude };
    }
    return geocode;
  }

  private getRouteTotalCapacity(orderIdsInRoute: Set<string>, orderIdToOrderMap: models.OrderMapping, capacityUnit: string): number {
    let totalCapacity: number = 0;
    orderIdsInRoute.forEach((orderId: any) => {
      const trIdsInOrder = orderIdToOrderMap.get(orderId)?.constituentTrs;
      const trDetails = orderIdToOrderMap.get(orderId)?.trIdToTrDetails;

      trIdsInOrder?.forEach((trId: string) => {
        if (trIdsInOrder !== undefined && trDetails !== undefined && !(trIdsInOrder?.size > 1 && trDetails[trId]?.dimensionType == constants.ESTIMATE_TYPE)) {
          if (capacityUnit == CapacityConstants.VOLUME) {
            totalCapacity += trDetails[trId]?.volume.value ?? 0;
          } else if (capacityUnit == CapacityConstants.WEIGHT) {
            totalCapacity += trDetails[trId]?.weight.value ?? 0;
          }
        }
      });
    });
    return totalCapacity;
  }

  private getRouteDurationSeconds(routeSteps: models.RouteStep[]): number {
    let totalDuration: number = 0;
    routeSteps.forEach((routeStep: any, index) => {
      if (index === 0 && routeSteps[0].routeStepActions.some((routeStepAction) => routeStepAction.actionType === constants.ACTION_TYPE.ADD)) {
        totalDuration += routeStep.totalServiceTime;
      } else {
        totalDuration += routeStep.totalServiceTime + routeStep.totalTransitTime;
      }
    });

    return totalDuration;
  }

  private splitActionsListOnTransitEvents(actions: any): Array<Array<number>> {
    const indicesToSplitListOn: number[] = [];
    for (let i = 1; i < actions.length; i++) {
      if (actions[i].type === constants.ACTION_TYPE.TRANSIT) {
        indicesToSplitListOn.push(i);
      }
    }
    indicesToSplitListOn.push(actions.length);

    const actionsListSplitOnTransitEvents: Array<Array<number>> = [];
    let fromIndex = 0;
    indicesToSplitListOn.forEach((toIndex: any) => {
      actionsListSplitOnTransitEvents.push(actions.slice(fromIndex, toIndex));
      fromIndex = toIndex;
    });

    return actionsListSplitOnTransitEvents;
  }

  private buildRouteStepActions(actionList: any, orderIdToOrderMap: any): models.RouteStepActionsValues {
    let totalTransitTime: number = 0;
    let totalServiceTime: number = 0;

    const routeStepActions: Mutable<models.RouteStepAction>[] = [];
    const orderIdsInStep: Set<string> = new Set();

    actionList.forEach((action: any) => {
      const actionType = action.type;
      if (actionType === constants.ACTION_TYPE.TRANSIT) {
        totalTransitTime += action.seconds;
      } else if (actionType === constants.ACTION_TYPE.EXECUTE || actionType === constants.ACTION_TYPE.ADD || actionType === constants.ACTION_TYPE.REMOVE) {
        totalServiceTime += action.seconds;

        const orderId = action.externalIdMap.ORDER_ID;

        if (orderIdsInStep.has(orderId)) {
          routeStepActions.filter((rsa) => rsa.actionType !== constants.ACTION_TYPE.WAIT && rsa.order.orderId === orderId).forEach((rsa) => (rsa.serviceTime = rsa.serviceTime + action.seconds));
        } else {
          orderIdsInStep.add(orderId);
          routeStepActions.push({
            serviceTime: action.seconds,
            offerWindowStartTime: action.offerWindow!.start * constants.MS_MULTIPLIER,
            startTime: action.startTime * constants.MS_MULTIPLIER,
            actionType: action.id.endsWith(constants.ACTION_TYPE.ADD) ? constants.ACTION_TYPE.ADD : constants.ACTION_TYPE.REMOVE,
            order: orderIdToOrderMap.get(orderId),
            isLocked: action.lockStatus == constants.LOCK_STATUS.LOCKED ? true : false,
          });
        }
      }
    });

    const lastRouteStepAction: Mutable<models.RouteStepAction>[] = [];
    lastRouteStepAction.push(routeStepActions[routeStepActions.length - 1]);

    if (lastRouteStepAction && lastRouteStepAction[0] !== undefined) {
      lastRouteStepAction[0].startTime = lastRouteStepAction[0].startTime + totalServiceTime * constants.MS_MULTIPLIER;
    }

    return { totalTransitTime: totalTransitTime, totalServiceTime: totalServiceTime, orderIdsInStep: orderIdsInStep, routeStepActions: routeStepActions };
  }
}
