import React from 'react';
import { Order, RouteStep } from '../../clients';
import { timezoneManager } from '../../utilities';
import Link from '@amzn/awsui-components-react/polaris/link';
import Box from '@amzn/awsui-components-react/polaris/box';
import Table from '@amzn/awsui-components-react/polaris/table';
import Icon from 'rsuite/lib/Icon';
import { TIME_FORMAT } from './utilities/constants';

export interface PlannedRouteActionsProps {
  readonly routeSteps: RouteStep[];
  readonly onOrderClick: (order: Order) => void;
}

export class PlannedRouteActions extends React.Component<PlannedRouteActionsProps, {}> {
  render() {
    return (
      <Table<RouteStep>
        columnDefinitions={[
          {
            id: 'stepNumber',
            width: 100,
            header: 'Step Number',
            cell: (item: RouteStep) => {
              if (item.routeStepActions.length > 0) {
                return <Box>{item.stepNumber}</Box>;
              }
            },
          },
          {
            id: 'actionType',
            width: 200,
            header: 'Action',
            cell: (item: RouteStep) => {
              return item.routeStepActions.map((action, index) => (
                <Box key={index}>
                  {action.actionType == 'Add' ? (
                    <Box color="text-status-success">
                      <Icon icon={'angle-double-up'} size="lg" />
                    </Box>
                  ) : (
                    <Icon icon={'angle-double-down'} size="lg" style={{ color: 'purple' }} />
                  )}
                </Box>
              ));
            },
          },
          {
            id: 'orderId',
            width: 200,
            header: 'Order ID',
            cell: (item: RouteStep) => {
              return item.routeStepActions.map((action, index) => (
                <Box key={index}>
                  <Link
                    onFollow={(evt) => {
                      evt.preventDefault();
                      if (action.order) {
                        this.props.onOrderClick(action.order);
                      }
                    }}
                  >
                    {action.order.orderId}
                  </Link>
                </Box>
              ));
            },
          },
          {
            id: 'orderType',
            width: 200,
            header: 'Order Type',
            cell: (item: RouteStep) => {
              if (item.routeStepActions.length > 0) {
                return item.routeStepActions.map((action, index) => <Box key={index}>{action.order.orderType}</Box>);
              } else {
                return <Box>Return To Station</Box>;
              }
            },
          },
          {
            id: 'slamStatus',
            width: 150,
            header: 'Slammed',
            cell: (item: RouteStep) => {
              return (
                <Box>
                  {item.routeStepActions.map((action, index) => (
                    <Box key={index}>{action.order.constituentTrs.size > 1 ? '👍' : '❌'}</Box>
                  ))}
                </Box>
              );
            },
          },
          {
            id: 'transportTime',
            width: 100,
            header: 'Transport Time (min)',
            cell: (item: RouteStep) => {
              return <Box>{timezoneManager.convertSecondsToMinutes(item.totalTransitTime)}</Box>;
            },
          },
          {
            id: 'serviceTime',
            width: 100,
            header: 'Service Time (min)',
            cell: (item: RouteStep) => {
              if (item.routeStepActions.length > 0) {
                return <Box>{timezoneManager.convertSecondsToMinutes(item.totalServiceTime)}</Box>;
              }
            },
          },
          {
            id: 'action Start',
            width: 200,
            header: 'Action Start',
            cell: (item: RouteStep) => {
              return item.routeStepActions.map((action, index) => <Box key={index}>{timezoneManager.convertTimestampToString(action.startTime, { format: TIME_FORMAT })}</Box>);
            },
          },
          {
            id: 'offerWindowStart',
            width: 200,
            header: 'Offer Window Start',
            cell: (item: RouteStep) => {
              return item.routeStepActions.map((action, index) => <Box key={index}>{timezoneManager.convertTimestampToString(action.offerWindowStartTime, { format: TIME_FORMAT })}</Box>);
            },
          },
          {
            id: 'deliveryWindow',
            width: 200,
            header: 'Delivery Window',
            cell: (item: RouteStep) => {
              return item.routeStepActions.map((action, index) => (
                <Box key={index}>
                  {timezoneManager.convertTimestampToString(action.order.deliveryWindowStart, { format: TIME_FORMAT }) +
                    '-' +
                    timezoneManager.convertTimestampToString(action.order.deliveryWindowEnd, { format: TIME_FORMAT })}
                </Box>
              ));
            },
          },
        ]}
        items={this.props.routeSteps}
      />
    );
  }
}
