import { RouteSequence, RouteSequenceAction, RouteSequenceTask } from '../../../clients';
import { Stop } from '../models';
import lodash from 'lodash';

type Mutable<T> = { -readonly [P in keyof T]: T[P] };

/**
 * convert sequence to stops so that it can be easily visualized.
 * @param sequence
 */
export function aggregateSequence(sequence: RouteSequence): Stop[] {
  const taskIdToTask: Map<string, RouteSequenceTask> = new Map();
  sequence.tasks.forEach((task) => {
    taskIdToTask.set(task.taskId, task);
  });

  const stops: Mutable<Stop>[] = [];
  for (let i = 0; i < sequence.actions.length; i++) {
    const action = sequence.actions[i];

    // skip all actions that are not EXECUTE or WAIT
    // only EXECUTE and WAIT have taskId
    if (typeof action.taskId !== 'string') {
      continue;
    }

    const task = taskIdToTask.get(action.taskId);
    if (task && action.location.addressId) {
      const taskType = task.taskType;
      const addressId = action.location.addressId;
      if (sequence.actions[i - 1]?.actionType === 'TRANSIT' || lodash.last(stops)?.taskType !== taskType) {
        // if previous action is transit or different from the previous stop, we need to create a new stop.
        stops.push({
          identifier: (stops.length + 1).toString(),
          addressId: addressId,
          taskType: taskType,
          geocode: action.location.geocode,
          tasks: [task],
          startTime: action.startTime,
          duration: action.duration,
        });
      } else {
        const lastStop = lodash.last(stops)!;
        lastStop.tasks.push(task);
        lastStop.startTime = Math.min(lastStop.startTime, action.startTime);
        lastStop.duration += action.duration;
      }
    } else {
      console.warn('invalid action or taskId.');
    }
  }

  return stops;
}
