import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Link from '@amzn/awsui-components-react/polaris/link';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import './demo-image.scss';
export interface DemoImageProps {
  readonly imageLink: string;
  /**
   * alternative message to be displayed if image is not available.
   */
  readonly imageAlt: string;
}

export interface DemoImageState {
  readonly expand: boolean;
}

// eslint-disable-next-line no-redeclare
export default class DemoImage extends React.Component<DemoImageProps, DemoImageState> {
  constructor(props: DemoImageProps) {
    super(props);
    this.state = {
      expand: false,
    };
  }
  render() {
    return (
      <React.Fragment>
        {this.renderExpandModal()}
        <SpaceBetween direction="vertical" size="xs">
          <div
            className="demo-image"
            onClick={(evt) => {
              evt.preventDefault();
              this.setState({ expand: true });
            }}
          >
            <img src={this.props.imageLink} alt={this.props.imageAlt} />
          </div>
          <Link
            onFollow={(evt) => {
              evt.preventDefault();
              this.setState({ expand: true });
            }}
          >
            <Icon name="expand" />
            <Box margin={{ left: 'xs' }} variant="span" color="inherit">
              Expand
            </Box>
          </Link>
        </SpaceBetween>
      </React.Fragment>
    );
  }

  private renderExpandModal() {
    if (this.state.expand) {
      return (
        <Modal size="max" visible={true} onDismiss={(evt) => this.setState({ expand: false })} header={null}>
          <div className="modal-demo-image">
            <img src={this.props.imageLink} alt={this.props.imageAlt} />
          </div>
        </Modal>
      );
    }
  }
}
