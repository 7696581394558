import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { GlobalContext } from '../../main-app/global-context';

export class ArtifactSearchPageTools extends React.Component<{}, {}> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  private renderContactLink() {
    return (
      <Link external externalIconAriaLabel="Opens in a new tab" href="https://oncall.corp.amazon.com/#/view/flex-assignment/schedule">
        flex assignment team
      </Link>
    );
  }

  private renderDescription() {
    return (
      <Box>
        This page gets artifacts stored in DRAS based on user search. There are currently two ways to search for artifacts:
        <ul>
          <li>Search by Artifact Id</li>
          <li>Get Latest Artifact by Scope</li>
        </ul>
        Note that upon downloading an artifact, you will have a shareable URL. If denied access to download artifacts, please contact {this.renderContactLink()} to request permission for appropriate
        artifact(s).
      </Box>
    );
  }

  private renderSearchByArtifactIdNotes() {
    return (
      <Box>
        <Header variant="h3">Search by Artifact Id</Header>
        This search will download a given artifact based on artifact type and the artifact Id. This search may also redirect to the specific artifact render if UFRAAViz has supported it.
      </Box>
    );
  }

  private renderGetLatestArtifactByScopeNotes() {
    return (
      <Box>
        <Header variant="h3">Get Latest Artifact by Scope</Header>
        This search will fetch the latest artifact for a given artifact type and scope (i.e. service area). The Service Area Allowlist artifact type represents network-level allow lists for
        assignment, batching, and anomaly detection. The scope entered for Service Area Allowlist artifact type must be one of the following:
        <ul>
          <li>LORD_COMMANDER_SERVICE_AREA_ALLOWLIST</li>
          <li>SERVICE_AREA_BATCHING_ALLOW_LIST</li>
          <li>REAH_CONFIG_IDS_LIST</li>
        </ul>
        The DRIA Work Cadence Config is the only artifact that does not require a scope type as this is network-level and unique.
      </Box>
    );
  }

  private renderLearnMore() {
    return (
      <Box>
        <Header variant="h3">Learn more</Header>
        <SpaceBetween direction="vertical" size="xs">
          <Link external href="https://email-list.corp.amazon.com/email-list/expand-list/ufraaviz-interest" target="_blank">
            Subscribe to ufraaviz-interest
          </Link>
        </SpaceBetween>
      </Box>
    );
  }

  render() {
    return (
      <HelpPanel header={<Header variant="h2">Info</Header>}>
        <SpaceBetween direction="vertical" size="m">
          {this.renderDescription()}
          {this.renderSearchByArtifactIdNotes()}
          {this.renderGetLatestArtifactByScopeNotes()}
          {this.renderLearnMore()}
        </SpaceBetween>
      </HelpPanel>
    );
  }
}
