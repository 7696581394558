import { ArtifactSearch, RawArtifactSearch } from '../components/artifacts-page/models';
import { UserSearch } from '../components/lm-route-page/models';

export function buildURLSearchParamsFromArtifactSearch(artifactSearch: ArtifactSearch): { [key: string]: string } {
  switch (artifactSearch.searchType) {
    case 'artifactId': {
      const query: { [key: string]: string } = { artifactId: artifactSearch.text };
      typeof artifactSearch.artifactType === 'string' ? (query['artifactType'] = artifactSearch.artifactType) : 0;
      return query;
    }
    case 'scope': {
      const query: { [key: string]: string } = { scope: artifactSearch.text };
      typeof artifactSearch.artifactType === 'string' ? (query['artifactType'] = artifactSearch.artifactType) : 0;
      return query;
    }
    default:
      throw new Error(`invalid artifact search type ${artifactSearch.searchType}`);
  }
}

export function buildURLSearchParamsFromLmRouteUserSearch(userSearch: UserSearch): { [key: string]: string } {
  switch (userSearch.type) {
    case 'routeId':
      {
        const query: { [key: string]: string } = { routeId: userSearch.text };
        typeof userSearch.version === 'string' && userSearch.version.trim().length > 0 ? (query['version'] = userSearch.version) : 0;
        return query;
      }
      break;
    case 'correlationId':
      {
        return { correlationId: userSearch.text };
      }
      break;

    case 'trId':
    case 'trackingId':
    case 'transporterId': {
      const query: { [key: string]: string } = {};
      query[userSearch.type] = userSearch.text;
      typeof userSearch.serviceAreaId === 'string' ? (query['serviceAreaId'] = userSearch.serviceAreaId) : 0;
      typeof userSearch.dispatchDate === 'string' ? (query['dispatchDate'] = userSearch.dispatchDate) : 0;
      return query;
    }
    default:
      throw new Error(`invalid search type ${userSearch.type}`);
  }
}

export function buildArtifactSearchFromURLSearchParams(params: URLSearchParams): RawArtifactSearch | undefined {
  const artifactType = params.get('artifactType');

  const artifactId = params.get('artifactId');
  if (typeof artifactId === 'string') {
    return {
      text: artifactId,
      artifactType: artifactType && artifactType.length > 9 ? artifactType : undefined,
      searchType: 'artifactId',
    };
  }

  const scope = params.get('scope');
  if (typeof scope === 'string') {
    return {
      text: scope,
      artifactType: artifactType && artifactType.length > 9 ? artifactType : undefined,
      searchType: 'scope',
    };
  }

  /**
   * If user just supplies artifactType in queryParam, no way to distinguish which search type to use.
   * Therefore, we will default to artifactId search. This conditional is done at the end since
   * specifying search type should take priority.
   */
  if (typeof artifactType === 'string') {
    return {
      text: '',
      artifactType: artifactType,
      searchType: 'artifactId',
    };
  }

  return undefined;
}

export function buildLmRouteUserSearchFromURLSearchParams(params: URLSearchParams): UserSearch | undefined {
  const version = params.get('version');
  const dispatchDate = params.get('dispatchDate');
  const serviceAreaId = params.get('serviceAreaId');

  const routeId = params.get('routeId');
  if (typeof routeId === 'string') {
    return {
      text: routeId,
      version: version ?? undefined,
      type: 'routeId',
    };
  }

  const correlationId = params.get('correlationId');
  if (typeof correlationId === 'string') {
    return {
      text: correlationId,
      type: 'correlationId',
    };
  }

  const trId = params.get('trId');
  if (typeof trId === 'string') {
    return {
      text: trId,
      type: 'trId',
      serviceAreaId: serviceAreaId ?? undefined,
      dispatchDate: dispatchDate ?? undefined,
    };
  }

  const trackingId = params.get('trackingId');
  if (typeof trackingId === 'string') {
    return {
      text: trackingId,
      type: 'trackingId',
      serviceAreaId: serviceAreaId ?? undefined,
      dispatchDate: dispatchDate ?? undefined,
    };
  }

  const transporterId = params.get('transporterId');
  if (typeof transporterId === 'string') {
    return {
      text: transporterId,
      type: 'transporterId',
      dispatchDate: dispatchDate ?? undefined,
      serviceAreaId: serviceAreaId ?? undefined,
    };
  }

  return undefined;
}

export function buildRoutingPlanUrl(snapshotId?: string, tab?: string, filteringText?: string, serviceAreaId?: string) {
  const url = `/routing-plan/${snapshotId ? encodeURIComponent(snapshotId) : 'live'}`;
  const queryParam: string[] = [];
  if (tab) {
    queryParam.push(`tab=${encodeURIComponent(tab)}`);
  }
  if (filteringText) {
    queryParam.push(`filtering=${encodeURIComponent(filteringText)}`);
  }
  if (serviceAreaId) {
    queryParam.push(`serviceAreaId=${serviceAreaId}`);
  }

  if (queryParam.length > 0) {
    return `${url}?${queryParam.join('&')}`;
  } else {
    return url;
  }
}

export function buildAssignmentPlanUrl(plannerInputId: string | 'live' | 'drag-and-drop', tab?: string, filteringText?: string, serviceAreaId?: string, shadowPrefix: string = '') {
  let url: string;
  switch (plannerInputId) {
    case 'live':
      url = `/${shadowPrefix}assignment-plan/live`;
      break;
    case 'drag-and-drop':
      url = '/assignment-plan/drag-and-drop';
      break;
    default:
      url = `/${shadowPrefix}assignment-plan/${encodeURIComponent(plannerInputId)}`;
      break;
  }

  const queryParam: string[] = [];
  if (tab) {
    queryParam.push(`tab=${encodeURIComponent(tab)}`);
  }
  if (filteringText) {
    queryParam.push(`filtering=${encodeURIComponent(filteringText)}`);
  }
  if (serviceAreaId) {
    queryParam.push(`serviceAreaId=${serviceAreaId}`);
  }

  if (queryParam.length > 0) {
    return `${url}?${queryParam.join('&')}`;
  } else {
    return url;
  }
}
