import React from 'react';
import { ProgressStatus } from '../clients';
import Alert, { AlertProps } from '@amzn/awsui-components-react/polaris/alert';
import ExpandableSection from '@amzn/awsui-components-react/polaris/expandable-section';
import Box from '@amzn/awsui-components-react/polaris/box';

export interface ProgressAlertProps {
  readonly progress: ProgressStatus;
  readonly itemIdsHeader?: string;
}

export interface ProgressAlertState {
  readonly expand: boolean;
}

export default class ProgressAlert extends React.Component<ProgressAlertProps, ProgressAlertState> {
  constructor(props: ProgressAlertProps) {
    super(props);

    this.state = {
      expand: false,
    };
  }

  render() {
    let alertType: AlertProps.Type;

    switch (this.props.progress.type) {
      case 'FAILURE':
        alertType = 'error';
        break;
      case 'IN_PROGRESS':
        alertType = 'info';
        break;
      case 'SUCCESS':
        alertType = 'success';
        break;
    }

    return (
      <Alert type={alertType}>
        {this.props.progress.message}
        {this.renderItemIds()}
      </Alert>
    );
  }

  private renderItemIds() {
    if (this.props.progress.itemIds.length > 0) {
      return (
        <ExpandableSection headerText={this.props.itemIdsHeader ?? 'Items'}>
          {this.props.progress.itemIds.map((id, index) => {
            return <Box key={index}>{id}</Box>;
          })}
        </ExpandableSection>
      );
    }
  }
}
