import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import ufraaVizClient, { ServiceAreaSummary } from '../../clients';
import Table, { TableProps } from '@amzn/awsui-components-react/polaris/table';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import * as lodash from 'lodash';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';

const SERVICE_AREAS_PER_PAGE = 30;
const INITIAL_FILTERING_TEXT = '';

interface State {
  readonly serviceAreaSummariesInCurrentPage?: ServiceAreaSummary[];

  // current page, start from 1
  readonly currentPage: number;
  readonly totalPage: number;

  readonly filteringText: string;
}

interface Props extends WithBundleProps {}

export class ServiceAreaPage extends React.Component<Props, State> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  // partitioned based on number of service area per page.
  private serviceAreaSummaries?: ServiceAreaSummary[];
  private filteredServiceAreaSummaries?: ServiceAreaSummary[];
  private partitionedFilteredServiceAreaSummaries?: ServiceAreaSummary[][];
  constructor(props: Props) {
    super(props);
    this.state = {
      currentPage: 1,
      totalPage: 1,
      filteringText: INITIAL_FILTERING_TEXT,
    };
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.updateBreadcrumbItems([{ text: 'Service Areas', href: '/service-areas' }]);
    try {
      this.serviceAreaSummaries = await ufraaVizClient.getServiceAreaSummaries();
    } catch (err: any) {
      this.context.addNotification({
        type: 'error',
        header: 'Error',
        content: err.message,
      });
    }
    this.refreshFilterAndPartition(1, this.state.filteringText);
  }

  refreshFilterAndPartition(currentPage: number, filteringText: string) {
    if (this.serviceAreaSummaries !== undefined) {
      if (filteringText === '') {
        this.filteredServiceAreaSummaries = this.serviceAreaSummaries;
      } else {
        this.filteredServiceAreaSummaries = this.serviceAreaSummaries.filter((sa) => this.matchServiceArea(sa, filteringText));
      }
      this.partitionedFilteredServiceAreaSummaries = lodash.chunk(this.filteredServiceAreaSummaries, SERVICE_AREAS_PER_PAGE);
    }

    this.setState({
      serviceAreaSummariesInCurrentPage: this.getserviceAreaSummariesInCurrentPage(currentPage),
      currentPage: currentPage,
      totalPage: this.getTotalPage(),
      filteringText: filteringText,
    });
  }

  matchServiceArea(serviceAreaSummary: ServiceAreaSummary, text: string): boolean {
    const lowercaseSearchText = text.toLowerCase();
    return (
      serviceAreaSummary.stationCode.toLowerCase().includes(lowercaseSearchText) ||
      serviceAreaSummary.serviceAreaName.toLowerCase().includes(lowercaseSearchText) ||
      serviceAreaSummary.serviceAreaId.toLowerCase().includes(lowercaseSearchText)
    );
  }

  getserviceAreaSummariesInCurrentPage(currentPage: number) {
    if (this.partitionedFilteredServiceAreaSummaries === undefined) {
      return undefined;
    } else if (currentPage <= this.partitionedFilteredServiceAreaSummaries.length) {
      return this.partitionedFilteredServiceAreaSummaries[currentPage - 1];
    } else {
      return [];
    }
  }

  getTotalPage(): number {
    if (this.partitionedFilteredServiceAreaSummaries === undefined) {
      return 0;
    } else {
      return this.partitionedFilteredServiceAreaSummaries.length;
    }
  }

  renderHeader() {
    return <Header variant="h2">{this.props.bundle.getMessage('SERVICE_AREA_PAGE:HEADER')}</Header>;
  }

  renderPagination() {
    return (
      <Pagination
        currentPageIndex={this.state.currentPage}
        pagesCount={this.state.totalPage}
        ariaLabels={{
          nextPageLabel: 'Next page',
          previousPageLabel: 'Previous page',
          pageLabel: (pageNumber) => `Page ${pageNumber} of ${this.state.totalPage}`,
        }}
        onChange={(evt) => {
          const selectedPage = evt.detail.currentPageIndex;
          this.setState({
            serviceAreaSummariesInCurrentPage: this.getserviceAreaSummariesInCurrentPage(selectedPage),
            currentPage: selectedPage,
          });
        }}
      />
    );
  }

  renderFilter() {
    return (
      <TextFilter
        filteringText={this.state.filteringText}
        filteringPlaceholder={this.props.bundle.getMessage('SERVICE_AREA_PAGE:FILTERING_PLACEHOLDER')}
        onChange={(evt) => {
          this.setState({ filteringText: evt.detail.filteringText });
        }}
        onDelayedChange={(evt) => {
          const filteringText = evt.detail.filteringText;
          this.refreshFilterAndPartition(1, filteringText);
        }}
      />
    );
  }

  render() {
    const tableDefinition: TableProps.ColumnDefinition<ServiceAreaSummary>[] = [
      {
        id: 'sa-code',
        header: this.props.bundle.getMessage('SERVICE_AREA_PAGE:STATION_CODE'),
        cell: (item) => item.stationCode,
        maxWidth: '150px',
      },
      {
        id: 'sa-name',
        header: this.props.bundle.getMessage('SERVICE_AREA_PAGE:SERVICE_AREA_NAME'),
        cell: (item) => (
          <Link
            href={`/service-areas/${item.serviceAreaId}`}
            onFollow={(evt) => {
              // prevent browser from sending requests to backend. Doing in-browser navgiation.
              evt.preventDefault();
              if (typeof evt.detail.href === 'string') {
                this.context.onGoto(evt.detail.href);
              }
            }}
          >
            {item.serviceAreaName}
          </Link>
        ),
        maxWidth: '360px',
      },
      {
        id: 'sa-id',
        header: this.props.bundle.getMessage('SERVICE_AREA_PAGE:SERVICE_AREA_ID'),
        cell: (item) => item.serviceAreaId,
        maxWidth: '360px',
      },
    ];

    return (
      <Table<ServiceAreaSummary>
        header={this.renderHeader()}
        columnDefinitions={tableDefinition}
        loading={this.state.serviceAreaSummariesInCurrentPage === undefined}
        loadingText={this.props.bundle.getMessage('SERVICE_AREA_PAGE:LOADING_SERVICE_AREAS')}
        items={this.state.serviceAreaSummariesInCurrentPage === undefined ? [] : this.state.serviceAreaSummariesInCurrentPage}
        stickyHeader={true}
        filter={this.renderFilter()}
        pagination={this.renderPagination()}
      />
    );
  }
}

export default withBundle('ServiceAreaPage')(ServiceAreaPage);
