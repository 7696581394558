import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import ufraaVizClient, { FlexRouteAssignmentPlannerInput, FlexRouteAssignmentPlan, ServiceAreaDetails } from '../../clients';
import AssignmentPlanContent from './assignment-plan-content';
import { buildAssignmentPermissionWarningNotification, PlanDetector } from './utilities';
import { DEFAULT_TITLE } from '../../constants';
import { timezoneManager } from '../../utilities';

interface Props {
  readonly serviceAreaId: string;
  readonly activeTab?: string;
  readonly onTabChange: (tab: string) => void;
  readonly filteringText?: string;
  readonly onFilteringTextChange: (text: string) => void;
  readonly isShadow?: boolean;
}

interface State {
  readonly plan?: FlexRouteAssignmentPlan | null;
  readonly plannerInput?: FlexRouteAssignmentPlannerInput | null;
  readonly serviceAreaDetails?: ServiceAreaDetails | null;
  readonly prefixLink: string;
}

export class LiveAssignmentPlanPage extends React.Component<Props, State> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  private planDetector?: PlanDetector;

  constructor(props: Props) {
    super(props);
    this.state = {
      plan: undefined,
      plannerInput: undefined,
      serviceAreaDetails: undefined,
      prefixLink: this.props.isShadow ? 'shadow-' : '',
    };
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.updateBreadcrumbItems([
      {
        text: 'live',
        href: `/${this.state.prefixLink}assignment-plan/live?serviceAreaId=${this.props.serviceAreaId}`,
      },
    ]);
    this.context.updateLiveModeEffectEnabled(true);
    this.loadServiceAreaDetails(this.props.serviceAreaId);
    this.planDetector = new PlanDetector(this.props.serviceAreaId);
    this.planDetector.onNewPlanArtifacts = this.onNewPlanArtifacts.bind(this);
    this.planDetector.start();

    try {
      const permissions = await ufraaVizClient.getUserPermissions();
      if (!permissions.includes('ASSIGNMENT_ACCESS')) {
        this.context.addNotification(buildAssignmentPermissionWarningNotification());
      }
    } catch (err: any) {
      this.context.addNotification({
        header: 'Error',
        content: 'Unable to load user permission.',
        type: 'warning',
      });
    }
  }

  private onNewPlanArtifacts(plannerInput: FlexRouteAssignmentPlannerInput, plan: FlexRouteAssignmentPlan) {
    document.title = this.formatTitle(plannerInput);
    this.setState({
      plannerInput,
      plan,
    });
  }

  async loadServiceAreaDetails(serviceAreaId: string): Promise<void> {
    try {
      const serviceAreaDetails = await ufraaVizClient.getServiceAreaDetails(serviceAreaId);
      this.setState({
        serviceAreaDetails: serviceAreaDetails,
      });
      this.context.updateStationTimezone(serviceAreaDetails.timeZone);
      this.context.updateBreadcrumbItems([
        {
          text: `${serviceAreaDetails.serviceAreaName}`,
          href: `/service-areas/${serviceAreaDetails.serviceAreaId}`,
        },
        {
          text: 'live',
          href: `/${this.state.prefixLink}assignment-plan/live?serviceAreaId=${this.props.serviceAreaId}`,
        },
      ]);
    } catch (err: any) {
      this.context.addNotification({
        header: `Cannot found service area`,
        content: `Unable to find the service area details ${serviceAreaId} (${err?.response?.data})`,
        type: 'error',
      });
    }
  }

  private formatTitle(plannerInput: FlexRouteAssignmentPlannerInput): string {
    return `${plannerInput.serviceArea.defaultStationCode} @ ${timezoneManager.convertTimestampToString(plannerInput.serviceArea.lastUpdated, {
      format: 'HH:mm z',
    })} (Live)`;
  }

  // cleanup
  componentWillUnmount() {
    this.context.updateStationTimezone(undefined);
    this.context.updateLiveModeEffectEnabled(false);
    if (this.planDetector) {
      this.planDetector.stop();
      this.planDetector = undefined;
    }
    document.title = DEFAULT_TITLE;
  }

  render() {
    return (
      <AssignmentPlanContent
        mode="live"
        plannerInput={this.state.plannerInput}
        plan={this.state.plan}
        serviceAreaDetails={this.state.serviceAreaDetails}
        isShadow={this.props.isShadow}
        activeTab={this.props.activeTab}
        onFilteringTextChange={this.props.onFilteringTextChange}
        onTabChange={this.props.onTabChange}
        filteringText={this.props.filteringText}
      />
    );
  }
}
