import React from 'react';
import Header from '@amzn/awsui-components-react/polaris/header';
import Tabs, { TabsProps } from '@amzn/awsui-components-react/polaris/tabs';
import Table, { TableProps } from '@amzn/awsui-components-react/polaris/table';
import Container from '@amzn/awsui-components-react/polaris/container';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Box from '@amzn/awsui-components-react/polaris/box';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import { GlobalContext } from '../../main-app/global-context';
import { ExecutionSnapshot, ExecutionPlan, ExecutionPlanInfo, UnplannedAction } from '../../clients';
import { timezoneManager } from '../../utilities';
import { PlannedRoutesInfo } from './planned-routes-info';
import JSONViewer from '../../shared-components/json-viewer';

interface RoutingPlanDetailsProps {
  readonly snapshot?: ExecutionSnapshot | null;
  readonly plan?: ExecutionPlan | null;
  readonly activeTab?: string;
  readonly onTabChange: (tab: string) => void;
  readonly planInfo: ExecutionPlanInfo | null;
}

export class RoutingPlanDetails extends React.Component<RoutingPlanDetailsProps> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  render() {
    const validTabs: string[] = ['route-details', 'unplanned-orders-details', 'routing-config'];
    let activeTabId = validTabs[0];
    if (validTabs.find((tab) => tab === this.props.activeTab)) {
      activeTabId = this.props.activeTab!;
    }
    return (
      <Tabs
        activeTabId={activeTabId}
        onChange={(evt) => {
          this.props.onTabChange(evt.detail.activeTabId);
        }}
        tabs={[
          {
            label: 'Planned Routes',
            id: validTabs[0],
            content: this.renderRouteDetails(),
          },
          {
            label: 'Unplanned Orders',
            id: validTabs[1],
            content: this.renderUnplannedOrders(),
          },
          {
            label: 'Routing Config',
            id: validTabs[2],
            content: this.renderRoutingConfig(),
          },
        ]}
      />
    );
  }

  private renderRouteDetails() {
    if (this.props.planInfo) {
      return <PlannedRoutesInfo planInfo={this.props.planInfo} />;
    } else if (this.props.planInfo === undefined) {
      return this.renderDetailsNotFound('Plan not found');
    } else {
      return this.renderLoading('Loading');
    }
  }

  private renderUnplannedOrders() {
    if (this.props.snapshot && this.props.plan) {
      const tableDefinition: TableProps.ColumnDefinition<UnplannedAction>[] = [
        {
          id: 'order_id',
          header: 'Order Id',
          cell: (item) => <Box>{item.orderId}</Box>,
          maxWidth: '2000px',
        },
        {
          id: 'dwStart',
          header: 'Delivery Window',
          cell: (item) => <Box>{timezoneManager.convertTimestampToString(item.deliveryWindowStart) + '-' + timezoneManager.convertTimestampToString(item.deliveryWindowEnd)} </Box>,
          maxWidth: '360px',
        },
      ];

      return (
        <Table<UnplannedAction>
          columnDefinitions={tableDefinition}
          header={this.renderUnplannedOrdersHeader()}
          loadingText={'Loading unplanned orders...'}
          items={this.props.planInfo?.unplannedOrders === undefined ? [] : this.props.planInfo?.unplannedOrders}
          stickyHeader={true}
        />
      );
    }
  }

  private renderUnplannedOrdersHeader() {
    return <Box margin={{ bottom: 'xxxs' }}>Number of unplanned Orders: {this.props.planInfo?.unplannedOrders.length}</Box>;
  }

  private renderRoutingConfig() {
    if (this.props.snapshot) {
      return (
        <Container header={<Header variant="h2">Routing Config</Header>}>
          <JSONViewer data={this.props.snapshot.serviceAreaConfig} />
        </Container>
      );
    } else if (this.props.snapshot === null) {
      return this.renderDetailsNotFound('Routing Config not found');
    } else {
      return this.renderLoading('Routing Config');
    }
  }

  private renderLoading(header: string) {
    return (
      <Container header={<Header variant="h2">{header}</Header>}>
        <Box textAlign="center">
          <StatusIndicator type="info">Loading ...</StatusIndicator>
        </Box>
      </Container>
    );
  }

  private renderDetailsNotFound(header: string) {
    return (
      <Container header={<Header variant="h2">{header}</Header>}>
        <Box textAlign="center">
          <StatusIndicator type="info">Plan not found</StatusIndicator>
        </Box>
      </Container>
    );
  }
}
