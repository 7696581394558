import { preferencesStore } from '../../../utilities';

const SUFFIX = '-TABLE-HIDDEN-COLUMN';

export function upsertHiddenColumnIds(tableName: string, columnIds: string[]) {
  preferencesStore.set<string[]>(`${tableName}${SUFFIX}`, columnIds);
}

export function retrieveHiddenColumnIds(tableName: string): string[] {
  return preferencesStore.get<string[]>(`${tableName}${SUFFIX}`, []);
}

export function upsertCompactRouteWindowFlag(compactMode: boolean) {
  preferencesStore.set<string>(`CompactRouteTable`, compactMode ? 'true' : 'false');
}

export function retrieveCompactRouteWindowFlag(): boolean {
  return preferencesStore.get<string>(`CompactRouteTable`, 'true') === 'true';
}
