import { ArtifactSearchType } from './models';

// @TODO: add to list as more artifact-specific pages are implemented
export const IMPLEMENTED_ARTIFACT_TYPES: ReadonlyArray<string> = ['FLEX_ROUTE_ASSIGNMENT_PLANNER_INPUT', 'FLEX_ROUTE_ASSIGNMENT_PLAN', 'EXECUTION_SNAPSHOT', 'EXECUTION_PLAN'];

export const ALLOW_LIST_ARTIFACT_TYPES: ReadonlyArray<string> = ['LORD_COMMANDER_SERVICE_AREA_ALLOWLIST', 'SERVICE_AREA_BATCHING_ALLOW_LIST', 'REAH_CONFIG_IDS_LIST'];

export const SUPPORTED_SEARCH_TYPES: ReadonlyArray<ArtifactSearchType> = ['scope', 'artifactId'];

// @TODO: add to record as we implement other artifacts
export const IMPLEMENTED_ARTIFACT_TYPES_TO_HREF: Readonly<Record<string, string>> = {
  FLEX_ROUTE_ASSIGNMENT_PLANNER_INPUT: '/assignment-plan/',
  FLEX_ROUTE_ASSIGNMENT_PLAN: '/assignment-plan/',
  EXECUTION_SNAPSHOT: '/routing-plan/',
  EXECUTION_PLAN: '/routing-plan/',
};

export const ARTIFACT_SEARCH_TYPE_TO_LABEL: Readonly<Record<ArtifactSearchType, string>> = {
  artifactId: 'Artifact Id',
  scope: 'Scope',
};

export const ARTIFACT_SEARCH_TYPE_TO_OPTION_LABEL: Readonly<Record<ArtifactSearchType, string>> = {
  artifactId: 'Search by Artifact Id',
  scope: 'Search Latest Artifact by Scope',
};
