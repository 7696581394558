import React from 'react';
import { FlexRouteAssignmentPlannerInput, FlexRouteAssignmentPlan, FdpAssignment, FungibleAssignment } from '../../clients';
import Tabs from '@amzn/awsui-components-react/polaris/tabs';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import JSONViewer from '../../shared-components/json-viewer';
import AssignmentStatus from './assignment-status';
import UserNotes from './user-notes';
import { getAssignmentAlgorithm, getOperatingEntity } from './utilities/helper';
import Box from '@amzn/awsui-components-react/polaris/box';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import { AmzlAssignmentCsvExporter, buildAmzlTableDefinition, buildFdpTableDefinition, buildFungibleTableDefinition, getCyclesFromAssignments } from './table-definitions';
import { FdpAmzlAssignmentConverter, FdpAmzlAssignmentConverterProps, FdpAssignmentConverter, FungibleAssignmentConverter, parseRouteNotes, parseTransporterNotes } from './converters';
import { INITIAL_AMZL_FILTERING_TEXT, amzlFilter, INITIAL_FDP_FILTERING_TEXT, fdpFilter, INITIAL_FUNGIBLE_FILTERING_TEXT, fungibleFilter } from './filters';
import { preferencesStore } from '../../utilities';
import { ASSIGNMENT_PLAN_USER_GENERATED_DATA, DEFAULT_USER_GENERATED_DATA, UFRAAVIZ_TO_CONFIG_MANAGER_STAGE_SUFFIX } from './constants';
import { RouteNote, TransporterNote, UserGeneratedData } from './models';
import { config } from '../../utilities';
import Button from '@amzn/awsui-components-react/polaris/button';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import { MessageBundle } from '@amzn/arb-tools';

interface Props extends WithBundleProps {
  readonly plannerInput?: FlexRouteAssignmentPlannerInput | null;
  readonly plan?: FlexRouteAssignmentPlan | null;
  readonly activeTab?: string;
  readonly onTabChange: (tab: string) => void;
  readonly filteringText?: string;
  readonly onFilteringTextChange: (text: string) => void;
  readonly onRouteClick: (routeId: string, version: number) => void;
  readonly isShadow?: boolean;
}

interface State {
  readonly filteringText?: string;
  readonly assignments?: FdpAssignment[] | FungibleAssignment[];
  readonly filteredAssignments?: FdpAssignment[] | FungibleAssignment[];
  readonly userGeneratedData: UserGeneratedData;
  readonly prefixHeader: string;
}

interface RefreshAssignmentsStateProps {
  readonly userGeneratedData: UserGeneratedData;
}

class AssignmentPlanDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      assignments: [],
      filteredAssignments: [],
      filteringText: this.props.filteringText,
      userGeneratedData: preferencesStore.get<UserGeneratedData>(ASSIGNMENT_PLAN_USER_GENERATED_DATA, DEFAULT_USER_GENERATED_DATA),
      prefixHeader: this.props.isShadow ? 'Shadow ' : '',
    };
  }

  componentDidMount() {
    if (this.props.plannerInput && this.props.plan) {
      this.refreshAssignmentsState(this.props.plannerInput, this.props.plan, {
        userGeneratedData: this.state.userGeneratedData,
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.plannerInput) {
      let shouldRefresh = prevProps.plan !== this.props.plan || prevProps.plannerInput !== this.props.plannerInput;
      if (this.props.plan && shouldRefresh) {
        this.refreshAssignmentsState(this.props.plannerInput, this.props.plan, {
          userGeneratedData: this.state.userGeneratedData,
        });
      }
    }
  }

  private async refreshFungibleAssignmentState(plannerInput: FlexRouteAssignmentPlannerInput, plan: FlexRouteAssignmentPlan) {
    const filteringText = this.state.filteringText ?? INITIAL_FUNGIBLE_FILTERING_TEXT;
    const assignment = new FungibleAssignmentConverter().convert(plannerInput, plan);
    const filteredAssignments = fungibleFilter(filteringText, assignment);
    this.setState({
      assignments: assignment,
      filteredAssignments: filteredAssignments,
      filteringText: filteringText,
    });
  }

  private async refreshAmzlAssignmentState(plannerInput: FlexRouteAssignmentPlannerInput, plan: FlexRouteAssignmentPlan, props: RefreshAssignmentsStateProps) {
    const filteringText = this.state.filteringText ?? INITIAL_AMZL_FILTERING_TEXT;
    const amzlConverterInput = await this.parseUserGeneratedDataForAmzl(props.userGeneratedData);
    const assignments = new FdpAmzlAssignmentConverter(amzlConverterInput).convert(plannerInput, plan);
    const filteredAssignments = amzlFilter(filteringText, assignments);
    this.setState({
      assignments: assignments,
      filteredAssignments: filteredAssignments,
      filteringText: filteringText,
    });
  }

  private async refreshFdpAssignmentState(plannerInput: FlexRouteAssignmentPlannerInput, plan: FlexRouteAssignmentPlan) {
    const filteringText = this.state.filteringText ?? INITIAL_FDP_FILTERING_TEXT;
    const assignments = new FdpAssignmentConverter().convert(plannerInput, plan);
    const filteredAssignments = fdpFilter(filteringText, assignments);
    this.setState({
      assignments: assignments,
      filteredAssignments: filteredAssignments,
      filteringText: filteringText,
    });
  }

  private async refreshAssignmentsState(plannerInput: FlexRouteAssignmentPlannerInput, plan: FlexRouteAssignmentPlan, props: RefreshAssignmentsStateProps) {
    const algorithm = getAssignmentAlgorithm(plannerInput);
    const operatingEntity = getOperatingEntity(plannerInput);
    const shouldRenderFungibleAssignmentStatus =
      algorithm === 'FUNGIBLE_ASSIGNMENT_ALGORITHM' || plannerInput.assignmentConfig.fastSsdMigrationToFdpEnabled || plannerInput.assignmentConfig.enableTransporterRollupForFdp;

    // refresh state
    if (shouldRenderFungibleAssignmentStatus) {
      this.refreshFungibleAssignmentState(plannerInput, plan);
    } else if (algorithm === 'FLEX_DISPATCH_PLANNER' && operatingEntity === 'AMZL OPS') {
      this.refreshAmzlAssignmentState(plannerInput, plan, props);
    } else if (algorithm === 'FLEX_DISPATCH_PLANNER') {
      this.refreshFdpAssignmentState(plannerInput, plan);
    }
  }

  private async parseUserGeneratedDataForAmzl(userGeneratedData: UserGeneratedData): Promise<FdpAmzlAssignmentConverterProps> {
    let transporterIdToTransporterNotes: ReadonlyMap<string, TransporterNote> = new Map();
    let routeNameToRouteNotes: ReadonlyMap<string, RouteNote> = new Map();
    try {
      transporterIdToTransporterNotes = await parseTransporterNotes(userGeneratedData.transportersCSV);
    } catch (err: any) {
      console.error(err.message);
    }

    try {
      routeNameToRouteNotes = await parseRouteNotes(userGeneratedData.routesCSV);
    } catch (err: any) {
      console.error(err.message);
    }
    return {
      transporterIdToTransporterNotes: transporterIdToTransporterNotes,
      routeNameToRouteNotes: routeNameToRouteNotes,
    };
  }

  private renderLoading(header: string) {
    return (
      <Container header={<Header variant="h2">{header}</Header>}>
        <Box textAlign="center">
          <StatusIndicator type="info">{this.props.bundle.getMessage('ASSIGNMENT_PLAN_DETAILS:LOADING')}</StatusIndicator>
        </Box>
      </Container>
    );
  }

  private renderPlanNotFound(header: string) {
    return (
      <Container header={<Header variant="h2">{header}</Header>}>
        <Box textAlign="center">
          <StatusIndicator type="info">{this.props.bundle.getMessage('ASSIGNMENT_PLAN_DETAILS:PLAN_NOT_FOUND')}</StatusIndicator>
        </Box>
      </Container>
    );
  }

  private renderConfigExternalLink(scope: string) {
    const stageSuffix = UFRAAVIZ_TO_CONFIG_MANAGER_STAGE_SUFFIX[config.stage];
    const marketplace = config.marketplace.toLowerCase();
    const href = `https://cm-${marketplace}${stageSuffix}.flex-routing.last-mile.amazon.dev/assignment-service-areas/${scope}`;

    return (
      <Button iconAlign="right" iconName="external" target="_blank" href={href}>
        {this.props.bundle.getMessage('ASSIGNMENT_PLAN_DETAILS:UFRAA_CONFIGS_REDIRECT')}
      </Button>
    );
  }

  private renderAssignmentConfig() {
    if (this.props.plannerInput) {
      return (
        <Container
          header={
            <Header variant="h2" actions={this.renderConfigExternalLink(this.props.plannerInput.serviceArea.serviceAreaId)}>
              {this.props.bundle.getMessage('ASSIGNMENT_PLAN_DETAILS:ASSIGNMENT_CONFIG')}
            </Header>
          }
        >
          <JSONViewer data={this.props.plannerInput.assignmentConfig} />
        </Container>
      );
    } else if (this.props.plannerInput === null) {
      return this.renderPlanNotFound(`${this.state.prefixHeader}${this.props.bundle.getMessage('ASSIGNMENT_PLAN_DETAILS:ASSIGNMENT_CONFIG')}`);
    } else {
      return this.renderLoading(`${this.state.prefixHeader}${this.props.bundle.getMessage('ASSIGNMENT_PLAN_DETAILS:ASSIGNMENT_CONFIG')}`);
    }
  }

  private renderFungibleAssignmentStatus() {
    return (
      <AssignmentStatus<FungibleAssignment>
        bundle={this.props.bundle}
        plannerInput={this.props.plannerInput!}
        plan={this.props.plan!}
        algorithm={'FUNGIBLE_ASSIGNMENT_ALGORITHM'}
        assignments={(this.state.filteredAssignments ?? []) as FungibleAssignment[]}
        textFiltering={{
          filteringText: this.state.filteringText ? this.state.filteringText : '',
          updateFilterText: (text: string) => {
            this.setState({ filteringText: text });
          },
          runFilter: (text: string) => {
            const filteredAssignments = this.state.assignments ? fungibleFilter(text, this.state.assignments as FungibleAssignment[]) : [];
            this.setState({
              filteredAssignments: filteredAssignments,
              filteringText: text,
            });
            this.props.onFilteringTextChange(text);
          },
        }}
        assignmentTableDefinitionFactory={buildFungibleTableDefinition}
        onRouteClick={this.props.onRouteClick}
      />
    );
  }

  private renderAmzlAssignmentStatus() {
    const csvExporter = new AmzlAssignmentCsvExporter((this.state.filteredAssignments ?? []) as FdpAssignment[]);
    return (
      <React.Fragment>
        <AssignmentStatus<FdpAssignment>
          bundle={this.props.bundle}
          plannerInput={this.props.plannerInput!}
          plan={this.props.plan!}
          algorithm={'FLEX_DISPATCH_PLANNER'}
          assignments={(this.state.filteredAssignments ?? []) as FdpAssignment[]}
          textFiltering={{
            filteringText: this.state.filteringText ? this.state.filteringText : '',
            updateFilterText: (text: string) => {
              this.setState({ filteringText: text });
            },
            runFilter: (text: string) => {
              let filteredAssignments = this.state.assignments ? amzlFilter(text, this.state.assignments as FdpAssignment[]) : [];
              this.setState({
                filteredAssignments: filteredAssignments,
                filteringText: text,
              });
              this.props.onFilteringTextChange(text);
            },
          }}
          assignmentTableDefinitionFactory={buildAmzlTableDefinition}
          onRouteClick={this.props.onRouteClick}
          buildAutoAssignCsv={(hiddenColumnIds: ReadonlyArray<string>, cycleName?: string, bundle?: MessageBundle) => csvExporter.export(hiddenColumnIds, cycleName, bundle)}
          cycles={getCyclesFromAssignments((this.state.filteredAssignments ?? []) as FdpAssignment[])}
        />
      </React.Fragment>
    );
  }

  private renderFdpAssignmentStatus() {
    return (
      <AssignmentStatus<FdpAssignment>
        bundle={this.props.bundle}
        plannerInput={this.props.plannerInput!}
        plan={this.props.plan!}
        algorithm={'FLEX_DISPATCH_PLANNER'}
        assignments={(this.state.filteredAssignments ?? []) as FdpAssignment[]}
        textFiltering={{
          filteringText: this.state.filteringText ? this.state.filteringText : '',
          updateFilterText: (text: string) => {
            this.setState({ filteringText: text });
          },
          runFilter: (text: string) => {
            const filteredAssignments = this.state.assignments ? fdpFilter(text, this.state.assignments as FdpAssignment[]) : [];
            this.setState({
              filteredAssignments: filteredAssignments,
              filteringText: text,
            });
            this.props.onFilteringTextChange(text);
          },
        }}
        // todo: implement the advanced filtering function.
        // advancedFiltering={{
        //   initialQuery: { tokens: [], operation: 'and' },
        //   filteringProperties: FDP_FILTERING_PROPERTIES,
        //   runQuery: fdpQuery,
        // }}
        assignmentTableDefinitionFactory={buildFdpTableDefinition}
        onRouteClick={this.props.onRouteClick}
      />
    );
  }

  private renderAssignmentStatus() {
    if (this.props.plannerInput && this.props.plan) {
      const algorithm = getAssignmentAlgorithm(this.props.plannerInput);
      const operatingEntity = getOperatingEntity(this.props.plannerInput);
      const shouldRenderFungibleAssignmentStatus =
        algorithm === 'FUNGIBLE_ASSIGNMENT_ALGORITHM' ||
        this.props.plannerInput.assignmentConfig.fastSsdMigrationToFdpEnabled ||
        this.props.plannerInput.assignmentConfig.enableTransporterRollupForFdp;

      if (shouldRenderFungibleAssignmentStatus) {
        return this.renderFungibleAssignmentStatus();
      } else if (algorithm === 'FLEX_DISPATCH_PLANNER' && operatingEntity === 'AMZL OPS') {
        return this.renderAmzlAssignmentStatus();
      } else if (algorithm === 'FLEX_DISPATCH_PLANNER') {
        return this.renderFdpAssignmentStatus();
      } else {
        return (
          <Container header={<Header variant="h2">Unsupported Assignment Algorithm</Header>}>
            <Box textAlign="center">
              <StatusIndicator type="info">{this.props.plannerInput?.assignmentConfig.dispatchPlannerType} visualization is not supported yet.</StatusIndicator>
            </Box>
          </Container>
        );
      }
    } else if (this.props.plannerInput === null || this.props.plan === null) {
      return this.renderPlanNotFound(this.props.bundle.getMessage('ASSIGNMENT_PLAN_DETAILS:ASSIGNMENT_STATUS'));
    } else {
      return this.renderLoading(this.props.bundle.getMessage('ASSIGNMENT_PLAN_DETAILS:ASSIGNMENT_STATUS'));
    }
  }

  render() {
    // tabs
    const validTabs: string[] = ['assignment-status', 'assignment-config', 'user-notes'];
    let activeTabId = validTabs[0];
    if (validTabs.find((tab) => tab === this.props.activeTab)) {
      activeTabId = this.props.activeTab!;
    }
    return (
      <Tabs
        activeTabId={activeTabId}
        onChange={(evt) => {
          this.props.onTabChange(evt.detail.activeTabId);
        }}
        tabs={[
          {
            label: this.props.bundle.getMessage('ASSIGNMENT_PLAN_DETAILS:ASSIGNMENT_STATUS'),
            id: 'assignment-status',
            content: this.renderAssignmentStatus(),
          },
          {
            label: this.props.bundle.getMessage('ASSIGNMENT_PLAN_DETAILS:ASSIGNMENT_CONFIG'),
            id: 'assignment-config',
            content: this.renderAssignmentConfig(),
          },
          {
            label: this.props.bundle.getMessage('ASSIGNMENT_PLAN_DETAILS:USER_NOTES'),
            id: 'user-notes',
            content: this.renderUserNotes(),
          },
        ]}
      />
    );
  }

  private renderUserNotes() {
    return (
      <UserNotes
        userGeneratedData={this.state.userGeneratedData}
        onUserGeneratedReset={() => {
          this.setState({ userGeneratedData: DEFAULT_USER_GENERATED_DATA });
          preferencesStore.set(ASSIGNMENT_PLAN_USER_GENERATED_DATA, DEFAULT_USER_GENERATED_DATA);
          if (this.props.plannerInput && this.props.plan) {
            this.refreshAssignmentsState(this.props.plannerInput, this.props.plan, {
              userGeneratedData: DEFAULT_USER_GENERATED_DATA,
            });
          }
        }}
        onUserGeneratedUpdate={(data) => {
          this.setState({ userGeneratedData: data });
          preferencesStore.set(ASSIGNMENT_PLAN_USER_GENERATED_DATA, data);
          if (this.props.plannerInput && this.props.plan) {
            this.refreshAssignmentsState(this.props.plannerInput, this.props.plan, {
              userGeneratedData: data,
            });
          }
        }}
      />
    );
  }
}

export default withBundle('AssignmentPlanDetails')(AssignmentPlanDetails);
