import React from 'react';
import Header from '@amzn/awsui-components-react/polaris/header';
import Table from '@amzn/awsui-components-react/polaris/table';
import Hotspot from '@amzn/awsui-components-react/polaris/hotspot';
import { RouteChangeLog, RsDepartureSet } from '../../clients';
import { buildTimelineTableDefinition } from './timeline-table-definition';
import Button from '@amzn/awsui-components-react/polaris/button';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import lodash from 'lodash';
import { download } from './utils';
import { RouteDepartureSetModal } from './route-departure-set-modal';
import { RouteTimelinePreferencesModal, RouteTimelineSettings } from './route-timeline-preferences';
import { preferencesStore } from '../../utilities';

export interface RouteTimelineProps {
  readonly routeId: string;
  readonly version?: number;
  readonly timeline: ReadonlyArray<RouteChangeLog>;
  readonly onVersionClick: (version: number) => void;
  readonly onSequenceClick: (version: number) => void;
}

interface RouteTimelineState {
  readonly currentPage: number;
  readonly sortedTimeline: RouteChangeLog[];
  readonly selectedDepartureSetVersion?: number;
  readonly selectedDepartureSet?: RsDepartureSet;
  readonly settings: RouteTimelineSettings;
  readonly visiblePreferences: boolean;
}

const INITIAL_PAGE = 1;
const RouteTimelineSettingsStoreKey = 'RouteTimelineSettingsStoreKey';
const DEFAULT_ROUTE_TIMELINE_SETTINGS: RouteTimelineSettings = {
  hiddenColumnIds: [],
  timeFormat: 'HH:mm:ss z',
  eventsPerPage: 10,
};

export class RouteTimeline extends React.Component<RouteTimelineProps, RouteTimelineState> {
  constructor(props: RouteTimelineProps) {
    super(props);
    const timeline = Array.from(this.props.timeline);
    timeline.sort((t1, t2) => t2.version - t1.version);

    // The settings properties is stored in browser, to keep backward compatibility when we add new settings property, we should declare the
    // settings read from browser with Partial.
    const settings = preferencesStore.get<Partial<RouteTimelineSettings>>(RouteTimelineSettingsStoreKey, DEFAULT_ROUTE_TIMELINE_SETTINGS);

    this.state = {
      currentPage: INITIAL_PAGE,
      sortedTimeline: timeline,
      settings: {
        ...DEFAULT_ROUTE_TIMELINE_SETTINGS,
        ...settings,
      },
      visiblePreferences: false,
    };
  }

  componentDidMount() {
    if (typeof this.props.version === 'number') {
      this.setState({
        currentPage: this.findVersionPage(this.state.sortedTimeline, this.props.version),
      });
    }
  }

  componentDidUpdate(prevProps: RouteTimelineProps) {
    if (prevProps.routeId !== this.props.routeId || prevProps.timeline.length !== this.props.timeline.length || prevProps.version !== this.props.version) {
      // timeline data change, need to refresh states.
      const timeline = Array.from(this.props.timeline);
      timeline.sort((t1, t2) => t2.version - t1.version);
      const initalPage = typeof this.props.version === 'number' ? this.findVersionPage(timeline, this.props.version) : INITIAL_PAGE;
      this.setState({
        currentPage: initalPage,
        sortedTimeline: timeline,
      });
    }
  }

  render() {
    const resequenceUpdates = this.state.sortedTimeline.filter((change) => change.routeUpdate.updateType.updateType === 'RESEQUENCED' || change.routeUpdate.updateType.updateType === 'SEQUENCED');
    const timelineInDisplay = this.state.sortedTimeline.slice((this.state.currentPage - 1) * this.state.settings.eventsPerPage, this.state.currentPage * this.state.settings.eventsPerPage);
    // todo: support filtering
    return (
      <React.Fragment>
        {this.renderRouteDepatureSet(resequenceUpdates)}
        {this.renderRouteTimelinePreferences()}
        <Table<RouteChangeLog>
          header={
            <Hotspot side="left" hotspotId="route-timeline">
              {this.header()}
            </Hotspot>
          }
          resizableColumns={true}
          columnDefinitions={buildTimelineTableDefinition({
            hiddenColumnIds: this.state.settings.hiddenColumnIds,
            targetVersion: this.props.version,
            onVersionClick: (version) => this.props.onVersionClick(version),
            onDepartureSetClick: (version, departureSet) =>
              this.setState({
                selectedDepartureSetVersion: version,
                selectedDepartureSet: departureSet,
              }),
            timeformat: this.state.settings.timeFormat,
          })}
          items={timelineInDisplay}
          stickyHeader={false}
          pagination={this.pagination()}
          preferences={
            <Hotspot side="left" hotspotId="timeline-customization">
              <Button iconName="settings" variant="icon" onClick={() => this.setState({ visiblePreferences: true })} />
            </Hotspot>
          }
        />
      </React.Fragment>
    );
  }

  private renderRouteDepatureSet(resequenceUpdates: ReadonlyArray<RouteChangeLog>) {
    if (this.state.selectedDepartureSet !== undefined && typeof this.state.selectedDepartureSetVersion === 'number') {
      return (
        <RouteDepartureSetModal
          routeVersion={this.state.selectedDepartureSetVersion}
          resequenceUpdates={resequenceUpdates}
          timeFormat={this.state.settings.timeFormat}
          onClose={() =>
            this.setState({
              selectedDepartureSetVersion: undefined,
              selectedDepartureSet: undefined,
            })
          }
          departureSet={this.state.selectedDepartureSet}
        />
      );
    }
  }

  private renderRouteTimelinePreferences() {
    if (this.state.visiblePreferences) {
      return (
        <RouteTimelinePreferencesModal
          settings={this.state.settings}
          visible={this.state.visiblePreferences}
          onCancel={() => this.setState({ visiblePreferences: false })}
          onSave={(settings) => {
            let currentPage = this.state.currentPage;
            if (this.state.settings.eventsPerPage !== settings.eventsPerPage) {
              // if events per page changes, we should reset the pagination to show the first page.
              currentPage = INITIAL_PAGE;
            }
            this.setState({
              settings: settings,
              currentPage: currentPage,
              visiblePreferences: false,
            });
            preferencesStore.set<RouteTimelineSettings>(RouteTimelineSettingsStoreKey, settings);
          }}
        />
      );
    }
  }

  private header() {
    return (
      <Header
        variant="h2"
        actions={
          <Hotspot side="left" hotspotId="timeline-download-btn">
            <Button
              onClick={(evt) => {
                download(this.props.timeline, `${this.props.routeId}-timeline.json`);
              }}
            >
              Download
            </Button>
          </Hotspot>
        }
      >
        Timeline
      </Header>
    );
  }

  private pagination() {
    return (
      <Pagination
        ariaLabels={{
          nextPageLabel: 'Next page',
          previousPageLabel: 'Previous page',
          pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
        }}
        currentPageIndex={this.state.currentPage}
        onChange={({ detail }) => this.setState({ currentPage: detail.currentPageIndex })}
        pagesCount={Math.ceil(this.state.sortedTimeline.length / this.state.settings.eventsPerPage)}
      />
    );
  }

  private findVersionPage(timeline: RouteChangeLog[], version: number): number {
    const versionPages = lodash.chunk(timeline, this.state.settings.eventsPerPage).map((partialTimeline) => partialTimeline.map((t) => t.version));
    for (let i = 0; i < versionPages.length; i++) {
      if (versionPages[i].includes(version)) {
        return i + 1;
      }
    }
    return INITIAL_PAGE;
  }
}
