import {
  FlexRouteAssignmentPlan,
  FlexRouteAssignmentPlannerInput,
  Transporter,
  Route,
  FdpAssignment,
  UnAssignedTransporterReason,
  UnAssignedRouteReason,
  DispatchPlannerType,
  FdpAssignmentStatus,
  CostBreakDownMatrix,
} from '../../../clients';
import { RouteNote, TransporterNote } from '../models';
import { convertActions } from '../route-details';
import { AssignmentConverter } from './assignment-converter';

export interface FdpAmzlAssignmentConverterProps {
  readonly transporterIdToTransporterNotes: ReadonlyMap<string, TransporterNote>;
  readonly routeNameToRouteNotes: ReadonlyMap<string, RouteNote>;
}
export class FdpAmzlAssignmentConverter implements AssignmentConverter<FdpAssignment> {
  readonly type: DispatchPlannerType;

  private readonly serviceTypeIdToNurseryLabel: ReadonlyMap<string, string>;
  private readonly transporterIdToTransporterNotes: ReadonlyMap<string, TransporterNote>;
  private readonly routeNameToRouteNotes: ReadonlyMap<string, RouteNote>;
  private readonly cycleIdToCycleName: Map<string, string>;
  private readonly reservationIdToBlockTransporter: Map<string, Transporter>;
  private readonly transporterIdToIOTransporter: Map<string, Transporter>;
  private readonly transporterIdToOverflowTransporter: Map<string, Transporter>;
  private readonly routeIdToPlannedReservationId: Map<string, string>;
  private readonly routeIdToOriginalRoute: Map<string, Route>;
  private readonly routeIdToTransporterIdAffinity: Map<string, Map<string, number>>;

  constructor(props: FdpAmzlAssignmentConverterProps) {
    this.type = 'FLEX_DISPATCH_PLANNER';
    const serviceTypeIdToNurseryLabel = new Map();
    // JP flex service types https://logistics.amazon.co.jp/flex/operations/serviceTypeManagementConsole
    serviceTypeIdToNurseryLabel.set('amzn1.flex.st.v1.03PSJCClRY+x4gFtGz49hg', 'AmFlex Kei Van');
    serviceTypeIdToNurseryLabel.set('amzn1.flex.st.v1.KCGOcbCnT7uAv0nyJjygsw', 'AmFlex Kei Van Nursery Route Level 0');
    serviceTypeIdToNurseryLabel.set('amzn1.flex.st.v1.mJFcQbQKTFmKQULe9n+1uw', 'AmFlex Kei Van Nursery Route Level 1');
    serviceTypeIdToNurseryLabel.set('amzn1.flex.st.v1.u8GP8tLRTeanlucUGT0hrA', 'AmFlex Kei Van Nursery Route Level 2');
    serviceTypeIdToNurseryLabel.set('amzn1.flex.st.v1.cOt-Jc6EQiCc4zODUkCSTw', 'AmFlex Kei Van (Recovery)');
    serviceTypeIdToNurseryLabel.set('amzn1.flex.st.v1.kG+7t+8rTAO5n8vsBfl5jg', 'AmFlex Kei Van (S2)');
    serviceTypeIdToNurseryLabel.set('amzn1.flex.st.v1.tCGY1algThGKLIrGe+2pcw', 'AmFlex Kei Van (Zoned)');

    serviceTypeIdToNurseryLabel.set('amzn1.flex.st.v1.SG4uVh-hT9eSOUSnGmXinQ', 'AmFlex Kei Car Lrg');
    serviceTypeIdToNurseryLabel.set('amzn1.flex.st.v1.-sJyNkDzRoydq53l8JS03Q', 'AmFlex Kei Car Lrg Nursery Route Level 1');
    serviceTypeIdToNurseryLabel.set('amzn1.flex.st.v1.QSKKktAGTjeBwi7+kYI6hA', 'AmFlex Kei Car Lrg Nursery Route Level 2');

    serviceTypeIdToNurseryLabel.set('amzn1.flex.st.v1.10vIfdfPRqej-G6OZevqow', 'AmFlex Kei Car Sml');
    serviceTypeIdToNurseryLabel.set('amzn1.flex.st.v1.kXk4wOkKRBiCYEPFZDBLwQ', 'AmFlex Kei Car Sml Nursery Route Level 1');
    serviceTypeIdToNurseryLabel.set('amzn1.flex.st.v1.PszrbDX2TTOeXO48GR7wRA', 'AmFlex Kei Car Sml Nursery Route Level 2');

    serviceTypeIdToNurseryLabel.set('amzn1.flex.st.v1.hIOvYwE6RwyL72j5TrsKfQ', 'Standard Parcel - 2 Ton Van (Milkrun)');

    // NA flex service types https://logistics.amazon.com/flex/operations/serviceTypeManagementConsole
    serviceTypeIdToNurseryLabel.set('amzn1.flex.st.v1.PuyOplzlR1idvfPkv5138g', 'AmFlex Vehicle');

    this.serviceTypeIdToNurseryLabel = serviceTypeIdToNurseryLabel;

    this.transporterIdToTransporterNotes = props.transporterIdToTransporterNotes;
    this.routeNameToRouteNotes = props.routeNameToRouteNotes;

    this.cycleIdToCycleName = new Map();
    this.reservationIdToBlockTransporter = new Map();
    this.transporterIdToIOTransporter = new Map();
    this.transporterIdToOverflowTransporter = new Map();
    this.routeIdToPlannedReservationId = new Map();
    this.routeIdToOriginalRoute = new Map();
    this.routeIdToTransporterIdAffinity = new Map();
  }

  convert(plannerInput: FlexRouteAssignmentPlannerInput, plan: FlexRouteAssignmentPlan): FdpAssignment[] {
    // build a transporterId to transporter mapping
    plannerInput.transporters.forEach((transporter) => this.processTransporter(transporter));

    if (plannerInput.preProcessingFilteredOutTransporters) {
      // include cycle data from filtered out transporters
      Object.values(plannerInput.preProcessingFilteredOutTransporters).forEach((transporters) => transporters?.forEach((transporter) => this.processTransporter(transporter)));
    }

    /**
     * FDP will mutate the route start times and persist the modified routes in plan.
     * But AMZL business wants to see the original routing system planned routes.
     */
    plannerInput.routes.forEach((route) => this.routeIdToOriginalRoute.set(route.routeId, route));
    if (plannerInput.preProcessingFilteredOutRoutes) {
      Object.values(plannerInput.preProcessingFilteredOutRoutes).forEach((routes) => routes?.forEach((route) => this.routeIdToOriginalRoute.set(route.routeId, route)));
    }

    if (plan.routeIdToProviderReservationIdMap) {
      Object.entries(plan.routeIdToProviderReservationIdMap).forEach((entry) => {
        this.routeIdToPlannedReservationId.set(entry[0], entry[1]);
      });
    }

    this.processCostBreakDownMatrix(plannerInput.costBreakDownMatrix);

    let assignments: FdpAssignment[] = [];
    // convert fungible assignments
    assignments = assignments.concat(this.convertBlockAssignment(plan.fungibleBlockAssignments));

    // convert unassigned route to internal assignment mode, the transporterGroup is undefined in this case.
    if (plan.unAssignedRoutes) {
      for (let unassignedRoute of plan.unAssignedRoutes) {
        assignments.push(this.convertNoAssignmentRoute(unassignedRoute.route, unassignedRoute.reason));
      }
    }

    // add fitlered out assignments from planner input to assignment results
    if (plannerInput.preProcessingFilteredOutRoutes?.ASSIGNED && plannerInput.preProcessingFilteredOutTransporters?.ASSIGNED) {
      const routes = plannerInput.preProcessingFilteredOutRoutes?.ASSIGNED;
      const filteredTransporterIdToTransporterMap: Map<string, Transporter> = new Map();
      for (let transporter of plannerInput.preProcessingFilteredOutTransporters.ASSIGNED) {
        filteredTransporterIdToTransporterMap.set(transporter.transporterId, transporter);
      }
      assignments = assignments.concat(this.convertFilteredOutAssignedRoutes(filteredTransporterIdToTransporterMap, routes));
    }

    // add transportersToBeReleased to the assignment result
    if (plan.transportersToBeReleased) {
      assignments = assignments.concat(this.convertNoAssignmentTransporters(plan.transportersToBeReleased, 'Discharged'));
    }

    // add transportersWithAutoAssignmentNotEnabled to the assignment result
    if (plan.transportersWithAutoAssignmentNotEnabled) {
      assignments = assignments.concat(this.convertNoAssignmentTransporters(plan.transportersWithAutoAssignmentNotEnabled, 'AutoAssignDisabled'));
    }

    // add transportersWithoutAssignment to the assignment result
    if (plan.transportersWithoutAssignment) {
      assignments = assignments.concat(this.convertNoAssignmentTransporters(plan.transportersWithoutAssignment, 'No Assignment'));
    }

    return assignments;
  }

  private processCostBreakDownMatrix(costBreakDownMatrix?: CostBreakDownMatrix) {
    if (costBreakDownMatrix && costBreakDownMatrix.routeIds && costBreakDownMatrix.transporterIds && costBreakDownMatrix.costBreakdownMatrix?.HistoricalAffinity) {
      costBreakDownMatrix.routeIds?.forEach((routeId, routeIndex) => {
        let routeAffinity = new Map();
        costBreakDownMatrix?.transporterIds?.forEach((transporterId, transporterIndex) => {
          const affinityCost = costBreakDownMatrix?.costBreakdownMatrix?.HistoricalAffinity?.[routeIndex][transporterIndex];
          if (typeof affinityCost === 'number') {
            routeAffinity.set(transporterId, affinityCost);
          }
          this.routeIdToTransporterIdAffinity.set(routeId, routeAffinity);
        });
      });
    }
  }

  private processTransporter(transporter: Transporter) {
    const cycleId = transporter.cycle?.cycleId;
    const cycleName = transporter.cycle?.cycleName;
    if (typeof cycleId === 'string' && typeof cycleName === 'string') {
      this.cycleIdToCycleName.set(cycleId, cycleName);
    }

    if (transporter.type === 'BLOCK') {
      if (typeof transporter.providerReservationId !== 'string') {
        console.error(`Block transporter ${transporter.transporterId} doesn't have a reservationId`);
        return;
      }
      this.reservationIdToBlockTransporter.set(transporter.providerReservationId, transporter);
    } else if (transporter.type === 'IO') {
      this.transporterIdToIOTransporter.set(transporter.transporterId, transporter);
    } else if (transporter.type === 'OVERFLOW') {
      this.transporterIdToOverflowTransporter.set(transporter.transporterId, transporter);
    } else {
      console.warn(`Unknown transporter type ${transporter.type}, transporter id ${transporter.transporterId}`);
    }
  }

  private getAffinityScore(routeId: string, transporterId: string): number | undefined {
    const affinityCost = this.routeIdToTransporterIdAffinity.get(routeId)?.get(transporterId);
    if (typeof affinityCost === 'number') {
      return 1 - affinityCost;
    }
  }

  private getAssignmentStatus(route: Route): FdpAssignmentStatus {
    if (route.statuses?.includes('TRANSPORTER_WORK_ASSIGNED')) {
      return 'Assigned';
    } else if (route.statuses?.includes('TRANSPORTER_ACCEPTED') || route.statuses?.includes('TRANSPORTER_OFFER_ACCEPTED')) {
      return 'Accepted';
    } else if (route.statuses?.includes('TRANSPORTER_ACCEPT_PENDING') || route.statuses?.includes('TRANSPORTER_OFFER_PENDING')) {
      return 'Offered';
    }
    return 'Planned';
  }

  private getNurseryLevel(serviceTypeId?: string | null) {
    if (typeof serviceTypeId === 'string') {
      return this.serviceTypeIdToNurseryLabel.get(serviceTypeId) ?? serviceTypeId;
    }
  }

  private getPackageCount(route: Route): number {
    let count = 0;
    if (route.actions) {
      const collapsedAddActions = convertActions(route.actions).filter((action) => action.type === 'ADD');
      count = collapsedAddActions.reduce((count, current) => count + current.trs.length, 0);
    }
    return count;
  }

  /**
   * Convert FDP block assignment to the UI assignment model.
   * @param blockAssignment FDP block assignment, should contain one a single route.
   * @param transporterIdToTransporter
   */
  private convertBlockAssignment(blockAssignment: Record<string, Route[]>): FdpAssignment[] {
    const transporterIds = Object.keys(blockAssignment);

    const assignments: FdpAssignment[] = [];
    for (let transporterId of transporterIds) {
      if (blockAssignment[transporterId]?.length === 1) {
        const route = blockAssignment[transporterId][0];
        const routeStartTime = this.routeIdToOriginalRoute.get(route.routeId)?.actions?.find((action) => action.type === 'START')?.startTime;
        const rejectedTransporterIds = route.rejectedTransporterIds;
        const providerReservationId = this.routeIdToPlannedReservationId.get(route.routeId);
        const transporter = typeof providerReservationId === 'string' ? this.reservationIdToBlockTransporter.get(providerReservationId) : undefined;
        const routeNote = typeof route.routeName === 'string' ? this.routeNameToRouteNotes.get(route.routeName) : undefined;
        const transporterNote = transporter ? this.transporterIdToTransporterNotes.get(transporter.transporterId) : undefined;
        const packageCount = this.getPackageCount(route);
        assignments.push({
          route: route,
          routeExtras: {
            cycleName: typeof route.cycleId === 'string' ? this.cycleIdToCycleName.get(route.cycleId) : undefined,
            nurseryLevel: this.getNurseryLevel(route.serviceTypeId),
            routeUserNotes: routeNote?.notes,
            routeStartTime: routeStartTime,
            packageCount: packageCount,
          },
          transporter: transporter,
          transporterExtras: {
            nurseryLevel: this.getNurseryLevel(transporter?.serviceTypeId),
            transporterName: transporterNote?.name,
            transporterUserNotes: transporterNote?.notes,
            transporterDuration: this.getBlockDuration(transporter?.originalShiftStartTime, transporter?.originalShiftEndTime),
            adjustedTransporterDuration: this.getBlockDuration(transporter?.shiftStartTime, transporter?.shiftEndTime),
          },
          assignmentStatus: this.getAssignmentStatus(route),
          rejectedTransporterIds: rejectedTransporterIds ? Array.from(rejectedTransporterIds) : [],
          assignmentExtras: {
            affinityScore: this.getAffinityScore(route.routeId, transporterId),
          },
        });
      } else {
        throw new Error(`Invalid FDP block assignment on transporter ${transporterId}, it was planned ${blockAssignment[transporterId]?.length} routes but expected one.`);
      }
    }
    return assignments;
  }

  private convertFilteredOutAssignedRoutes(transporterIdToTransporter: Map<string, Transporter>, routes: Route[]): FdpAssignment[] {
    const assignments: FdpAssignment[] = [];
    for (let route of routes) {
      const rejectedTransporterIds = route.rejectedTransporterIds;
      const transporter = typeof route.assignedTransporter === 'string' ? transporterIdToTransporter.get(route.assignedTransporter) : undefined;
      const routeNote = typeof route.routeName === 'string' ? this.routeNameToRouteNotes.get(route.routeName) : undefined;
      const transporterNote = transporter ? this.transporterIdToTransporterNotes.get(transporter.transporterId) : undefined;
      const assignmentStatus = this.getAssignmentStatus(route);
      const routeStartTime = this.routeIdToOriginalRoute.get(route.routeId)?.actions?.find((action) => action.type === 'START')?.startTime;
      const packageCount = this.getPackageCount(route);
      assignments.push({
        route: route,
        routeExtras: {
          cycleName: typeof route.cycleId === 'string' ? this.cycleIdToCycleName.get(route.cycleId) : undefined,
          nurseryLevel: this.getNurseryLevel(route.serviceTypeId),
          routeUserNotes: routeNote?.notes,
          routeStartTime: routeStartTime,
          packageCount: packageCount,
        },
        transporter: transporter,
        transporterExtras: {
          nurseryLevel: this.getNurseryLevel(transporter?.serviceTypeId),
          transporterName: transporterNote?.name,
          transporterUserNotes: transporterNote?.notes,
          transporterDuration: this.getBlockDuration(transporter?.originalShiftStartTime, transporter?.originalShiftEndTime),
          adjustedTransporterDuration: this.getBlockDuration(transporter?.shiftStartTime, transporter?.shiftEndTime),
        },
        assignmentStatus: assignmentStatus,
        rejectedTransporterIds: rejectedTransporterIds ? Array.from(rejectedTransporterIds) : [],
      });
    }

    return assignments;
  }

  /**
   * Convert an array of transporters without assignment information to the UI assignment model along with a reason code.
   * @param transporters an array of transporters who don't have assignment information.
   * @param reason A reason code.
   */
  private convertNoAssignmentTransporters(transporters: Transporter[], reason: UnAssignedTransporterReason): FdpAssignment[] {
    const assignments: FdpAssignment[] = [];
    for (let transporter of transporters) {
      const transporterNote = this.transporterIdToTransporterNotes.get(transporter.transporterId);
      assignments.push({
        transporter: transporter,
        transporterExtras: {
          nurseryLevel: this.getNurseryLevel(transporter.serviceTypeId),
          transporterName: transporterNote?.name,
          transporterUserNotes: transporterNote?.notes,
          transporterDuration: this.getBlockDuration(transporter?.originalShiftStartTime, transporter?.originalShiftEndTime),
          adjustedTransporterDuration: this.getBlockDuration(transporter?.shiftStartTime, transporter?.shiftEndTime),
        },
        transporterUnAssignedReason: reason,
      });
    }
    return assignments;
  }

  /**
   * Convert a route without assignment information to the UI assignment model along with a reason code.
   * @param route the route without assignment information
   * @param reason the reason code.
   * @returns
   */
  private convertNoAssignmentRoute(route: Route, reason: UnAssignedRouteReason): FdpAssignment {
    let assignmentStatus: FdpAssignmentStatus = 'Unknown';
    switch (reason) {
      case 'Infeasible':
        assignmentStatus = 'Infeasible';
        break;
      default:
        assignmentStatus = 'Unknown';
    }
    const routeStartTime = this.routeIdToOriginalRoute.get(route.routeId)?.actions?.find((action) => action.type === 'START')?.startTime;
    const routeNote = typeof route.routeName === 'string' ? this.routeNameToRouteNotes.get(route.routeName) : undefined;
    const packageCount = this.getPackageCount(route);

    return {
      route,
      routeExtras: {
        cycleName: typeof route.cycleId === 'string' ? this.cycleIdToCycleName.get(route.cycleId) : undefined,
        nurseryLevel: this.getNurseryLevel(route.serviceTypeId),
        routeUserNotes: routeNote?.notes,
        routeStartTime: routeStartTime,
        packageCount: packageCount,
      },
      routeUnAssignedReason: reason,
      assignmentStatus: assignmentStatus,
    };
  }

  /**
   * Gets block duration in seconds from a given start and end time
   * @param startTime
   * @param endTime
   */
  private getBlockDuration(startTime?: string | null, endTime?: string | null) {
    if (startTime && endTime) {
      const blockLength = new Date(endTime).valueOf() - new Date(startTime).valueOf(); // Date.valueOf() is in milliseconds
      return blockLength / 1000;
    }
  }
}
