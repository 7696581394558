import { RouteNote, TransporterNote } from '../models';
import { parse } from 'csv-parse';
import { CSV_PARSER_OPTIONS, ROUTE_NAME, ROUTE_NOTES, TRANSPORTER_ID, TRANSPORTER_NAME, TRANSPORTER_NOTES } from '../constants';

async function parseCsv(text: string): Promise<any[]> {
  text = text.replaceAll('、', ',');
  const promise = new Promise<any[]>((resolve, reject) => {
    parse(text, CSV_PARSER_OPTIONS, (err, records) => {
      if (err) {
        reject(err);
      } else {
        resolve(records);
      }
    });
  });

  return promise;
}

export async function parseTransporterNotes(notes: string): Promise<Map<string, TransporterNote>> {
  const results: Map<string, TransporterNote> = new Map();
  let parsedData;
  try {
    parsedData = await parseCsv(notes);
  } catch (err) {
    throw new Error('transporter notes string is not in a valid csv format.');
  }
  parsedData?.forEach((row: any, index: number) => {
    if (typeof row === 'object' && typeof row[TRANSPORTER_ID] === 'string' && row[TRANSPORTER_ID].trim().length > 0) {
      results.set(row[TRANSPORTER_ID].trim(), {
        id: row[TRANSPORTER_ID].trim(),
        name: row[TRANSPORTER_NAME],
        notes: row[TRANSPORTER_NOTES],
      });
    } else {
      throw new Error(`Row ${index + 1} doesn't have a valid ${TRANSPORTER_ID}.`);
    }
  });

  return results;
}

export async function parseRouteNotes(notes: string): Promise<Map<string, RouteNote>> {
  const results: Map<string, RouteNote> = new Map();
  let parsedData;
  try {
    parsedData = await parseCsv(notes);
  } catch (err) {
    throw new Error('route notes string is not in a valid csv format.');
  }
  parsedData?.map((row: any, index: number) => {
    if (typeof row === 'object' && typeof row[ROUTE_NAME] === 'string' && row[ROUTE_NAME].trim().length > 0) {
      results.set(row[ROUTE_NAME].trim(), {
        name: row[ROUTE_NAME].trim(),
        notes: row[ROUTE_NOTES],
      });
    } else {
      throw new Error(`Row ${index + 1} doesn't have a valid ${ROUTE_NAME}.`);
    }
  });

  return results;
}
