import React from 'react';

import { ArtifactMetadata } from '../../clients';
import { ActionInformation, ExecutionPlanInformation } from './models';
import { SelectionType, ValueWithLabel } from './utilities';

import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';

interface Props {
  readonly metadata: ReadonlyArray<ArtifactMetadata>;
  readonly artifacts: ReadonlyArray<ExecutionPlanInformation>;
  readonly selectorType?: SelectionType;
  readonly selectedOrder?: string;
  readonly selectedTr?: string;
  readonly setTrCallback: (tr: string) => void;
  readonly setOrderCallback: (tr: string) => void;
}

interface State {}

export default class SummaryBox extends React.Component<Props, State> {
  public render() {
    // TODO move to another file. use callbacks to update selection
    let orderId: string | undefined;
    let mostRecentRelevantInfo: ExecutionPlanInformation | undefined;
    if (this.props.selectorType === 'ORDER') {
      orderId = this.props.selectedOrder ?? 'Unknown Order';
      for (const artifact of this.props.artifacts) {
        if (artifact.orders.has(orderId)) {
          mostRecentRelevantInfo = artifact;
          break;
        }
      }
    } else if (this.props.selectorType === 'TR') {
      const trId = this.props.selectedTr ?? 'Unknown TR';
      for (const artifact of this.props.artifacts) {
        const candidateId = artifact.trs.get(trId)?.orderId;
        if (candidateId !== undefined) {
          orderId = candidateId;
          mostRecentRelevantInfo = artifact;
          break;
        }
      }
    }

    return (
      <Container header={<Header variant="h2">Details</Header>}>
        <ColumnLayout columns={2}>
          <SpaceBetween size="l">
            {this.renderArtifactsReferencedSummary()}
            {this.renderAssociatedOrderSummary(orderId)}
          </SpaceBetween>
          {this.renderAssociatedTrsSummary(orderId, mostRecentRelevantInfo)}
        </ColumnLayout>
      </Container>
    );
  }

  private renderArtifactsReferencedSummary() {
    const counts: Map<string, number> = new Map<string, number>();
    const artifactTypes: string[] = this.props.metadata.map((metadata) => metadata.artifactType);
    artifactTypes.forEach((type) => counts.set(type, (counts.get(type) ?? 0) + 1));
    const artifactRows = Array.from(counts.entries())
      .map((entry) => ({ artifactType: entry[0], count: entry[1] }))
      .sort((entry) => entry.count);

    return (
      <ValueWithLabel label="Artifacts Referenced">
        <SpaceBetween direction="vertical" size="xxs">
          {artifactRows.map((row) => (
            <SpaceBetween direction="horizontal" size="s">
              {row.count.toString().padStart(3, ' ')}
              {row.artifactType}
            </SpaceBetween>
          ))}
        </SpaceBetween>
      </ValueWithLabel>
    );
  }

  private renderAssociatedOrderSummary(orderId?: string) {
    if (orderId === undefined) {
      return undefined;
    }

    return (
      <ValueWithLabel label="Order ID">
        <SpaceBetween direction="horizontal" size="s">
          <Button iconName="search" variant="inline-icon" onClick={() => this.props.setOrderCallback(orderId)} />
          <Box>{orderId}</Box>
        </SpaceBetween>
      </ValueWithLabel>
    );
  }

  private renderAssociatedTrsSummary(orderId?: string, mostRecentRelevantInfo?: ExecutionPlanInformation) {
    if (orderId === undefined || mostRecentRelevantInfo === undefined) {
      return undefined;
    }

    let trIds = mostRecentRelevantInfo.orders.get(orderId)?.constituentTrs;
    let trArray = trIds === undefined ? [] : Array.from(trIds);

    return (
      <ValueWithLabel label="Related TRs">
        <SpaceBetween direction="vertical" size="xxxs">
          {trArray.map((trId) => {
            // let placeholder = false;  TODO pending execution snapshot
            // let locked = false;       TODO route plan ones aren't useful for lock status, pending assignment plan ingestion
            let unplannedReason: string | undefined = undefined;

            const actionIds: Array<string> = Array.from(mostRecentRelevantInfo.trs.get(trId)?.relatedActions || new Set());
            const isPlanned = (p: any): p is ActionInformation => !!p.duration;

            actionIds.forEach((actionId) => {
              const action = mostRecentRelevantInfo.actions.get(actionId);
              if (action === undefined) {
                return;
              }

              if (!isPlanned(action)) {
                unplannedReason = action.category;
                return;
              }
            });

            if (unplannedReason) {
              return <Box>{`${trId} Unplanned ${unplannedReason}`}</Box>;
            } else {
              return (
                <SpaceBetween direction="horizontal" size="s">
                  <Button iconName="search" variant="inline-icon" onClick={() => this.props.setTrCallback(trId)} />
                  <Box>{trId}</Box>
                </SpaceBetween>
              );
            }
          })}
        </SpaceBetween>
      </ValueWithLabel>
    );
  }
}
