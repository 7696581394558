import React from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './markdown.module.scss';

export interface MarkdownProps {
  readonly markdownText: string;
}
export default class Markdown extends React.Component<MarkdownProps> {
  render() {
    return <ReactMarkdown className={styles['markdown-body']} children={this.props.markdownText} escapeHtml={false} renderers={{}} />;
  }
}
