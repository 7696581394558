import React from 'react';
import { InternalActionType } from '../../clients';
import Box from '@amzn/awsui-components-react/polaris/box';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Header from '@amzn/awsui-components-react/polaris/header';

export interface OrderDetailsProps {
  readonly trIds: string[];
}

export class OrderDetails extends React.Component<OrderDetailsProps, {}> {
  // integrate with package snapshot so that we can display enhanced data, such as the orderId, package volume, etc.
  render() {
    return (
      <Box>
        {this.props.trIds.map((id, index) => (
          <Box key={index}>{id}</Box>
        ))}
      </Box>
    );
  }
}

export interface OrderDetailsModalProps extends OrderDetailsProps {
  readonly actionType: InternalActionType;
  readonly onClose: () => void;
}

export class OrderDetailsModal extends React.Component<OrderDetailsModalProps, {}> {
  render() {
    return (
      <Modal onDismiss={() => this.props.onClose()} header={<Header variant="h3">Tr ids in this {this.props.actionType}</Header>} visible={true} closeAriaLabel="Close modal" size="medium">
        <OrderDetails trIds={this.props.trIds} />
      </Modal>
    );
  }
}
