import React from 'react';
import { RouteSnapshot, LmRoute } from '../../clients';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Container from '@amzn/awsui-components-react/polaris/container';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Box from '@amzn/awsui-components-react/polaris/box';
import { timezoneManager } from '../../utilities';
import { getTimezone } from './helper';

interface Props {
  readonly routeSnapshotId: string;
  readonly routeSnapshot?: RouteSnapshot | null;
}

export class RouteSnapshotSummary extends React.Component<Props, {}> {
  renderHeader() {
    return (
      <Header variant="h2" description={this.props.routeSnapshotId}>
        Snapshot Summary
      </Header>
    );
  }

  renderRouteCounts(routes: LmRoute[]) {
    // todo: display route count in different status.
    return (
      <Box>
        <Box margin={{ bottom: 'xxxs' }} color="text-label">
          Route Counts
        </Box>
        <Box margin={{ bottom: 'xxxs' }}>
          <SpaceBetween direction="horizontal" size="s">
            <Box>
              <Box fontWeight="bold">Total</Box>
            </Box>
            <Box>
              <Box>{routes.length}</Box>
            </Box>
          </SpaceBetween>
        </Box>
      </Box>
    );
  }

  renderContent(routeSnapshot: RouteSnapshot) {
    const timezone = getTimezone(routeSnapshot);

    return (
      <ColumnLayout columns={4} variant="text-grid">
        <Box>
          <Box margin={{ bottom: 'xxxs' }} color="text-label">
            Service Area
          </Box>
          <Box margin={{ bottom: 'xxxs' }}>{routeSnapshot.serviceAreaId}</Box>
        </Box>
        <Box>
          <Box margin={{ bottom: 'xxxs' }} color="text-label">
            Station timezone
          </Box>
          <Box margin={{ bottom: 'xxxs' }}>{timezone ? timezone : 'N/A'}</Box>
        </Box>
        <Box>
          <Box margin={{ bottom: 'xxxs' }} color="text-label">
            Generation Time
          </Box>
          <Box margin={{ bottom: 'xxxs' }}>{timezoneManager.convertTimestampToString(routeSnapshot.creationTime)}</Box>
        </Box>
        {this.renderRouteCounts(routeSnapshot.routes)}
      </ColumnLayout>
    );
  }

  render() {
    return <Container header={this.renderHeader()}>{this.props.routeSnapshot ? this.renderContent(this.props.routeSnapshot) : null}</Container>;
  }
}
