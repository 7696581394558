import React from 'react';
import { TransportRequestDetails } from '../../clients';
import Box from '@amzn/awsui-components-react/polaris/box';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Header from '@amzn/awsui-components-react/polaris/header';

export interface TrDetailsProps {
  readonly trDetails: TransportRequestDetails[];
}

export class TrDetails extends React.Component<TrDetailsProps, {}> {
  render() {
    return this.props.trDetails.map((tr, index) => {
      return <Box key={index}>{tr.scannableId}</Box>;
    });
  }
}

export interface TrDetailsModalProps extends TrDetailsProps {
  readonly onClose: () => void;
}

export class TrDetailsModal extends React.Component<TrDetailsModalProps, {}> {
  render() {
    return (
      <Modal
        onDismiss={() => this.props.onClose()}
        header={
          <Header variant="h3" description={`Package count: ${this.props.trDetails.length}`}>
            Tracking Ids
          </Header>
        }
        visible={true}
        closeAriaLabel="Close modal"
        size="medium"
      >
        <TrDetails trDetails={this.props.trDetails} />
      </Modal>
    );
  }
}
