import React, { useEffect, useState, useRef } from 'react';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import Hotspot from '@amzn/awsui-components-react/polaris/hotspot';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Map, Marker, Popup, MapRef } from 'react-map-gl';
import { Signer } from 'aws-amplify';
import { credentials } from '../../utilities';
import { config } from '../../utilities';
import * as awsui from '@amzn/awsui-design-tokens';

export interface SimpleMapProps {
  readonly geocode?: { readonly lat: number; readonly long: number };
}

interface MapState {
  readonly longitude: number;
  readonly latitude: number;
  readonly zoom: number;
}

// AWS re:Invent building, the hackathon page born there
const DEFAULT_STATE: MapState = {
  longitude: -122.3375455,
  latitude: 47.6167655,
  zoom: 13,
};

const MARKER = awsui.colorChartsOrange400;

/**
 * https://docs.mapbox.com/mapbox-gl-js/api/
 * https://code.amazon.com/packages/AWSWaypointWebApp/blobs/amzn-internal-fork/--/src/atomicui/pages/DemoPage/DemoPage.tsx
 */

function SequenceMapHeader() {
  return (
    <Hotspot side="left" hotspotId="route-map">
      <Header variant="h2">Map</Header>
    </Hotspot>
  );
}

export function SimpleMap(props: SimpleMapProps) {
  const mapViewRef = useRef<MapRef | null>(null);

  useEffect(() => {
    const geocode = props.geocode;
    if (geocode) {
      mapViewRef?.current?.flyTo({ center: [geocode.long, geocode.lat] });
    }
  }, [props.geocode]);

  const transformRequest = (url: string, resourceType: string) => {
    if (resourceType === 'Style' && !url.includes('://')) {
      url = `https://maps.geo.${config.awsRegion}.amazonaws.com/maps/v0/maps/${url}/style-descriptor`;
    }

    if (url.includes('amazonaws.com')) {
      url = Signer.signUrl(url, {
        access_key: credentials.accessKeyId,
        secret_key: credentials.secretAccessKey,
        session_token: credentials.sessionToken,
      });
      return { url: url };
    }

    return { url: url };
  };

  console.log(props.geocode);

  return (
    <Container header={SequenceMapHeader()} disableContentPaddings={true}>
      <Map
        ref={mapViewRef}
        maxTileCacheSize={100}
        initialViewState={DEFAULT_STATE}
        // 668 is a magic number, it makes the map has the same height as the 15-item table on the right.
        style={{ height: 668 }}
        mapStyle={config.mapStyle}
        maxZoom={16}
        minZoom={3}
        onError={(e) => console.error('map error', e)}
        transformRequest={transformRequest}
      >
        {props.geocode ? <Marker longitude={props.geocode.long} latitude={props.geocode.lat} anchor="bottom" color={MARKER} /> : null}
      </Map>
    </Container>
  );
}
