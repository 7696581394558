import React from 'react';
import Header from '@amzn/awsui-components-react/polaris/header';
import Container from '@amzn/awsui-components-react/polaris/container';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Textarea from '@amzn/awsui-components-react/polaris/textarea';
import { UserGeneratedData } from './models';
import { Button } from '@amzn/awsui-components-react';
import { parseRouteNotes, parseTransporterNotes } from './converters';
import { DEFAULT_USER_GENERATED_DATA } from './constants';
import { WithBundleProps, withBundle } from '@amzn/react-arb-tools';

export interface UserNotesProps extends WithBundleProps {
  readonly userGeneratedData: UserGeneratedData;
  readonly onUserGeneratedUpdate: (value: UserGeneratedData) => void;
  readonly onUserGeneratedReset: () => void;
}

export interface UserNotesState {
  readonly transportersCSVData: string;
  readonly routesCSVData: string;
  readonly transportersCSVDataErrorMessage?: string;
  readonly routesCSVDataErrorMessage?: string;
  readonly isUpdating: boolean;
}

class UserNotes extends React.Component<UserNotesProps, UserNotesState> {
  constructor(props: UserNotesProps) {
    super(props);
    this.state = {
      transportersCSVData: this.props.userGeneratedData.transportersCSV,
      routesCSVData: this.props.userGeneratedData.routesCSV,
      isUpdating: false,
    };
  }

  render() {
    return (
      <Container header={this.renderHeader()}>
        <SpaceBetween size="l">
          <FormField label={this.props.bundle.getMessage('USER_NOTES:TRANSPORTER_NOTES_CSV')} stretch={true} errorText={this.state.transportersCSVDataErrorMessage}>
            <Textarea
              value={this.state.transportersCSVData}
              onChange={(evt) => {
                this.setState({ transportersCSVData: evt.detail.value });
              }}
            />
          </FormField>
          <FormField label={this.props.bundle.getMessage('USER_NOTES:ROUTE_NOTES_CSV')} stretch={true} errorText={this.state.routesCSVDataErrorMessage}>
            <Textarea
              value={this.state.routesCSVData}
              onChange={(evt) => {
                this.setState({ routesCSVData: evt.detail.value });
              }}
            />
          </FormField>
        </SpaceBetween>
      </Container>
    );
  }

  private renderHeader() {
    return (
      <Header variant="h2" actions={this.renderActions()} description={this.props.bundle.getMessage('USER_NOTES:DESCRIPTION')}>
        {this.props.bundle.getMessage('USER_NOTES:HEADER')}
      </Header>
    );
  }

  private renderActions() {
    return (
      <SpaceBetween size="xs" direction="horizontal">
        <Button
          disabled={this.state.isUpdating}
          onClick={() => {
            this.props.onUserGeneratedReset();
            this.setState({
              transportersCSVData: DEFAULT_USER_GENERATED_DATA.transportersCSV,
              routesCSVData: DEFAULT_USER_GENERATED_DATA.routesCSV,
            });
          }}
        >
          {this.props.bundle.getMessage('USER_NOTES:RESET')}
        </Button>
        <Button
          disabled={this.state.isUpdating}
          onClick={async () => {
            this.setState({ isUpdating: true });
            try {
              let valid = true;
              try {
                await parseRouteNotes(this.state.routesCSVData);
                this.setState({ routesCSVDataErrorMessage: undefined });
              } catch (err: any) {
                valid = false;
                this.setState({ routesCSVDataErrorMessage: err.message });
              }

              try {
                await parseTransporterNotes(this.state.transportersCSVData);
                this.setState({ transportersCSVDataErrorMessage: undefined });
              } catch (err: any) {
                valid = false;
                this.setState({ transportersCSVDataErrorMessage: err.message });
              }

              if (valid) {
                this.props.onUserGeneratedUpdate({
                  transportersCSV: this.state.transportersCSVData,
                  routesCSV: this.state.routesCSVData,
                });
              }
            } finally {
              this.setState({ isUpdating: false });
            }
          }}
        >
          {this.props.bundle.getMessage('USER_NOTES:UPDATE')}
        </Button>
      </SpaceBetween>
    );
  }
}

export default withBundle('UserNotes')(UserNotes);
