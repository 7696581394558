import React from 'react';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { LmRoute } from '../../clients';
import Box from '@amzn/awsui-components-react/polaris/box';
import Link from '@amzn/awsui-components-react/polaris/link';
import { buildURLSearchParamsFromLmRouteUserSearch } from '../../main-app/url-utils';
import { formatDuration, timezoneManager } from '../../utilities';

export type RouteSelectionColumnDefinition = TableProps.ColumnDefinition<LmRoute> & { id: string; header: string };

export interface RouteSelectionTabelProps {
  readonly onRouteClick: (routeId: string) => void;
  readonly operatingEntity: 'SSD' | 'GSF' | 'AMZL' | string | undefined;
}

export function buildRouteSelectionTableDefinition(props: RouteSelectionTabelProps): RouteSelectionColumnDefinition[] {
  const items: RouteSelectionColumnDefinition[] = [];

  /**
   * todo: display the latest status. We need to write a latest status detection function. The route returns a list of statuses,
   * but it doesn't come with a timestamp when the status is added to the route. However, we should be able to write a function
   * to infer the latest status based on the operational flow.
   */

  items.push({
    id: 'routeId',
    width: 300,
    header: 'RouteId',
    cell: (route: LmRoute) => {
      const query = buildURLSearchParamsFromLmRouteUserSearch({
        type: 'routeId',
        text: route.routeId,
      });
      const qs = new URLSearchParams(query);
      return (
        <Box>
          <Link
            href={`route?${qs.toString()}`}
            onFollow={(evt) => {
              evt.preventDefault();
              props.onRouteClick(route.routeId);
            }}
          >
            {route.routeId}
          </Link>
        </Box>
      );
    },
  });

  if (props.operatingEntity !== 'GSF') {
    // The GSF route name in route store is a long string, e.g. A3KG283ZOG8X00-2023-01-09T19:09:40-0000
    // Avoid showing GSF route name since the name is decoupled into other fields.
    items.push({
      id: 'routeName',
      header: 'Route name',
      cell: (route: LmRoute) => {
        const routeName = route.metadata['ROUTE_NAME'] ?? '-';
        return <Box>{routeName}</Box>;
      },
    });
  }

  items.push({
    id: 'routeStartTime',
    header: 'Route start time',
    cell: (route: LmRoute) => {
      const startTimeInSeconds = route.sequence?.startTime;
      if (typeof startTimeInSeconds === 'number') {
        return <Box>{timezoneManager.convertTimestampToString(startTimeInSeconds * 1000)}</Box>;
      } else {
        return <Box>-</Box>;
      }
    },
  });

  items.push({
    id: 'duration',
    header: 'Route duration',
    cell: (route: LmRoute) => {
      const duration = route.sequence?.duration;
      if (typeof duration === 'number') {
        return <Box>{formatDuration(duration)}</Box>;
      } else {
        return <Box>-</Box>;
      }
    },
  });

  items.push({
    id: 'dispatchDate',
    header: 'Dispatch date',
    cell: (route: LmRoute) => {
      const dispatchDate = route.metadata['DISPATCH_DATE'] ?? '-';
      return <Box>{dispatchDate}</Box>;
    },
  });

  items.push({
    id: 'transporter',
    header: 'Transporter',
    cell: (route: LmRoute) => {
      const assignment = route.operations?.assignment;
      if (assignment) {
        const group = assignment.transporterGroup?.groupType;
        const transporterId = assignment.transporterId;
        return (
          <Box>
            {group} / {transporterId}
          </Box>
        );
      } else {
        return <Box>-</Box>;
      }
    },
  });

  items.push({
    id: 'lastUpdatedAt',
    header: 'Updated at',
    cell: (route: LmRoute) => {
      const lastUpdatedAt = route.auditInfo?.timestamp;
      // For some reasons, SSD route auditing info has timestamp = 0;
      if (typeof lastUpdatedAt === 'number' && lastUpdatedAt !== 0) {
        return <Box>{timezoneManager.convertTimestampToString(lastUpdatedAt * 1000)}</Box>;
      } else {
        return <Box>-</Box>;
      }
    },
  });

  return items;
}
