import React, { ReactElement } from 'react';
import { Route, DispatchPlannerType, TransporterGroup, TransportRequestDetails, DepartureSet, FlexRouteAssignmentPlan, FlexRouteAssignmentPlannerInput } from '../../clients';
import Header from '@amzn/awsui-components-react/polaris/header';
import Table from '@amzn/awsui-components-react/polaris/table';
import { RouteDetailsModal } from './route-details';
import { TransporterGroupModal } from './transporter-group';
import { RejectedTransportersModal } from './rejected-transporters';
import { TrDetailsModal } from './tr-details';
import { DepartureSetModal } from './departure-set';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import CollectionPreferences, { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import { retrieveHiddenColumnIds, upsertHiddenColumnIds, retrieveCompactRouteWindowFlag, upsertCompactRouteWindowFlag } from './utilities/table-preferences-store';
import { AssignmentTableDefinitionProps, AssignmentColumnDefinition } from './table-definitions';
import { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';
import { ExportAutoAssignCsvButton } from './export-auto-assign-button';
import { WithBundleProps, withBundle } from '@amzn/react-arb-tools';
import { MessageBundle } from '@amzn/arb-tools';

/**
 * i18n strings for the property filter
 * https://tiny.amazon.com/19u4khj6z/polaa2zawsu
 */
const PROPERTY_FILTER_I18N: PropertyFilterProps.I18nStrings = {
  filteringAriaLabel: 'your choice',
  dismissAriaLabel: 'Dismiss',
  filteringPlaceholder: 'Filter',
  groupValuesText: 'Values',
  groupPropertiesText: 'Properties',
  operatorsText: 'Operators',
  operationAndText: 'and',
  operationOrText: 'or',
  operatorLessText: 'Less than',
  operatorLessOrEqualText: 'Less than or equal',
  operatorGreaterText: 'Greater than',
  operatorGreaterOrEqualText: 'Greater than or equal',
  operatorContainsText: 'Contains',
  operatorDoesNotContainText: 'Does not contain',
  operatorEqualsText: 'Equals',
  operatorDoesNotEqualText: 'Does not equal',
  editTokenHeader: 'Edit filter',
  propertyText: 'Property',
  operatorText: 'Operator',
  valueText: 'Value',
  cancelActionText: 'Cancel',
  applyActionText: 'Apply',
  allPropertiesLabel: 'All properties',
  tokenLimitShowMore: 'Show more',
  tokenLimitShowFewer: 'Show fewer',
  clearFiltersText: 'Clear filters',
  removeTokenButtonAriaLabel: () => 'Remove token',
  enteredTextLabel: (text) => `Use: "${text}"`,
};

export interface TextFiltering<T> {
  readonly filteringText: string;
  /**
   * For performance concern, we have the updateFilterText method, which only updates the filter text, but don't actually run the filter.
   */
  readonly updateFilterText: (filteringText: string) => void;

  /**
   * Run the actual filter.
   */
  readonly runFilter: (filteringText: string) => void;
}

export interface AdvancedFiltering<T> {
  readonly initialQuery: PropertyFilterProps.Query;
  readonly filteringProperties: PropertyFilterProps.FilteringProperty[];
  readonly runQuery: (query: PropertyFilterProps.Query, assignments: T[]) => T[];
}

export interface Props<T> extends WithBundleProps {
  readonly plannerInput: FlexRouteAssignmentPlannerInput;
  readonly plan: FlexRouteAssignmentPlan;
  readonly algorithm: DispatchPlannerType;
  readonly assignments: T[];
  readonly assignmentTableDefinitionFactory: (props: AssignmentTableDefinitionProps) => AssignmentColumnDefinition<T>[];
  readonly onRouteClick: (routeId: string, version: number) => void;
  // regular filter
  readonly textFiltering?: TextFiltering<T>;
  readonly buildAutoAssignCsv?: (hiddenColumnIds: ReadonlyArray<string>, cycleName?: string, bundle?: MessageBundle) => string;
  readonly cycles?: ReadonlyArray<string>;
}

export interface State<T> {
  readonly selectedRouteToDisplay?: Route;

  readonly selectedRejectedTransporterIds?: string[];

  readonly selectedTransporterGroup?: TransporterGroup;

  readonly selectedTrDetails?: TransportRequestDetails[];

  readonly selectedDepartureSet?: DepartureSet;

  readonly hiddenColumnIds: string[];

  readonly compactRouteWindow: boolean;
}

/**
 * Render an array of assignment in a table. The AssignmentStatus is generic and supports both FDP and Fungible assignment algorithm.
 *
 * T: the generic parameter can be FdpAssignment or FungibleAssignment
 */
class AssignmentStatus<T> extends React.Component<Props<T>, State<T>> {
  constructor(props: Props<T>) {
    super(props);

    this.state = {
      selectedRouteToDisplay: undefined,
      hiddenColumnIds: retrieveHiddenColumnIds(this.props.algorithm),
      compactRouteWindow: retrieveCompactRouteWindowFlag(),
    };
  }

  private renderHeaderActions() {
    if (this.props.buildAutoAssignCsv) {
      return (
        <ExportAutoAssignCsvButton<T>
          bundle={this.props.bundle}
          disabled={this.props.assignments.length === 0 || !this.props.cycles}
          cycles={this.props.cycles ?? []}
          hiddenColumnIds={this.state.hiddenColumnIds}
          stationCode={this.props.plannerInput.serviceArea.defaultStationCode}
          planTime={this.props.plan.lastUpdated}
          buildAutoAssignCsv={this.props.buildAutoAssignCsv}
        />
      );
    }
  }

  private renderHeader() {
    const dispatchPlannerTypeToAlgorithmName: Partial<Record<DispatchPlannerType, string>> = {
      FLEX_DISPATCH_PLANNER: 'FDP',
      FUNGIBLE_ASSIGNMENT_ALGORITHM: 'Fungible',
    };
    return (
      <Header variant="h2" counter={`(${this.props.assignments.length})`} actions={this.renderHeaderActions()}>
        {`${dispatchPlannerTypeToAlgorithmName[this.props.algorithm]} ${this.props.bundle.getMessage('ASSIGNMENT_STATUS:HEADER')}`}
      </Header>
    );
  }

  private renderSelectedRoute() {
    if (this.state.selectedRouteToDisplay) {
      return (
        <RouteDetailsModal
          route={this.state.selectedRouteToDisplay}
          onClose={() => {
            this.setState({ selectedRouteToDisplay: undefined });
          }}
          onRouteClick={this.props.onRouteClick}
          compactRouteWindow={this.state.compactRouteWindow}
        />
      );
    }
  }

  private renderSelectedRejectedTransporterIds() {
    if (this.state.selectedRejectedTransporterIds) {
      return (
        <RejectedTransportersModal
          transporterIds={this.state.selectedRejectedTransporterIds}
          onClose={() => {
            this.setState({ selectedRejectedTransporterIds: undefined });
          }}
        />
      );
    }
  }

  private renderSelectedTransporterGroup() {
    if (this.state.selectedTransporterGroup) {
      return (
        <TransporterGroupModal
          transporterGroup={this.state.selectedTransporterGroup}
          onClose={() => {
            this.setState({ selectedTransporterGroup: undefined });
          }}
        />
      );
    }
  }

  private renderSelectedDepartureSet() {
    if (this.state.selectedDepartureSet) {
      return (
        <DepartureSetModal
          departureSet={this.state.selectedDepartureSet}
          onClose={() => {
            this.setState({ selectedDepartureSet: undefined });
          }}
        />
      );
    }
  }

  private renderSelectedTrDetails() {
    if (this.state.selectedTrDetails) {
      return (
        <TrDetailsModal
          trDetails={this.state.selectedTrDetails}
          onClose={() => {
            this.setState({ selectedTrDetails: undefined });
          }}
        />
      );
    }
  }

  private renderFilter() {
    if (this.props.textFiltering) {
      return (
        <TextFilter
          filteringText={this.props.textFiltering.filteringText}
          filteringPlaceholder={'Filter'}
          onChange={(evt) => {
            if (this.props.textFiltering) {
              this.props.textFiltering.updateFilterText(evt.detail.filteringText);
            }
          }}
          onDelayedChange={(evt) => {
            if (this.props.textFiltering) {
              this.props.textFiltering.runFilter(evt.detail.filteringText);
            }
          }}
        />
      );
    } else {
      return null;
    }
  }

  private renderPreferences(columnOptions: CollectionPreferencesProps.VisibleContentOption[]) {
    const removeableColumnIds = columnOptions.map((option) => option.id);

    return (
      <CollectionPreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        preferences={{
          wrapLines: this.state.compactRouteWindow,
          visibleContent: removeableColumnIds.filter((id) => !this.state.hiddenColumnIds.includes(id)),
        }}
        wrapLinesPreference={{
          label: 'Compact route window',
          description: 'Display route window in compact mode.',
        }}
        visibleContentPreference={{
          title: 'Select columns',
          options: [
            {
              label: 'Columns',
              options: columnOptions,
            },
          ],
        }}
        onConfirm={(evt) => {
          const compactMode = !!evt.detail.wrapLines;
          const visibleColumnIds = Array.from(evt.detail.visibleContent ? evt.detail.visibleContent : []);
          const hiddenColumnIds = removeableColumnIds.filter((id) => !visibleColumnIds.includes(id));
          upsertHiddenColumnIds(this.props.algorithm, hiddenColumnIds);
          upsertCompactRouteWindowFlag(compactMode);
          this.setState({
            hiddenColumnIds,
            compactRouteWindow: compactMode,
          });
        }}
      />
    );
  }

  render() {
    // build the table definitions from the factory method.
    const tableDefinitions = this.props.assignmentTableDefinitionFactory({
      bundle: this.props.bundle,
      onRouteClick: (route: Route) => {
        this.setState({ selectedRouteToDisplay: route });
      },
      onRejectionCountClick: (transporterIds: string[]) => {
        this.setState({ selectedRejectedTransporterIds: transporterIds });
      },
      onTransporterGroupClick: (transporterGroup: TransporterGroup) => {
        this.setState({ selectedTransporterGroup: transporterGroup });
      },
      onTrDetailsClick: (trDetails: TransportRequestDetails[]) => {
        this.setState({ selectedTrDetails: trDetails });
      },
      onDepartureSetStatusClick: (departureSet: DepartureSet) => {
        this.setState({ selectedDepartureSet: departureSet });
      },
    });

    // find the removeable columns
    const removableColumns = tableDefinitions
      .filter((definition) => definition.removable)
      .map(
        (definition) =>
          ({
            id: definition.id,
            label: definition.header,
          } as CollectionPreferencesProps.VisibleContentOption),
      );

    // remove columns if the user disabled them.
    const filteredTableDefinitions = tableDefinitions.filter((item) => !this.state.hiddenColumnIds.includes(item.id));

    return (
      <React.Fragment>
        {/* render popups */}
        {this.renderSelectedRoute()}
        {this.renderSelectedRejectedTransporterIds()}
        {this.renderSelectedTransporterGroup()}
        {this.renderSelectedTrDetails()}
        {this.renderSelectedDepartureSet()}
        <Table<T>
          header={this.renderHeader()}
          resizableColumns={true}
          columnDefinitions={filteredTableDefinitions}
          items={this.props.assignments}
          stickyHeader={true}
          filter={this.renderFilter()}
          preferences={this.renderPreferences(removableColumns)}
          // pagination={this.renderPagination()}
        />
      </React.Fragment>
    );
  }
}

// Workaround for using HOC on generic component
// https://stackoverflow.com/questions/51984093/typescript-with-react-use-hoc-on-a-generic-component-class
export default withBundle('AssignmentStatus')(AssignmentStatus) as <T>(props: Props<T>) => ReactElement;
