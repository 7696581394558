/**
 * Default the tasks in the training tutorial.
 *
 * https://polaris.a2z.com/components/annotation-context/?tabId=playground
 *
 */

import React from 'react';

import { AnnotationContextProps } from '@amzn/awsui-components-react/polaris/annotation-context';
import { Tutorial } from '../main-app/global-context';
import { LM_ROUTE_PAGE_TUTORIAL } from '../components/lm-route-page';

export function findTutorial(tutorial: Tutorial): AnnotationContextProps.Tutorial | undefined {
  switch (tutorial) {
    case 'lm-route-page':
      return LM_ROUTE_PAGE_TUTORIAL;
    default:
      return undefined;
  }
}
