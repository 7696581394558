import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { GlobalContext } from '../../main-app/global-context';

export class NetworkHealthPageTools extends React.Component<{}, {}> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: {}) {
    super(props);
  }

  // @TODO add tutorial

  private renderDescription() {
    return <Box variant="p">This page captures planning metrics reported by LMES/LMDP for routing snapshots/plans at both network and service area level.</Box>;
  }

  private renderNotes() {
    return (
      <Box>
        <Header variant="h3">Notes</Header>
        <ul>
          <li>Block/IO Drivers indicates available drivers</li>
          <li>Block Drivers are the difference of total and assigned block drivers, and are checked in</li>
          <li>IO drivers are based on the time radius determined by the respective service area config, specifically the "IO Max Pickup Search Radius (Sec)" field</li>
          <li>The live button refreshes the table every minute as planning is done at a minute cadence</li>
          <li>The export button will export all visible rows on the page - to export for all rows, click the icon under refresh and select all service areas, then export</li>
          <li>Prime Now Program Type includes Amazon Fresh Stores (AFS) and Amazon Fresh Online (AFO) </li>
        </ul>
      </Box>
    );
  }

  private renderLearnMore() {
    return (
      <Box>
        <Header variant="h3">Learn more</Header>
        <SpaceBetween direction="vertical" size="xs">
          <Link external href="https://sim.amazon.com/issues/create?template=26bb5990-da35-4c39-b378-8c42b0a12e56" target="_blank">
            Request permission
          </Link>
          <Link external href="https://quip-amazon.com/EaL4AiUEGYuw/Network-Health-Page-Feedback" target="_blank">
            User feedback
          </Link>
          <Link external href="https://email-list.corp.amazon.com/email-list/expand-list/ufraaviz-interest" target="_blank">
            Subscribe to ufraaviz-interest
          </Link>
        </SpaceBetween>
      </Box>
    );
  }

  render() {
    return (
      <HelpPanel header={<Header variant="h2">Info</Header>}>
        <SpaceBetween direction="vertical" size="m">
          {this.renderDescription()}
          {this.renderNotes()}
          {this.renderLearnMore()}
        </SpaceBetween>
      </HelpPanel>
    );
  }
}
