import React from 'react';
import ufraaVizClient, { ServiceAreaDetails } from '../../clients';
import Container from '@amzn/awsui-components-react/polaris/container';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Box from '@amzn/awsui-components-react/polaris/box';
import Header from '@amzn/awsui-components-react/polaris/header';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import Button from '@amzn/awsui-components-react/polaris/button';
import { GlobalContext } from '../../main-app/global-context';
import Clock from '../../shared-components/clock';
import { WithBundleProps } from '@amzn/react-arb-tools';

export interface ServiceAreaSummaryProps extends WithBundleProps {
  readonly serviceAreaId: string;
  readonly onSettingsClick: () => void;
}

export interface ServiceAreaSummaryState {
  readonly serviceAreaDetails?: ServiceAreaDetails | null;
  readonly validTimezone: boolean;
  readonly stationTimezone?: string;
}

export default class ServiceAreaSummary extends React.Component<ServiceAreaSummaryProps, ServiceAreaSummaryState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: ServiceAreaSummaryProps) {
    super(props);
    this.state = {
      serviceAreaDetails: undefined,
      validTimezone: true,
      stationTimezone: undefined,
    };
  }

  async componentDidMount() {
    let serviceArea: ServiceAreaDetails;
    try {
      serviceArea = await ufraaVizClient.getServiceAreaDetails(this.props.serviceAreaId);
      this.setState({ serviceAreaDetails: serviceArea });
    } catch (err) {
      this.context.addNotification({
        header: 'Error',
        content: `Cannot find service area ${this.props.serviceAreaId}`,
        type: 'error',
      });
      this.setState({ serviceAreaDetails: null, validTimezone: false });
      return;
    }

    try {
      this.context.updateStationTimezone(serviceArea.timeZone);
      // update context to refresh the time.
      this.setState({
        stationTimezone: serviceArea.timeZone,
      });
    } catch (err) {
      this.context.addNotification({
        header: 'Warning',
        content: `Invalid timezone ${serviceArea.timeZone}`,
        type: 'warning',
      });
      this.setState({
        validTimezone: false,
      });
    }
  }

  componentWillUnmount() {
    this.context.updateStationTimezone(undefined);
  }

  renderHeader() {
    return (
      <Header variant="h2" description={this.props.serviceAreaId} actions={this.renderHeaderAction()}>
        {this.props.bundle.getMessage('STATION_PAGE:HEADER')}
      </Header>
    );
  }

  renderText(text?: string) {
    if (typeof text === 'string') {
      return text;
    } else {
      return <Spinner />;
    }
  }

  render() {
    return (
      <Container header={this.renderHeader()}>
        <ColumnLayout columns={4} variant="text-grid">
          <Box>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              Service Area Name
            </Box>
            <Box margin={{ bottom: 'xxxs' }}>{this.state.serviceAreaDetails === null ? 'N/A' : this.renderText(this.state.serviceAreaDetails?.serviceAreaName)}</Box>
          </Box>
          <Box>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              Station Code
            </Box>
            <Box margin={{ bottom: 'xxxs' }}>{this.state.serviceAreaDetails === null ? 'N/A' : this.renderText(this.state.serviceAreaDetails?.stationCode)}</Box>
          </Box>
          <Box>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              Station Timezone
            </Box>
            <Box margin={{ bottom: 'xxxs' }}>{this.state.validTimezone ? this.renderText(this.state.stationTimezone) : 'N/A'}</Box>
          </Box>
          <Box>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              Station Time
            </Box>
            <Box margin={{ bottom: 'xxxs' }}>{this.state.stationTimezone ? <Clock timezone={this.state.stationTimezone} /> : 'N/A'}</Box>
          </Box>
        </ColumnLayout>
      </Container>
    );
  }

  private renderHeaderAction() {
    return <Button iconName="settings" variant="inline-icon" onClick={() => this.props.onSettingsClick()} />;
  }
}
