import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import CopyText from '../../shared-components/copy-text';

export interface RejectedTransportersProps {
  readonly transporterIds: string[];
}

export class RejectedTransporters extends React.Component<RejectedTransportersProps, {}> {
  render() {
    return (
      <SpaceBetween direction="vertical" size="s">
        {this.props.transporterIds.map((transporterId, index) => {
          return (
            <Box key={index}>
              <CopyText copyText={transporterId} />
            </Box>
          );
        })}
      </SpaceBetween>
    );
  }
}

export interface RejectedTransportersModalProps extends RejectedTransportersProps {
  readonly onClose: () => void;
}

export class RejectedTransportersModal extends React.Component<RejectedTransportersModalProps, {}> {
  render() {
    return (
      <Modal onDismiss={() => this.props.onClose()} header={<Header variant="h3">Rejected Transporters</Header>} visible={true} closeAriaLabel="Close modal" size="medium">
        <RejectedTransporters transporterIds={this.props.transporterIds} />
      </Modal>
    );
  }
}
