import { DeploymentStage, StageConfiguration } from '../models';

export class WebsiteConfigurationProps {
  readonly domain?: string;
  readonly identifier?: string;
}

/**
 * A helper class that manages stage configurations
 */
export class WebsiteConfigAccessor {
  readonly stageConfigurations: StageConfiguration[];

  constructor(stageConfigurations: StageConfiguration[]) {
    this.stageConfigurations = stageConfigurations;
  }

  /**
   * Get the website configurations. If the
   * @returns
   */
  getStageConfigurationByDomain(domain: string): StageConfiguration {
    const configs = this.stageConfigurations.filter((sc) => sc.domain === domain);
    if (configs.length === 0) {
      throw new Error(`No stage configuration existed for domain ${domain}`);
    } else if (configs.length > 1) {
      throw new Error(`${configs.length} stage configurations existed for domain ${domain}, but only needs one`);
    }
    return configs[0];
  }

  getStageConfigurationById(id: string): StageConfiguration {
    const configs = this.stageConfigurations.filter((sc) => sc.id === id);
    if (configs.length === 0) {
      throw new Error(`No stage configuration existed for id ${id}`);
    } else if (configs.length > 1) {
      throw new Error(`${configs.length} stage configurations existed for id ${id}, but only needs one`);
    }
    return configs[0];
  }

  getConfigurationsByStage(stage: DeploymentStage): StageConfiguration[] {
    return this.stageConfigurations.filter((sc) => sc.stage === stage);
  }
}
