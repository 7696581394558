/**
 * Create a global singleton client object that can be used anywhere
 */

import { UfraaVizClientImpl } from './ufraaviz-client-impl';
import { config, MidwayCognitoAuthClientBuilder } from '../utilities';
import axios, { AxiosInstance } from 'axios';

let httpClient: AxiosInstance;

if (config.identityPoolId === '') {
  // calling the local backend (when calling local backend, we don't need aws credentials)
  httpClient = MidwayCognitoAuthClientBuilder.build({
    baseUrl: config.apiGatewayBaseURL,
  });
} else {
  httpClient = MidwayCognitoAuthClientBuilder.build({
    baseUrl: config.apiGatewayBaseURL,
    identityPoolId: config.identityPoolId,
    awsRegion: config.awsRegion,
  });
}

export const ufraaVizClient = new UfraaVizClientImpl(httpClient, axios.create());
