import React from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Table from '@amzn/awsui-components-react/polaris/table';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { buildRouteSelectionTableDefinition } from './route-selection-table-definition';
import { LmRoute } from '../../clients';

export interface RouteSelectionProps {
  readonly routes: ReadonlyArray<LmRoute>;
  readonly onRouteClick: (routeId: string) => void;
}

export interface RouteSelectionState {
  readonly currentPage: number;
}

const ITEM_PER_PAGE = 10;

export class RouteSelection extends React.Component<RouteSelectionProps, RouteSelectionState> {
  constructor(props: RouteSelectionProps) {
    super(props);
    this.state = {
      // start from 1
      currentPage: 1,
    };
  }

  render() {
    return (
      <SpaceBetween direction="vertical" size="m">
        {this.renderRouteSelectionPrompt()}
        {this.renderRouteSelectionTable()}
      </SpaceBetween>
    );
  }

  private renderRouteSelectionTable() {
    // find the items in the current page
    const itemsToDisplay = this.props.routes.slice((this.state.currentPage - 1) * ITEM_PER_PAGE, this.state.currentPage * ITEM_PER_PAGE);
    return (
      <Table<LmRoute>
        resizableColumns={true}
        columnDefinitions={buildRouteSelectionTableDefinition({
          onRouteClick: this.props.onRouteClick,
          // customize the table columns based on program type.
          operatingEntity: this.inferOperatingEntity(),
        })}
        items={itemsToDisplay}
        stickyHeader={false}
        pagination={this.renderPagination()}
      />
    );
  }

  private inferOperatingEntity(): string | undefined {
    // All AMZL, SSD, GSF routes have operating entity.
    if (this.props.routes.length > 0) {
      return this.props.routes[0].metadata['OPERATING_ENTITY'];
    }
  }
  private renderPagination() {
    return (
      <Pagination
        ariaLabels={{
          nextPageLabel: 'Next page',
          previousPageLabel: 'Previous page',
          pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
        }}
        currentPageIndex={this.state.currentPage}
        onChange={({ detail }) => this.setState({ currentPage: detail.currentPageIndex })}
        pagesCount={Math.ceil(this.props.routes.length / ITEM_PER_PAGE)}
      />
    );
  }

  private renderRouteSelectionPrompt() {
    return (
      <Alert type="info" header="Multiple routes">
        We found {this.props.routes.length} routes associated with the search. Select one of the routes to see in details.
      </Alert>
    );
  }
}
