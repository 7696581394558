import React from 'react';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Box from '@amzn/awsui-components-react/polaris/box';
import Toggle from '@amzn/awsui-components-react/polaris/toggle';
import { GlobalContext } from './global-context';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import RadioGroup from '@amzn/awsui-components-react/polaris/radio-group';
import { timezoneManager, TimezonePreference } from '../utilities';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import './preferences-style.scss';

interface Props extends WithBundleProps {
  visible: boolean;
  hide: () => void;
}

export class Preferences extends React.Component<Props, {}> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  renderPreferredTimezoneSelection() {
    return (
      <RadioGroup
        value={this.context.timezonePreference}
        items={[
          { value: 'station_tz', label: this.props.bundle.getMessage('PREFERENCES:STATION_LOCAL_TIMEZONE') },
          { value: 'user_tz', label: `${this.props.bundle.getMessage('PREFERENCES:USER_LOCAL_TIMEZONE')}: ${timezoneManager.getUserTimezone()}` },
          { value: 'utc_tz', label: `UTC+0` },
        ]}
        onChange={(evt) => {
          this.context.updateTimezonePreference(evt.detail.value as TimezonePreference);
        }}
      />
    );
  }

  render() {
    return (
      <Modal header={this.props.bundle.getMessage('PREFERENCES:PREFERENCES_HEADER')} visible={this.props.visible} onDismiss={this.props.hide}>
        <SpaceBetween direction="vertical" size="s">
          <Box className="item">
            <Box className="item-name item-name-text">{this.props.bundle.getMessage('PREFERENCES:DARK_MODE')}</Box>
            <Box className="item-value item-value-toggle">
              <Toggle
                checked={this.context.uiMode === 'dark'}
                onChange={(event) => {
                  this.context.updateUIMode(event.detail.checked ? 'dark' : 'light');
                }}
              />
            </Box>
          </Box>
          <Box className="item">
            <Box className="item-name item-name-text">{this.props.bundle.getMessage('PREFERENCES:COMPACT_MODE')}</Box>
            <Box className="item-value item-value-toggle">
              <Toggle
                checked={this.context.density === 'compact'}
                onChange={(event) => {
                  this.context.updateDensity(event.detail.checked ? 'compact' : 'comfortable');
                }}
              />
            </Box>
          </Box>
          <Box className="item">
            <Box className="item-name item-name-text">{this.props.bundle.getMessage('PREFERENCES:VISUAL_REFRESH')}</Box>
            <Box className="item-value item-value-toggle">
              <Toggle
                checked={this.context.theme === 'visual-refresh'}
                onChange={(event) => {
                  this.context.updateTheme(event.detail.checked ? 'visual-refresh' : 'regular');
                }}
              />
            </Box>
          </Box>
          <Box className="item item-vertical">
            <Box className="item-name item-name-text">{this.props.bundle.getMessage('PREFERENCES:PREFERRED_TIMEZONE')}</Box>
            <Box className="item-value item-value-radio-button">{this.renderPreferredTimezoneSelection()}</Box>
          </Box>
        </SpaceBetween>
      </Modal>
    );
  }
}

export default withBundle('Preferences')(Preferences);
