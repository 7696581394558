import { AmzlRouteStagingItem } from '../../clients';
import { timezoneManager } from '../../utilities';
import { saveAs } from 'file-saver';

export function downloadSCCInput(routeStagingItems: ReadonlyArray<AmzlRouteStagingItem>, timezone: string, filename: string) {
  const rows: string[] = ['dispatchTime,routeCode,routeID'];
  const temp = {
    tz: timezone,
    format: 'HH:mm:ss',
  };

  routeStagingItems
    .map((item) => ({
      dispatchTime: timezoneManager.convertTimestampToString(item.dispatchTime, temp),
      routeCode: item.routeName,
      routeID: item.correlationId,
    }))
    .sort((i1, i2) => i1.dispatchTime.localeCompare(i2.dispatchTime))
    .forEach((item) => {
      rows.push(`${item.dispatchTime},${item.routeCode},${item.routeID}`);
    });

  saveAs(new Blob([rows.join('\n')], { type: 'text/csv;charset=utf-8,' }), filename + '.csv');
}
