import React from 'react';
import { timezoneManager, formatDuration } from '../../utilities';
import Box from '@amzn/awsui-components-react/polaris/box';
import Link from '@amzn/awsui-components-react/polaris/link';
import Badge from '@amzn/awsui-components-react/polaris/badge';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { RouteChangeLog, RouteStatus, RsDepartureSet } from '../../clients';
import lodash from 'lodash';
import { systemDatabse } from '../../utilities/system-database';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';

const NotAvaliable = '-';
const AWS_ACCOUNT_ID_REGEX = /arn:aws:sts::(\d+):assumed-role/;

export type RouteTimelineColumnId = 'version' | 'event' | 'updateCategory' | 'updateType' | 'updatedBy' | 'updatedAt';

export const routeTimelineColumnIdToName: Readonly<Record<RouteTimelineColumnId, string>> = {
  version: 'Version',
  event: 'Event',
  updateCategory: 'Update category',
  updateType: 'Update type',
  updatedBy: 'Updated by',
  updatedAt: 'Updated at',
};

export type RouteTimelineColumnDefinition = TableProps.ColumnDefinition<RouteChangeLog> & { id: RouteTimelineColumnId; header: string; removable?: boolean };

export interface TimelineTabelProps {
  readonly targetVersion?: number;
  readonly onVersionClick: (version: number) => void;
  readonly onDepartureSetClick: (version: number, departureSet: RsDepartureSet) => void;
  readonly hiddenColumnIds: ReadonlyArray<RouteTimelineColumnId>;
  readonly timeformat: 'HH:mm:ss z' | 'YYYY-MM-DD HH:mm:ss z';
}

const CANONICAL_NAME_TO_COLOR: Map<string, 'blue' | 'grey' | 'green' | 'red'> = new Map();
CANONICAL_NAME_TO_COLOR.set('RouteDispatchManager', 'green');
CANONICAL_NAME_TO_COLOR.set('FlexRouteAssignmentExecutionService', 'blue');
CANONICAL_NAME_TO_COLOR.set('DynamicRouteInjector', 'grey');
CANONICAL_NAME_TO_COLOR.set('PtrasRouteSyncProcessor', 'blue');
CANONICAL_NAME_TO_COLOR.set('DispatchPlanningWorkflow', 'grey');
CANONICAL_NAME_TO_COLOR.set('RoutePreparationManagerService', 'red');
CANONICAL_NAME_TO_COLOR.set('DepartureSetStatusEventProcessor', 'green');

function convertStatusToText(status: RouteStatus): string {
  return status.toLowerCase().split('_').join(' ');
}
/**
 * Table definition for route timeline.
 * @param props
 */
export function buildTimelineTableDefinition(props: TimelineTabelProps): RouteTimelineColumnDefinition[] {
  const columns: RouteTimelineColumnDefinition[] = [
    {
      id: 'version',
      width: 100,
      header: routeTimelineColumnIdToName['version'],
      cell: (event: RouteChangeLog) => {
        if (props.targetVersion === event.version) {
          return <Badge color="blue">{event.version}</Badge>;
        } else {
          return (
            <Box>
              <Link
                onFollow={(evt) => {
                  evt.preventDefault();
                  props.onVersionClick(event.version);
                }}
              >
                {event.version}
              </Link>
            </Box>
          );
        }
      },
    },
    {
      id: 'event',
      header: routeTimelineColumnIdToName['event'],
      cell: (event: RouteChangeLog) => {
        switch (`${event.routeUpdate.updateType.updateCategory}_${event.routeUpdate.updateType.updateType}`) {
          case 'GENERAL_CREATED':
            return <Box>Route created</Box>;
          case 'GENERAL_SEQUENCED':
            {
              const sequenceSummary = event.routeUpdate.routeSequenceUpdated?.sequenceSummary;
              const line1Text: string[] = ['Route sequence generated'];
              if (sequenceSummary) {
                if (typeof sequenceSummary.startTime === 'number') {
                  line1Text.push(`, start at ${timezoneManager.convertTimestampToString(sequenceSummary.startTime * 1000, { format: props.timeformat })}`);
                }
                if (typeof sequenceSummary.duration === 'number') {
                  line1Text.push(`, duration ${formatDuration(sequenceSummary.duration)}`);
                }
              }

              let lines: any[] = [];
              const departureSets = event.routeUpdate.routeSequenceUpdated?.sequenceSummary?.departureSets;
              if (Array.isArray(departureSets)) {
                lines = departureSets.map((departureSet, index) => {
                  return (
                    <Box key={index}>
                      Departure set{' '}
                      <Link
                        onFollow={(evt) => {
                          evt.preventDefault();
                          props.onDepartureSetClick(event.version, departureSet);
                        }}
                      >
                        {departureSet.departureSetId}
                      </Link>
                    </Box>
                  );
                });
              }
              return (
                <Box>
                  <Box>{line1Text.join('')}</Box>
                  {lines}
                </Box>
              );
            }
            break;
          case 'GENERAL_LOCKED':
            return <Box>Route locked</Box>;
          case 'GENERAL_STATUS_CHANGED':
            {
              const status = event?.routeUpdate?.routeStatusChanged?.status;
              if (Array.isArray(status)) {
                return <Box>Add route status: {status.map((item) => convertStatusToText(item)).join(', ')}</Box>;
              }

              return <Box>Unrecognized status update</Box>;
            }
            break;
          case 'GENERAL_CANCELED':
            {
              return <Box>Route canceled</Box>;
            }
            break;
          case 'GENERAL_COMPLETED':
            {
              return <Box>Route completed</Box>;
            }
            break;
          case 'GENERAL_DEPARTURE_SET_STATUS_CHANGED':
            {
              const departureSetId = event.routeUpdate?.departureSetUpdate?.departureSetId;
              const status = event.routeUpdate?.departureSetUpdate?.status;

              if (Array.isArray(status) && status.includes('ROUTE_UTR_OPERATION_COMPLETED') && typeof departureSetId === 'string') {
                return <Box>Departure set {departureSetId} operation is completed.</Box>;
              }

              if (Array.isArray(status) && status.includes('BATCHING_SIDELINED') && typeof departureSetId === 'string') {
                return <Box>Departure set {departureSetId} is sidelined.</Box>;
              }

              if (Array.isArray(status) && status.includes('BATCHING_STARTED') && typeof departureSetId === 'string') {
                return <Box>Start batching departure set {departureSetId}.</Box>;
              }

              if (Array.isArray(status) && status.includes('BATCHING_COMPLETED') && typeof departureSetId === 'string') {
                return <Box>Departure set {departureSetId} is batching completed.</Box>;
              }

              return <Box>⚠️ Unrecognized departure set status update</Box>;
            }
            break;
          case 'GENERAL_RESEQUENCED':
            {
              const duration = event.routeUpdate?.routeSequenceUpdated?.sequenceSummary?.duration;
              const startTime = event.routeUpdate?.routeSequenceUpdated?.sequenceSummary?.startTime;
              const departureSets = event.routeUpdate?.routeSequenceUpdated?.sequenceSummary?.departureSets;

              if (typeof duration === 'number' && typeof startTime === 'number' && Array.isArray(departureSets)) {
                const line1Text: string = `Re-sequence: route starts at ${timezoneManager.convertTimestampToString(startTime * 1000, { format: props.timeformat })}, duration ${formatDuration(
                  duration,
                )}`;
                const lines: any[] = departureSets.map((departureSet, index) => {
                  return (
                    <Box key={index}>
                      Departure set{' '}
                      <Link
                        onFollow={(evt) => {
                          evt.preventDefault();
                          props.onDepartureSetClick(event.version, departureSet);
                        }}
                      >
                        {departureSet.departureSetId}
                      </Link>
                    </Box>
                  );
                });
                return (
                  <Box>
                    <Box>{line1Text}</Box>
                    {lines}
                  </Box>
                );
              }

              return <Box>⚠️ Unrecognized re-sequence update</Box>;
            }
            break;
          case 'GENERAL_DISPATCH_DATE_CHANGED':
            {
              const newDispatchDate = event.routeUpdate?.dispatchDateUpdate?.dispatchDate;
              if (typeof newDispatchDate === 'string') {
                return <Box>Dispatch date changed to {newDispatchDate}</Box>;
              }
              return <Box>⚠️ Unrecognized dispatch date update</Box>;
            }
            break;
          case 'PLAN_DEPARTURE_WINDOW':
            {
              const departureSetId = event.routeUpdate?.departureSetUpdate?.departureSetId;
              const departureWindowAssigned = event.routeUpdate?.departureSetUpdate?.departureWindowAssigned;

              if (departureSetId && departureWindowAssigned) {
                const startTimestamp = departureWindowAssigned.departureWindow?.startTime;
                const endTimestamp = departureWindowAssigned.departureWindow?.endTime;
                const startTime = typeof startTimestamp === 'number' ? timezoneManager.convertTimestampToString(startTimestamp * 1000, { format: props.timeformat }) : NotAvaliable;
                const endTime = typeof endTimestamp === 'number' ? timezoneManager.convertTimestampToString(endTimestamp * 1000, { format: props.timeformat }) : NotAvaliable;

                return (
                  <Box>
                    <Box>Update departure set {departureSetId}</Box>
                    <Box>
                      departure window to {startTime} - {endTime}
                    </Box>
                  </Box>
                );
              }

              return <Box>⚠️ Unrecognized departure window update</Box>;
            }
            break;
          case 'PLAN_TRANSPORTER':
            {
              const transporterId = event.routeUpdate?.transporterAssigned?.transporterId;
              if (typeof transporterId === 'string') {
                return <Box>Route planned to driver {transporterId}</Box>;
              }

              return <Box>⚠️ Unrecognized plan transporter update</Box>;
            }
            break;
          case 'PLAN_TRANSPORTER_GROUP':
            {
              const groupType = event.routeUpdate?.transporterGroupAssigned?.transporterGroup?.groupType;
              let displayedGroupType = '';
              if (typeof groupType === 'string') {
                if (groupType === 'FLEX') {
                  displayedGroupType = 'Flex';
                } else {
                  const groupId = event.routeUpdate?.transporterGroupAssigned?.transporterGroup?.groupId;
                  const suffix = typeof groupId === 'string' ? ` - ${groupId}` : '';
                  displayedGroupType = `${groupType}${suffix}`;
                }
                return <Box>Route planned to {displayedGroupType}</Box>;
              }

              return <Box>⚠️ Unrecognized plan transporter update</Box>;
            }
            break;
          case 'PLAN_TRANSPORTER_UNASSIGN':
            {
              return <Box>Route unassigned</Box>;
            }
            break;
          case 'PLAN_SORT_INSTRUCTIONS':
            {
              const departureSetId = event.routeUpdate?.departureSetUpdate?.departureSetId;

              if (departureSetId) {
                return (
                  <Box>
                    <Box>Departure set {departureSetId} sort instructions planned</Box>
                  </Box>
                );
              }

              return <Box>⚠️ Unrecognized sort instructions change</Box>;
            }
            break;
          case 'OVERRIDE_TRANSPORTER':
            {
              const transporterId = event.routeUpdate?.transporterAssigned?.transporterId;
              if (typeof transporterId === 'string') {
                return <Box>Override route assignment to transporter {transporterId}</Box>;
              }
              return <Box>⚠️ Unrecognized overriden transporter update</Box>;
            }
            break;
          case 'OVERRIDE_TRANSPORTER_GROUP':
            {
              const transporterGroup = event.routeUpdate?.transporterGroupAssigned?.transporterGroup;
              if (typeof transporterGroup?.groupType === 'string') {
                return (
                  <Box>
                    Override route assignment to transporter group {transporterGroup.groupType} {transporterGroup.groupId}
                  </Box>
                );
              }
              return <Box>⚠️ Unrecognized overriden transporter group update</Box>;
            }
            break;
          case 'EXECUTION_ROUTE_STAGED':
            {
              const location = event.routeUpdate?.departureSetUpdate?.stageLocationUpdate?.stageLocation;
              const departureSetId = event.routeUpdate?.departureSetUpdate?.departureSetId;
              const status = event.routeUpdate?.departureSetUpdate?.status;
              if (typeof location === 'string' && typeof departureSetId === 'string') {
                return (
                  <Box>
                    <Box>Update departure set {departureSetId}</Box>
                    <Box>stage location to {location}</Box>
                  </Box>
                );
              } else if (Array.isArray(status) && status.includes('BATCHING_COMPLETED') && typeof departureSetId === 'string') {
                return <Box>Departure set {departureSetId} is batching completed.</Box>;
              }

              return <Box>⚠️ Unrecognized stage location update</Box>;
            }
            break;
          case 'EXECUTION_TRANSPORTER_WORK_ASSIGNED':
            {
              const transporterId = event.routeUpdate?.transporterAssigned?.transporterId;
              if (typeof transporterId === 'string') {
                return <Box>Route assigned to driver {transporterId}</Box>;
              }

              return <Box>⚠️ Unrecognized assigned transporter update</Box>;
            }
            break;
          case 'EXECUTION_TRANSPORTER_ACCEPT_PENDING':
            {
              const transporterId = event.routeUpdate?.transporterAssigned?.transporterId;
              if (typeof transporterId === 'string') {
                return <Box>Waiting transporter {transporterId} to accept</Box>;
              }

              return <Box>⚠️ Unrecognized transporter accept pending update</Box>;
            }
            break;
          case 'EXECUTION_TRANSPORTER_ACCEPTED':
            {
              const transporterId = event.routeUpdate?.transporterAssigned?.transporterId;
              if (typeof transporterId === 'string') {
                return <Box>Transporter {transporterId} accepted</Box>;
              }

              return <Box>⚠️ Unrecognized transporter accepted update</Box>;
            }
            break;
          case 'EXECUTION_PICK_STARTED':
            {
              const departureSetId = event.routeUpdate?.departureSetUpdate?.departureSetId;
              const status = event.routeUpdate?.departureSetUpdate?.status;

              if (Array.isArray(status) && status.includes('BATCHING_STARTED') && typeof departureSetId === 'string') {
                return <Box>Start batching departure set {departureSetId}.</Box>;
              }

              return <Box>⚠️ Unrecognized departure set status update</Box>;
            }
            break;
          case 'EXECUTION_TRANSPORTER_OFFER_PENDING':
            {
              const transporterId = event.routeUpdate?.transporterAssigned?.transporterId;
              if (typeof transporterId === 'string') {
                return <Box>IO offer pending - {transporterId}</Box>;
              }

              return <Box>⚠️ Unrecognized transporter offer pending update</Box>;
            }
            break;
          case 'EXECUTION_TRANSPORTER_OFFER_ACCEPTED':
            {
              const transporterId = event.routeUpdate?.transporterAssigned?.transporterId;
              if (typeof transporterId === 'string') {
                return <Box>IO offer accepted - {transporterId}</Box>;
              }

              return <Box>⚠️ Unrecognized transporter offer accepted update</Box>;
            }
            break;
          default:
            return <Box>{`⚠️ Unrecognized update ${event.routeUpdate.updateType.updateCategory}-${event.routeUpdate.updateType.updateType}`}</Box>;
        }
      },
    },
    {
      id: 'updateCategory',
      header: routeTimelineColumnIdToName['updateCategory'],
      width: 200,
      cell: (event: RouteChangeLog) => {
        return <Box>{lodash.capitalize(event.routeUpdate.updateType.updateCategory)}</Box>;
      },
    },
    {
      id: 'updateType',
      header: routeTimelineColumnIdToName['updateType'],
      width: 200,
      cell: (event: RouteChangeLog) => {
        return <Box>{lodash.capitalize(event.routeUpdate.updateType.updateType).split('_').join(' ')}</Box>;
      },
    },
    {
      id: 'updatedBy',
      header: routeTimelineColumnIdToName['updatedBy'],
      width: 200,
      cell: (event: RouteChangeLog) => {
        const system = systemDatabse.get(event.updatedBy);
        const accountIds = event.systemId?.match(AWS_ACCOUNT_ID_REGEX);
        if (system !== undefined) {
          const accountId = Array.isArray(accountIds) && accountIds.length > 1 ? accountIds[1] : undefined;
          let color = CANONICAL_NAME_TO_COLOR.get(system.canonicalName);
          color = color ? color : 'grey';
          return (
            <Popover
              dismissButton={false}
              size="small"
              triggerType="custom"
              content={
                <Box>
                  <SpaceBetween direction="vertical" size="s">
                    <Box>{system.canonicalName}</Box>
                    <Box>
                      AWS Account{' '}
                      {accountId ? (
                        <Link external href={`https://conduit.security.a2z.com/accounts/aws/${accountId}`}>
                          {accountId}
                        </Link>
                      ) : (
                        <Box color="text-status-error">Unknown</Box>
                      )}
                    </Box>
                  </SpaceBetween>
                </Box>
              }
            >
              <Badge color={color}>{system.abbreviation}</Badge>
            </Popover>
          );
        } else {
          return <Box>{event.updatedBy}</Box>;
        }
      },
    },
    {
      id: 'updatedAt',
      width: 200,
      header: routeTimelineColumnIdToName['updatedAt'],
      cell: (event: RouteChangeLog) => {
        return (
          <Box>
            {timezoneManager.convertTimestampToString(event.timestamp, {
              format: props.timeformat,
            })}
          </Box>
        );
      },
    },
  ];

  return columns.filter((column) => !props.hiddenColumnIds.includes(column.id));
}
