import React from 'react';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import ButtonDropdown from '@amzn/awsui-components-react/polaris/button-dropdown';
import Button from '@amzn/awsui-components-react/polaris/button';
import Input from '@amzn/awsui-components-react/polaris/input';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import Box from '@amzn/awsui-components-react/polaris/box';
import Form from '@amzn/awsui-components-react/polaris/form';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Link from '@amzn/awsui-components-react/polaris/link';
import Hotspot from '@amzn/awsui-components-react/polaris/hotspot';
import { RouteSearchType, UserSearch, UserSearchValidationMessages } from './models';
import { routeSearchTypeToText } from './constants';
import { validateUserSearch } from './utils';

export interface RouteSearchProps {
  readonly userSearch?: UserSearch;
  readonly onSearch: (search: UserSearch) => void;
  readonly isLoading: boolean;
}

interface RouteSearchState {
  readonly userSearch: UserSearch;
  readonly errorMessages: UserSearchValidationMessages;
}

const SUPPORTED_SEARCH_TYEPS: RouteSearchType[] = ['routeId', 'correlationId', 'trId', 'trackingId', 'transporterId'];

function buildEmptyUserSearch(type: RouteSearchType): UserSearch {
  return {
    type: type,
    text: '',
  };
}

export class RouteSearch extends React.Component<RouteSearchProps, RouteSearchState> {
  constructor(props: RouteSearchProps) {
    super(props);
    const userSearch: UserSearch = this.props.userSearch ? this.props.userSearch : buildEmptyUserSearch('routeId');
    this.state = {
      userSearch: userSearch,
      errorMessages: {},
    };
  }

  componentDidMount() {}

  private searchContent() {
    switch (this.state.userSearch.type) {
      case 'routeId': {
        return (
          <SpaceBetween direction="vertical" size="xs">
            {this.textInput()}
            {this.versionInput()}
          </SpaceBetween>
        );
      }
      case 'correlationId': {
        return (
          <SpaceBetween direction="vertical" size="xs">
            {this.textInput()}
          </SpaceBetween>
        );
      }
      case 'trId':
      case 'trackingId':
      case 'transporterId': {
        return (
          <SpaceBetween direction="vertical" size="xs">
            {this.searchLimitationAlert()}
            {this.textInput()}
            {this.serviceAreaIdInput()}
            {this.dispatchDateInput()}
          </SpaceBetween>
        );
      }
      default:
        return <Box>{this.state.userSearch.type} search is not implemented yet.</Box>;
    }
  }

  private textInput() {
    return (
      <FormField label={routeSearchTypeToText[this.state.userSearch.type]} errorText={this.state.errorMessages.textErrorMessage}>
        {this.state.userSearch.type === 'routeId' ? (
          <Hotspot side="right" hotspotId="route-search-input">
            {this.textInputBox()}
          </Hotspot>
        ) : (
          this.textInputBox()
        )}
      </FormField>
    );
  }

  private textInputBox() {
    return (
      <Input
        onChange={({ detail }) => {
          const newSearch: UserSearch = {
            ...this.state.userSearch,
            text: detail.value,
          };
          this.setState({ userSearch: newSearch });
        }}
        value={this.state.userSearch.text}
        placeholder={routeSearchTypeToText[this.state.userSearch.type]}
      />
    );
  }

  private versionInput() {
    return (
      <FormField label={'Route version'} description="Optional route version. Default returns the latest version." errorText={this.state.errorMessages.versionErrorMessage}>
        <Input
          onChange={({ detail }) => {
            const newSearch: UserSearch = {
              ...this.state.userSearch,
              version: detail.value,
            };
            this.setState({ userSearch: newSearch });
          }}
          value={this.state.userSearch.version ?? ''}
        />
      </FormField>
    );
  }

  private serviceAreaIdInput() {
    return (
      <FormField label={'Search area Id'} errorText={this.state.errorMessages.searchAreaIdErrorMessage}>
        <Input
          value={this.state.userSearch.serviceAreaId ?? ''}
          onChange={(event) => {
            const newSearch = {
              ...this.state.userSearch,
              serviceAreaId: event.detail.value,
            };
            this.setState({ userSearch: newSearch });
          }}
          placeholder="Example: 479968bb-e253-4c6e-a78a-1629507a8c63"
        />
      </FormField>
    );
  }

  private dispatchDateInput() {
    return (
      <FormField
        label={'Dispatch date'}
        description="Dispatch date in service area timezone. If left empty, the current date will be used."
        errorText={this.state.errorMessages.dispatchDateErrorMessage}
      >
        <Input
          value={this.state.userSearch.dispatchDate ?? ''}
          onChange={(event) => {
            const newSearch = {
              ...this.state.userSearch,
              dispatchDate: event.detail.value,
            };
            this.setState({ userSearch: newSearch });
          }}
          placeholder="YYYY-MM-DD"
        />
      </FormField>
    );
  }

  private searchLimitationAlert() {
    return (
      <Alert dismissAriaLabel="Close alert" header="Limitations">
        Due to the backend limitations, the website can only find a route by tr Id, tracking Id, or transporter Id when the route is opening. The wesbite can't find the route once it is closed or
        canceled.&nbsp;
        <Link external externalIconAriaLabel="Opens in a new tab" href="https://sim.amazon.com/issues/P78542919">
          SIM P78542919
        </Link>{' '}
        is tracking the limitation.
      </Alert>
    );
  }

  private header() {
    return (
      <Header
        variant="h2"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Hotspot side="left" hotspotId="route-search-type">
              <ButtonDropdown
                items={SUPPORTED_SEARCH_TYEPS.map((type) => ({ text: routeSearchTypeToText[type], id: type, disabled: false }))}
                onItemClick={(event) => {
                  const newSearch = {
                    ...this.state.userSearch,
                    type: event.detail.id as RouteSearchType,
                  };
                  this.setState({ userSearch: newSearch });
                }}
              >
                {routeSearchTypeToText[this.state.userSearch.type]}
              </ButtonDropdown>
            </Hotspot>
            <Hotspot side="right" hotspotId="route-search-button">
              <Button
                variant="primary"
                onClick={(evt) => {
                  // verify search is valid, update error message.
                  const errorMessages = validateUserSearch(this.state.userSearch);
                  if (errorMessages !== undefined) {
                    this.setState({ errorMessages: errorMessages });
                  } else {
                    this.setState({ errorMessages: {} });
                    this.props.onSearch(this.state.userSearch);
                  }
                }}
                disabled={this.props.isLoading}
                formAction="submit"
              >
                {this.props.isLoading ? <Spinner /> : null}
                Search
              </Button>
            </Hotspot>
          </SpaceBetween>
        }
      >
        Route Search
      </Header>
    );
  }

  render() {
    return (
      <form
        onSubmit={(e) => {
          // called when user hits Enter
          e.preventDefault();
          if (!this.props.isLoading) {
            const errorMessages = validateUserSearch(this.state.userSearch);
            if (errorMessages !== undefined) {
              this.setState({ errorMessages: errorMessages });
            } else {
              this.setState({ errorMessages: {} });
              this.props.onSearch(this.state.userSearch);
            }
          }
        }}
      >
        <Container
          header={
            <Hotspot side="left" hotspotId="introduction">
              {this.header()}
            </Hotspot>
          }
        >
          <Form>{this.searchContent()}</Form>
        </Container>
      </form>
    );
  }
}
