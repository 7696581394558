/**
 * reference: https://www.npmjs.com/package/aws4-axios
 *  https://code.amazon.com/packages/MidwayIdentityJavaScriptClient
 */

import axios, { AxiosRequestConfig } from 'axios';
import { AxiosInstance } from 'axios';
import { axiosSigV4Interceptor } from './axios-aws-sigv4-interceptor';
import { MidwayTokenRetriever } from './midway-token-retriever';
import { credentials } from '.';

interface Props {
  readonly baseUrl: string;
  readonly identityPoolId?: string;
  readonly awsRegion?: string;
}

/**
 * Build a axios http client that integrates with Midway and CognitoIdentityPool
 */
export class MidwayCognitoAuthClientBuilder {
  static build(props: Props): AxiosInstance {
    const client = axios.create({
      baseURL: props.baseUrl,
      timeout: 40000, // 40 second
    });

    client.interceptors.request.use(async (config: AxiosRequestConfig) => {
      config.headers['X-Midway-Token'] = await MidwayTokenRetriever.getTokenOrRedirect();
      return config;
    });

    if (typeof props.identityPoolId === 'string') {
      if (props.awsRegion === undefined) {
        throw new Error('MidwayCognitoAuthClientBuilder missing awsRegion.');
      }
      client.interceptors.request.use(
        axiosSigV4Interceptor(
          {
            region: props.awsRegion,
          },
          credentials,
        ),
      );
    }

    return client;
  }
}
