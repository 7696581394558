import React from 'react';
import Header from '@amzn/awsui-components-react/polaris/header';
import Table from '@amzn/awsui-components-react/polaris/table';
import Button from '@amzn/awsui-components-react/polaris/button';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Hotspot from '@amzn/awsui-components-react/polaris/hotspot';
import { download } from './utils';
import { buildSequenceTableDefinition } from './itinerary-table-definition';
import { Stop } from './models';
import { RouteSequence } from '../../clients';

export interface RouteItineraryProps {
  readonly version: number;
  readonly routeId: string;
  readonly stops: ReadonlyArray<Stop>;
  readonly sequence: RouteSequence;
  readonly selectedStopId?: string;
  readonly onSelectedStopIdChange: (identifier: string | undefined) => void;
}

interface RouteItineraryState {
  // start from 1
  readonly currentPage: number;
  readonly selectedStop?: Stop;
  readonly itemsInDisplay: ReadonlyArray<Stop>;
}

const ITEMS_PER_PAGE = 15;
export class RouteItinerary extends React.Component<RouteItineraryProps, RouteItineraryState> {
  constructor(props: RouteItineraryProps) {
    super(props);
    const page = this.inferSelectedStopIdPage(props.selectedStopId);
    this.state = {
      selectedStop: this.props.stops.find((stop) => stop.identifier === props.selectedStopId),
      currentPage: page,
      itemsInDisplay: this.itemsInCurrentPage(page),
    };
  }

  componentDidUpdate(prevProps: Readonly<RouteItineraryProps>): void {
    if (this.props.selectedStopId !== prevProps.selectedStopId || this.props.stops !== prevProps.stops) {
      const selectedStop = this.props.stops.find((stop) => stop.identifier === this.props.selectedStopId);
      const page = this.inferSelectedStopIdPage(this.props.selectedStopId);
      const items = this.itemsInCurrentPage(page);
      this.setState({
        selectedStop: selectedStop,
        currentPage: page,
        itemsInDisplay: items,
      });
    }
  }

  private header() {
    const description = `${this.props.routeId} (version ${this.props.version})`;
    return (
      <Header
        variant="h2"
        description={description}
        actions={
          <Hotspot side="left" hotspotId="sequence-download-btn">
            <Button
              onClick={(evt) => {
                download(this.props.sequence, `${this.props.routeId}-v${this.props.version}-sequence.json`);
              }}
            >
              Download
            </Button>
          </Hotspot>
        }
      >
        Sequence
      </Header>
    );
  }

  render() {
    return (
      <Table<Stop>
        header={
          <Hotspot side="left" hotspotId="route-sequence">
            {this.header()}
          </Hotspot>
        }
        resizableColumns={true}
        columnDefinitions={buildSequenceTableDefinition({
          version: this.props.version,
        })}
        items={this.state.itemsInDisplay}
        stickyHeader={false}
        selectionType="single"
        onSelectionChange={(evt) => {
          if (evt.detail.selectedItems.length > 0) {
            this.props.onSelectedStopIdChange(evt.detail.selectedItems[0].identifier);
          } else {
            this.props.onSelectedStopIdChange(undefined);
          }
        }}
        selectedItems={this.state.selectedStop ? [this.state.selectedStop] : undefined}
        pagination={this.renderPagination()}
      />
    );
  }

  private itemsInCurrentPage(page: number): ReadonlyArray<Stop> {
    return this.props.stops.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE);
  }

  private inferSelectedStopIdPage(stopId?: string): number {
    if (stopId === undefined) {
      return 1;
    } else {
      const index = this.props.stops.findIndex((stop) => stop.identifier === stopId);
      if (index === -1) {
        return 1;
      } else {
        return Math.ceil((index + 1) / ITEMS_PER_PAGE);
      }
    }
  }

  private renderPagination() {
    return (
      <Pagination
        ariaLabels={{
          nextPageLabel: 'Next page',
          previousPageLabel: 'Previous page',
          pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
        }}
        currentPageIndex={this.state.currentPage}
        onChange={({ detail }) => {
          const items = this.itemsInCurrentPage(detail.currentPageIndex);
          this.setState({
            currentPage: detail.currentPageIndex,
            itemsInDisplay: items,
          });
        }}
        pagesCount={Math.ceil(this.props.stops.length / ITEMS_PER_PAGE)}
      />
    );
  }
}
