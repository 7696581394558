import React from 'react';
import { GlobalContext } from '../../main-app/global-context';

import ufraaVizClient, { ServiceAreaSummary } from '../../clients';
import { TextEditor } from '../../shared-components/text-editor';
import { copyToClipboard } from '../../utilities';
import { SummaryField, getValue, INPUT_OPTIONS, OUTPUT_OPTIONS, TITLECASE_LOOKUP } from './models';

import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Header from '@amzn/awsui-components-react/polaris/header';
import ButtonDropdown from '@amzn/awsui-components-react/polaris/button-dropdown';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';

interface State {
  readonly inputText: string;
  readonly inputType: SummaryField;
  readonly outputText: string;
  readonly outputType: Exclude<SummaryField, 'autoDetect'>;
}

interface Props {}

const MAX_LINE = 35;
const MIN_LINE = 30;

export class ServiceAreaConversion extends React.Component<Props, State> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  private data?: ServiceAreaSummary[];

  constructor(props: Props) {
    super(props);
    this.state = {
      inputText: '',
      inputType: 'autoDetect',
      outputType: 'serviceAreaId',
      outputText: '',
    };
  }

  async componentDidMount() {
    this.context.updateBreadcrumbItems([{ text: 'Service Area Conversion', href: '/service-area-conversion' }]);

    try {
      this.data = await ufraaVizClient.getServiceAreaSummaries();
    } catch (err: any) {
      this.context.addNotification({
        type: 'error',
        header: 'Error',
        content: err.message,
      });
    }

    // if user hasn't entered text yet, default to first three stations
    if (this.state.inputText === '') {
      this.setState({
        inputText: this.data
          ? this.data!.slice(0, 3)
              .map((area) => area.stationCode)
              .join('\n')
          : '',
      });
    }

    this.refreshResults(this.state.inputText, this.state.inputType, this.state.outputType);
  }

  private detectInputType(areas: ServiceAreaSummary[]): SummaryField | undefined {
    const searchTokens = this.state.inputText.split(/\r?\n/).slice(0, 3);

    for (let i = 0; i < areas.length; i++) {
      const area = areas[i];
      if (searchTokens.includes(area.serviceAreaId)) {
        return 'serviceAreaId';
      } else if (searchTokens.includes(area.stationCode)) {
        return 'stationCode';
      } else if (searchTokens.includes(area.serviceAreaName)) {
        return 'serviceAreaName';
      }
    }

    return undefined;
  }

  private refreshResults(inputText: string, inputType: SummaryField, outputType: Exclude<SummaryField, 'autoDetect'>) {
    if (inputText.length === 0 || this.data === undefined) {
      return '';
    }

    if (inputType === 'autoDetect') {
      const detectedType = this.detectInputType(this.data ?? []);
      if (detectedType === undefined && inputText.includes('\n')) {
        return 'Cannot recognize input format.\nDouble check region.';
      }
      inputType = detectedType ?? 'stationCode';
    }

    const lookup = new Map<string, string>();
    this.data?.forEach((area) => {
      lookup.set(getValue(area, inputType!), getValue(area, outputType));
    });

    const results: string[] = [];
    inputText.split(/\r?\n/).forEach((token: string) => {
      if (token.length === 0) {
        results.push('');
      } else {
        results.push(lookup.get(token) ?? 'UNKNOWN');
      }
    });
    this.setState({ outputText: results.join('\n') });
  }

  render() {
    return (
      <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
        <Container
          header={
            <Header
              variant="h2"
              description={'No commas, capitalization matters.'}
              actions={
                <SpaceBetween direction="horizontal" size="s">
                  <ButtonDropdown
                    items={INPUT_OPTIONS}
                    onItemClick={(evt) => {
                      const newInputType: SummaryField = evt.detail.id as SummaryField;
                      this.setState({ inputType: newInputType });
                      this.refreshResults(this.state.inputText, newInputType, this.state.outputType);
                    }}
                  >
                    {' '}
                    {TITLECASE_LOOKUP[this.state.inputType]}
                  </ButtonDropdown>
                  <Button
                    onClick={() => {
                      this.setState({ inputText: '', outputText: '' });
                    }}
                  >
                    Clear
                  </Button>
                </SpaceBetween>
              }
            >
              Text Input
            </Header>
          }
        >
          <TextEditor
            data={this.state.inputText}
            minLine={MIN_LINE}
            maxLine={MAX_LINE}
            onChange={(newText: string) => {
              this.setState({ inputText: newText });
              this.refreshResults(newText, this.state.inputType, this.state.outputType);
            }}
          />
        </Container>

        <Container
          header={
            <Header
              variant="h2"
              description="Output values, read-only."
              actions={
                <SpaceBetween size="s" direction="horizontal">
                  <ButtonDropdown
                    items={OUTPUT_OPTIONS}
                    onItemClick={(evt) => {
                      const newOutputType: Exclude<SummaryField, 'autoDetect'> = evt.detail.id as Exclude<SummaryField, 'autoDetect'>;
                      this.setState({ outputType: newOutputType });
                      this.refreshResults(this.state.inputText, this.state.inputType, newOutputType);
                    }}
                  >
                    {TITLECASE_LOOKUP[this.state.outputType]}
                  </ButtonDropdown>
                  <Button
                    iconName="copy"
                    onClick={() => {
                      copyToClipboard(this.state.outputText ?? '');
                    }}
                  >
                    Copy
                  </Button>
                </SpaceBetween>
              }
            >
              Output
            </Header>
          }
        >
          <TextEditor data={this.state.outputText} minLine={MIN_LINE} maxLine={MAX_LINE} />
        </Container>
      </Grid>
    );
  }
}
