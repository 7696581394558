import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import ufraaVizClient, { AssignmentEvent } from '../../clients';
import Table, { TableProps } from '@amzn/awsui-components-react/polaris/table';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import ButtonDropdown from '@amzn/awsui-components-react/polaris/button-dropdown';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import { timezoneManager } from '../../utilities';
import * as lodash from 'lodash';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import CollectionPreferences, { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import { retrieveHiddenColumnIds, upsertHiddenColumnIds } from '../assignment-plan-page/utilities';

const RESOURCE_ID_DROPDOWN_TEXT = 'Resource Id';
const QUERY_DROPDOWN_TEXT = 'Query';
const EVENTS_TABLE_NAME = 'ASSIGNMENT_EVENTS_TABLE';

export type EventColumnDefinition = TableProps.ColumnDefinition<AssignmentEvent> & { id: string; removable?: boolean };

type PageStatus = 'EMPTY' | 'LOADING' | 'LOADED' | 'FAILED';

interface State {
  readonly analyticsEventsInCurrentPage?: AssignmentEvent[];
  readonly queryType: string;
  readonly queryText: string;
  readonly pageSize: number;
  readonly currentPage: number;
  readonly totalPage: number;
  readonly pageStatus: PageStatus;
  readonly hiddenColumnIds: string[];
}

interface Props {}

function renderTime(time: string | null) {
  return typeof time === 'string' ? timezoneManager.convertTimestampToString(time) : '-';
}

function renderString(attribute: string | null) {
  return typeof attribute === 'string' ? attribute : '-';
}

function renderArray(arr: string[] | null) {
  return Array.isArray(arr) ? arr.join(', ') : '-';
}

export class SearchPage extends React.Component<Props, State> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  private analyticsEvents?: AssignmentEvent[];
  private partitionedAnalyticsEvents?: AssignmentEvent[][];

  constructor(props: Props) {
    super(props);
    this.state = {
      queryType: 'RESOURCE_ID',
      queryText: '',
      pageSize: 20,
      currentPage: 1,
      totalPage: 1,
      pageStatus: 'EMPTY',
      hiddenColumnIds: retrieveHiddenColumnIds(EVENTS_TABLE_NAME),
    };
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.updateBreadcrumbItems([
      {
        text: 'Search',
        href: '/search',
      },
    ]);
  }

  partitionResults(currentPage: number) {
    if (this.analyticsEvents) {
      this.partitionedAnalyticsEvents = lodash.chunk(this.analyticsEvents, this.state.pageSize);
    }

    this.setState({
      analyticsEventsInCurrentPage: this.getAnalyticsEventsInCurrentPage(currentPage),
      currentPage: currentPage,
      totalPage: this.getTotalPage(),
    });
  }

  getAnalyticsEventsInCurrentPage(currentPage: number) {
    if (!this.partitionedAnalyticsEvents) {
      return undefined;
    } else if (currentPage <= this.partitionedAnalyticsEvents.length) {
      return this.partitionedAnalyticsEvents[currentPage - 1];
    } else {
      return [];
    }
  }

  getTotalPage(): number {
    return this.partitionedAnalyticsEvents ? this.partitionedAnalyticsEvents.length : 0;
  }

  renderHeader() {
    return (
      <Header
        variant="h2"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="primary"
              onClick={async () => {
                this.setState({ pageStatus: 'LOADING' });
                try {
                  this.analyticsEvents = await ufraaVizClient.getSearchByQueryIdRequest({
                    requestString: this.state.queryText,
                    searchType: this.state.queryType === 'RESOURCE_ID' ? 'RESOURCE_ID' : 'QUERY',
                  });
                } catch (err: any) {
                  this.setState({ pageStatus: 'FAILED' });
                  this.context.addNotification({
                    header: `There has been an error retrieving the events.`,
                    content: `Unable to display the events requested.`,
                    type: 'error',
                  });
                  return;
                }

                this.partitionResults(this.state.currentPage);
                this.setState({ pageStatus: 'LOADED' });
              }}
            >
              Submit
            </Button>
          </SpaceBetween>
        }
      >
        Search
      </Header>
    );
  }

  renderPagination() {
    return (
      <Pagination
        currentPageIndex={this.state.currentPage}
        pagesCount={this.state.totalPage}
        ariaLabels={{
          nextPageLabel: 'Next page',
          previousPageLabel: 'Previous page',
          pageLabel: (pageNumber) => `Page ${pageNumber} of ${this.state.totalPage}`,
        }}
        onChange={(evt) => {
          const selectedPage = evt.detail.currentPageIndex;
          this.setState({
            analyticsEventsInCurrentPage: this.getAnalyticsEventsInCurrentPage(selectedPage),
            currentPage: selectedPage,
          });
        }}
      />
    );
  }

  renderFilter() {
    return (
      <SpaceBetween direction="horizontal" size="xs">
        <ButtonDropdown
          items={[
            { text: RESOURCE_ID_DROPDOWN_TEXT, id: 'RESOURCE_ID', disabled: false },
            { text: QUERY_DROPDOWN_TEXT, id: 'QUERY', disabled: false },
          ]}
          onItemClick={(evt) => {
            this.setState({ queryType: evt.detail.id });
          }}
        >
          {this.state.queryType === 'RESOURCE_ID' ? RESOURCE_ID_DROPDOWN_TEXT : QUERY_DROPDOWN_TEXT}
        </ButtonDropdown>
        <TextFilter
          filteringText={this.state.queryText}
          filteringPlaceholder={this.state.queryType === 'RESOURCE_ID' ? 'Type a Resource Id, such as a TrId, OrderId ...' : '' + 'Type a CloudSearch query'}
          onChange={(evt) => {
            this.setState({ queryText: evt.detail.filteringText });
          }}
        />
      </SpaceBetween>
    );
  }

  renderEmptyTableMessage() {
    return (
      <Box textAlign="center" color="inherit">
        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
          No events to display.
        </Box>
      </Box>
    );
  }

  renderPreferences(columnOptions: CollectionPreferencesProps.VisibleContentOption[]) {
    const columnIds = columnOptions.map((option) => option.id);

    return (
      <CollectionPreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        preferences={{
          visibleContent: columnIds.filter((id) => !this.state.hiddenColumnIds.includes(id)),
        }}
        visibleContentPreference={{
          title: 'Select columns',
          options: [
            {
              label: 'Columns',
              options: columnOptions,
            },
          ],
        }}
        onConfirm={(evt) => {
          const visibleColumnIds = Array.from(evt.detail.visibleContent ? evt.detail.visibleContent : []);
          const hiddenColumnIds = columnIds.filter((id) => !visibleColumnIds.includes(id));
          upsertHiddenColumnIds(EVENTS_TABLE_NAME, hiddenColumnIds);
          this.setState({
            hiddenColumnIds: hiddenColumnIds,
          });
        }}
      />
    );
  }

  render() {
    const tableDefinition: EventColumnDefinition[] = [
      {
        id: 'creationTime',
        header: 'Creation Time',
        removable: false,
        cell: (item) => timezoneManager.convertTimestampToString(item.creationTime),
      },
      {
        id: 'planId',
        header: 'Plan ID',
        removable: true,
        cell: (item) => item.planId.split('/').pop(),
      },
      {
        id: 'serviceAreaId',
        header: 'Service Area ID',
        removable: true,
        cell: (item) => item.serviceAreaId,
      },
      {
        id: 'routeId',
        header: 'Route ID',
        removable: true,
        cell: (item) => renderString(item.routeId),
      },
      {
        id: 'transporterId',
        header: 'Transporter ID',
        removable: true,
        cell: (item) => renderString(item.transporterId),
      },
      {
        id: 'transporterType',
        header: 'Transporter Type',
        removable: true,
        cell: (item) => renderString(item.transporterType),
      },
      {
        id: 'offerWindowStart',
        header: 'Offer Window - Start',
        removable: true,
        cell: (item) => renderTime(item.offerWindowStart),
      },
      {
        id: 'offerWindowEnd',
        header: 'Offer Window - End',
        removable: true,
        cell: (item) => renderTime(item.offerWindowEnd),
      },
      {
        id: 'dispatchStart',
        header: 'Dispatch - Start',
        removable: true,
        cell: (item) => renderTime(item.dispatchStart),
      },
      {
        id: 'dispatchEnd',
        header: 'Dispatch - End',
        removable: true,
        cell: (item) => renderTime(item.dispatchEnd),
      },
      {
        id: 'blockStartTime',
        header: 'Block Start Time',
        removable: true,
        cell: (item) => renderTime(item.blockStartTime),
      },
      {
        id: 'blockEndTime',
        header: 'Block End Time',
        removable: true,
        cell: (item) => renderTime(item.blockEndTime),
      },
      {
        id: 'readyToWorkState',
        header: 'Ready To Work State',
        removable: true,
        cell: (item) => (typeof item.readyToWorkState === 'boolean' ? String(item.readyToWorkState) : '-'),
      },
      {
        id: 'routeDuration',
        header: 'Route Duration',
        removable: true,
        cell: (item) => (typeof item.routeDuration === 'number' ? item.routeDuration : '-'),
      },
      {
        id: 'trIds',
        header: 'Tr IDs',
        removable: true,
        cell: (item) => renderArray(item.trIds),
      },
      {
        id: 'orderIds',
        header: 'Order IDs',
        removable: true,
        cell: (item) => renderArray(item.orderIds),
      },
      {
        id: 'groupIds',
        header: 'Group IDs',
        removable: true,
        cell: (item) => renderArray(item.groupIds),
      },
      {
        id: 'scannableIds',
        header: 'Scannable Ids',
        removable: true,
        cell: (item) => renderArray(item.scannableIds),
      },
      {
        id: 'reason',
        header: 'Unassigned Route Reason',
        removable: true,
        cell: (item) => (typeof item.reason === 'string' ? item.reason : '-'),
      },
    ];

    const removableColumns = tableDefinition
      .filter((definition) => definition.removable)
      .map(
        (definition) =>
          ({
            id: definition.id,
            label: definition.header,
          } as CollectionPreferencesProps.VisibleContentOption),
      );

    // remove columns if the user disabled them.
    const filteredTableDefinitions = tableDefinition.filter((item) => !this.state.hiddenColumnIds.includes(item.id));

    return (
      <Table<AssignmentEvent>
        header={this.renderHeader()}
        columnDefinitions={filteredTableDefinitions}
        loading={this.state.pageStatus === 'LOADING'}
        loadingText={'Loading events...'}
        empty={this.renderEmptyTableMessage()}
        filter={this.renderFilter()}
        preferences={this.renderPreferences(removableColumns)}
        stickyHeader={true}
        resizableColumns={true}
        items={this.state.analyticsEventsInCurrentPage === undefined ? [] : this.state.analyticsEventsInCurrentPage}
        pagination={this.renderPagination()}
      />
    );
  }
}
