export interface System {
  readonly canonicalName: string;
  readonly abbreviation: string;
  readonly alias?: ReadonlyArray<string>;
  readonly oncallUrl?: string;
}

export interface SystemDatabase {
  get(name: string): System | undefined;
}

class LocalSystemDatabase implements SystemDatabase {
  private readonly aliasToSystem: Map<string, System>;

  constructor(systems: ReadonlyArray<System>) {
    this.aliasToSystem = new Map();
    for (let i = 0; i < systems.length; i++) {
      systems[i].alias?.forEach((alias) => {
        const system = this.aliasToSystem.get(alias);
        if (system !== undefined) {
          console.warn(`found duplicated system alias ${alias}`);
        } else {
          this.aliasToSystem.set(alias, systems[i]);
        }
      });
      this.aliasToSystem.set(systems[i].canonicalName, systems[i]);
      this.aliasToSystem.set(systems[i].abbreviation, systems[i]);
    }
  }

  get(name: string): System | undefined {
    return this.aliasToSystem.get(name);
  }

  abbreviation(name: string): string {
    const system = this.aliasToSystem.get(name);
    return system?.abbreviation ?? name;
  }
}

const DELIVERY_INJECTION_ONCALL = 'https://oncall.corp.amazon.com/#/view/delivery-injection-oncall/schedule';
const DELIVERY_EXECUTION_ONCALL = 'https://oncall.corp.amazon.com/#/view/delivery-execution-flow-team/schedule';
const FLEX_ASSIGNMENT_ONCALL = 'https://oncall.corp.amazon.com/#/view/flex-assignment/schedule';
const GSF_ROUTING_ONCALL = 'https://oncall.corp.amazon.com/#/view/flex-routing/schedule';
const RMS_ROUTING_ONCALL = 'https://oncall.corp.amazon.com/#/view/route-management/schedule';
const DISPATCH_PLANNING_ONCALL = 'https://oncall.corp.amazon.com/#/view/lmp-ssd-dev-primary/schedule';

const SYSTEMS: ReadonlyArray<System> = [
  {
    canonicalName: 'ATREX',
    abbreviation: 'ATREX',
  },
  {
    canonicalName: 'COREInterfaceService',
    abbreviation: 'COREInterfaceService',
    alias: ['COREInterfaceService:XORWorkflowSILService', 'COREInterfaceService:XORService'],
    oncallUrl: DELIVERY_INJECTION_ONCALL,
  },
  {
    canonicalName: 'COREPlanExecutor',
    abbreviation: 'PE',
    oncallUrl: DELIVERY_EXECUTION_ONCALL,
  },
  {
    canonicalName: 'DepartureSetStatusEventProcessor',
    abbreviation: 'UTR Orchestrator',
    oncallUrl: GSF_ROUTING_ONCALL,
  },
  {
    canonicalName: 'DispatchPlanningWorkflow',
    abbreviation: 'DPW',
    oncallUrl: DISPATCH_PLANNING_ONCALL,
  },
  {
    canonicalName: 'DynamicRouteInjector',
    abbreviation: 'DRI',
    oncallUrl: GSF_ROUTING_ONCALL,
  },
  {
    canonicalName: 'FlexRouteInjector (Deprecated)',
    abbreviation: 'FRI',
  },
  {
    canonicalName: 'FlexLordCommander',
    abbreviation: 'LC',
    alias: ['LordCommander'],
    oncallUrl: FLEX_ASSIGNMENT_ONCALL,
  },
  {
    canonicalName: 'FlexRouteAssignmentExecutionService',
    abbreviation: 'FRAES',
    oncallUrl: FLEX_ASSIGNMENT_ONCALL,
  },
  {
    canonicalName: 'P2PTransportRequestAssignmentService',
    abbreviation: 'PTRAS',
    alias: ['PTRAS_SESSION_SQS'],
    oncallUrl: DELIVERY_EXECUTION_ONCALL,
  },
  {
    canonicalName: 'PtrasRouteSyncProcessor',
    abbreviation: 'PtrasSync',
    alias: ['RouteSyncHandler'],
    oncallUrl: FLEX_ASSIGNMENT_ONCALL,
  },
  {
    canonicalName: 'LMRouteExecutionAnomalyHandler',
    abbreviation: 'REAH',
    alias: ['urn:cdo:LMRouteExecutionAnomalyHandler:AWS:Default'],
    oncallUrl: FLEX_ASSIGNMENT_ONCALL,
  },
  {
    canonicalName: 'Rabbit App',
    abbreviation: 'Rabbit',
    alias: ['Device', 'DEVICE'],
  },
  {
    canonicalName: 'RouteDispatchManager',
    abbreviation: 'RDM',
    oncallUrl: RMS_ROUTING_ONCALL,
  },
  {
    canonicalName: 'RoutePreparationManagerService',
    abbreviation: 'RPMS',
    oncallUrl: RMS_ROUTING_ONCALL,
  },
  {
    canonicalName: 'FlexPlatformScheduling',
    abbreviation: 'FPS',
  },
  {
    canonicalName: 'LastMileRoutePlanningEnrichmentService',
    abbreviation: 'LMRPES',
  },
  {
    canonicalName: 'LMRouteStoreRoutingPlanPopulation',
    abbreviation: 'RP',
    alias: ['ROUTE_POPULATION'],
  },
];

export const systemDatabse = new LocalSystemDatabase(SYSTEMS);
