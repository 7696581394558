import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import { GlobalContext } from '../../main-app/global-context';

interface Props {
  readonly serviceAreaId: string;
  readonly startTime?: number;
  readonly endTime?: number;
}

interface State {}

export class AssignmentTimelinePage extends React.Component<Props, State> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.context.resetLayout();
    this.context.updateBreadcrumbItems([
      {
        text: 'Assignment Timline',
        href: '/assignment-timline',
      },
      {
        text: this.props.serviceAreaId,
        href: `/assignment-timline/${this.props.serviceAreaId}`,
      },
    ]);
  }

  render() {
    return <Box>Coming soon</Box>;
  }
}
