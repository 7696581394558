import { MessageBundle } from '@amzn/arb-tools';
import { FdpAssignment, RouteProgramType } from '../../../clients';
import { AmzlTableRenderer } from './amzl-table-definition';
import { ASSIGNMENT_COLUMN_TO_LABEL, ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING, NOT_AVAILABLE } from './constants';
import { AssignmentCsvExporter } from './models';

export function convertNullable(default_: string, text?: string | null): string {
  return text ? text : default_;
}

export const ROUTE_PROGRAM_TYPE_TO_LABEL: Readonly<Record<RouteProgramType, string>> = {
  PRISTINE_SSD: 'Pristine SSD',
  PRISTINE_GROCERY: 'Pristine Grocery',
  COMMINGLED_SSD_GROCERY: 'Commingled',
  UNKNOWN: 'Unknown',
};

export function getCyclesFromAssignments(assignments: FdpAssignment[]): ReadonlyArray<string> {
  const cycleSet = new Set<string>();

  assignments.forEach((assignment) => {
    const cycleName = assignment.routeExtras?.cycleName;
    if (typeof cycleName === 'string' && !cycleSet.has(cycleName)) {
      cycleSet.add(cycleName);
    }
  });

  return Array.from(cycleSet.values());
}

// compare by driver start time, then driver shift duration, then route name
export function compareAmzlAssignments(a: FdpAssignment, b: FdpAssignment): number {
  const aStartTime = a.transporter?.originalShiftStartTime;
  const bStartTime = b.transporter?.originalShiftStartTime;
  const aBlockDuration = a.transporterExtras?.transporterDuration;
  const bBlockDuration = b.transporterExtras?.transporterDuration;

  if (aStartTime && bStartTime && new Date(aStartTime).getTime() !== new Date(bStartTime).getTime()) {
    return new Date(aStartTime).getTime() - new Date(bStartTime).getTime();
  } else if (aBlockDuration && bBlockDuration && aBlockDuration !== bBlockDuration) {
    return aBlockDuration - bBlockDuration;
  } else if (a.route?.routeName && b.route?.routeName) {
    if (a.route.routeName.length !== b.route.routeName.length) {
      return a.route.routeName.length - b.route.routeName.length; // first compare route names by length, i.e. CX8 vs CX13
    } else {
      return a.route.routeName.localeCompare(b.route.routeName); // otherwise compare route name alphabetically
    }
  }

  return 0;
}

function exportAMZLTableToCsv(assignments: ReadonlyArray<FdpAssignment>, hiddenColumnIds: ReadonlyArray<string>, cycleName: string, bundle?: MessageBundle): string {
  const visibleColumnIds = Object.keys(ASSIGNMENT_COLUMN_TO_LABEL).filter((item) => !hiddenColumnIds.includes(item));
  const csv: string[] = [
    visibleColumnIds.map((visibleColumnId) => (bundle ? bundle.getMessage(ASSIGNMENT_COLUMN_TO_RESOURCE_MAPPING[visibleColumnId]) : ASSIGNMENT_COLUMN_TO_LABEL[visibleColumnId])).join(','),
  ];
  const filteredAssignments = assignments
    .filter((assignment) => assignment.routeExtras?.cycleName === cycleName || assignment.transporter?.cycle?.cycleName === cycleName)
    .sort(compareAmzlAssignments);

  filteredAssignments.forEach((item) => {
    const row: string[] = [];
    for (var column of visibleColumnIds) {
      switch (column) {
        case 'routeName': {
          row.push(AmzlTableRenderer.renderRouteName(item));
          break;
        }
        case 'routeNotes': {
          row.push(AmzlTableRenderer.renderRouteNotes(item));
          break;
        }
        case 'routeCycle': {
          row.push(AmzlTableRenderer.renderRouteCycle(item));
          break;
        }
        case 'routeStartTime': {
          row.push(AmzlTableRenderer.renderRouteStartTime(item));
          break;
        }
        case 'routeNurseryLevel': {
          row.push(AmzlTableRenderer.renderRouteNurseryLevel(item));
          break;
        }
        case 'dispatchWindowStartTime': {
          row.push(AmzlTableRenderer.renderDispatchWindowStartTime(item));
          break;
        }
        case 'dispatchWindowEndTime': {
          row.push(AmzlTableRenderer.renderDispatchWindowEndTime(item));
          break;
        }
        case 'routeDuration': {
          row.push(AmzlTableRenderer.renderRouteDuration(item));
          break;
        }
        case 'packageCount': {
          row.push(AmzlTableRenderer.renderPackageCount(item));
          break;
        }
        case 'rejectionCount': {
          if (item.route) {
            const transporterIds = item.rejectedTransporterIds ?? [];
            row.push(transporterIds.length.toString());
          } else {
            row.push(NOT_AVAILABLE);
          }
          break;
        }
        case 'assignmentStatus': {
          row.push(AmzlTableRenderer.renderAssignmentStatus(item));
          break;
        }
        case 'transporterId': {
          row.push(AmzlTableRenderer.renderTransporterId(item));
          break;
        }
        case 'transporterName': {
          row.push(AmzlTableRenderer.renderTransporterName(item));
          break;
        }
        case 'transporterNotes': {
          row.push(AmzlTableRenderer.renderTransporterNotes(item));
          break;
        }
        case 'transporterCycleId': {
          row.push(AmzlTableRenderer.renderTransporterCycleId(item));
          break;
        }
        case 'trasnsporterNurseryLevel': {
          row.push(AmzlTableRenderer.renderTransporterNurseryLevel(item));
          break;
        }
        case 'transporterType': {
          row.push(AmzlTableRenderer.renderTransporterType(item));
          break;
        }
        case 'readyToWork': {
          row.push(AmzlTableRenderer.renderReadyToWork(item));
          break;
        }
        case 'driverShiftStart': {
          row.push(AmzlTableRenderer.renderDriverShiftStart(item));
          break;
        }
        case 'driverShiftEnd': {
          row.push(AmzlTableRenderer.renderDriverShiftEnd(item));
          break;
        }
        case 'driverBlockLength': {
          row.push(AmzlTableRenderer.renderDriverBlockLength(item));
          break;
        }
        case 'adjustedDriverShiftStart': {
          row.push(AmzlTableRenderer.renderAdjustedDriverShiftStart(item));
          break;
        }
        case 'adjustedDriverShiftEnd': {
          row.push(AmzlTableRenderer.renderAdjustedDriverShiftEnd(item));
          break;
        }
        case 'adjustedBlockLength': {
          row.push(AmzlTableRenderer.renderAdjustedBlockLength(item));
          break;
        }
        case 'affinityScore': {
          row.push(AmzlTableRenderer.renderAffinityScore(item));
          break;
        }
        default:
          console.warn('missing columnId for exporting AMZL table');
          break;
      }
    }
    csv.push(row.join(','));
  });

  return csv.join('\n');
}

export class AmzlAssignmentCsvExporter implements AssignmentCsvExporter {
  private assignments: ReadonlyArray<FdpAssignment>;

  constructor(assignments: ReadonlyArray<FdpAssignment>) {
    this.assignments = assignments;
  }

  export(hiddenColumnIds: ReadonlyArray<string>, cycleName?: string, bundle?: MessageBundle): string {
    return exportAMZLTableToCsv(this.assignments, hiddenColumnIds, cycleName!, bundle!);
  }
}
