import { COMPONENT_ARTIFACT_TYPE } from './models';

export const SUPPORTED_COMPONENT_ARTIFACT_TYPES: ReadonlyArray<COMPONENT_ARTIFACT_TYPE> = [
  'ROUTING_PLAN',
  'ASSIGNMENT_PLAN',
  'SHADOW_ASSIGNMENT_PLAN',
  'SNAPSHOT_SERVICE_AREA',
  'SNAPSHOT_ROUTES',
  'SNAPSHOT_PROVIDERS',
  'SNAPSHOT_PACKAGES',
  'SNAPSHOT_ROUTE_LABOR_ADJUSTMENT',
  'UBP_PLAN',
  'PICKUP_READY_PLAN',
];

// bundle resource are defined in /resources directory
export const COMPONENT_ARTIFACT_TYPE_TO_BUNDLE_RESOURCE: Readonly<Record<COMPONENT_ARTIFACT_TYPE, string>> = {
  ROUTING_PLAN: 'STATION_PAGE:ROUTING_PLANS',
  ASSIGNMENT_PLAN: 'STATION_PAGE:ASSIGNMENT_PLANS',
  SNAPSHOT_SERVICE_AREA: 'STATION_PAGE:SERVICE_AREA_SNAPSHOTS',
  SNAPSHOT_ROUTES: 'STATION_PAGE:ROUTE_SNAPSHOTS',
  SNAPSHOT_PROVIDERS: 'STATION_PAGE:PROVIDER_SNAPSHOTS',
  SNAPSHOT_PACKAGES: 'STATION_PAGE:PACKAGE_SNAPSHOTS',
  SNAPSHOT_ROUTE_LABOR_ADJUSTMENT: 'STATION_PAGE:RLAS_SNAPSHOTS',
  UBP_PLAN: 'STATION_PAGE:UBP_PLANS',
  PICKUP_READY_PLAN: 'STATION_PAGE:PICKUP_READY_PLANS',
  SHADOW_ASSIGNMENT_PLAN: '',
};
