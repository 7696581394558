import React from 'react';
import { Action, ActionType, Route, TransportRequestDetails } from '../../clients';
import Box from '@amzn/awsui-components-react/polaris/box';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import Timeline from 'rsuite/lib/Timeline';
import 'rsuite/lib/Timeline/styles';
import Icon from 'rsuite/lib/Icon';
import 'rsuite/lib/Icon/styles';
import { timezoneManager } from '../../utilities';
import './route-details.scss';
import { buildURLSearchParamsFromLmRouteUserSearch } from '../../main-app/url-utils';
import CopyText from '../../shared-components/copy-text';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
export interface RouteDetailsProps {
  readonly route: Route;
  readonly compactRouteWindow: boolean;
}

/**
 * Timeline component
 * Reference https://rsuitejs.com/components/timeline/
 */
interface Order {}

interface CollapsedAction {
  readonly startTime: string;
  readonly type: ActionType;
  // in seconds
  readonly transitDuration: number;
  // in meters
  readonly distanceInMeters: number;
  readonly trs: TransportRequestDetails[];
}

export function convertActions(actions: Action[]): CollapsedAction[] {
  const collapsedActions: CollapsedAction[] = [];
  let collapsedAction: CollapsedAction | undefined = undefined;
  for (let i = 0; i < actions.length; i++) {
    if (collapsedAction) {
      if (collapsedAction.startTime === actions[i].startTime && collapsedAction.type === actions[i].type && (collapsedAction.type === 'ADD' || collapsedAction.type === 'REMOVE')) {
        // collapsable.
        if (actions[i].transportRequestDetails) {
          collapsedAction.trs.push(actions[i].transportRequestDetails!);
          continue;
        }
      } else {
        collapsedActions.push(collapsedAction);
      }
    }

    const trs: TransportRequestDetails[] = [];
    if (actions[i].transportRequestDetails) {
      trs.push(actions[i].transportRequestDetails!);
    }
    collapsedAction = {
      startTime: actions[i].startTime,
      type: actions[i].type,
      transitDuration: (actions[i].durationInSeconds === null ? 0 : actions[i].durationInSeconds) as number,
      distanceInMeters: (actions[i].distanceInMeters === null ? 0 : actions[i].distanceInMeters) as number,
      trs,
    };
  }

  if (collapsedAction) {
    collapsedActions.push(collapsedAction);
  }

  return collapsedActions;
}

export class RouteDetails extends React.Component<RouteDetailsProps, {}> {
  renderStartAction(action: CollapsedAction, index: number) {
    return (
      <Timeline.Item key={index} dot={<Icon icon="user" size="2x" />}>
        <Box>{timezoneManager.convertTimestampToString(action.startTime)}</Box>
        <Box>[{action.type}]</Box>
      </Timeline.Item>
    );
  }

  renderTransitAction(action: CollapsedAction, index: number) {
    return (
      <Timeline.Item key={index} dot={<Icon icon="car" size="2x" />}>
        <Box>{timezoneManager.convertTimestampToString(action.startTime)}</Box>
        <Box>
          [{action.type}] Distance {action.distanceInMeters} meters, duration {action.transitDuration} seconds
        </Box>
      </Timeline.Item>
    );
  }

  renderAddOrRemoveAction(action: CollapsedAction, index: number, displayTrIds: boolean) {
    let actionContents: string[] = [];

    if (displayTrIds) {
      actionContents = action.trs.map((tr) => `${tr.orderId} ${tr.transportRequestId}`);
    } else {
      for (let tr of action.trs) {
        if (!actionContents.includes(tr.orderId)) {
          actionContents.push(tr.orderId);
        }
      }
    }

    return (
      <Timeline.Item key={index} dot={<Icon icon={action.type === 'ADD' ? 'angle-double-up' : 'angle-double-down'} size="2x" />}>
        <Box>{timezoneManager.convertTimestampToString(action.startTime)}</Box>
        <Box>[{action.type}]</Box>
        <Box>
          {actionContents.map((content, index) => {
            return <Box key={index}>{content}</Box>;
          })}
        </Box>
      </Timeline.Item>
    );
  }

  renderAction(action: CollapsedAction, index: number, compactRouteWindow: boolean) {
    switch (action.type) {
      case 'START':
        return this.renderStartAction(action, index);
      case 'TRANSIT':
        return this.renderTransitAction(action, index);
      case 'ADD':
      case 'REMOVE':
        return this.renderAddOrRemoveAction(action, index, !compactRouteWindow);
      default:
        return this.renderStartAction(action, index);
    }
  }

  render() {
    const collapsedActions = convertActions(this.props.route?.actions ? this.props.route?.actions : []);
    return (
      <Box>
        <Timeline className="custom-timeline">{collapsedActions.map((action, index) => this.renderAction(action, index, this.props.compactRouteWindow))}</Timeline>
      </Box>
    );
  }
}

export interface RouteDetailsPropsModal extends RouteDetailsProps {
  readonly onClose: () => void;
  readonly onRouteClick: (routeId: string, version: number) => void;
}

export class RouteDetailsModal extends React.Component<RouteDetailsPropsModal, {}> {
  render() {
    return (
      <Modal
        onDismiss={() => this.props.onClose()}
        header={
          <Header
            variant="h3"
            description={
              <SpaceBetween direction="horizontal" size="xs">
                <CopyText copyText={this.props.route.routeId} />
                <Link
                  onFollow={(evt) => {
                    evt.preventDefault();
                    this.props.onRouteClick(this.props.route.routeId, this.props.route.version);
                  }}
                  href={`/route?${new URLSearchParams(
                    buildURLSearchParamsFromLmRouteUserSearch({ type: 'routeId', text: this.props.route.routeId, version: this.props.route.version.toString() }),
                  ).toString()}`}
                >
                  (v{this.props.route.version})
                </Link>
              </SpaceBetween>
            }
          >
            Route
          </Header>
        }
        visible={true}
        closeAriaLabel="Close modal"
        size="medium"
      >
        <RouteDetails route={this.props.route} compactRouteWindow={this.props.compactRouteWindow} />
      </Modal>
    );
  }
}
