import React from 'react';
import { ExecutionPlanInfo, Order, RouteInfo } from '../../clients';
import { OrderDetailModal } from './order-details';
import { Box, Button, Cards, ColumnLayout, SpaceBetween } from '@amzn/awsui-components-react';
import { timezoneManager } from '../../utilities';
import { PlannedRouteActions } from './route-actions';
import { NOT_AVAILABLE, TIME_FORMAT } from './utilities/constants';

export interface PlannedRoutesInfoProps {
  readonly planInfo: ExecutionPlanInfo;
}

export interface PlannedRoutesInfoState {
  readonly selectedOrderDetails?: Order;
  readonly showPartialCompletedRoutes?: boolean;
}

export class PlannedRoutesInfo extends React.Component<PlannedRoutesInfoProps, PlannedRoutesInfoState> {
  constructor(props: PlannedRoutesInfoProps) {
    super(props);

    this.state = {
      selectedOrderDetails: undefined,
      showPartialCompletedRoutes: false,
    };
  }

  render() {
    return (
      <SpaceBetween size={'s'}>
        <Box>{this.renderRoutes(this.props.planInfo.plannedRoutes)}</Box>
        <Button
          variant="primary"
          onClick={(evt) => {
            evt.preventDefault();
            this.setState({
              showPartialCompletedRoutes: !this.state.showPartialCompletedRoutes,
            });
          }}
        >
          {this.state.showPartialCompletedRoutes ? 'Hide' : 'Show'} Partially Completed Routes
        </Button>
        {this.state.showPartialCompletedRoutes && <Box>{this.renderRoutes(this.props.planInfo.partiallyCompletedRoutes)}</Box>}
      </SpaceBetween>
    );
  }

  private renderRoutes(routes: RouteInfo[] | undefined) {
    return (
      <React.Fragment>
        {this.renderSelectedOrder()}
        <Cards<RouteInfo>
          cardDefinition={{
            header: (item) => {
              return (
                <>
                  <Box variant="h3">Route {item.index}:</Box>
                  <Box variant="p">
                    {item.routeId} {item.driverType}
                  </Box>
                </>
              );
            },
            sections: [
              {
                id: 'route',
                header: 'Route',
                content: (item) => this.renderRouteDetails(item),
              },
            ],
          }}
          cardsPerRow={[{ cards: 1 }]}
          items={routes ?? []}
          loadingText="Loading ..."
        />
      </React.Fragment>
    );
  }

  private renderRouteDetails(routeInfo: RouteInfo) {
    return (
      <SpaceBetween direction="vertical" size="m">
        <Box>
          {this.props.planInfo.plannedRoutes ? (
            <PlannedRouteActions routeSteps={routeInfo.routeSteps ? routeInfo.routeSteps : []} onOrderClick={(action) => this.setState({ selectedOrderDetails: action })} />
          ) : null}
        </Box>
        <Box>{this.renderRouteInfo(routeInfo)}</Box>
      </SpaceBetween>
    );
  }

  private renderRouteInfo(routeInfo: RouteInfo) {
    return (
      <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween direction="vertical" size="s">
          <Box>Lock Status: {routeInfo.lockStatus}</Box>
          <Box>Route Duration Minutes: {timezoneManager.convertSecondsToMinutes(routeInfo.routeDuration)}</Box>
          <Box>Route Total Volume: {routeInfo.routeTotalVolume.value ? routeInfo.routeTotalVolume.value.toFixed(2) : 0} Cubic Ft.</Box>
        </SpaceBetween>
        <SpaceBetween direction="vertical" size="s">
          <Box>Route Total Weight: {routeInfo.routeTotalWeight.value ? routeInfo.routeTotalWeight.value.toFixed(2) : 0} pounds</Box>
          <Box>Route Provider Type: {routeInfo.driverType}</Box>
          <Box>Vehicle Type: {routeInfo.vehicleType ?? NOT_AVAILABLE}</Box>
        </SpaceBetween>
        <SpaceBetween direction="vertical" size="s">
          <Box>Transporter Duration Minutes: {routeInfo.scheduleInfo?.scheduleDuration ?? NOT_AVAILABLE}</Box>
          <Box>
            Transporter Block:{' '}
            {routeInfo.scheduleInfo?.scheduleStart
              ? timezoneManager.convertTimestampToString(routeInfo.scheduleInfo?.scheduleStart, { format: TIME_FORMAT }) +
                '-' +
                timezoneManager.convertTimestampToString(routeInfo.scheduleInfo?.scheduleEnd, { format: TIME_FORMAT })
              : NOT_AVAILABLE}
          </Box>
          <Box>Suggested Transporter: {routeInfo.transporterInfo?.id ?? NOT_AVAILABLE}</Box>
        </SpaceBetween>
      </ColumnLayout>
    );
  }

  private renderSelectedOrder() {
    if (this.state.selectedOrderDetails) {
      return (
        <OrderDetailModal
          orderDetails={this.state.selectedOrderDetails}
          onClose={() => {
            this.setState({ selectedOrderDetails: undefined });
          }}
        />
      );
    }
  }
}
