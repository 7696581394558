export interface JsonFileDropEvent {
  readonly filename: string;
  readonly data: any;
}
export function handleJsonFileDropEvent(dataTransfer: DataTransfer): Promise<JsonFileDropEvent> {
  const promise = new Promise<any | undefined>((resolve, reject) => {
    if (dataTransfer.items.length === 0) {
      reject(new Error('Missing input data.'));
      return;
    }

    if (dataTransfer.items.length > 1) {
      reject(new Error('Drop one file at a time.'));
      return;
    }

    const file = dataTransfer.items[0].getAsFile();

    if (file === null) {
      reject(new Error('Input file is not a valid JSON file.'));
      return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', (evt) => {
      const text = evt.target?.result;
      if (typeof text === 'string') {
        try {
          const result: JsonFileDropEvent = {
            filename: file.name,
            data: JSON.parse(text),
          };
          resolve(result);
        } catch (err) {
          reject(new Error('Input file is not a valid JSON file.'));
          return;
        }
      } else {
        reject(new Error('Input file is not a valid JSON file.'));
        return;
      }
    });

    reader.addEventListener('error', (evt) => {
      // example error: the user drop a directory
      reject(new Error('Input file is not a valid JSON file.'));
      return;
    });
    reader.readAsText(file, 'utf-8');
  });

  return promise;
}
