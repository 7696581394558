export const NOT_AVAILABLE = '-';
export const TIME_FORMAT = 'HH:mm:ss z';
export const MS_MULTIPLIER = 1000;
export const INSTANT_OFFER_DRIVER_SUBSTRING = 'instantoffers';
export const OUTSIDE_PLANNING_HORIZON = 'OutsidePlanningHorizon';
export const ESTIMATE_TYPE = 'ESTIMATE';

export enum TRANSPORTER_TYPE {
  BLOCK = 'BLOCK',
  INSTANT_OFFER = 'INSTANT_OFFER',
  OVERFLOW = 'OVERFLOW',
}

export enum ACTION_TYPE {
  ADD = 'Add',
  REMOVE = 'Remove',
  EXECUTE = 'Execute',
  WAIT = 'Wait',
  START = 'Start',
  TRANSIT = 'Transit',
}

export enum LOCK_STATUS {
  LOCKED = 'LOCKED',
  UNLOCKED = 'UNLOCKED',
}

export enum WeightUnitConstants {
  LB = 'LB',
  KG = 'KG',
  G = 'G',
}

export enum VolumeUnitConstants {
  CU_FT = 'CU_FT',
  CU_IN = 'CU_IN',
  CU_CM = 'CU_CM',
  CU_M = 'CU_M',
}

export enum DimensionUnitConstants {
  IN = 'IN',
  CM = 'CM',
  M = 'M',
}

export enum CapacityConstants {
  VOLUME = 'VOLUME',
  WEIGHT = 'WEIGHT',
}
