import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import moment from 'moment';

import ufraaVizClient, { ArtifactMetadata, ArtifactType, ServiceAreaSummary, Artifact, ExecutionPlan, ServiceAreaDetails } from '../../clients';
import TimeRangePicker from '../../shared-components/time-range-picker';
import { validRangeResult } from '../network-health-page/utilities';
import { timezoneManager } from '../../utilities';
import { SelectionType, asOptionDefinition } from './utilities';
import TimelineParser from './snapshot-parser';
import TrViewTable from './tr-table';
import SummaryBox from './summary-box';

import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';

import Container from '@amzn/awsui-components-react/polaris/container';
import Box from '@amzn/awsui-components-react/polaris/box';
import Header from '@amzn/awsui-components-react/polaris/header';
import Select from '@amzn/awsui-components-react/polaris/select';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Form from '@amzn/awsui-components-react/polaris/form';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import ProgressBar from '@amzn/awsui-components-react/polaris/progress-bar';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';

// For the scope dropdown,
//   ideally would use "auto" filtering here however rendering stutters with 1000+ SAs.
// Can't dynamically switch between auto and manual either without bugs
//   so do a "homemade" auto filtering that only kicks after a few characters.
const MIN_FILTER_CHARS = 2;

const ARTIFACT_TYPE: ArtifactType = 'EXECUTION_PLAN';
const ARTIFACT_TYPE_NAME: string = 'Execution Plan';

interface State {
  readonly serviceAreaSummaries?: ReadonlyArray<ServiceAreaSummary>;
  readonly filterText: string;
  readonly selectedServiceArea?: OptionDefinition;
  readonly serviceAreaDetails?: ServiceAreaDetails;

  readonly selectedOrder?: OptionDefinition;
  readonly selectedTr?: OptionDefinition;
  readonly selectorType?: SelectionType;

  // could do this as a single anchor time & a fixed 2 hour interval
  readonly startTimeMs: number;
  readonly endTimeMs: number;

  readonly snapshotMetadata?: ReadonlyArray<ArtifactMetadata>;
  readonly completedDownloads: number;

  readonly parsedDataReady: boolean;
  readonly currentlyParsing: boolean;
}

interface Props {}

export default class OrderTimelinePage extends React.Component<Props, State> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  // we are trying to keep the (potentially) gigabytes of snapshot data outside react state
  private parser?: TimelineParser = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      serviceAreaSummaries: undefined,
      filterText: '',
      selectedServiceArea: undefined,
      serviceAreaDetails: undefined,
      selectedOrder: undefined,
      selectedTr: undefined,
      startTimeMs: moment().subtract(10, 'minutes').valueOf(),
      endTimeMs: moment().valueOf(),
      snapshotMetadata: undefined,
      completedDownloads: 0,
      parsedDataReady: false,
      currentlyParsing: false,
    };
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.updateBreadcrumbItems([{ text: 'Order Timeline', href: '/order-timeline' }]);
    try {
      this.setState({ serviceAreaSummaries: await ufraaVizClient.getServiceAreaSummaries() });
    } catch (err: any) {
      this.context.addNotification({
        type: 'error',
        header: 'Error',
        content: err.message,
      });
    }
  }

  render() {
    return (
      <Grid gridDefinition={[{ colspan: { default: 12, xs: 6, xl: 4 } }, { colspan: { default: 12, xs: 6, xl: 4 } }, { colspan: { default: 12, xl: 8 } }, { colspan: { default: 12, xl: 8 } }]}>
        <Container header={<Header variant="h3">{'1) Fetch Snapshots'}</Header>}>
          <Form actions={this.renderDownloadButton()}>
            <SpaceBetween direction="vertical" size="m">
              {this.renderScopeDropdown()}
              {this.renderTimeRangeInput()}
            </SpaceBetween>
          </Form>
        </Container>
        <Container header={<Box variant="h3">{'2) Select Tr Id'}</Box>}>
          <SpaceBetween direction="vertical" size="m">
            {this.state.snapshotMetadata === undefined ? (
              <StatusIndicator type="in-progress">First fetch some snapshots.</StatusIndicator>
            ) : (
              <React.Fragment>
                {this.renderDownloadProgressBar()}
                {this.renderIdSelectionGroup('Select an Order', this.state.selectorType === 'ORDER', this.renderOrderSelect())}
                {this.renderIdSelectionGroup('Or select a TR', this.state.selectorType === 'TR', this.renderTrSelect())}
              </React.Fragment>
            )}
          </SpaceBetween>
        </Container>
        {this.state.parsedDataReady && (this.state.selectedOrder || this.state.selectedTr) ? (
          <SummaryBox
            metadata={this.state.snapshotMetadata ?? []}
            artifacts={this.parser?.parsedInfo ?? []}
            selectorType={this.state.selectorType}
            selectedTr={this.state.selectedTr?.value}
            setTrCallback={(tr: string) =>
              this.setState({
                selectedTr: asOptionDefinition(tr),
                selectorType: 'TR',
              })
            }
            selectedOrder={this.state.selectedOrder?.value}
            setOrderCallback={(order: string) =>
              this.setState({
                selectedOrder: asOptionDefinition(order),
                selectorType: 'ORDER',
              })
            }
          />
        ) : null}
        {this.renderTimeline()}
      </Grid>
    );
  }

  private renderScopeDropdown() {
    const summaries = this.state.serviceAreaSummaries ?? [];

    const options: OptionDefinition[] = new Array<OptionDefinition>();
    if (this.state.filterText.length >= MIN_FILTER_CHARS) {
      summaries.forEach((summary) => {
        options.push({
          label: summary.stationCode,
          value: summary.serviceAreaId,
          tags: [summary.stationCode, summary.serviceAreaName, summary.serviceAreaId],
        });
      });
    }

    return (
      // TODO -- marking the "missing SA" error text here
      <FormField label="Service Area">
        <Select
          selectedOption={this.state.selectedServiceArea ?? null}
          options={options}
          statusType={this.state.serviceAreaSummaries === undefined ? 'loading' : undefined}
          loadingText="Loading area summaries..."
          placeholder="Select a service area"
          filteringType={'auto'}
          filteringPlaceholder={`Type at least ${MIN_FILTER_CHARS} characters to see options`}
          onChange={async ({ detail }) => {
            try {
              if (detail.selectedOption.value !== undefined) {
                timezoneManager.setStationTimezone((await ufraaVizClient.getServiceAreaDetails(detail.selectedOption.value)).timeZone);
              }
            } catch (err) {
              this.context.addNotification({
                header: 'Error',
                content: `Cannot find service area ${detail.selectedOption.value}`,
                type: 'error',
              });
              timezoneManager.setStationTimezone(undefined);
            }
            this.setState({ selectedServiceArea: detail.selectedOption });
          }}
          onLoadItems={(evt) => {
            this.setState({
              filterText: evt.detail.filteringText,
            });
          }}
        />
      </FormField>
    );
  }

  private renderTimeRangeInput() {
    // TODO will have PTRS data fill the picker automatically
    return (
      <FormField label="Time Range">
        <TimeRangePicker
          timezonePreference={timezoneManager.getInUseTimezonePreference()}
          utcOffset={timezoneManager.getUTCOffset()}
          startTime={this.state.startTimeMs.valueOf()}
          endTime={this.state.endTimeMs.valueOf()}
          onChange={(startTime, endTime) =>
            this.setState({
              startTimeMs: startTime,
              endTimeMs: endTime,
            })
          }
          isValidRange={(startTime, endTime) => validRangeResult(startTime, endTime)}
        />
      </FormField>
    );
  }

  private renderDownloadButton() {
    return (
      <Button
        variant="normal"
        disabled={!this.state.selectedServiceArea}
        loading={this.state.currentlyParsing}
        onClick={async () => {
          const scope = this.state.selectedServiceArea?.value;
          if (scope === undefined) {
            return;
          }
          this.setState({
            currentlyParsing: true,
            parsedDataReady: false,
          });
          this.parser = new TimelineParser(await this.downloadData(scope));
          this.setState({
            currentlyParsing: false,
            parsedDataReady: true,
          });
        }}
      >
        Fetch Snapshots
      </Button>
    );
  }

  private async downloadData(scope: string): Promise<Artifact<ExecutionPlan>[]> {
    const metadata = await ufraaVizClient.listArtifactMetadata({
      scope: scope,
      artifactType: ARTIFACT_TYPE,
      startTime: this.state.startTimeMs,
      endTime: this.state.endTimeMs,
      timeUnits: 'MILLISECONDS',
    });

    this.setState({
      snapshotMetadata: metadata, // TODO we can filter out cached ones here
    });

    if (metadata.length === 0) {
      return [];
    }

    const downloadStream = ufraaVizClient.getArtifactsFromReferences_incrementally({
      artifactIdentifiers: metadata,
    });

    this.setState({ completedDownloads: 0 });
    const plans: Artifact<ExecutionPlan>[] = [];
    for await (const artifactBatch of downloadStream) {
      // we do this generator trick to both: batch calls, have an accurate loading bar
      artifactBatch.forEach((artifact) => {
        plans.push(artifact as any);
      });
      this.setState({ completedDownloads: plans.length });
    }
    return plans;
  }

  private renderDownloadProgressBar() {
    if (this.state.snapshotMetadata === undefined) {
      return;
    }

    const done = this.state.completedDownloads;
    const total = this.state.snapshotMetadata.length;

    if (total === 0) {
      return <ProgressBar label="Artifact Download" status="error" resultText={`No ${ARTIFACT_TYPE_NAME}s found in given range.`} />;
    } else if (this.state.parsedDataReady) {
      return <ProgressBar label="Artifact Download" status="success" resultText={`Downloaded ${done} artifacts.`} />;
    } else {
      return <ProgressBar label="Artifact Download" value={100 * (done / total)} description={`Downloaded ${done}/${total} snapshots.`} />;
    }
  }

  private renderOrderSelect() {
    const options: OptionDefinition[] | undefined = this.parser?.allOrders.map((id) => {
      return { label: id, value: id };
    });

    return (
      <Select
        key="order-select"
        selectedOption={this.state.selectorType === 'ORDER' ? this.state.selectedOrder ?? null : null}
        disabled={options === undefined}
        options={options ?? []}
        placeholder="Order Id"
        filteringType={'auto'}
        filteringPlaceholder={'search'}
        onChange={({ detail }) =>
          this.setState({
            selectedOrder: detail.selectedOption,
            selectorType: 'ORDER',
          })
        }
      />
    );
  }

  private renderTrSelect() {
    const options: OptionDefinition[] | undefined = this.parser?.allTrs.map((id) => {
      return { label: id, value: id };
    });

    return (
      <Select
        key="tr-select"
        selectedOption={this.state.selectorType === 'TR' ? this.state.selectedTr ?? null : null}
        disabled={options === undefined}
        options={options ?? []}
        placeholder="TR id"
        filteringType={'auto'}
        filteringPlaceholder={'search'}
        onChange={({ detail }) =>
          this.setState({
            selectedTr: detail.selectedOption,
            selectorType: 'TR',
          })
        }
      />
    );
  }

  private renderIdSelectionGroup(label: string, inUse: boolean, selectionBar?: JSX.Element | undefined) {
    if (this.state.snapshotMetadata === undefined || this.state.snapshotMetadata.length === 0) {
      return undefined;
    }

    return (
      <SpaceBetween direction="vertical" size="xxs">
        <Box color="text-body-secondary"> {label} </Box>
        {selectionBar}
      </SpaceBetween>
    );
  }

  private renderTimeline() {
    const parser = this.parser;

    let lookupId: string | undefined;
    if (this.state.selectorType === 'TR') {
      lookupId = this.state.selectedTr?.value;
    }

    if (this.state.selectorType === 'ORDER') {
      lookupId = this.state.selectedOrder?.value;
    }

    return <TrViewTable key={lookupId} rawInfo={parser?.parsedInfo ?? []} lookupId={lookupId} idType={this.state.selectorType} />;
  }
}
