import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import Link from '@amzn/awsui-components-react/polaris/link';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { JsonVisualizationDataType, JsonVisualizationTableColumnDefinition, Row } from './models';
import { timezoneManager } from '../../utilities';

interface Props {
  readonly columns: ReadonlyArray<JsonVisualizationTableColumnDefinition>;
  readonly onGeocodeClick: (lat: number, long: number) => void;
}

interface RenderDataProps {
  readonly onGeocodeClick: (lat: number, long: number) => void;
}

export function buildColumnDefinitions(props: Props): TableProps.ColumnDefinition<Row>[] {
  const columns: TableProps.ColumnDefinition<Row>[] = [];
  props.columns.forEach((column) => {
    columns.push({
      header: column.columnName,
      cell: (item) => {
        return renderDataArray(item, column.columnId, column.dataType, props);
      },
    });
  });
  return columns;
}

function renderDataArray(row: Row, key: string, type: JsonVisualizationDataType, props: RenderDataProps) {
  const values = row[key];
  if (Array.isArray(values) && values.length > 0) {
    return (
      <Box>
        {values.map((v, i) => {
          return renderData(v, type, i, {
            onGeocodeClick: props.onGeocodeClick,
          });
        })}
      </Box>
    );
  } else {
    return '-';
  }
}

function renderData(value: any, type: JsonVisualizationDataType, index: number, props: RenderDataProps) {
  switch (type) {
    case 'string': {
      if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
        return <Box>{value.toString()}</Box>;
      } else if (!value) {
        return <Box>-</Box>;
      } else {
        return <Box>Invalid data</Box>;
      }
    }
    case 'url': {
      if (typeof value === 'string' && (value.startsWith('http') || value.startsWith('https'))) {
        return (
          <Link href={value} target="_blank">
            {value}
          </Link>
        );
      } else if (!value) {
        return <Box>-</Box>;
      } else {
        return <Box>Invalid url</Box>;
      }
    }
    case 'timestamp': {
      if (typeof value === 'number') {
        if (value >= 1000000000000) {
          return <Box key={index}>{timezoneManager.convertTimestampToString(value)}</Box>;
        } else {
          return <Box key={index}>{timezoneManager.convertTimestampToString(value * 1000)}</Box>;
        }
      } else if (typeof value === 'string') {
        const date = new Date(value);
        if (Number.isNaN(date.getTime())) {
          return <Box key={index}>Invalid time</Box>;
        } else {
          return <Box key={index}>{timezoneManager.convertTimestampToString(date.getTime())}</Box>;
        }
      } else {
        return <Box key={index}>{value}</Box>;
      }
    }
    case 'geocode': {
      if (!value) {
        return <Box>-</Box>;
      }

      let lat: number = 0;
      let long: number = 0;

      if (Array.isArray(value) && value.length === 2 && typeof value[0] === 'number' && typeof value[1] === 'number') {
        lat = value[0];
        long = value[1];
      } else if (value && typeof value['lat'] === 'number' && typeof value['long'] === 'number') {
        lat = value['lat'];
        long = value['long'];
      } else if (value && typeof value['latitude'] === 'number' && typeof value['longitude'] === 'number') {
        lat = value['latitude'];
        long = value['longitude'];
      } else {
        return <Box>Invalid Geocode</Box>;
      }

      console.log(lat, long);

      return (
        <Link
          onFollow={(e) => {
            e.preventDefault();
            props.onGeocodeClick(lat, long);
          }}
        >{`${lat}, ${long}`}</Link>
      );
    }
    case 'object': {
      return <Box>Under construction</Box>;
    }
    default:
      return <Box key={index}>{value}</Box>;
  }
}
