import { saveAs } from 'file-saver';

/**
 * Converts CSV string to JSON format
 * @param csvString string of comma separated values, first row MUST be columm headers
 * @returns
 */
export function convertCsvStringToJson(csvString: string) {
  const rows = csvString.split('\n');
  const headers = rows[0].split(',');
  let nodes: Record<string, string>[] = [];

  for (let row = 1; row < rows.length; row++) {
    let node: Record<string, string> = {};
    let currRow = rows[row].split(','); // each element is a column
    for (let col = 0; col < headers.length; col++) {
      node[headers[col]] = currRow[col].trim();
    }
    nodes.push(node);
  }

  return JSON.stringify(nodes);
}

/**
 * Saves CSV string to .csv file
 * @param csvString CSV string
 * @param filename name of file
 */
export function saveCsvString(csvString: string, filename: string) {
  saveAs(
    new Blob(
      [
        new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
        csvString,
      ],
      { type: 'text/csv;charset=utf-8,' },
    ),
    filename,
  );
}
