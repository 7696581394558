import * as React from 'react';

import Box from '@amzn/awsui-components-react/polaris/box';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import { ActionInformation, UnplannedActionInformation } from './models';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';

export type SelectionType = 'ORDER' | 'TR';

export const NOT_AVAILABLE = '-';

export function buildActionStatusString(actions: (ActionInformation | UnplannedActionInformation | undefined)[]) {
  if (actions.length === 0) {
    return NOT_AVAILABLE;
  }

  let actionMissing = '';
  let actionUnplanned = '';
  let actionLocked = '';
  let actionUnlocked = '';

  actions.forEach((action) => {
    if (action === undefined) {
      actionMissing = 'Missing ';
    } else if (!('lockStatus' in action)) {
      actionUnplanned = 'Unplanned ';
    } else if (action.lockStatus) {
      actionLocked = 'Locked ';
    } else {
      actionUnlocked = 'Unlocked ';
    }
  });

  return (actionMissing + actionUnplanned + actionLocked + actionUnlocked).trim();
}

interface ValueWithLabelProps {
  readonly label: string;
  readonly children: JSX.Element;
}

export class ValueWithLabel extends React.Component<ValueWithLabelProps> {
  render() {
    return (
      <div>
        <Box variant="awsui-key-label">{this.props.label}</Box>
        <div>{this.props.children}</div>
      </div>
    );
  }
}

export function displayTruncatedId(id: string) {
  if (id.length < 30) {
    return <Box>{id}</Box>;
  }
  return (
    <Popover content={id} triggerType="text" dismissButton={false}>
      {/* TODO - add a "copy" button for this text */}
      {/* TODO - additionally we could use a route id hash */}
      {id.substring(0, 24)}
    </Popover>
  );
}

export function asOptionDefinition(id: string): OptionDefinition {
  return { label: id, value: id };
}
