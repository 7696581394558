import React from 'react';
import { DateRangePickerProps } from '@amzn/awsui-components-react/polaris/date-range-picker';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { ExecutionPlanMetadata, NetworkHealthDetails } from '../../clients';
import { timezoneManager, PREFERRED_FORMAT, formatStringToEpochMillisecond, convertCsvStringToJson } from '../../utilities';
import { COLUMN_IDS_TO_HEADERS, MAXIMUM_QUERY_WINDOW_SIZE_IN_MS, MAXIMUM_QUERY_WINDOW_SIZE_TEXT, MULTISELECT_PROGRAM_TYPE_GROUP_IDS, TWO_HOURS_IN_MS } from './constants';
import { TimeRange } from './models';
import { BreadcrumbItem } from '../../main-app/global-context';
import { saveAs } from 'file-saver';

export function convertPlanTime(instant: number, timezone: string): string {
  const options = {
    tz: timezoneManager.getTimezonePreference() === 'station_tz' ? timezone : timezoneManager.getPreferredTimezone(),
    format: PREFERRED_FORMAT,
  };
  return timezoneManager.convertTimestampToString(instant, options);
}

export function renderNullableField(metric: number | null, color?: string) {
  const text = metric ?? 'N/A';
  return color ? <div style={{ color }}>{text}</div> : text;
}

export function compareMetrics(metricA: number | null, metricB: number | null): number {
  if (metricA === null) {
    return -1;
  } else if (metricB === null) {
    return 1;
  }
  return metricA - metricB;
}

export function findIndexForProperty(options: ReadonlyArray<OptionDefinition>, property: string): number {
  return options.findIndex((option) => option.value === property);
}

export function filterByStatus(executionPlanMetadata: ExecutionPlanMetadata, options: ReadonlyArray<OptionDefinition>): boolean {
  const autoAssignEnabledIndex = findIndexForProperty(options, 'auto-assign');
  const ioEnabledIndex = findIndexForProperty(options, 'io');
  if ((autoAssignEnabledIndex > -1 && !executionPlanMetadata.isAutoAssignEnabled) || (autoAssignEnabledIndex === -1 && executionPlanMetadata.isAutoAssignEnabled)) {
    return false;
  }
  if ((ioEnabledIndex > -1 && !executionPlanMetadata.isIoEnabled) || (ioEnabledIndex === -1 && executionPlanMetadata.isIoEnabled)) {
    return false;
  }
  return true;
}

export function filterByProgramTypes(executionPlanMetadata: ExecutionPlanMetadata, selectedOptions: ReadonlyArray<OptionDefinition>): boolean {
  // Since service areas can support multiple program types we filter inclusively,
  // meaning if we see a match in program type we do not filter it out.
  // i.e. if user selects COPPERFIELD, we return true if programTypes=['HOUDINI', 'COPPERFILED']
  for (let programType of executionPlanMetadata.programTypes) {
    if (selectedOptions.some((option) => option.value === 'other')) {
      // if RAS decides to add another program type, we should consider this as other
      return programType.toLowerCase() === 'other' || !MULTISELECT_PROGRAM_TYPE_GROUP_IDS.includes(programType.toLowerCase());
    } else if (findIndexForProperty(selectedOptions, programType.toLowerCase()) > -1) {
      return true;
    }
  }
  return false;
}

export function filterSelectedOptions(networkHealthDetails: NetworkHealthDetails, selectedOptions: ReadonlyArray<OptionDefinition>): boolean {
  // if we see all, skip filtering by status
  const showAllStatuses = selectedOptions.some((option) => option.value === 'all_statuses');
  const showAllProgramTypes = selectedOptions.some((option) => option.value === 'all_program_types');

  if (showAllStatuses && showAllProgramTypes) {
    return true;
  } else if (showAllStatuses) {
    return filterByProgramTypes(networkHealthDetails.executionPlanMetadata, selectedOptions);
  } else if (showAllProgramTypes) {
    return filterByStatus(networkHealthDetails.executionPlanMetadata, selectedOptions);
  }

  // filter by status and program type
  return filterByStatus(networkHealthDetails.executionPlanMetadata, selectedOptions) && filterByProgramTypes(networkHealthDetails.executionPlanMetadata, selectedOptions);
}

export const validRangeResult = (startTime: number, endTime: number): DateRangePickerProps.ValidRangeResult | DateRangePickerProps.InvalidRangeResult => {
  if (endTime > Date.now() || startTime > Date.now()) {
    return { valid: false, errorMessage: 'Cannot pick a time range in the future' };
  } else if (endTime - startTime > MAXIMUM_QUERY_WINDOW_SIZE_IN_MS) {
    return { valid: false, errorMessage: `Query window size cannot exceed ${MAXIMUM_QUERY_WINDOW_SIZE_TEXT}.` };
  } else if (endTime - startTime < 0) {
    return { valid: false, errorMessage: 'End time must be after start time' };
  }
  return { valid: true };
};

export function decodeQueryParams(queryParams?: string): TimeRange {
  const params = new URLSearchParams(queryParams);
  const startTime = formatStringToEpochMillisecond(params.get('st'));
  const endTime = formatStringToEpochMillisecond(params.get('et'));

  return {
    startTime: startTime ?? Date.now() - TWO_HOURS_IN_MS,
    endTime: endTime ?? Date.now(),
  };
}

export function encodeQueryParams(timeRange?: TimeRange): string {
  const queryParams: string[] = [];
  if (typeof timeRange?.startTime === 'number') {
    queryParams.push(`st=${new Date(timeRange.startTime).toISOString()}`);
  }
  if (typeof timeRange?.endTime === 'number') {
    queryParams.push(`et=${new Date(timeRange.endTime).toISOString()}`);
  }
  return queryParams.join('&');
}

export function provideBreadCrumbs(isSingleServiceArea: boolean, liveMode: boolean, serviceAreaId?: string, queryParams?: string): BreadcrumbItem[] {
  let breadcrumbItems: BreadcrumbItem[] = [];
  breadcrumbItems.push({
    text: 'Network Health',
    href: liveMode ? `/network-health/live` : `/network-health${queryParams}`,
  });

  if (isSingleServiceArea) {
    breadcrumbItems.push({
      text: serviceAreaId!,
      href: liveMode ? `/network-health/live?serviceAreaId=${serviceAreaId!}` : `/network-health/${serviceAreaId!}${queryParams}`,
    });
  }
  return breadcrumbItems;
}

export function downloadTable(visibleColumns: readonly string[] | undefined, data: readonly NetworkHealthDetails[], extension: string, serviceAreaId?: string) {
  // visibleColumns is guaranteed to have 1 element: sa-code or plan-id
  let csv: string[] = [visibleColumns!.map((column) => COLUMN_IDS_TO_HEADERS[column]).join(',')];

  data.forEach((item) => {
    let row = [];
    for (var column of visibleColumns!) {
      switch (column) {
        case 'station-code':
          row.push(item.executionPlanMetadata.stationCode);
          break;
        case 'plan-id':
          row.push(item.executionPlanMetadata.planId);
          break;
        case 'plan-time':
          row.push(`${convertPlanTime(item.executionPlanMetadata.creationTimeMillis, item.executionPlanMetadata.serviceAreaTimeZone)}`);
          break;
        case 'routes':
          row.push(`${renderNullableField(item.networkHealthMetrics.routeCount)}`);
          break;
        case 'orders':
          row.push(`${renderNullableField(item.networkHealthMetrics.totalOrderCount)}`);
          break;
        case 'total-unassigned-order-count':
          row.push(`${renderNullableField(item.networkHealthMetrics.totalUnassignedOrderCount)}`);
          break;
        case 'unplanned-orders':
          row.push(`${renderNullableField(item.networkHealthMetrics.unplannedOrderCount)}`);
          break;
        case 'unassigned-tplus-10-order-count':
          row.push(`${renderNullableField(item.networkHealthMetrics.unassignedTPlus10OrderCount)}`);
          break;
        case 'unassigned-tplus-20-order-count':
          row.push(`${renderNullableField(item.networkHealthMetrics.unassignedTPlus20OrderCount)}`);
          break;
        case 'unassigned-tplus-30-order-count':
          row.push(`${renderNullableField(item.networkHealthMetrics.unassignedTPlus30OrderCount)}`);
          break;
        case 'assigned-block-driver-count':
          row.push(`${renderNullableField(item.networkHealthMetrics.assignedBlockDriverCount)}`);
          break;
        case 'available-block-driver-count':
          if (item.networkHealthMetrics.assignedBlockDriverCount === null) {
            row.push('N/A');
          } else {
            row.push(item.networkHealthMetrics.totalBlockDriverCount - item.networkHealthMetrics.assignedBlockDriverCount);
          }
          break;
        case 'assigned-io-driver-count':
          row.push(`${renderNullableField(item.networkHealthMetrics.assignedIODriverCount)}`);
          break;
        case 'unassigned-io-drivers-within-time-radius-count':
          row.push(`${renderNullableField(item.networkHealthMetrics.unassignedIODriverWithinTimeRadiusCount)}`);
          break;
        default:
          break;
      }
    }
    csv.push(row.join(','));
  });

  const csvString = csv.join('\n');
  const serviceAreaIdName = typeof serviceAreaId === 'string' ? `${serviceAreaId}_` : '';
  const filename = `network_health_details_${serviceAreaIdName}${new Date().toISOString()}.${extension}`;
  switch (extension) {
    case 'csv':
      saveAs(new Blob([csvString], { type: 'text/csv;charset=utf-8,' }), filename);
      break;
    case 'json':
      const json = convertCsvStringToJson(csvString);
      saveAs(new Blob([json]), filename);
      break;
    default:
      // constrained to BUTTON_DROPDOWN_ITEMS
      break;
  }
}

/**
 * https://sage.amazon.com/questions/1371292#1371320
 *
 * A new comparator function is created on render if we define comparator inside column definitions.
 * Defining comparator to ensure sortingComparator being used is the same as what was defined for the table.
 *
 * Defining two separate comparators (SA Code for all area table, Plan Id for single area table)
 */
export const saCodeSortingComparator = (metricsA: NetworkHealthDetails, metricsB: NetworkHealthDetails) => {
  return metricsA.executionPlanMetadata.stationCode.localeCompare(metricsB.executionPlanMetadata.stationCode);
};

export const planIdSortingComparator = (metricsA: NetworkHealthDetails, metricsB: NetworkHealthDetails) => {
  return metricsA.executionPlanMetadata.planId.localeCompare(metricsB.executionPlanMetadata.planId);
};
