import { UNITTEST_IDENTIFIER } from '../constants';
import { Environment } from '../models';

export class EnvironmentAccessor {
  static IDENTIFIER_ENV_KEY = 'STAGE_ID';
  getEnvironment(): Environment {
    let identifier: string | undefined = undefined;

    // NODE_ENV=test is setup by jest.
    if (process.env.NODE_ENV === 'test') {
      identifier = UNITTEST_IDENTIFIER;
    } else {
      identifier = process.env[EnvironmentAccessor.IDENTIFIER_ENV_KEY];
    }
    return {
      domain: window.location.hostname,
      identifier,
    };
  }
}
