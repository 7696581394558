import React from 'react';
import AceEditor from 'react-ace';
import stringify from 'json-stable-stringify';
// a reusable AWS style json viewer
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-xcode';
import 'ace-builds/src-noconflict/ext-searchbox';

export interface JSONViewerProps {
  showGutter?: boolean;
  data: any;
}

// eslint-disable-next-line no-redeclare
export default class JSONViewer extends React.Component<JSONViewerProps> {
  render() {
    return (
      <AceEditor
        setOptions={{ useWorker: false }}
        mode="json"
        theme="xcode"
        showPrintMargin={false} // if true, there is a vertical line indicate #chars on a line
        readOnly={true}
        focus={false}
        width={'100%'}
        // onChange={onChange}
        name="UNIQUE_ID_OF_DIV"
        maxLines={70}
        minLines={10}
        showGutter={this.props.showGutter}
        value={stringify(this.props.data, { space: 2 })}
        editorProps={{ $blockScrolling: true }}
      />
    );
  }
}
