import React, { useState, useContext, useEffect } from 'react';
import { GlobalContext } from '../../main-app/global-context';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import Button from '@amzn/awsui-components-react/polaris/button';
import ButtonDropdown from '@amzn/awsui-components-react/polaris/button-dropdown';
import CollectionPreferences, { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import TokenGroup from '@amzn/awsui-components-react/polaris/token-group';
import Multiselect, { MultiselectProps } from '@amzn/awsui-components-react/polaris/multiselect';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import ufraaVizClient, { NetworkHealthDetails, NetworkHealthDetailsRequest } from '../../clients';
import { preferencesStore, timezoneManager } from '../../utilities';
import TimeRangePicker from '../../shared-components/time-range-picker';
import { decodeQueryParams, downloadTable, encodeQueryParams, provideBreadCrumbs, validRangeResult } from './utilities';
import Table, { TableProps } from '@amzn/awsui-components-react/polaris/table';
import Header from '@amzn/awsui-components-react/polaris/header';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { NetworkHealthParams, TimeRange, UserPreferences } from './models';
import {
  MULTISELECT_OPTIONS_AVAILABLE,
  TWO_HOURS_IN_MS,
  NetworkHealthPagePreferencesStoreKey,
  BUTTON_DROPDOWN_ITEMS,
  SINGLE_AREA_PAGE_SIZE_PREFERENCE,
  ALL_AREAS_PAGE_SIZE_PREFERENCE,
  LIVE_INTERVAL_IN_MS,
  MULTISELECT_STATUS_GROUP,
  MULTISELECT_PROGRAM_TYPE_GROUP,
  MULTISELECT_OPTIONS_DEFAULT,
} from './constants';
import {
  VISIBLE_CONTENT_PREFERENCE,
  ALL_AREAS_FILTERING_OPTIONS,
  ALL_AREAS_TABLE_DEFINITION,
  SINGLE_AREA_TABLE_DEFINITION,
  SINGLE_AREA_FILTERING_OPTIONS,
  ALL_AREAS_PREFERENCES,
  SINGLE_AREA_PREFERENCES,
} from './table-definitions';
import './component-styles.css';

export function NetworkHealthPage(networkHealthParams: NetworkHealthParams) {
  const filteringPlaceholder = networkHealthParams.isSingleServiceArea ? 'Filter by Plan Id' : 'Filter by Station Code';
  const collectionPreferencesProps: CollectionPreferencesProps = {
    pageSizePreference: networkHealthParams.isSingleServiceArea ? SINGLE_AREA_PAGE_SIZE_PREFERENCE : ALL_AREAS_PAGE_SIZE_PREFERENCE,
    visibleContentPreference: VISIBLE_CONTENT_PREFERENCE,
    cancelLabel: 'Cancel',
    confirmLabel: 'Confirm',
    title: 'Preferences',
  };
  const context = useContext(GlobalContext);
  const [columnDefinitions, setColumnDefinitions] = useState<TableProps.ColumnDefinition<NetworkHealthDetails>[]>(
    networkHealthParams.isSingleServiceArea ? SINGLE_AREA_TABLE_DEFINITION : ALL_AREAS_TABLE_DEFINITION(context, networkHealthParams),
  );
  const [networkHealthDetailsList, setNetworkHeatlhDetailsList] = useState<NetworkHealthDetails[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [stationCode, setStationCode] = useState<string>('');
  const [preferences, setPreferences] = useState<UserPreferences>(
    preferencesStore.get<UserPreferences>(NetworkHealthPagePreferencesStoreKey, networkHealthParams.isSingleServiceArea ? SINGLE_AREA_PREFERENCES : ALL_AREAS_PREFERENCES),
  );
  const [currentTimeRange, setCurrentTimeRange] = useState<TimeRange>(decodeQueryParams(networkHealthParams.queryParams));
  const [multiSelectOptions, setMultiSelectOptions] = useState<ReadonlyArray<MultiselectProps.OptionGroup>>(MULTISELECT_OPTIONS_AVAILABLE);
  const [selectedOptions, setSelectedOptions] = useState<ReadonlyArray<OptionDefinition>>(networkHealthParams.isSingleServiceArea ? [] : MULTISELECT_OPTIONS_DEFAULT);
  const [liveHandler, setLiveHandler] = useState<number | null>(null);

  const { items, collectionProps, filterProps, paginationProps } = useCollection(networkHealthDetailsList, {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    filtering: networkHealthParams.isSingleServiceArea ? SINGLE_AREA_FILTERING_OPTIONS() : ALL_AREAS_FILTERING_OPTIONS(selectedOptions),
  });

  const getNetworkHealthDetails = async (timeRange: TimeRange, serviceAreaId?: string) => {
    try {
      let networkHealthDetailsRequest: NetworkHealthDetailsRequest = {
        serviceAreaId: typeof serviceAreaId === 'string' ? serviceAreaId : null,
        startTime: timeRange.startTime,
        endTime: timeRange.endTime,
      };

      const response = await ufraaVizClient.getNetworkHealthDetailsList(networkHealthDetailsRequest);
      setNetworkHeatlhDetailsList(response);
    } catch (err: any) {
      context.addNotification({
        type: 'error',
        header: `Error trying to get network health details`,
        content: typeof err?.response?.data === 'string' ? err?.response?.data : err?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const hitRefresh = (timeRange: TimeRange) => {
    setIsLoading(true);
    setCurrentTimeRange(timeRange);
    getNetworkHealthDetails(timeRange, networkHealthParams.serviceAreaId);
  };

  const hitExport = (extension: string) => {
    downloadTable(preferences.visibleContent, items, extension, networkHealthParams.serviceAreaId);
  };

  const timeRangePickerOnChange = (startTime: number, endTime: number) => {
    const timeRange = {
      startTime: startTime,
      endTime: endTime,
    };
    const routeParam = networkHealthParams.isSingleServiceArea ? `/${networkHealthParams.serviceAreaId}?` : '?';
    const queryParams = encodeQueryParams(timeRange);
    const newUrl = `/network-health${routeParam}${queryParams}`;
    context.onGoto(newUrl);
  };

  const setPreferencesOnServiceAreaChange = () => {
    const selectablePreferences = preferences.visibleContent!.slice(1);
    const newPreferences = {
      pageSize: preferences.pageSize,
      visibleContent: networkHealthParams.isSingleServiceArea ? ['plan-id', ...selectablePreferences] : ['station-code', ...selectablePreferences],
    };
    setPreferences(newPreferences);
    preferencesStore.set<UserPreferences>(NetworkHealthPagePreferencesStoreKey, newPreferences);
  };

  const clearFilteringText = () => {
    let evt = { detail: { filteringText: '' } };
    filterProps.onChange(evt);
  };

  useEffect(() => {
    clearFilteringText();
    context.resetLayout();
    context.setTools('network-health-page');
    context.updateStationTimezone(undefined);
    context.updateLiveModeEffectEnabled(networkHealthParams.liveMode);
    context.updateBreadcrumbItems(provideBreadCrumbs(networkHealthParams.isSingleServiceArea, networkHealthParams.liveMode, networkHealthParams.serviceAreaId, networkHealthParams.queryParams));
    setStationCode('');
    setIsLoading(true);
    setMultiSelectOptions(MULTISELECT_OPTIONS_AVAILABLE);

    const timeRange = decodeQueryParams(networkHealthParams.queryParams);
    setCurrentTimeRange(timeRange);
    setColumnDefinitions(networkHealthParams.isSingleServiceArea ? SINGLE_AREA_TABLE_DEFINITION : ALL_AREAS_TABLE_DEFINITION(context, networkHealthParams));
    setSelectedOptions(networkHealthParams.isSingleServiceArea ? [] : MULTISELECT_OPTIONS_DEFAULT);
    setPreferencesOnServiceAreaChange();
    getNetworkHealthDetails(timeRange, networkHealthParams.serviceAreaId);
    if (networkHealthParams.liveMode) {
      if (!liveHandler) {
        let handler = setInterval(hitRefresh, LIVE_INTERVAL_IN_MS, { startTime: Date.now() - TWO_HOURS_IN_MS, endTime: Date.now() }, true);
        setLiveHandler(handler);
      }
    }

    // acts as componentWillUnmount()
    return function cleanup() {
      if (liveHandler) {
        clearInterval(liveHandler);
        setLiveHandler(null);
      }
    };
  }, [networkHealthParams.isSingleServiceArea, networkHealthParams.liveMode, networkHealthParams.queryParams]);

  useEffect(() => {
    // Work around to define single service area properties (station code, filters).
    // Since statuses and program types exist only in plan metadata, we have to load the data first.
    if (networkHealthDetailsList.length > 0 && networkHealthParams.isSingleServiceArea) {
      let statusOptions = MULTISELECT_STATUS_GROUP.options.slice(1);
      let programTypesOptions = MULTISELECT_PROGRAM_TYPE_GROUP.options.slice(1);
      const planMetadata = networkHealthDetailsList[0].executionPlanMetadata;
      const programTypes = planMetadata.programTypes;

      if (!planMetadata.isAutoAssignEnabled) {
        statusOptions = statusOptions.filter((option) => option.value !== 'auto-assign');
      }
      if (!planMetadata.isIoEnabled) {
        statusOptions = statusOptions.filter((option) => option.value !== 'io');
      }

      programTypesOptions = programTypesOptions.filter((option) => programTypes.includes(option.value!.toUpperCase()));
      setSelectedOptions([...statusOptions, ...programTypesOptions].map((option) => ({ ...option, disabled: true })));
      setStationCode(`- ${planMetadata.stationCode}`);
      context.updateStationTimezone(planMetadata.serviceAreaTimeZone);
    }
  }, [networkHealthDetailsList]);

  const renderNewTabBtn = () => {
    return (
      <Button target="_blank" href={`/network-health${networkHealthParams.isSingleServiceArea ? '/' + networkHealthParams.serviceAreaId : ''}`} iconAlign="right" iconName="external">
        New Tab
      </Button>
    );
  };

  const renderGotoLiveModeBtn = () => {
    const queryParams = networkHealthParams.isSingleServiceArea ? `?serviceAreaId=${networkHealthParams.serviceAreaId!}` : '';
    const href = `/network-health/live${queryParams}`;
    return (
      <Button
        href={href}
        onClick={(evt) => {
          evt.preventDefault();
          context.onGoto(href);
        }}
      >
        Live
      </Button>
    );
  };

  return (
    <Table<NetworkHealthDetails>
      {...collectionProps}
      header={
        <Header
          variant="h2"
          actions={
            <SpaceBetween direction="horizontal" size="s">
              {networkHealthParams.liveMode ? renderNewTabBtn() : renderGotoLiveModeBtn()}
              <ButtonDropdown disabled={isLoading} items={BUTTON_DROPDOWN_ITEMS} onItemClick={(evt) => hitExport(evt.detail.id)}>
                Export as
              </ButtonDropdown>
              <Button disabled={networkHealthParams.liveMode} loading={isLoading} onClick={() => hitRefresh({ startTime: Date.now() - TWO_HOURS_IN_MS, endTime: Date.now() })}>
                Refresh
              </Button>
            </SpaceBetween>
          }
        >
          {`Network Health Dashboard ${networkHealthParams.isSingleServiceArea ? stationCode : ''}`}
        </Header>
      }
      columnDefinitions={columnDefinitions}
      visibleColumns={preferences.visibleContent}
      items={items}
      pagination={<Pagination {...paginationProps} />}
      filter={
        <div>
          <div className="input-container">
            <div className="input-filter">
              <TextFilter {...filterProps} filteringPlaceholder={filteringPlaceholder} />
            </div>
            <div className="select-filter">
              <Multiselect
                options={multiSelectOptions}
                selectedOptions={selectedOptions}
                placeholder={'Apply filters'}
                keepOpen={true}
                onChange={(evt) => {
                  setSelectedOptions(evt.detail.selectedOptions);
                }}
                hideTokens={true}
                disabled={networkHealthParams.isSingleServiceArea}
              />
            </div>
          </div>
          <div className="time-range-picker">
            <TimeRangePicker
              timezonePreference={timezoneManager.getInUseTimezonePreference()}
              utcOffset={timezoneManager.getUTCOffset()}
              startTime={currentTimeRange.startTime}
              endTime={currentTimeRange.endTime}
              onChange={(startTime, endTime) => timeRangePickerOnChange(startTime, endTime)}
              isValidRange={(startTime, endTime) => validRangeResult(startTime, endTime)}
            />
          </div>
          <div>
            <TokenGroup
              onDismiss={({ detail: { itemIndex } }) => {
                let newSelectedOptions = [...selectedOptions.slice(0, itemIndex), ...selectedOptions.slice(itemIndex + 1)];
                setSelectedOptions(newSelectedOptions);
              }}
              items={selectedOptions}
            />
          </div>
        </div>
      }
      preferences={
        <CollectionPreferences
          {...collectionPreferencesProps}
          preferences={preferences}
          onConfirm={({ detail }) => {
            let newPreferences = {
              pageSize: detail.pageSize,
              visibleContent: detail.visibleContent!.slice(0),
            };
            setPreferences(newPreferences);
            preferencesStore.set<UserPreferences>(NetworkHealthPagePreferencesStoreKey, newPreferences);
          }}
        />
      }
      loading={isLoading && !networkHealthParams.liveMode}
      loadingText={'Loading health, may take a moment...'}
    />
  );
}
