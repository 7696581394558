import React from 'react';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import { GlobalContext } from '../../main-app/global-context';
import { ExecutionSnapshot, ExecutionPlan, ExecutionPlanInfo, ServiceAreaDetails } from '../../clients';
import { RoutingPlanSummary } from './routing-plan-summary';
import { RoutingPlanDetails } from './routing-plan-details';
import { downloadJsonData } from '../../utilities';

interface Props {
  readonly plan?: ExecutionPlan | null;
  readonly snapshot?: ExecutionSnapshot | null;
  readonly serviceAreaDetails?: ServiceAreaDetails | null;
  readonly liveMode: boolean;
  readonly planInfo: ExecutionPlanInfo | null;

  readonly activeTab?: string;
  readonly onTabChange: (tab: string) => void;
}

export class RoutingPlanContent extends React.Component<Props> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <SpaceBetween direction="vertical" size="m">
        {this.renderHeader()}
        <RoutingPlanSummary plan={this.props.plan} snapshot={this.props.snapshot} planInfo={this.props.planInfo} serviceAreaDetails={this.props.serviceAreaDetails} />
        <RoutingPlanDetails plan={this.props.plan} snapshot={this.props.snapshot} activeTab={this.props.activeTab} onTabChange={this.props.onTabChange} planInfo={this.props.planInfo} />
      </SpaceBetween>
    );
  }

  private renderHeader() {
    return (
      <Header
        variant="h1"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button disabled={!this.props.snapshot} onClick={() => downloadJsonData(this.props.snapshot, `${this.props.snapshot?.id}.json`)}>
              Download Snapshot
            </Button>
            <Button disabled={!this.props.plan} onClick={() => downloadJsonData(this.props.plan, `${this.props.plan?.id}.json`)}>
              Download Plan
            </Button>
          </SpaceBetween>
        }
      >
        Routing Plan
      </Header>
    );
  }
}
