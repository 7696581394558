/**
 * Default the tasks in the training tutorial.
 *
 * https://polaris.a2z.com/components/annotation-context/?tabId=playground
 *
 */

import React from 'react';

import { AnnotationContextProps } from '@amzn/awsui-components-react/polaris/annotation-context';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Badge from '@amzn/awsui-components-react/polaris/badge';
import Header from '@amzn/awsui-components-react/polaris/header';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import Link from '@amzn/awsui-components-react/polaris/link';

/**
 * A tutorial has multiple tasks, a task has multiple steps.
 */
export const LM_ROUTE_PAGE_TUTORIAL: AnnotationContextProps.Tutorial = {
  title: 'Route page tutorial',
  description: 'Introduce the functions of route page.',
  tasks: [
    {
      title: 'Welcome',
      steps: [
        {
          title: 'Introduction',
          hotspotId: 'introduction',
          content: (
            <SpaceBetween direction="vertical" size="xs">
              <Box>In this tutorial, we walk you through how to use the route search page.</Box>
              <Box>
                The route search page gives you access on{' '}
                <Link href="https://w.amazon.com/bin/view/ExecutionPlanning/RouteManagementSystem/RouteStore/" external>
                  RouteStore
                </Link>{' '}
                routes, it renders a route in three sections, route metadata, route timeline, and route sequence. Downloading the route store data in JSON format is also available.
              </Box>
              <Box>
                <Header variant="h3">Permission</Header>
                <Box>
                  The tutorial doesn't require any permission. However, to use the page on a regular basis, you need to be a member of{' '}
                  <Link href="https://permissions.amazon.com/a/team/ufraaviz-route-view" external>
                    ufraaviz-route-view
                  </Link>{' '}
                  team. Request permission by opening the{' '}
                  <Link href="https://sim.amazon.com/issues/create?template=dc5a4062-cd98-4b02-bc3e-c33a3a8ce70f" external>
                    SIM
                  </Link>
                  .
                </Box>
              </Box>
            </SpaceBetween>
          ),
        },
      ],
    },
    {
      title: 'Search',
      steps: [
        {
          title: 'Search type',
          hotspotId: 'route-search-type',
          content: (
            <SpaceBetween direction="vertical" size="xs">
              <Box>You can search a route by routeId, trId, trackingId or transporterId. For the tutorial, select "Route Id".</Box>
              <Box>Note</Box>
              <Box>
                1). There are certain{' '}
                <Link href="https://sim.amazon.com/issues/P78542919" external>
                  limitations
                </Link>{' '}
                on searching by trId, trackingId or transporterId, you may not always get the result.
              </Box>
              <Box>2). RouteStore data retention period is 3 months, the UI won't be able to return routes that are older than 3 months.</Box>
            </SpaceBetween>
          ),
        },
        {
          title: 'Input route id',
          hotspotId: 'route-search-input',
          content: (
            <SpaceBetween direction="vertical" size="xs">
              <Box>
                For the tutorial, put <Box fontWeight="bold">fa983ca8-ae52-4dd7-bbb0-78b4f8d0f124</Box> in the Route Id input.
              </Box>
              <Box>
                The route version is an optional input. RouteStore routes are versioned, if you want to look at a specific route version, you can put its version number. For the tutorial, we leave it
                empty and show you how to navigate through versions in the timeline table.
              </Box>
            </SpaceBetween>
          ),
        },
        {
          title: 'Route search button',
          hotspotId: 'route-search-button',
          content: <Box>Click on the "Search" button.</Box>,
        },
      ],
    },
    {
      title: 'Route summary',
      steps: [
        {
          title: 'Route summary',
          hotspotId: 'route-summary',
          content: (
            <SpaceBetween direction="vertical" size="xs">
              <Box>The route summary section displays route information, including its dispatch date, service area, timezone, planned driver, etc.</Box>
              <Box>If the route version input is empty, it displays the latest version of the route. In this tutorial, the latest version of the route is 22.</Box>
              <Box>Note, different programs may have a slightly different views. For example, it displays route name for SSD routes.</Box>
            </SpaceBetween>
          ),
        },
        {
          title: 'Download route',
          hotspotId: 'route-download-btn',
          content: (
            <SpaceBetween direction="vertical" size="xs">
              <Box>Click the "Download" button to download the route in JSON format.</Box>
              <Box>Note, the route itself doesn't include sequence information. Route sequence is displayed at the sequence section.</Box>
            </SpaceBetween>
          ),
        },
      ],
    },
    {
      title: 'Route timeline',
      steps: [
        {
          title: 'Route timeline',
          hotspotId: 'route-timeline',
          content: (
            <SpaceBetween direction="vertical" size="xs">
              <Box>A route can change over time, so routes in RouteStore are versioned. The timeline table displays how a route changes over time.</Box>
              <Box>
                Click on version <Badge color="blue">19</Badge>, you will notice the route metadata and route sequence also change to version 19.
              </Box>
              <Box>
                Each route update comes with a update category and update type, they are concepts in{' '}
                <Link href="https://w.amazon.com/bin/view/ExecutionPlanning/RouteManagementSystem/RouteStore/" external>
                  RouteStore
                </Link>
                . Besides that, the table also displays the system that made the change in the "Update by" column.
              </Box>
            </SpaceBetween>
          ),
        },
        {
          title: 'Download timeline',
          hotspotId: 'timeline-download-btn',
          content: (
            <SpaceBetween direction="vertical" size="xs">
              <Box>Click the "Download" button to download the timeline in JSON format, which includes the RouteStore data.</Box>
            </SpaceBetween>
          ),
        },
        {
          title: 'Timeline customization',
          hotspotId: 'timeline-customization',
          content: (
            <SpaceBetween direction="vertical" size="xs">
              <Box>The table allows certain customizations, including hidding columns and changing time format.</Box>
            </SpaceBetween>
          ),
        },
      ],
    },
    {
      title: 'Route map',
      steps: [
        {
          title: 'Route map',
          hotspotId: 'route-map',
          content: (
            <SpaceBetween direction="vertical" size="xs">
              <Box>The map visualizes the route's pickup and delivery locations. Orange marks denote pickup locations and blue marks denote delivery locations.</Box>
              <Box>Clicking the stop item on the sequence table will place the location at the center of the map, clicking a stop in the map also highlights the stop in the sequence table.</Box>
              <Box>
                Note, GSF routes currently don't have geolocation data, we are tracking the feature in the{' '}
                <Link href="https://sim.amazon.com/issues/FLXRT-2924" external={true}>
                  SIM
                </Link>
              </Box>
              <Box>
                <Header variant="h3">Privacy compliance</Header>
                <Box>
                  To protect customer data, geolocations are truncated to{' '}
                  <Link href="https://www.usgs.gov/faqs/how-much-distance-does-a-degree-minute-and-second-cover-your-maps" external>
                    a minute
                  </Link>{' '}
                  precision.
                </Box>
              </Box>
            </SpaceBetween>
          ),
        },
      ],
    },
    {
      title: 'Route sequence',
      steps: [
        {
          title: 'Route sequence',
          hotspotId: 'route-sequence',
          content: (
            <SpaceBetween direction="vertical" size="xs">
              <Box>The sequence table displays stops and actions in the route. Delivery sequence and durations between two stops can change over time, so route sequence is also versioned.</Box>
              <Box>Using the timeline table to navigate through different versions, and it will display the sequence associated with that specific route version.</Box>
            </SpaceBetween>
          ),
        },
        {
          title: 'Download sequence',
          hotspotId: 'sequence-download-btn',
          content: (
            <SpaceBetween direction="vertical" size="xs">
              <Box>Click the "Download" button to download the sequence in JSON format.</Box>
              <Box>
                Congratulations 🎉, you have finished the tutorial. You can find additional information, or re-visit the tutorial by clicking on the <Icon name="status-info" /> icon on the top right
                corner.
              </Box>
            </SpaceBetween>
          ),
        },
      ],
    },
  ],
  completedScreenDescription: 'Congratulations 🎉',
  completed: false,
};
