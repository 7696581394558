import { RouteSequence } from '../../../../clients';
import v2 from './v2-sequence.json';
import v3 from './v3-sequence.json';
import v4 from './v4-sequence.json';
import v5 from './v5-sequence.json';
import v6 from './v6-sequence.json';
import v7 from './v7-sequence.json';
import v8 from './v8-sequence.json';
import v9 from './v9-sequence.json';
import v10 from './v10-sequence.json';
import v11 from './v11-sequence.json';
import v12 from './v12-sequence.json';
import v13 from './v13-sequence.json';
import v14 from './v14-sequence.json';
import v15 from './v15-sequence.json';
import v16 from './v16-sequence.json';
import v17 from './v17-sequence.json';
import v18 from './v18-sequence.json';
import v19 from './v19-sequence.json';
import v20 from './v20-sequence.json';
import v21 from './v21-sequence.json';
import v22 from './v22-sequence.json';

export const sequences: RouteSequence[] = [
  v2 as RouteSequence,
  v3 as RouteSequence,
  v4 as RouteSequence,
  v5 as RouteSequence,
  v6 as RouteSequence,
  v7 as RouteSequence,
  v8 as RouteSequence,
  v9 as RouteSequence,
  v10 as RouteSequence,
  v11 as RouteSequence,
  v12 as RouteSequence,
  v13 as RouteSequence,
  v14 as RouteSequence,
  v15 as RouteSequence,
  v16 as RouteSequence,
  v17 as RouteSequence,
  v18 as RouteSequence,
  v19 as RouteSequence,
  v20 as RouteSequence,
  v21 as RouteSequence,
  v22 as RouteSequence,
];
