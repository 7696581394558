import React from 'react';
import { TransporterGroup as TransporterGroupType } from '../../clients';
import Box from '@amzn/awsui-components-react/polaris/box';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Header from '@amzn/awsui-components-react/polaris/header';

export interface TransporterGroupProps {
  readonly transporterGroup: TransporterGroupType;
}

export class TransporterGroup extends React.Component<TransporterGroupProps, {}> {
  render() {
    return this.props.transporterGroup.transporters.map((transporter, index) => {
      return <Box key={index}>{transporter.transporterId}</Box>;
    });
  }
}

export interface TransporterGroupModalProps extends TransporterGroupProps {
  readonly onClose: () => void;
}

export class TransporterGroupModal extends React.Component<TransporterGroupModalProps, {}> {
  render() {
    return (
      <Modal
        onDismiss={() => this.props.onClose()}
        header={
          <Header variant="h3" description={this.props.transporterGroup.transporterGroupId}>
            Transporter Group
          </Header>
        }
        visible={true}
        closeAriaLabel="Close modal"
        size="medium"
      >
        <TransporterGroup transporterGroup={this.props.transporterGroup} />
      </Modal>
    );
  }
}
