import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import ufraaVizClient, { ArtifactIdentifier, ArtifactType, AssignmentMetadata } from '../../clients';
import { timezoneManager } from '../../utilities';
import Table, { TableProps } from '@amzn/awsui-components-react/polaris/table';
import Header from '@amzn/awsui-components-react/polaris/header';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import Link from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import TimeRangePicker from '../../shared-components/time-range-picker';
import { WithBundleProps } from '@amzn/react-arb-tools';

interface Props extends WithBundleProps {
  readonly serviceAreaId: string;
  readonly startTime: number;
  readonly endTime: number;
  readonly onQueryWindowChange: (startTime: number, endTime: number) => void;
  readonly isShadow?: boolean;
}

const MAXIMUM_QUERY_WINDOW_SIZE_IN_MS = 6 * 3600 * 1000;
const MAXIMUM_QUERY_WINDOW_SIZE_TEXT = '6 hours';

interface State {
  readonly assignmentPlanMetadataList?: AssignmentMetadata[];
  readonly selectedMetadata?: AssignmentMetadata[];
  readonly isDownloading: boolean;
  readonly inputArtifact: ArtifactType;
  readonly planArtifact: ArtifactType;
  readonly prefixHeader: string;
  readonly prefixLink: string;
}

export default class AssignmentPlanTable extends React.Component<Props, State> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      assignmentPlanMetadataList: undefined,
      selectedMetadata: undefined,
      isDownloading: false,
      inputArtifact: this.props.isShadow ? 'SHADOW_FLEX_ROUTE_ASSIGNMENT_PLANNER_INPUT' : 'FLEX_ROUTE_ASSIGNMENT_PLANNER_INPUT',
      planArtifact: this.props.isShadow ? 'SHADOW_FLEX_ROUTE_ASSIGNMENT_PLAN' : 'FLEX_ROUTE_ASSIGNMENT_PLAN',
      prefixHeader: this.props.isShadow ? 'Shadow ' : '',
      prefixLink: this.props.isShadow ? 'shadow-' : '',
    };
  }

  async componentDidMount() {
    await this.loadAssignmentPlanMetadata(this.props.startTime, this.props.endTime);
  }

  async loadAssignmentPlanMetadata(startTime: number, endTime: number) {
    try {
      const assignmentPlanMetadataList = await ufraaVizClient.listAssignmentMetadata(
        {
          serviceAreaId: this.props.serviceAreaId,
          startTime,
          endTime,
        },
        this.state.inputArtifact,
        this.state.planArtifact,
      );
      this.setState({
        assignmentPlanMetadataList,
      });
    } catch (err) {
      this.renderError(err);
      this.setState({
        assignmentPlanMetadataList: [],
      });
    }
  }

  private async downloadArtifact() {
    this.setState({ isDownloading: true });
    try {
      if (this.state.selectedMetadata) {
        const artifactIdentifiers: ArtifactIdentifier[] = [];
        this.state.selectedMetadata.forEach((metadata) => {
          artifactIdentifiers.push({ artifactId: metadata.planId, artifactType: this.state.planArtifact });
          artifactIdentifiers.push({ artifactId: metadata.plannerInputId, artifactType: this.state.inputArtifact });
        });

        await ufraaVizClient.downloadArtifacts(artifactIdentifiers);
      }
    } catch (err) {
      this.renderError(err);
    } finally {
      this.setState({ isDownloading: false });
    }
  }

  private renderError(err: any) {
    this.context.addNotification({
      type: 'error',
      header: 'Error',
      content: typeof err?.response?.data === 'string' ? err?.response?.data : err?.message,
    });
  }

  private renderHeader() {
    return (
      <Header
        variant="h2"
        // todo: display the time window
        // counter={this.state.assignmentPlanMetadataList?.length.toString()}
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button iconName="external" target="_blank" href={`/${this.state.prefixLink}assignment-plan/live?serviceAreaId=${this.props.serviceAreaId}`}>
              {this.props.bundle.getMessage('STATION_PAGE:GO_LIVE')}
            </Button>
            <Button
              variant="primary"
              loading={this.state.isDownloading}
              disabled={this.state.selectedMetadata === undefined || this.state.selectedMetadata?.length === 0}
              onClick={async () => await this.downloadArtifact()}
            >
              {this.props.bundle.getMessage('STATION_PAGE:DOWNLOAD')}
            </Button>
          </SpaceBetween>
        }
      >
        {this.state.prefixHeader}
        {this.props.bundle.getMessage('STATION_PAGE:ASSIGNMENT_PLANS')}
      </Header>
    );
  }

  private renderDateRangePicker() {
    return (
      <TimeRangePicker
        timezonePreference={timezoneManager.getInUseTimezonePreference()}
        utcOffset={timezoneManager.getUTCOffset()}
        startTime={this.props.startTime}
        endTime={this.props.endTime}
        onChange={async (startTime, endTime) => {
          this.props.onQueryWindowChange(startTime, endTime);
          this.setState({ selectedMetadata: undefined, assignmentPlanMetadataList: undefined });
          await this.loadAssignmentPlanMetadata(startTime, endTime);
        }}
        isValidRange={(startTime, endTime) => {
          if (endTime - startTime > MAXIMUM_QUERY_WINDOW_SIZE_IN_MS) {
            return { valid: false, errorMessage: `Query window size cannot exceed ${MAXIMUM_QUERY_WINDOW_SIZE_TEXT}.` };
          }
          return { valid: true };
        }}
      />
    );
  }

  render() {
    const tableDefinition: TableProps.ColumnDefinition<AssignmentMetadata>[] = [
      // todo: As for now, we only have the plan generation time, but it will be intuitive to have a planner input generation to display on the UI.
      // Update LordCommander to include a planner input generation time.
      {
        id: 'plannerInputGenerationTime',
        header: `${this.state.prefixHeader}${this.props.bundle.getMessage('STATION_PAGE:ASSIGNMENT_PLANNER_INPUT_GENERATION_TIME')}`,
        cell: (item) => {
          return timezoneManager.convertTimestampToString(item.plannerInputGenerationTime);
        },
      },
      {
        id: 'planGenerationTime',
        header: `${this.state.prefixHeader}${this.props.bundle.getMessage('STATION_PAGE:PLAN_GENERATION_TIME')}`,
        cell: (item) => {
          if (typeof item.planGenerationTime === 'string') {
            return timezoneManager.convertTimestampToString(item.planGenerationTime);
          } else {
            return <StatusIndicator type="in-progress">Plan Not Generated</StatusIndicator>;
          }
        },
      },
      {
        id: 'assingmentPlannerInputId',
        header: `${this.state.prefixHeader}${this.props.bundle.getMessage('STATION_PAGE:ASSIGNMENT_PLAN_COLUMN_HEADER')}`,
        cell: (item) => (
          <Link
            href={`/${this.state.prefixLink}assignment-plan/${encodeURIComponent(item.plannerInputId)}`}
            onFollow={(evt) => {
              // prevent browser from sending requests to backend. Doing in-browser navgiation.
              evt.preventDefault();
              if (typeof evt.detail.href === 'string') {
                this.context.onGoto(evt.detail.href);
              }
            }}
          >
            {item.plannerInputId}
          </Link>
        ),
      },
    ];

    return (
      <Table<AssignmentMetadata>
        header={this.renderHeader()}
        columnDefinitions={tableDefinition}
        loading={this.state.assignmentPlanMetadataList === undefined}
        loadingText={this.props.bundle.getMessage('STATION_PAGE:LOADING_ASSIGNMENT_PLANS')}
        items={this.state.assignmentPlanMetadataList === undefined ? [] : this.state.assignmentPlanMetadataList}
        stickyHeader={false}
        filter={this.renderDateRangePicker()}
        selectionType="multi"
        onSelectionChange={(evt) => {
          this.setState({
            selectedMetadata: evt.detail.selectedItems,
          });
        }}
        selectedItems={this.state.selectedMetadata}
      />
    );
  }
}
