import { AnnotationContextProps } from '@amzn/awsui-components-react/polaris/annotation-context';

export const I18nStrings: AnnotationContextProps.I18nStrings = {
  stepCounterText: (stepIndex, totalStepCount) => 'Step ' + (stepIndex + 1) + '/' + totalStepCount,
  taskTitle: (taskIndex, taskTitle) => 'Task ' + (taskIndex + 1) + ': ' + taskTitle,
  labelHotspot: (openState) => 'tutorial open state ' + openState,
  nextButtonText: 'Next',
  previousButtonText: 'Previous',
  finishButtonText: 'Finish',
  labelDismissAnnotation: 'dismiss annotation',
};
