import React from 'react';
import Header from '@amzn/awsui-components-react/polaris/header';
import Table from '@amzn/awsui-components-react/polaris/table';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import { SimpleMap } from './simple-map';
import { buildColumnDefinitions } from './column-definitions';
import { JsonVisualizationTableColumnDefinition, Row } from './models';

export interface Props {
  readonly columns: ReadonlyArray<JsonVisualizationTableColumnDefinition>;
  readonly rows?: ReadonlyArray<Row>;
}

export interface State {
  readonly geocode?: { readonly lat: number; readonly long: number };
}

export class JsonTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const columnDefinitions = buildColumnDefinitions({
      columns: this.props.columns,
      onGeocodeClick: (lat, long) => {
        this.setState({ geocode: { lat, long } });
      },
    });

    if (this.props.columns.find((c) => c.dataType === 'geocode')) {
      return (
        <Grid gridDefinition={[{ colspan: 5 }, { colspan: 7 }]}>
          <SimpleMap geocode={this.state.geocode} />
          <Table header={this.renderHeader()} columnDefinitions={columnDefinitions} items={this.props.rows ?? []}></Table>
        </Grid>
      );
    }

    return <Table header={this.renderHeader()} columnDefinitions={columnDefinitions} items={this.props.rows ?? []}></Table>;
  }

  private renderHeader() {
    return <Header variant="h2">JSON Table</Header>;
  }
}
