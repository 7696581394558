import React from 'react';
import { RouteChangeLog, RsDepartureSet, TaskSubject } from '../../clients';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Header from '@amzn/awsui-components-react/polaris/header';
import Box from '@amzn/awsui-components-react/polaris/box';
import RadioGroup from '@amzn/awsui-components-react/polaris/radio-group';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import CopyText from '../../shared-components/copy-text';
import { Select, SelectProps, StatusIndicator, TextContent } from '@amzn/awsui-components-react';
import { systemDatabse } from '../../utilities/system-database';
import { timezoneManager } from '../../utilities';

export interface RouteDepartureSetProps {
  readonly routeVersion: number;
  readonly departureSet: RsDepartureSet;
  readonly resequenceUpdates: ReadonlyArray<RouteChangeLog>;
  readonly timeFormat: string;
}

const NONE_SELECTED_DEPARTURE_SET_VERSION = 'None';

export interface RouteDepartureSetState {
  readonly mode: 'tr' | 'trackingId' | 'tr_and_trackingId';
  readonly selectedDepartureSetVersion?: string;
}

const MODE_OPTIONS: ReadonlyArray<{ value: RouteDepartureSetState['mode']; label: string }> = [
  { value: 'tr', label: 'TR' },
  { value: 'trackingId', label: 'Tracking Id' },
  { value: 'tr_and_trackingId', label: 'TR and Tracking Id' },
];

export class RouteDepartureSet extends React.Component<RouteDepartureSetProps, RouteDepartureSetState> {
  constructor(props: RouteDepartureSetProps) {
    super(props);
    this.state = { mode: 'tr' };
  }

  render() {
    return (
      <SpaceBetween direction="vertical" size="m">
        {this.renderDepartureSetComparisonSelect()}
        {this.renderModeOptions()}
        {this.renderContent()}
      </SpaceBetween>
    );
  }

  private renderStats() {
    const numberOfTrs = this.props.departureSet.taskSubjects.filter((subject) => typeof subject.trId === 'string').map((subject) => subject.trId as string).length;
    const numberOfTrackingIds = this.props.departureSet.taskSubjects.filter((subject) => typeof subject.trackingId === 'string').map((subject) => subject.trackingId as string).length;

    if (numberOfTrs === numberOfTrackingIds) {
      return <Box>Departure set contains {numberOfTrs} trs.</Box>;
    } else {
      return (
        <Box>
          Departure set contains {numberOfTrs} trs and {numberOfTrackingIds} tracking Ids.
        </Box>
      );
    }
  }

  private renderDepartureSetComparisonSelect() {
    const options: SelectProps.Option[] = [
      {
        value: NONE_SELECTED_DEPARTURE_SET_VERSION,
        label: 'None',
      },
    ];
    this.props.resequenceUpdates.forEach((update) => {
      if (update.version === this.props.routeVersion) {
        // skip allowing self comparison.
        return;
      }
      const system = systemDatabse.get(update.updatedBy);
      const time = timezoneManager.convertTimestampToString(update.timestamp, {
        format: this.props.timeFormat,
      });
      options.push({
        value: update.version.toString(),
        label: `Version ${update.version}, updated by ${system?.abbreviation ?? update.updatedBy} at ${time}.`,
      });
    });

    const selectedOption = options.find((option) => option.value === this.state.selectedDepartureSetVersion);

    return (
      <Select
        options={options}
        placeholder="Choose another departure set to compare."
        filteringType={'manual'}
        filteringPlaceholder={'Enter 2 letters to start search'}
        selectedOption={selectedOption ?? null}
        onChange={(evt) => {
          this.setState({ selectedDepartureSetVersion: evt.detail.selectedOption.value });
        }}
      />
    );
  }

  private renderContent() {
    if (this.state.selectedDepartureSetVersion === undefined || this.state.selectedDepartureSetVersion === NONE_SELECTED_DEPARTURE_SET_VERSION) {
      return (
        <React.Fragment>
          {this.renderStats()}
          {this.renderTaskSubjects(this.props.departureSet.taskSubjects)}
        </React.Fragment>
      );
    } else {
      const departureSetsToCompare = this.props.resequenceUpdates.find((update) => update.version.toString() === this.state.selectedDepartureSetVersion)?.routeUpdate.routeSequenceUpdated
        ?.sequenceSummary.departureSets;

      if (!departureSetsToCompare || departureSetsToCompare.length === 0) {
        return (
          <StatusIndicator type="warning">
            <span> Route version {this.state.selectedDepartureSetVersion} doesn't have an updated deparure sets. Download the route timeline raw JSON data to probe the details.</span>
          </StatusIndicator>
        );
      } else {
        const departureSetToCompare = departureSetsToCompare.find((ds) => ds.departureSetId === this.props.departureSet.departureSetId);
        if (!departureSetToCompare) {
          return (
            <TextContent>
              <StatusIndicator type="warning">
                <span>
                  The current departureSet is {this.props.departureSet.departureSetId}, but the selected route version {this.state.selectedDepartureSetVersion} has different departureSet Id(s).
                </span>
              </StatusIndicator>
            </TextContent>
          );
        } else {
          return this.renderDepartureSetDiff(this.props.departureSet, departureSetToCompare, this.state.selectedDepartureSetVersion);
        }
      }
    }
  }

  private renderModeOptions() {
    return (
      <RadioGroup
        value={this.state.mode}
        items={MODE_OPTIONS}
        onChange={(evt) => {
          this.setState({ mode: evt.detail.value as RouteDepartureSetState['mode'] });
        }}
      />
    );
  }

  /**
   * In theory, we should use taskSubjectId to uniquely identify a tr, but different route store clients have different rules for setting the taskSubjectId.
   * For instance, LMRPES creates the inital route sequence, and it set the taskSubjectId to be the package's trackingId. However, after RDM re-sequence,
   * the taskSubjectId becomes ts-dab34f02-6b51-4ec1-b809-9b38067da825 for the same package.
   *
   * For a meaningful visualization, we prefer the "stable" trId as the identifier for a task subject.
   *
   * ToDo: support SWA departure set diff. SWA route's trIds can be null.
   * @param ds1
   * @param ds2
   * @param ds2Version
   * @returns
   */
  private renderDepartureSetDiff(ds1: RsDepartureSet, ds2: RsDepartureSet, ds2Version: string) {
    const taskSubject =
      ds1.taskSubjects.find((subject) => subject.trId === undefined || subject.trId === null) ?? ds2.taskSubjects.find((subject) => subject.trId === undefined || subject.trId === null);

    if (taskSubject) {
      return (
        <StatusIndicator type="warning">
          <span> Can't compare departure set because task subject {taskSubject.taskSubjectId} doesn't have trId.</span>
        </StatusIndicator>
      );
    }

    const departureSet1Identifiers = new Set(ds1.taskSubjects.map((subject) => subject.trId));
    const departureSet2Identifiers = new Set(ds2.taskSubjects.map((subject) => subject.trId));

    // for task subject in departure set 1 but not in departure set 2
    const taskSubjectsAddedInDepartureSet1: TaskSubject[] = ds1.taskSubjects
      .filter((subject) => {
        return !departureSet2Identifiers.has(subject.trId);
      })
      .sort((s1, s2) => (s1.trId ?? '').localeCompare(s2.trId ?? ''));

    // for task subject in departure set 2 but not in departure set 1
    const taskSubjectsRemovedInDepartureSet1: TaskSubject[] = ds2.taskSubjects
      .filter((subject) => {
        return !departureSet1Identifiers.has(subject.trId);
      })
      .sort((s1, s2) => (s1.trId ?? '').localeCompare(s2.trId ?? ''));

    if (taskSubjectsRemovedInDepartureSet1.length === 0 && taskSubjectsAddedInDepartureSet1.length === 0) {
      return <Box color="text-status-info">No difference.</Box>;
    } else {
      return (
        <SpaceBetween direction="vertical" size="m">
          <Box color="text-status-info">Compared to route version {ds2Version}'s departure set,</Box>
          {taskSubjectsAddedInDepartureSet1.length > 0 ? (
            <React.Fragment>
              <Box fontWeight="bold">
                {taskSubjectsAddedInDepartureSet1.length} tr(s) are added to route version {this.props.routeVersion}.
              </Box>
              {this.renderTaskSubjects(taskSubjectsAddedInDepartureSet1)}
            </React.Fragment>
          ) : null}
          {taskSubjectsRemovedInDepartureSet1.length > 0 ? (
            <React.Fragment>
              <Box fontWeight="bold">
                {taskSubjectsRemovedInDepartureSet1.length} tr(s) are removed from route version {this.props.routeVersion}.
              </Box>
              {this.renderTaskSubjects(taskSubjectsRemovedInDepartureSet1)}
            </React.Fragment>
          ) : null}
        </SpaceBetween>
      );
    }
  }

  private renderTaskSubjects(taskSubjects: ReadonlyArray<TaskSubject>) {
    switch (this.state.mode) {
      case 'tr':
        return this.renderTrs(taskSubjects);
      case 'trackingId':
        return this.renderTrackingIds(taskSubjects);
      case 'tr_and_trackingId':
        return this.renderTrAndTrackingIds(taskSubjects);
    }
  }
  private renderTrs(taskSubjects: ReadonlyArray<TaskSubject>) {
    return (
      <Box>
        {taskSubjects
          .filter((subject) => typeof subject.trId === 'string')
          .map((subject) => subject.trId)
          .sort()
          .map((trId, index) => {
            return <CopyText key={index} copyText={trId as string} />;
          })}
      </Box>
    );
  }

  private renderTrackingIds(taskSubjects: ReadonlyArray<TaskSubject>) {
    return (
      <Box>
        {taskSubjects
          .filter((subject) => typeof subject.trackingId === 'string')
          .map((subject) => subject.trackingId)
          .sort()
          .map((trackingId, index) => {
            return <CopyText key={index} copyText={trackingId as string} />;
          })}
      </Box>
    );
  }

  private renderTrAndTrackingIds(taskSubjects: ReadonlyArray<TaskSubject>) {
    return (
      <Box>
        {taskSubjects
          .filter((subject) => typeof subject.trackingId === 'string' || typeof subject.trId === 'string')
          .map((subject) => subject.trId + ' / ' + subject.trackingId)
          .sort()
          .map((text, index) => {
            return <CopyText key={index} copyText={text} />;
          })}
      </Box>
    );
  }
}

export interface RouteDepartureSetModalProps extends RouteDepartureSetProps {
  readonly onClose: () => void;
}

export class RouteDepartureSetModal extends React.Component<RouteDepartureSetModalProps, {}> {
  render() {
    return (
      <Modal
        onDismiss={() => this.props.onClose()}
        header={
          <Header variant="h3" description={`${this.props.departureSet.departureSetId} (version ${this.props.routeVersion})`}>
            Departure set
          </Header>
        }
        visible={true}
        closeAriaLabel="Close modal"
        size="medium"
      >
        <RouteDepartureSet routeVersion={this.props.routeVersion} departureSet={this.props.departureSet} resequenceUpdates={this.props.resequenceUpdates} timeFormat={this.props.timeFormat} />
      </Modal>
    );
  }
}
