import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import ufraaVizClient, { ArtifactIdentifier, ArtifactMetadata } from '../../clients';
import { timezoneManager } from '../../utilities';
import Table, { TableProps } from '@amzn/awsui-components-react/polaris/table';
import Header from '@amzn/awsui-components-react/polaris/header';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import Link from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import TimeRangePicker from '../../shared-components/time-range-picker';
import { WithBundleProps } from '@amzn/react-arb-tools';

interface Props extends WithBundleProps {
  readonly serviceAreaId: string;
  readonly startTime: number;
  readonly endTime: number;
  readonly onQueryWindowChange: (startTime: number, endTime: number) => void;
}

const MAXIMUM_QUERY_WINDOW_SIZE_IN_MS = 6 * 3600 * 1000;
const MAXIMUM_QUERY_WINDOW_SIZE_TEXT = '6 hours';

export interface RoutingPlanMetadata {
  // By design, a LMES snapshot and its corresponding LMDP plan have the same Id.
  readonly snapshotId: string;
  readonly snapshotGenerationTime: string;
  readonly planGenerationTime?: string;
}

interface State {
  readonly routingPlanMetadataList?: RoutingPlanMetadata[];
  readonly selectedMetadata?: RoutingPlanMetadata[];
  readonly isDownloading: boolean;
}

export default class RoutingPlanTable extends React.Component<Props, State> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      routingPlanMetadataList: undefined,
      selectedMetadata: undefined,
      isDownloading: false,
    };
  }

  async componentDidMount() {
    await this.loadRoutingPlanMetadata(this.props.startTime, this.props.endTime);
  }

  async loadRoutingPlanMetadata(startTime: number, endTime: number) {
    try {
      const snapshotMetadataList = await ufraaVizClient.listArtifactMetadata({
        scope: this.props.serviceAreaId,
        artifactType: 'EXECUTION_SNAPSHOT',
        startTime,
        endTime,
        timeUnits: 'MILLISECONDS',
      });
      const planMetadataList = await ufraaVizClient.listArtifactMetadata({
        scope: this.props.serviceAreaId,
        artifactType: 'EXECUTION_PLAN',
        startTime,
        endTime,
        timeUnits: 'MILLISECONDS',
      });

      // plan and snapshot has the same artifact Id.
      const snapshotIdToPlanMetadata: Map<string, ArtifactMetadata> = new Map();
      planMetadataList.forEach((metadata) => snapshotIdToPlanMetadata.set(metadata.artifactId, metadata));

      this.setState({
        routingPlanMetadataList: snapshotMetadataList.map((metadata) => {
          return {
            snapshotId: metadata.artifactId,
            snapshotGenerationTime: metadata.creationTime,
            planGenerationTime: snapshotIdToPlanMetadata.get(metadata.artifactId)?.creationTime,
          };
        }),
      });
    } catch (err) {
      this.renderError(err);
      this.setState({
        routingPlanMetadataList: [],
      });
    }
  }

  async downloadArtifact() {
    this.setState({ isDownloading: true });
    try {
      if (this.state.selectedMetadata) {
        const artifactIdentifiers: ArtifactIdentifier[] = [];
        this.state.selectedMetadata.forEach((metadata) => {
          if (typeof metadata.planGenerationTime === 'string') {
            // if the plan generation time is undefined, that means the plan hasn't been generated
            artifactIdentifiers.push({ artifactId: metadata.snapshotId, artifactType: 'EXECUTION_PLAN' });
          }

          artifactIdentifiers.push({ artifactId: metadata.snapshotId, artifactType: 'EXECUTION_SNAPSHOT' });
        });

        await ufraaVizClient.downloadArtifacts(artifactIdentifiers);
      }
    } catch (err) {
      this.renderError(err);
    } finally {
      this.setState({ isDownloading: false });
    }
  }

  renderError(err: any) {
    this.context.addNotification({
      type: 'error',
      header: 'Error',
      content: typeof err?.response?.data === 'string' ? err?.response?.data : err?.message,
    });
  }

  renderHeader() {
    return (
      <Header
        variant="h2"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="primary"
              loading={this.state.isDownloading}
              disabled={this.state.selectedMetadata === undefined || this.state.selectedMetadata?.length === 0}
              onClick={async () => await this.downloadArtifact()}
            >
              {this.props.bundle.getMessage('STATION_PAGE:DOWNLOAD')}
            </Button>
          </SpaceBetween>
        }
      >
        {this.props.bundle.getMessage('STATION_PAGE:ROUTING_PLANS')}
      </Header>
    );
  }

  renderDateRangePicker() {
    return (
      <TimeRangePicker
        timezonePreference={timezoneManager.getInUseTimezonePreference()}
        utcOffset={timezoneManager.getUTCOffset()}
        startTime={this.props.startTime}
        endTime={this.props.endTime}
        onChange={async (startTime, endTime) => {
          this.props.onQueryWindowChange(startTime, endTime);
          this.setState({ selectedMetadata: undefined, routingPlanMetadataList: undefined });
          await this.loadRoutingPlanMetadata(startTime, endTime);
        }}
        isValidRange={(startTime, endTime) => {
          if (endTime - startTime > MAXIMUM_QUERY_WINDOW_SIZE_IN_MS) {
            return { valid: false, errorMessage: `Query window size cannot exceed ${MAXIMUM_QUERY_WINDOW_SIZE_TEXT}.` };
          }
          return { valid: true };
        }}
      />
    );
  }

  render() {
    const tableDefinition: TableProps.ColumnDefinition<RoutingPlanMetadata>[] = [
      // todo: As for now, we only have the plan generation time, but it will be intuitive to have a planner input generation to display on the UI.
      // Update LordCommander to include a planner input generation time.
      {
        id: 'snapshotGenerationTime',
        header: this.props.bundle.getMessage('STATION_PAGE:ROUTING_SNAPSHOT_GENERATION_TIME'),
        cell: (item) => {
          return timezoneManager.convertTimestampToString(item.snapshotGenerationTime);
        },
      },
      {
        id: 'planGenerationTime',
        header: this.props.bundle.getMessage('STATION_PAGE:PLAN_GENERATION_TIME'),
        cell: (item) => {
          if (typeof item.planGenerationTime === 'string') {
            return timezoneManager.convertTimestampToString(item.planGenerationTime);
          } else {
            return <StatusIndicator type="in-progress">Plan Not Generated</StatusIndicator>;
          }
        },
      },
      {
        id: 'snapshotId',
        header: this.props.bundle.getMessage('STATION_PAGE:ROUTING_PLAN_COLUMN_HEADER'),
        cell: (item) => (
          <Link
            href={`/routing-plan/${encodeURIComponent(item.snapshotId)}`}
            onFollow={(evt) => {
              // prevent browser from sending requests to backend. Doing in-browser navgiation.
              evt.preventDefault();
              if (typeof evt.detail.href === 'string') {
                this.context.onGoto(evt.detail.href);
              }
            }}
          >
            {item.snapshotId}
          </Link>
        ),
      },
    ];

    return (
      <Table<RoutingPlanMetadata>
        header={this.renderHeader()}
        columnDefinitions={tableDefinition}
        loading={this.state.routingPlanMetadataList === undefined}
        loadingText={this.props.bundle.getMessage('STATION_PAGE:LOADING_ROUTING_PLANS')}
        items={this.state.routingPlanMetadataList === undefined ? [] : this.state.routingPlanMetadataList}
        stickyHeader={false}
        filter={this.renderDateRangePicker()}
        selectionType="multi"
        onSelectionChange={(evt) => {
          this.setState({
            selectedMetadata: evt.detail.selectedItems,
          });
        }}
        selectedItems={this.state.selectedMetadata}
      />
    );
  }
}
