import { DeploymentStage, Marketplace } from '../../models';
import { RouteSearchType } from './models';

export const routeSearchTypeToText: Record<RouteSearchType, string> = {
  routeId: 'Route Id',
  correlationId: 'Correlation Id',
  trId: 'TR Id',
  trackingId: 'Tracking Id',
  transporterId: 'Transporter Id',
};

export const deploymentStageToRouteIqDomain: Record<DeploymentStage, string> = {
  devo: 'https://global.beta.eagle.routeiq.last-mile.amazon.dev',
  beta: 'https://global.beta.eagle.routeiq.last-mile.amazon.dev',
  gamma: 'https://global.gamma.eagle.routeiq.last-mile.amazon.dev',
  prod: 'https://global.eagle.routeiq.last-mile.amazon.dev',
};

export const martketplaceToRouteIqRegion: Record<Marketplace, string> = {
  NA: 'NA',
  EU: 'EU',
  FE: 'APAC',
  IN: 'IN',
};
