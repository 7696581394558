import { DRIA_SNAPSHOT_TYPE } from './models';

export const DRIA_SNAPSHOT_TYPE_TO_LABEL: Record<DRIA_SNAPSHOT_TYPE, string> = {
  SNAPSHOT_PACKAGES: 'package snapshot',
  SNAPSHOT_PROVIDERS: 'provider snapshot',
  SNAPSHOT_SERVICE_AREA: 'service area snapshot',
  SNAPSHOT_ROUTES: 'route snapshot',
  SNAPSHOT_ROUTE_LABOR_ADJUSTMENT: 'route labor adjustment snapshot',
};

export type QUERY_WINDOW_SIZE = '6 hours';

// todo: adjust the window.
export const DRIA_SNAPSHOT_TYPE_TO_MAX_QUERY_WINDOW: Record<DRIA_SNAPSHOT_TYPE, QUERY_WINDOW_SIZE> = {
  SNAPSHOT_PACKAGES: '6 hours',
  SNAPSHOT_PROVIDERS: '6 hours',
  SNAPSHOT_SERVICE_AREA: '6 hours',
  SNAPSHOT_ROUTES: '6 hours',
  SNAPSHOT_ROUTE_LABOR_ADJUSTMENT: '6 hours',
};

export const QUERY_WINDOW_2_MS: Record<QUERY_WINDOW_SIZE, number> = {
  '6 hours': 6 * 3600 * 1000,
};
