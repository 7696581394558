import { BackendJsonVisualizationProfile, JsonVisualizationProfile, JsonVisualizationTableColumnDefinition, Row } from './models';
import jsonata from 'jsonata';

export function convertToJsonVisualizationProfile(profile: BackendJsonVisualizationProfile): JsonVisualizationProfile {
  return {
    ...profile,
    columnDefinitions: JSON.parse(profile.columnDefinitions),
  };
}

export function convertToBackendJsonVisualizationProfile(profile: JsonVisualizationProfile): BackendJsonVisualizationProfile {
  return {
    ...profile,
    columnDefinitions: JSON.stringify(profile.columnDefinitions),
  };
}

export async function buildTableData(data: any, keyPath: string, columns: JsonVisualizationTableColumnDefinition[]): Promise<Row[]> {
  const keyToRow: Map<string, { [columnId: string]: any }> = new Map();
  const keyExpression = jsonata(keyPath); // return a list of
  const keys = await keyExpression.evaluate(data); //

  assertStringArray(keys);

  keys.sort((k1, k2) => k1.localeCompare(k2));

  keys.forEach((key) => {
    keyToRow.set(key, {});
  });

  for (let i = 0; i < columns.length; i++) {
    const expression = jsonata(columns[i].path);
    const keyValueList = await expression.evaluate(data);
    const keyToValues: Map<string, Set<any>> = new Map();

    if (Array.isArray(keyValueList)) {
      keyValueList.forEach((pair) => {
        Object.entries(pair).forEach((entry) => {
          let values = keyToValues.get(entry[0]);
          if (values === undefined) {
            values = new Set();
            keyToValues.set(entry[0], values);
          }
          values.add(entry[1]);
        });
      });
    } else {
      throw new Error('Failed to parsing JSON');
    }

    keys.forEach((key) => {
      const item = keyToRow.get(key);
      if (item !== undefined) {
        const values = keyToValues.get(key);
        item[columns[i].columnId] = values ? Array.from(values) : [];
      }
    });
  }

  return Array.from(keyToRow.values());
}

function assertStringArray(data: any): asserts data is string[] {
  if (Array.isArray(data)) {
    data.forEach((i) => {
      if (typeof i !== 'string') {
        throw new Error();
      }
    });
  } else {
    throw new Error();
  }
}
