import React from 'react';
import { InternalAction } from '../../clients';
import Box from '@amzn/awsui-components-react/polaris/box';
import { timezoneManager } from '../../utilities';
import Table from '@amzn/awsui-components-react/polaris/table';
import Link from '@amzn/awsui-components-react/polaris/link';
import Icon from 'rsuite/lib/Icon';
import 'rsuite/lib/Icon/styles';
export interface RouteActionsProps {
  readonly actions: InternalAction[];
  readonly onTrIdClick: (action: InternalAction) => void;
}

export class RouteActions extends React.Component<RouteActionsProps, {}> {
  render() {
    return (
      <Table<InternalAction>
        columnDefinitions={[
          {
            id: 'time',
            header: 'Time',
            cell: (item) => timezoneManager.convertTimestampToString(item.startTime * 1000),
          },
          {
            id: 'action',
            header: 'Action',
            cell: (item) => {
              switch (item.type) {
                case 'Start':
                  return <Icon icon={'user'} size="2x" style={{ color: 'green' }} />;
                case 'Pickup':
                  return (
                    <Box color="text-status-success">
                      <Icon icon={'angle-double-up'} size="2x" />
                    </Box>
                  );
                case 'Transit':
                  return <Icon icon={'car'} size="2x" />;
                case 'Dropoff':
                  return <Icon icon={'angle-double-down'} size="2x" style={{ color: 'purple' }} />;
                default:
                  return item.type;
              }
            },
          },
          {
            id: 'duration',
            header: 'Duration',
            cell: (item) => new Date(item.duration * 1000).toISOString().substr(11, 8),
          },
          {
            // todo: once we have orderId, display orderId instead of trId.
            id: 'tr',
            header: 'Transport Requests',
            cell: (item) => (
              <Box>
                <Link
                  onFollow={(evt) => {
                    evt.preventDefault();
                    this.props.onTrIdClick(item);
                  }}
                >
                  {item.taskSubjects[0]?.trId}
                </Link>
              </Box>
            ),
          },
        ]}
        items={this.props.actions.filter((action) => action.type === 'Pickup' || action.type === 'Dropoff' || action.type === 'Execute')}
      />
    );
  }
}
