import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import Button from '@amzn/awsui-components-react/polaris/button';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Container from '@amzn/awsui-components-react/polaris/container';
import Form from '@amzn/awsui-components-react/polaris/form';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Input from '@amzn/awsui-components-react/polaris/input';
import Header from '@amzn/awsui-components-react/polaris/header';
import Select, { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import ufraaVizClient, { ArtifactType, ArtifactTypeMetadata } from '../../clients';
import { ARTIFACT_SEARCH_TYPE_TO_LABEL, ARTIFACT_SEARCH_TYPE_TO_OPTION_LABEL, IMPLEMENTED_ARTIFACT_TYPES, SUPPORTED_SEARCH_TYPES } from './constants';
import { ArtifactSearch, ArtifactSearchType, RawArtifactSearch } from './models';
import { buildArtifactSearchFromRawArtifactSearch, buildEmptyArtifactSearch, createHref, getDescriptionFromArtifactSearch, validateTextInput } from './utilities';
import ButtonDropdown from '@amzn/awsui-components-react/polaris/button-dropdown';

interface Props {
  readonly rawArtifactSearch?: RawArtifactSearch;
  readonly onSubmit: (artifactSearch: ArtifactSearch) => void;
}

interface State {
  readonly artifactSearch: ArtifactSearch;
  readonly href: string;
  readonly isDownloading: boolean;
  readonly artifactTypes?: ReadonlyArray<ArtifactTypeMetadata>;

  readonly artifactTypeErrorMessage?: string;
  readonly textErrorMessage?: string;
}

export class ArtifactSearchPage extends React.Component<Props, State> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;
  private artifactTypeToArtifactTypeMetadata: Map<string, ArtifactTypeMetadata>;

  constructor(props: Props) {
    super(props);
    const artifactSearch = props.rawArtifactSearch ? buildArtifactSearchFromRawArtifactSearch(props.rawArtifactSearch) : buildEmptyArtifactSearch('scope');
    this.state = {
      artifactSearch: artifactSearch,
      href: createHref(artifactSearch),
      isDownloading: false,
    };
    this.artifactTypeToArtifactTypeMetadata = new Map();
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.setTools('artifacts-page');
    this.context.updateBreadcrumbItems([{ text: 'Artifacts', href: '/artifacts' }]);

    const types = await ufraaVizClient.listArtifactTypeMetadata();
    this.setState({ artifactTypes: types });

    this.artifactTypeToArtifactTypeMetadata = new Map();
    types.forEach((item) => {
      this.artifactTypeToArtifactTypeMetadata.set(item.artifactType, item);
    });

    // valid user url input after we got the valid artifact types from backend
    if (typeof this.state.artifactSearch.artifactType === 'string') {
      if (!this.artifactTypeToArtifactTypeMetadata.has(this.state.artifactSearch.artifactType)) {
        this.setState({ artifactTypeErrorMessage: `${this.state.artifactSearch.artifactType} is not a valid artifact type.` });
      }
    }
  }

  private validateTextInput(): boolean {
    let valid = true;
    const artifactType = this.state.artifactSearch.artifactType;
    if (typeof artifactType !== 'string') {
      this.setState({ artifactTypeErrorMessage: `Missing artifactType.` });
      valid = false;
    } else if (this.artifactTypeToArtifactTypeMetadata.size === 0) {
      this.setState({ artifactTypeErrorMessage: 'Loading artifact types.' });
      valid = false;
    } else if (!this.artifactTypeToArtifactTypeMetadata.has(artifactType)) {
      this.setState({ artifactTypeErrorMessage: `${artifactType} is not a valid artifact type.` });
      valid = false;
    } else {
      this.setState({ artifactTypeErrorMessage: undefined });
    }

    const result = validateTextInput(this.state.artifactSearch);
    if (typeof result === 'string') {
      this.setState({ textErrorMessage: result });
      valid = false;
    } else {
      this.setState({ textErrorMessage: undefined });
    }

    return valid;
  }

  private async handleDownload() {
    // validate user input
    const artifactType = this.state.artifactSearch.artifactType;
    if (!this.validateTextInput() || typeof artifactType !== 'string') {
      return;
    }

    this.setState({ isDownloading: true });

    try {
      switch (this.state.artifactSearch.searchType) {
        case 'artifactId':
          await ufraaVizClient.downloadArtifacts([
            {
              artifactId: this.state.artifactSearch.text,
              artifactType: this.state.artifactSearch.artifactType as ArtifactType,
            },
          ]);
          break;
        case 'scope':
          await ufraaVizClient.downloadLatestArtifact({
            artifactType: this.state.artifactSearch.artifactType as ArtifactType,
            scope: (this.state.artifactSearch.artifactType as ArtifactType) === 'DRIA_WORK_CADENCE_CONFIG' ? 'DRIA_WORK_CADENCE_CONFIG' : this.state.artifactSearch.text,
          });
          break;
        default:
          console.warn('Unknown artifact search type ' + this.state.artifactSearch.searchType);
      }
    } catch (err: any) {
      this.context.addNotification({
        type: 'error',
        header: `Error trying to download artifact ${this.artifactTypeToArtifactTypeMetadata.get(artifactType)?.artifactTypeName}`,
        content: typeof err?.response?.data === 'string' ? err?.response?.data : err?.message,
      });
    } finally {
      this.setState({ isDownloading: false });
    }
  }

  private renderSelectArtifact() {
    const options: SelectProps.Option[] | undefined = this.state.artifactTypes?.map((item) => ({ label: item.artifactTypeName, value: item.artifactType }));
    const selectedOption = options?.find((option) => option.value === this.state.artifactSearch.artifactType);

    return (
      <FormField label="Artifact Type" errorText={this.state.artifactTypeErrorMessage}>
        <Select
          placeholder="Select an artifact type"
          options={options}
          statusType={options === undefined ? 'loading' : 'finished'}
          loadingText="Loading..."
          filteringType="auto"
          onChange={(evt) => {
            const newSearch: ArtifactSearch = {
              ...this.state.artifactSearch,
              artifactType: evt.detail.selectedOption.value,
            };
            this.setState({
              artifactSearch: newSearch,
              href: createHref(newSearch),
              artifactTypeErrorMessage: undefined,
            });
          }}
          selectedOption={selectedOption ?? null}
        />
      </FormField>
    );
  }

  private renderTextInput() {
    return (
      <FormField
        label={ARTIFACT_SEARCH_TYPE_TO_LABEL[this.state.artifactSearch.searchType]}
        description={getDescriptionFromArtifactSearch(this.state.artifactSearch)}
        errorText={this.state.textErrorMessage}
      >
        <Input
          placeholder={'Enter ' + ARTIFACT_SEARCH_TYPE_TO_LABEL[this.state.artifactSearch.searchType]}
          disabled={(this.state.artifactSearch.artifactType as ArtifactType) === 'DRIA_WORK_CADENCE_CONFIG' && this.state.artifactSearch.searchType === 'scope'}
          onChange={(evt) => {
            const newSearch: ArtifactSearch = {
              ...this.state.artifactSearch,
              text: evt.detail.value,
            };
            this.setState({
              artifactSearch: newSearch,
              href: createHref(newSearch),
              textErrorMessage: undefined,
            });
          }}
          value={this.state.artifactSearch.text}
        />
      </FormField>
    );
  }

  private renderHeader() {
    return (
      <Header
        variant="h2"
        actions={
          <ButtonDropdown
            items={SUPPORTED_SEARCH_TYPES.map((artifactType) => ({ text: ARTIFACT_SEARCH_TYPE_TO_OPTION_LABEL[artifactType], id: artifactType }))}
            onItemClick={(evt) => {
              const newSearch: ArtifactSearch = {
                ...this.state.artifactSearch,
                searchType: evt.detail.id as ArtifactSearchType,
              };
              /**
               * Clear error messages since different search, otherwise text error message remains
               * i.e. artifact id cannot be empty -> switch to scope -> error message would stay without clearing
               */
              this.setState({
                artifactSearch: newSearch,
                textErrorMessage: undefined,
              });
            }}
          >
            {ARTIFACT_SEARCH_TYPE_TO_OPTION_LABEL[this.state.artifactSearch.searchType]}
          </ButtonDropdown>
        }
      >
        Artifact Search
      </Header>
    );
  }

  private renderOpenButton() {
    const disabled = typeof this.state.artifactSearch.artifactType !== 'string' || !IMPLEMENTED_ARTIFACT_TYPES.includes(this.state.artifactSearch.artifactType);
    return (
      <Button
        iconAlign="right"
        iconName="external"
        target="_blank"
        disabled={disabled}
        onClick={(evt) => {
          evt.preventDefault();
          const artifactType = this.state.artifactSearch.artifactType;
          if (!this.validateTextInput() || typeof artifactType !== 'string') {
            return;
          }
          this.context.onGoto(this.state.href);
        }}
        href={this.state.href}
      >
        Open
      </Button>
    );
  }

  private renderDownloadButton() {
    return (
      <Button formAction="submit" loading={this.state.isDownloading} disabled={this.state.isDownloading}>
        Download
      </Button>
    );
  }

  private renderSearchAlert() {
    /**
     * Check whether artifact type is valid at all (i.e. bad query param)
     * Check whether artifact type is undefined (default loading page)
     */
    const artifactType = this.state.artifactSearch.artifactType;
    if (typeof artifactType === 'string' && this.artifactTypeToArtifactTypeMetadata.has(artifactType)) {
      if (!IMPLEMENTED_ARTIFACT_TYPES.includes(artifactType)) {
        return (
          <Alert dismissAriaLabel="Close alert" header="Limitations">
            {`UFRAAViz cannot open ${this.artifactTypeToArtifactTypeMetadata.get(artifactType)?.artifactTypeName} because its rendering page has not been implemented.`}
          </Alert>
        );
      }
    }
  }

  private renderInvalidArtifactTypeAlert() {
    if (this.state.artifactTypeErrorMessage === undefined || this.state.artifactSearch.artifactType === undefined) {
      return null;
    }

    return (
      <Alert statusIconAriaLabel="Error" type="error" header="Invalid Artifact Type">
        {this.state.artifactTypeErrorMessage}
      </Alert>
    );
  }

  private renderSearchContainer() {
    return (
      <Container header={this.renderHeader()}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              {this.renderDownloadButton()}
              {this.state.artifactSearch.searchType === 'artifactId' ? this.renderOpenButton() : null}
            </SpaceBetween>
          }
        >
          <SpaceBetween direction="vertical" size="l">
            {this.state.artifactSearch.searchType === 'artifactId' ? this.renderSearchAlert() : null}
            {this.renderSelectArtifact()}
            {this.renderTextInput()}
          </SpaceBetween>
        </Form>
      </Container>
    );
  }

  render() {
    return (
      <form
        onSubmit={(evt) => {
          // attempt to perform download when user hits enter since shared across search types
          evt.preventDefault();
          this.handleDownload();
          this.props.onSubmit(this.state.artifactSearch);
        }}
      >
        <SpaceBetween direction="vertical" size="l">
          {this.renderInvalidArtifactTypeAlert()}
          {this.renderSearchContainer()}
        </SpaceBetween>
      </form>
    );
  }
}
