import React from 'react';
import { timezoneManager, formatDuration } from '../../utilities';
import Box from '@amzn/awsui-components-react/polaris/box';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { Stop } from './models';
import lodash from 'lodash';
const NotAvaliable = '-';
const TIME_FORMAT = 'HH:mm:ss z';

export type RouteSequenceColumnDefinition = TableProps.ColumnDefinition<Stop> & { id: keyof Stop; header: string; removable?: boolean };

export interface SequenceTableProps {
  readonly version: number;
}

/**
 * Table definition for route sequence.
 * @param props
 */
export function buildSequenceTableDefinition(props: SequenceTableProps): RouteSequenceColumnDefinition[] {
  return [
    {
      id: 'taskType',
      width: 150,
      header: 'Action Type',
      cell: (event: Stop) => lodash.capitalize(event.taskType),
    },
    {
      id: 'startTime',
      width: 150,
      header: 'Start Time',
      cell: (event: Stop) => {
        if (event.startTime) {
          return <Box>{timezoneManager.convertTimestampToString(event.startTime * 1000, { format: TIME_FORMAT })}</Box>;
        } else {
          return NotAvaliable;
        }
      },
    },
    {
      id: 'tasks',
      width: 150,
      header: 'Task Count',
      cell: (event: Stop) => event.tasks.length,
    },
    // {
    //   id: 'geocode',
    //   width: 150,
    //   header: 'Geocode',
    //   cell: (event: Stop) => {
    //     if (event.geocode === undefined) {
    //       return '-'
    //     } else {
    //       return `[${event.geocode?.latitude},${event.geocode?.longitude}]`
    //     }
    //   }
    // },
    {
      id: 'duration',
      width: 150,
      header: 'Duration',
      cell: (event: Stop) => formatDuration(event.duration),
    },
  ];
}
