import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import Link from '@amzn/awsui-components-react/polaris/link';
import { Notification } from '../../../main-app/global-context';
import './permission-warning-notification.scss';

// Team https://permissions.amazon.com/a/team/ufraaviz-routing-view
const UFRAAVIZ_ROUTING_VIEW_PERMISSION_REQUEST_URL = 'https://issues.amazon.com/issues/create?template=7719cc42-951a-40f8-8cbb-53050d233f59';

export function buildRoutingPermissionWarningNotification(): Notification {
  return {
    header: 'Attention',
    content: (
      <Box className={'permission-warning-content'}>
        You don't have access to routing plans. If your role requires access on routing plans, request permission by creating the
        <Link className={'team-link'} external={true} externalIconAriaLabel="Opens in a new tab" href={UFRAAVIZ_ROUTING_VIEW_PERMISSION_REQUEST_URL}>
          SIM issue
        </Link>
        .
      </Box>
    ),
    type: 'warning',
    dismissible: false,
  };
}
