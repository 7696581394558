import lodash from 'lodash';
import { QueryWindow, StationQueryWindows, COMPONENT_ARTIFACT_TYPE } from './models';
import { formatStringToEpochMillisecond } from '../../utilities/formatter';

// encode the query window in url so that others can open the url
export const COMPONENT_ARTIFACT_TYPES_TO_QUERY_WINDOW_ENCODER: Record<COMPONENT_ARTIFACT_TYPE, string> = {
  ROUTING_PLAN: 'routing',
  ASSIGNMENT_PLAN: 'assignment',
  SHADOW_ASSIGNMENT_PLAN: 'shadow-assignment',
  SNAPSHOT_ROUTES: 'routes',
  SNAPSHOT_PACKAGES: 'packages',
  SNAPSHOT_PROVIDERS: 'providers',
  SNAPSHOT_SERVICE_AREA: 'servicearea',
  SNAPSHOT_ROUTE_LABOR_ADJUSTMENT: 'rlas',
  UBP_PLAN: 'ubp',
  PICKUP_READY_PLAN: 'pickupReadyPlan',
};

export const COMPONENT_ARTIFACT_TYPES_TO_DEFAULT_QUERY_WINDOW_SIZE_IN_MS: Record<COMPONENT_ARTIFACT_TYPE, number> = {
  ROUTING_PLAN: 5 * 60_000,
  ASSIGNMENT_PLAN: 5 * 60_000,
  SHADOW_ASSIGNMENT_PLAN: 5 * 60_000,
  SNAPSHOT_ROUTES: 5 * 60_000,
  SNAPSHOT_PACKAGES: 5 * 60_000,
  SNAPSHOT_PROVIDERS: 5 * 60_000,
  SNAPSHOT_SERVICE_AREA: 30 * 60_000, // service area snapshots are generated less frequently, so we give it a large, 2-hour, default query window.
  UBP_PLAN: 5 * 60_000,
  PICKUP_READY_PLAN: 5 * 60_000,
  SNAPSHOT_ROUTE_LABOR_ADJUSTMENT: 30 * 60_000, // RLAS snapshot cadence is 15 minutes. Set the default window size to 30 minutes, so we can always see at least one snapshot.
};

export class QueryParamsHelper {
  private queryWindows: StationQueryWindows;

  constructor(queryParams: string) {
    this.queryWindows = this.decodeQueryParams(queryParams);
  }

  updateQueryParameters(type: COMPONENT_ARTIFACT_TYPE, queryWindow?: QueryWindow) {
    this.queryWindows[type] = queryWindow;
  }

  getQueryTime(type: COMPONENT_ARTIFACT_TYPE, start: boolean): number {
    if (start) {
      return this.queryWindows[type]?.startTime ?? Date.now() - COMPONENT_ARTIFACT_TYPES_TO_DEFAULT_QUERY_WINDOW_SIZE_IN_MS[type];
    } else {
      return this.queryWindows[type]?.endTime ?? Date.now();
    }
  }

  encodeQueryParams(): string {
    const queryParams: string[] = [];
    lodash.forOwn(this.queryWindows, (queryWindow, key_) => {
      const key = key_ as COMPONENT_ARTIFACT_TYPE;
      if (typeof queryWindow?.startTime === 'number') {
        queryParams.push(`${COMPONENT_ARTIFACT_TYPES_TO_QUERY_WINDOW_ENCODER[key]}.st=${new Date(queryWindow.startTime).toISOString()}`);
      }
      if (typeof queryWindow?.endTime === 'number') {
        queryParams.push(`${COMPONENT_ARTIFACT_TYPES_TO_QUERY_WINDOW_ENCODER[key]}.et=${new Date(queryWindow.endTime).toISOString()}`);
      }
    });
    return queryParams.join('&');
  }

  private decodeQueryParams(queryParams: string): StationQueryWindows {
    const params = new URLSearchParams(queryParams);
    const queryWindows: StationQueryWindows = {};
    lodash.forOwn(COMPONENT_ARTIFACT_TYPES_TO_QUERY_WINDOW_ENCODER, (encoder, key_) => {
      const key = key_ as COMPONENT_ARTIFACT_TYPE;
      const startTime = formatStringToEpochMillisecond(params.get(`${encoder}.st`));
      const endTime = formatStringToEpochMillisecond(params.get(`${encoder}.et`));
      queryWindows[key] = { startTime, endTime };
    });
    return queryWindows;
  }
}
