import { RouteChangeLog } from '../../../../clients';

export const timeline: RouteChangeLog[] = [
  {
    updatedBy: 'RDM',
    systemId: 'arn:aws:sts::178546219057:assumed-role/TsmsEventHandlerRole-NA-Prod/TsmsHandlerNA-Prod',
    timestamp: 1677701211656,
    version: 22,
    routeUpdate: {
      updateType: {
        updateCategory: 'GENERAL',
        updateType: 'STATUS_CHANGED',
      },
      routeCreated: null,
      routeStatusChanged: {
        status: ['ITINERARY_ASSIGNED'],
      },
      routeSequenceUpdated: null,
      transporterGroupAssigned: null,
      transporterAssigned: null,
      departureSetUpdate: null,
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'RDM',
    systemId: 'arn:aws:sts::178546219057:assumed-role/TsmsEventHandlerRole-NA-Prod/TsmsHandlerNA-Prod',
    timestamp: 1677701211650,
    version: 21,
    routeUpdate: {
      updateType: {
        updateCategory: 'EXECUTION',
        updateType: 'TRANSPORTER_WORK_ASSIGNED',
      },
      routeCreated: null,
      routeStatusChanged: null,
      routeSequenceUpdated: null,
      transporterGroupAssigned: null,
      transporterAssigned: {
        transporterId: 'AWVXWCHICCPB1',
        providerReservationId: 'amzn1.flex.pr.v1.AWVXWCHICCPB1.1677700800000.479968bb-e253-4c6e-a78a-1629507a8c63.amzn1.flex.st.v1.PuyOplzlR1idvfPkv5138g.CSP.EMPTY.240',
      },
      departureSetUpdate: null,
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'RDM',
    systemId: 'arn:aws:sts::178546219057:assumed-role/TsmsEventHandlerRole-NA-Prod/TsmsHandlerNA-Prod',
    timestamp: 1677701211645,
    version: 20,
    routeUpdate: {
      updateType: {
        updateCategory: 'GENERAL',
        updateType: 'STATUS_CHANGED',
      },
      routeCreated: null,
      routeStatusChanged: {
        status: ['TRANSPORTER_CHECKED_IN'],
      },
      routeSequenceUpdated: null,
      transporterGroupAssigned: null,
      transporterAssigned: null,
      departureSetUpdate: null,
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'FlexRouteAssignmentExecutionService',
    systemId: 'arn:aws:sts::913357832259:assumed-role/LambdaWithApiGateway-prod-LambdaFunctionServiceRol-17X2AI9SBPX84/LambdaWithApiGateway-prod-u-LambdaFunction7804BD21-ycXwVTKR57zw',
    timestamp: 1677701209350,
    version: 19,
    routeUpdate: {
      updateType: {
        updateCategory: 'GENERAL',
        updateType: 'RESEQUENCED',
      },
      routeCreated: null,
      routeStatusChanged: null,
      routeSequenceUpdated: {
        sequenceSummary: {
          serviceTypeId: 'amzn1.flex.st.v1.PuyOplzlR1idvfPkv5138g',
          maxRouteDuration: 14700,
          startTime: 1677695658,
          duration: 13769,
          taskIds: [
            'Add_TBA932868775000',
            'Add_TBA932820555000',
            'Add_TBA932816365000',
            'Add_TBA932818357000',
            'Add_TBA932824027000',
            'Add_TBA932865293000',
            'Add_TBA932825775000',
            'Add_TBA932812207000',
            'Add_TBA932822446000',
            'Add_TBA932809510000',
            'Add_TBA932802333000',
            'Add_TBA930287797000',
            'Add_TBA932816133000',
            'Add_TBA932822918000',
            'Add_TBA932830689000',
            'Add_TBA932810761000',
            'Add_TBA932864528000',
            'Add_TBA932871478000',
            'Add_TBA932870928000',
            'Add_TBA932833856000',
            'Add_TBA932815967000',
            'Add_TBA932832702000',
            'Add_TBA932820409000',
            'Add_TBA932818644000',
            'Add_TBA932860831000',
            'Add_TBA932859310000',
            'Add_TBA932806650000',
            'Add_TBA932820930000',
            'Add_TBA932807125000',
            'Add_TBA930975441000',
            'Remove_TBA932816133000',
            'Remove_TBA932860831000',
            'Remove_TBA932802333000',
            'Remove_TBA932859310000',
            'Remove_TBA932812207000',
            'Remove_TBA932870928000',
            'Remove_TBA932833856000',
            'Remove_TBA932820555000',
            'Remove_TBA932818644000',
            'Remove_TBA932832702000',
            'Remove_TBA932865293000',
            'Remove_TBA932825775000',
            'Remove_TBA932820409000',
            'Remove_TBA932824027000',
            'Remove_TBA932830689000',
            'Remove_TBA932806650000',
            'Remove_TBA932816365000',
            'Remove_TBA932810761000',
            'Remove_TBA932868775000',
            'Remove_TBA932818357000',
            'Remove_TBA930287797000',
            'Remove_TBA932864528000',
            'Remove_TBA932820930000',
            'Remove_TBA932871478000',
            'Remove_TBA932822918000',
            'Remove_TBA932822446000',
            'Remove_TBA932809510000',
            'Remove_TBA932807125000',
            'Remove_TBA932815967000',
            'Remove_TBA930975441000',
          ],
          departureSets: [
            {
              departureSetId: 'e6b24bc9-13ea-48a3-98a7-be448fc67bba',
              taskSubjects: [
                {
                  taskSubjectId: 'TBA932868775000',
                  trackingId: 'TBA932868775000',
                  trId: 'tr-1ec34f42-24f6-9bbc-957f-cbdc0f28e173',
                },
                {
                  taskSubjectId: 'TBA932820555000',
                  trackingId: 'TBA932820555000',
                  trId: 'tr-16c34f34-0281-7e8f-cfab-1dc845df49c5',
                },
                {
                  taskSubjectId: 'TBA932816365000',
                  trackingId: 'TBA932816365000',
                  trId: 'tr-0cc34f32-e1bb-23e6-63ce-27726a455311',
                },
                {
                  taskSubjectId: 'TBA932818357000',
                  trackingId: 'TBA932818357000',
                  trId: 'tr-06c34f33-62c1-adf9-9dea-69d6465de089',
                },
                {
                  taskSubjectId: 'TBA932824027000',
                  trackingId: 'TBA932824027000',
                  trId: 'tr-cac34f34-d21b-c610-9de6-2d7f743119ba',
                },
                {
                  taskSubjectId: 'TBA932865293000',
                  trackingId: 'TBA932865293000',
                  trId: 'tr-3cc34f41-1ca1-2221-cec2-2fc0f4a223cc',
                },
                {
                  taskSubjectId: 'TBA932825775000',
                  trackingId: 'TBA932825775000',
                  trId: 'tr-c8c34f35-456d-cc92-7e67-c64b70e4f2fc',
                },
                {
                  taskSubjectId: 'TBA932812207000',
                  trackingId: 'TBA932812207000',
                  trId: 'tr-64c34f31-d379-14b4-8bfc-58ed26a90acd',
                },
                {
                  taskSubjectId: 'TBA932822446000',
                  trackingId: 'TBA932822446000',
                  trId: 'tr-sh-82c34f34-6be7-dc8a-9c2b-62d5ee75be54',
                },
                {
                  taskSubjectId: 'TBA932809510000',
                  trackingId: 'TBA932809510000',
                  trId: 'tr-eac34f31-2029-0f89-1ce7-e26710d955ac',
                },
                {
                  taskSubjectId: 'TBA932802333000',
                  trackingId: 'TBA932802333000',
                  trId: 'tr-b6c34f2f-4fbd-ebea-66ac-38f044f64f04',
                },
                {
                  taskSubjectId: 'TBA930287797000',
                  trackingId: 'TBA930287797000',
                  trId: 'tr-74c34ed0-cbd5-355d-abba-d8e345a893e0',
                },
                {
                  taskSubjectId: 'TBA932816133000',
                  trackingId: 'TBA932816133000',
                  trId: 'tr-a6c34f32-cfed-aa65-5205-08b3657878d8',
                },
                {
                  taskSubjectId: 'TBA932822918000',
                  trackingId: 'TBA932822918000',
                  trId: 'tr-9ec34f34-889d-5017-ce90-61193eec660d',
                },
                {
                  taskSubjectId: 'TBA932830689000',
                  trackingId: 'TBA932830689000',
                  trId: 'tr-92c34f36-9684-f5ab-80a8-4a326e0ffbc1',
                },
                {
                  taskSubjectId: 'TBA932810761000',
                  trackingId: 'TBA932810761000',
                  trId: 'tr-c6c34f31-70c0-9ab2-02e2-f3efac39e0b6',
                },
                {
                  taskSubjectId: 'TBA932864528000',
                  trackingId: 'TBA932864528000',
                  trId: 'tr-1ac34f40-e5ba-a1ca-a351-12238671ae68',
                },
                {
                  taskSubjectId: 'TBA932871478000',
                  trackingId: 'TBA932871478000',
                  trId: 'tr-76c34f42-ddbf-18c2-4697-74df7943bd84',
                },
                {
                  taskSubjectId: 'TBA932870928000',
                  trackingId: 'TBA932870928000',
                  trId: 'tr-74c34f42-cf9c-81f1-75a9-8dbd8398a8d8',
                },
                {
                  taskSubjectId: 'TBA932833856000',
                  trackingId: 'TBA932833856000',
                  trId: 'tr-6ac34f37-531b-d260-609c-32f696d7fdf3',
                },
                {
                  taskSubjectId: 'TBA932815967000',
                  trackingId: 'TBA932815967000',
                  trId: 'tr-30c34f32-c708-974d-3173-700fd1e4081f',
                },
                {
                  taskSubjectId: 'TBA932832702000',
                  trackingId: 'TBA932832702000',
                  trId: 'tr-7ac34f37-0b09-7b8a-93a6-28f6bf34d8c4',
                },
                {
                  taskSubjectId: 'TBA932820409000',
                  trackingId: 'TBA932820409000',
                  trId: 'tr-e8c34f33-ea04-f3d4-b507-b0f8510a5da9',
                },
                {
                  taskSubjectId: 'TBA932818644000',
                  trackingId: 'TBA932818644000',
                  trId: 'tr-bcc34f33-7100-a629-0b60-33fb83993249',
                },
                {
                  taskSubjectId: 'TBA932860831000',
                  trackingId: 'TBA932860831000',
                  trId: 'tr-78c34f3f-d5d9-c9d4-de05-89f5ebf25b4f',
                },
                {
                  taskSubjectId: 'TBA932859310000',
                  trackingId: 'TBA932859310000',
                  trId: 'tr-0ec34f3f-4575-e521-0405-6d19110d5e8a',
                },
                {
                  taskSubjectId: 'TBA932806650000',
                  trackingId: 'TBA932806650000',
                  trId: 'tr-e4c34f30-92ee-5744-9fd0-af2f20c235e6',
                },
                {
                  taskSubjectId: 'TBA932820930000',
                  trackingId: 'TBA932820930000',
                  trId: 'tr-18c34f34-08ef-89e7-be70-7af445383882',
                },
                {
                  taskSubjectId: 'TBA932807125000',
                  trackingId: 'TBA932807125000',
                  trId: 'tr-28c34f30-8446-c551-d566-83be657bda7c',
                },
                {
                  taskSubjectId: 'TBA930975441000',
                  trackingId: 'TBA930975441000',
                  trId: 'tr-8ec34f32-0403-5288-5b57-e0019cfad221',
                },
              ],
            },
          ],
          totalVolume: 496564.88,
          distance: null,
        },
      },
      transporterGroupAssigned: null,
      transporterAssigned: null,
      departureSetUpdate: null,
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'FlexRouteAssignmentExecutionService',
    systemId: 'arn:aws:sts::913357832259:assumed-role/LambdaWithApiGateway-prod-LambdaFunctionServiceRol-17X2AI9SBPX84/LambdaWithApiGateway-prod-u-LambdaFunction7804BD21-ycXwVTKR57zw',
    timestamp: 1677701209229,
    version: 18,
    routeUpdate: {
      updateType: {
        updateCategory: 'PLAN',
        updateType: 'TRANSPORTER_GROUP',
      },
      routeCreated: null,
      routeStatusChanged: null,
      routeSequenceUpdated: null,
      transporterGroupAssigned: {
        transporterGroup: {
          groupType: 'FLEX',
          groupId: '2023-03-01T20:00:00Z/2023-03-02T00:00:00Z:SCHEDULED',
        },
      },
      transporterAssigned: null,
      departureSetUpdate: null,
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'FlexRouteAssignmentExecutionService',
    systemId: 'arn:aws:sts::913357832259:assumed-role/LambdaWithApiGateway-prod-LambdaFunctionServiceRol-17X2AI9SBPX84/LambdaWithApiGateway-prod-u-LambdaFunction7804BD21-ycXwVTKR57zw',
    timestamp: 1677701209222,
    version: 17,
    routeUpdate: {
      updateType: {
        updateCategory: 'PLAN',
        updateType: 'TRANSPORTER',
      },
      routeCreated: null,
      routeStatusChanged: null,
      routeSequenceUpdated: null,
      transporterGroupAssigned: null,
      transporterAssigned: {
        transporterId: 'AWVXWCHICCPB1',
        providerReservationId: 'amzn1.flex.pr.v1.AWVXWCHICCPB1.1677700800000.479968bb-e253-4c6e-a78a-1629507a8c63.amzn1.flex.st.v1.PuyOplzlR1idvfPkv5138g.CSP.EMPTY.240',
      },
      departureSetUpdate: null,
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'RoutePreparationManagerService',
    systemId: 'arn:aws:sts::782557815301:assumed-role/LMRoutePreparationManagerServiceLambda-na-prod-Role/UpdateRouteStagingLocation',
    timestamp: 1677697228044,
    version: 16,
    routeUpdate: {
      updateType: {
        updateCategory: 'EXECUTION',
        updateType: 'ROUTE_STAGED',
      },
      routeCreated: null,
      routeStatusChanged: null,
      routeSequenceUpdated: null,
      transporterGroupAssigned: null,
      transporterAssigned: null,
      departureSetUpdate: {
        departureSetId: 'e6b24bc9-13ea-48a3-98a7-be448fc67bba',
        sortInstructionsUpdate: null,
        pickStartUpdated: null,
        stageLocationUpdate: {
          stageLocation: 'STG.213',
        },
        departureWindowAssigned: null,
        departedUpdate: null,
        status: [],
      },
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'DispatchPlanningWorkflow',
    systemId: 'arn:aws:sts::475102872062:assumed-role/DispatchPlanningWorkflowLambdaRole/SyncRouteStoreV1',
    timestamp: 1677696923860,
    version: 15,
    routeUpdate: {
      updateType: {
        updateCategory: 'PLAN',
        updateType: 'DEPARTURE_WINDOW',
      },
      routeCreated: null,
      routeStatusChanged: null,
      routeSequenceUpdated: null,
      transporterGroupAssigned: null,
      transporterAssigned: null,
      departureSetUpdate: {
        departureSetId: 'e6b24bc9-13ea-48a3-98a7-be448fc67bba',
        sortInstructionsUpdate: null,
        pickStartUpdated: null,
        stageLocationUpdate: null,
        departureWindowAssigned: {
          departureWindow: {
            startTime: 1677696912,
            endTime: 1677702118,
          },
        },
        departedUpdate: null,
        status: [],
      },
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'DispatchPlanningWorkflow',
    systemId: 'arn:aws:sts::475102872062:assumed-role/DispatchPlanningWorkflowLambdaRole/SyncRouteStoreV1',
    timestamp: 1677696923852,
    version: 14,
    routeUpdate: {
      updateType: {
        updateCategory: 'GENERAL',
        updateType: 'LOCKED',
      },
      routeCreated: null,
      routeStatusChanged: {
        status: ['ROUTE_LOCKED'],
      },
      routeSequenceUpdated: null,
      transporterGroupAssigned: null,
      transporterAssigned: null,
      departureSetUpdate: null,
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'DispatchPlanningWorkflow',
    systemId: 'arn:aws:sts::475102872062:assumed-role/DispatchPlanningWorkflowLambdaRole/SyncRouteStoreV1',
    timestamp: 1677696923693,
    version: 13,
    routeUpdate: {
      updateType: {
        updateCategory: 'PLAN',
        updateType: 'TRANSPORTER_GROUP',
      },
      routeCreated: null,
      routeStatusChanged: null,
      routeSequenceUpdated: null,
      transporterGroupAssigned: {
        transporterGroup: {
          groupType: 'FLEX',
          groupId: null,
        },
      },
      transporterAssigned: null,
      departureSetUpdate: null,
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'DispatchPlanningWorkflow',
    systemId: 'arn:aws:sts::475102872062:assumed-role/DispatchPlanningWorkflowLambdaRole/SyncRouteStoreV1',
    timestamp: 1677696923575,
    version: 12,
    routeUpdate: {
      updateType: {
        updateCategory: 'GENERAL',
        updateType: 'RESEQUENCED',
      },
      routeCreated: null,
      routeStatusChanged: null,
      routeSequenceUpdated: {
        sequenceSummary: {
          serviceTypeId: 'amzn1.flex.st.v1.PuyOplzlR1idvfPkv5138g',
          maxRouteDuration: 14700,
          startTime: 1677695658,
          duration: 13769,
          taskIds: [
            'Add_TBA932868775000',
            'Add_TBA932820555000',
            'Add_TBA932816365000',
            'Add_TBA932818357000',
            'Add_TBA932824027000',
            'Add_TBA932865293000',
            'Add_TBA932825775000',
            'Add_TBA932812207000',
            'Add_TBA932822446000',
            'Add_TBA932809510000',
            'Add_TBA932802333000',
            'Add_TBA930287797000',
            'Add_TBA932816133000',
            'Add_TBA932822918000',
            'Add_TBA932830689000',
            'Add_TBA932810761000',
            'Add_TBA932864528000',
            'Add_TBA932871478000',
            'Add_TBA932870928000',
            'Add_TBA932833856000',
            'Add_TBA932815967000',
            'Add_TBA932832702000',
            'Add_TBA932820409000',
            'Add_TBA932818644000',
            'Add_TBA932860831000',
            'Add_TBA932859310000',
            'Add_TBA932806650000',
            'Add_TBA932820930000',
            'Add_TBA932807125000',
            'Add_TBA930975441000',
            'Remove_TBA932816133000',
            'Remove_TBA932860831000',
            'Remove_TBA932802333000',
            'Remove_TBA932859310000',
            'Remove_TBA932812207000',
            'Remove_TBA932870928000',
            'Remove_TBA932833856000',
            'Remove_TBA932820555000',
            'Remove_TBA932818644000',
            'Remove_TBA932832702000',
            'Remove_TBA932865293000',
            'Remove_TBA932825775000',
            'Remove_TBA932820409000',
            'Remove_TBA932824027000',
            'Remove_TBA932830689000',
            'Remove_TBA932806650000',
            'Remove_TBA932816365000',
            'Remove_TBA932810761000',
            'Remove_TBA932868775000',
            'Remove_TBA932818357000',
            'Remove_TBA930287797000',
            'Remove_TBA932864528000',
            'Remove_TBA932820930000',
            'Remove_TBA932871478000',
            'Remove_TBA932822918000',
            'Remove_TBA932822446000',
            'Remove_TBA932809510000',
            'Remove_TBA932807125000',
            'Remove_TBA932815967000',
            'Remove_TBA930975441000',
          ],
          departureSets: [
            {
              departureSetId: 'e6b24bc9-13ea-48a3-98a7-be448fc67bba',
              taskSubjects: [
                {
                  taskSubjectId: 'TBA932868775000',
                  trackingId: 'TBA932868775000',
                  trId: 'tr-1ec34f42-24f6-9bbc-957f-cbdc0f28e173',
                },
                {
                  taskSubjectId: 'TBA932820555000',
                  trackingId: 'TBA932820555000',
                  trId: 'tr-16c34f34-0281-7e8f-cfab-1dc845df49c5',
                },
                {
                  taskSubjectId: 'TBA932816365000',
                  trackingId: 'TBA932816365000',
                  trId: 'tr-0cc34f32-e1bb-23e6-63ce-27726a455311',
                },
                {
                  taskSubjectId: 'TBA932818357000',
                  trackingId: 'TBA932818357000',
                  trId: 'tr-06c34f33-62c1-adf9-9dea-69d6465de089',
                },
                {
                  taskSubjectId: 'TBA932824027000',
                  trackingId: 'TBA932824027000',
                  trId: 'tr-cac34f34-d21b-c610-9de6-2d7f743119ba',
                },
                {
                  taskSubjectId: 'TBA932865293000',
                  trackingId: 'TBA932865293000',
                  trId: 'tr-3cc34f41-1ca1-2221-cec2-2fc0f4a223cc',
                },
                {
                  taskSubjectId: 'TBA932825775000',
                  trackingId: 'TBA932825775000',
                  trId: 'tr-c8c34f35-456d-cc92-7e67-c64b70e4f2fc',
                },
                {
                  taskSubjectId: 'TBA932812207000',
                  trackingId: 'TBA932812207000',
                  trId: 'tr-64c34f31-d379-14b4-8bfc-58ed26a90acd',
                },
                {
                  taskSubjectId: 'TBA932822446000',
                  trackingId: 'TBA932822446000',
                  trId: 'tr-sh-82c34f34-6be7-dc8a-9c2b-62d5ee75be54',
                },
                {
                  taskSubjectId: 'TBA932809510000',
                  trackingId: 'TBA932809510000',
                  trId: 'tr-eac34f31-2029-0f89-1ce7-e26710d955ac',
                },
                {
                  taskSubjectId: 'TBA932802333000',
                  trackingId: 'TBA932802333000',
                  trId: 'tr-b6c34f2f-4fbd-ebea-66ac-38f044f64f04',
                },
                {
                  taskSubjectId: 'TBA930287797000',
                  trackingId: 'TBA930287797000',
                  trId: 'tr-74c34ed0-cbd5-355d-abba-d8e345a893e0',
                },
                {
                  taskSubjectId: 'TBA932816133000',
                  trackingId: 'TBA932816133000',
                  trId: 'tr-a6c34f32-cfed-aa65-5205-08b3657878d8',
                },
                {
                  taskSubjectId: 'TBA932822918000',
                  trackingId: 'TBA932822918000',
                  trId: 'tr-9ec34f34-889d-5017-ce90-61193eec660d',
                },
                {
                  taskSubjectId: 'TBA932830689000',
                  trackingId: 'TBA932830689000',
                  trId: 'tr-92c34f36-9684-f5ab-80a8-4a326e0ffbc1',
                },
                {
                  taskSubjectId: 'TBA932810761000',
                  trackingId: 'TBA932810761000',
                  trId: 'tr-c6c34f31-70c0-9ab2-02e2-f3efac39e0b6',
                },
                {
                  taskSubjectId: 'TBA932864528000',
                  trackingId: 'TBA932864528000',
                  trId: 'tr-1ac34f40-e5ba-a1ca-a351-12238671ae68',
                },
                {
                  taskSubjectId: 'TBA932871478000',
                  trackingId: 'TBA932871478000',
                  trId: 'tr-76c34f42-ddbf-18c2-4697-74df7943bd84',
                },
                {
                  taskSubjectId: 'TBA932870928000',
                  trackingId: 'TBA932870928000',
                  trId: 'tr-74c34f42-cf9c-81f1-75a9-8dbd8398a8d8',
                },
                {
                  taskSubjectId: 'TBA932833856000',
                  trackingId: 'TBA932833856000',
                  trId: 'tr-6ac34f37-531b-d260-609c-32f696d7fdf3',
                },
                {
                  taskSubjectId: 'TBA932815967000',
                  trackingId: 'TBA932815967000',
                  trId: 'tr-30c34f32-c708-974d-3173-700fd1e4081f',
                },
                {
                  taskSubjectId: 'TBA932832702000',
                  trackingId: 'TBA932832702000',
                  trId: 'tr-7ac34f37-0b09-7b8a-93a6-28f6bf34d8c4',
                },
                {
                  taskSubjectId: 'TBA932820409000',
                  trackingId: 'TBA932820409000',
                  trId: 'tr-e8c34f33-ea04-f3d4-b507-b0f8510a5da9',
                },
                {
                  taskSubjectId: 'TBA932818644000',
                  trackingId: 'TBA932818644000',
                  trId: 'tr-bcc34f33-7100-a629-0b60-33fb83993249',
                },
                {
                  taskSubjectId: 'TBA932860831000',
                  trackingId: 'TBA932860831000',
                  trId: 'tr-78c34f3f-d5d9-c9d4-de05-89f5ebf25b4f',
                },
                {
                  taskSubjectId: 'TBA932859310000',
                  trackingId: 'TBA932859310000',
                  trId: 'tr-0ec34f3f-4575-e521-0405-6d19110d5e8a',
                },
                {
                  taskSubjectId: 'TBA932806650000',
                  trackingId: 'TBA932806650000',
                  trId: 'tr-e4c34f30-92ee-5744-9fd0-af2f20c235e6',
                },
                {
                  taskSubjectId: 'TBA932820930000',
                  trackingId: 'TBA932820930000',
                  trId: 'tr-18c34f34-08ef-89e7-be70-7af445383882',
                },
                {
                  taskSubjectId: 'TBA932807125000',
                  trackingId: 'TBA932807125000',
                  trId: 'tr-28c34f30-8446-c551-d566-83be657bda7c',
                },
                {
                  taskSubjectId: 'TBA930975441000',
                  trackingId: 'TBA930975441000',
                  trId: 'tr-8ec34f32-0403-5288-5b57-e0019cfad221',
                },
              ],
            },
          ],
          totalVolume: 496564.88,
          distance: null,
        },
      },
      transporterGroupAssigned: null,
      transporterAssigned: null,
      departureSetUpdate: null,
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'DispatchPlanningWorkflow',
    systemId: 'arn:aws:sts::475102872062:assumed-role/DispatchPlanningWorkflowLambdaRole/SyncRouteStoreV1',
    timestamp: 1677696737663,
    version: 11,
    routeUpdate: {
      updateType: {
        updateCategory: 'PLAN',
        updateType: 'TRANSPORTER_GROUP',
      },
      routeCreated: null,
      routeStatusChanged: null,
      routeSequenceUpdated: null,
      transporterGroupAssigned: {
        transporterGroup: {
          groupType: 'FLEX',
          groupId: null,
        },
      },
      transporterAssigned: null,
      departureSetUpdate: null,
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'DispatchPlanningWorkflow',
    systemId: 'arn:aws:sts::475102872062:assumed-role/DispatchPlanningWorkflowLambdaRole/SyncRouteStoreV1',
    timestamp: 1677696737571,
    version: 10,
    routeUpdate: {
      updateType: {
        updateCategory: 'GENERAL',
        updateType: 'RESEQUENCED',
      },
      routeCreated: null,
      routeStatusChanged: null,
      routeSequenceUpdated: {
        sequenceSummary: {
          serviceTypeId: 'amzn1.flex.st.v1.PuyOplzlR1idvfPkv5138g',
          maxRouteDuration: 12900,
          startTime: 1677695472,
          duration: 12008,
          taskIds: [
            'Add_TBA932864528000',
            'Add_TBA932820555000',
            'Add_TBA932816365000',
            'Add_TBA932818357000',
            'Add_TBA932815967000',
            'Add_TBA932824027000',
            'Add_TBA932865293000',
            'Add_TBA932825775000',
            'Add_TBA932812207000',
            'Add_TBA932822446000',
            'Add_TBA932809510000',
            'Add_TBA932802333000',
            'Add_TBA932820409000',
            'Add_TBA930287797000',
            'Add_TBA932816133000',
            'Add_TBA932818644000',
            'Add_TBA932860831000',
            'Add_TBA932859310000',
            'Add_TBA932822918000',
            'Add_TBA932806650000',
            'Add_TBA932820930000',
            'Add_TBA932807125000',
            'Add_TBA932810761000',
            'Add_TBA930975441000',
            'Remove_TBA932816133000',
            'Remove_TBA932860831000',
            'Remove_TBA932802333000',
            'Remove_TBA932859310000',
            'Remove_TBA932812207000',
            'Remove_TBA932818644000',
            'Remove_TBA932820555000',
            'Remove_TBA932820930000',
            'Remove_TBA932864528000',
            'Remove_TBA932818357000',
            'Remove_TBA930287797000',
            'Remove_TBA932865293000',
            'Remove_TBA932825775000',
            'Remove_TBA932820409000',
            'Remove_TBA932824027000',
            'Remove_TBA932806650000',
            'Remove_TBA932816365000',
            'Remove_TBA932810761000',
            'Remove_TBA932822918000',
            'Remove_TBA932822446000',
            'Remove_TBA932809510000',
            'Remove_TBA932807125000',
            'Remove_TBA932815967000',
            'Remove_TBA930975441000',
          ],
          departureSets: [
            {
              departureSetId: 'e6b24bc9-13ea-48a3-98a7-be448fc67bba',
              taskSubjects: [
                {
                  taskSubjectId: 'TBA932864528000',
                  trackingId: 'TBA932864528000',
                  trId: 'tr-1ac34f40-e5ba-a1ca-a351-12238671ae68',
                },
                {
                  taskSubjectId: 'TBA932820555000',
                  trackingId: 'TBA932820555000',
                  trId: 'tr-16c34f34-0281-7e8f-cfab-1dc845df49c5',
                },
                {
                  taskSubjectId: 'TBA932816365000',
                  trackingId: 'TBA932816365000',
                  trId: 'tr-0cc34f32-e1bb-23e6-63ce-27726a455311',
                },
                {
                  taskSubjectId: 'TBA932818357000',
                  trackingId: 'TBA932818357000',
                  trId: 'tr-06c34f33-62c1-adf9-9dea-69d6465de089',
                },
                {
                  taskSubjectId: 'TBA932815967000',
                  trackingId: 'TBA932815967000',
                  trId: 'tr-30c34f32-c708-974d-3173-700fd1e4081f',
                },
                {
                  taskSubjectId: 'TBA932824027000',
                  trackingId: 'TBA932824027000',
                  trId: 'tr-cac34f34-d21b-c610-9de6-2d7f743119ba',
                },
                {
                  taskSubjectId: 'TBA932865293000',
                  trackingId: 'TBA932865293000',
                  trId: 'tr-3cc34f41-1ca1-2221-cec2-2fc0f4a223cc',
                },
                {
                  taskSubjectId: 'TBA932825775000',
                  trackingId: 'TBA932825775000',
                  trId: 'tr-c8c34f35-456d-cc92-7e67-c64b70e4f2fc',
                },
                {
                  taskSubjectId: 'TBA932812207000',
                  trackingId: 'TBA932812207000',
                  trId: 'tr-64c34f31-d379-14b4-8bfc-58ed26a90acd',
                },
                {
                  taskSubjectId: 'TBA932822446000',
                  trackingId: 'TBA932822446000',
                  trId: 'tr-sh-82c34f34-6be7-dc8a-9c2b-62d5ee75be54',
                },
                {
                  taskSubjectId: 'TBA932809510000',
                  trackingId: 'TBA932809510000',
                  trId: 'tr-eac34f31-2029-0f89-1ce7-e26710d955ac',
                },
                {
                  taskSubjectId: 'TBA932802333000',
                  trackingId: 'TBA932802333000',
                  trId: 'tr-b6c34f2f-4fbd-ebea-66ac-38f044f64f04',
                },
                {
                  taskSubjectId: 'TBA932820409000',
                  trackingId: 'TBA932820409000',
                  trId: 'tr-e8c34f33-ea04-f3d4-b507-b0f8510a5da9',
                },
                {
                  taskSubjectId: 'TBA930287797000',
                  trackingId: 'TBA930287797000',
                  trId: 'tr-74c34ed0-cbd5-355d-abba-d8e345a893e0',
                },
                {
                  taskSubjectId: 'TBA932816133000',
                  trackingId: 'TBA932816133000',
                  trId: 'tr-a6c34f32-cfed-aa65-5205-08b3657878d8',
                },
                {
                  taskSubjectId: 'TBA932818644000',
                  trackingId: 'TBA932818644000',
                  trId: 'tr-bcc34f33-7100-a629-0b60-33fb83993249',
                },
                {
                  taskSubjectId: 'TBA932860831000',
                  trackingId: 'TBA932860831000',
                  trId: 'tr-78c34f3f-d5d9-c9d4-de05-89f5ebf25b4f',
                },
                {
                  taskSubjectId: 'TBA932859310000',
                  trackingId: 'TBA932859310000',
                  trId: 'tr-0ec34f3f-4575-e521-0405-6d19110d5e8a',
                },
                {
                  taskSubjectId: 'TBA932822918000',
                  trackingId: 'TBA932822918000',
                  trId: 'tr-9ec34f34-889d-5017-ce90-61193eec660d',
                },
                {
                  taskSubjectId: 'TBA932806650000',
                  trackingId: 'TBA932806650000',
                  trId: 'tr-e4c34f30-92ee-5744-9fd0-af2f20c235e6',
                },
                {
                  taskSubjectId: 'TBA932820930000',
                  trackingId: 'TBA932820930000',
                  trId: 'tr-18c34f34-08ef-89e7-be70-7af445383882',
                },
                {
                  taskSubjectId: 'TBA932807125000',
                  trackingId: 'TBA932807125000',
                  trId: 'tr-28c34f30-8446-c551-d566-83be657bda7c',
                },
                {
                  taskSubjectId: 'TBA932810761000',
                  trackingId: 'TBA932810761000',
                  trId: 'tr-c6c34f31-70c0-9ab2-02e2-f3efac39e0b6',
                },
                {
                  taskSubjectId: 'TBA930975441000',
                  trackingId: 'TBA930975441000',
                  trId: 'tr-8ec34f32-0403-5288-5b57-e0019cfad221',
                },
              ],
            },
          ],
          totalVolume: 387269.06,
          distance: null,
        },
      },
      transporterGroupAssigned: null,
      transporterAssigned: null,
      departureSetUpdate: null,
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'DispatchPlanningWorkflow',
    systemId: 'arn:aws:sts::475102872062:assumed-role/DispatchPlanningWorkflowLambdaRole/SyncRouteStoreV1',
    timestamp: 1677696498009,
    version: 9,
    routeUpdate: {
      updateType: {
        updateCategory: 'PLAN',
        updateType: 'TRANSPORTER_GROUP',
      },
      routeCreated: null,
      routeStatusChanged: null,
      routeSequenceUpdated: null,
      transporterGroupAssigned: {
        transporterGroup: {
          groupType: 'FLEX',
          groupId: null,
        },
      },
      transporterAssigned: null,
      departureSetUpdate: null,
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'DispatchPlanningWorkflow',
    systemId: 'arn:aws:sts::475102872062:assumed-role/DispatchPlanningWorkflowLambdaRole/SyncRouteStoreV1',
    timestamp: 1677696497945,
    version: 8,
    routeUpdate: {
      updateType: {
        updateCategory: 'GENERAL',
        updateType: 'RESEQUENCED',
      },
      routeCreated: null,
      routeStatusChanged: null,
      routeSequenceUpdated: {
        sequenceSummary: {
          serviceTypeId: 'amzn1.flex.st.v1.PuyOplzlR1idvfPkv5138g',
          maxRouteDuration: 12900,
          startTime: 1677695232,
          duration: 11552,
          taskIds: [
            'Add_TBA932820555000',
            'Add_TBA932816365000',
            'Add_TBA932818357000',
            'Add_TBA932815967000',
            'Add_TBA932824027000',
            'Add_TBA932825775000',
            'Add_TBA932812207000',
            'Add_TBA932822446000',
            'Add_TBA932809510000',
            'Add_TBA932802333000',
            'Add_TBA932820409000',
            'Add_TBA930287797000',
            'Add_TBA932816133000',
            'Add_TBA932818644000',
            'Add_TBA932860831000',
            'Add_TBA932859310000',
            'Add_TBA932822918000',
            'Add_TBA932806650000',
            'Add_TBA932820930000',
            'Add_TBA932807125000',
            'Add_TBA932810761000',
            'Add_TBA930975441000',
            'Remove_TBA932816133000',
            'Remove_TBA932860831000',
            'Remove_TBA932802333000',
            'Remove_TBA932859310000',
            'Remove_TBA932812207000',
            'Remove_TBA932818644000',
            'Remove_TBA932820555000',
            'Remove_TBA932820930000',
            'Remove_TBA932818357000',
            'Remove_TBA930287797000',
            'Remove_TBA932825775000',
            'Remove_TBA932820409000',
            'Remove_TBA932824027000',
            'Remove_TBA932806650000',
            'Remove_TBA932816365000',
            'Remove_TBA932810761000',
            'Remove_TBA932822918000',
            'Remove_TBA932822446000',
            'Remove_TBA932809510000',
            'Remove_TBA932807125000',
            'Remove_TBA932815967000',
            'Remove_TBA930975441000',
          ],
          departureSets: [
            {
              departureSetId: 'e6b24bc9-13ea-48a3-98a7-be448fc67bba',
              taskSubjects: [
                {
                  taskSubjectId: 'TBA932820555000',
                  trackingId: 'TBA932820555000',
                  trId: 'tr-16c34f34-0281-7e8f-cfab-1dc845df49c5',
                },
                {
                  taskSubjectId: 'TBA932816365000',
                  trackingId: 'TBA932816365000',
                  trId: 'tr-0cc34f32-e1bb-23e6-63ce-27726a455311',
                },
                {
                  taskSubjectId: 'TBA932818357000',
                  trackingId: 'TBA932818357000',
                  trId: 'tr-06c34f33-62c1-adf9-9dea-69d6465de089',
                },
                {
                  taskSubjectId: 'TBA932815967000',
                  trackingId: 'TBA932815967000',
                  trId: 'tr-30c34f32-c708-974d-3173-700fd1e4081f',
                },
                {
                  taskSubjectId: 'TBA932824027000',
                  trackingId: 'TBA932824027000',
                  trId: 'tr-cac34f34-d21b-c610-9de6-2d7f743119ba',
                },
                {
                  taskSubjectId: 'TBA932825775000',
                  trackingId: 'TBA932825775000',
                  trId: 'tr-c8c34f35-456d-cc92-7e67-c64b70e4f2fc',
                },
                {
                  taskSubjectId: 'TBA932812207000',
                  trackingId: 'TBA932812207000',
                  trId: 'tr-64c34f31-d379-14b4-8bfc-58ed26a90acd',
                },
                {
                  taskSubjectId: 'TBA932822446000',
                  trackingId: 'TBA932822446000',
                  trId: 'tr-sh-82c34f34-6be7-dc8a-9c2b-62d5ee75be54',
                },
                {
                  taskSubjectId: 'TBA932809510000',
                  trackingId: 'TBA932809510000',
                  trId: 'tr-eac34f31-2029-0f89-1ce7-e26710d955ac',
                },
                {
                  taskSubjectId: 'TBA932802333000',
                  trackingId: 'TBA932802333000',
                  trId: 'tr-b6c34f2f-4fbd-ebea-66ac-38f044f64f04',
                },
                {
                  taskSubjectId: 'TBA932820409000',
                  trackingId: 'TBA932820409000',
                  trId: 'tr-e8c34f33-ea04-f3d4-b507-b0f8510a5da9',
                },
                {
                  taskSubjectId: 'TBA930287797000',
                  trackingId: 'TBA930287797000',
                  trId: 'tr-74c34ed0-cbd5-355d-abba-d8e345a893e0',
                },
                {
                  taskSubjectId: 'TBA932816133000',
                  trackingId: 'TBA932816133000',
                  trId: 'tr-a6c34f32-cfed-aa65-5205-08b3657878d8',
                },
                {
                  taskSubjectId: 'TBA932818644000',
                  trackingId: 'TBA932818644000',
                  trId: 'tr-bcc34f33-7100-a629-0b60-33fb83993249',
                },
                {
                  taskSubjectId: 'TBA932860831000',
                  trackingId: 'TBA932860831000',
                  trId: 'tr-78c34f3f-d5d9-c9d4-de05-89f5ebf25b4f',
                },
                {
                  taskSubjectId: 'TBA932859310000',
                  trackingId: 'TBA932859310000',
                  trId: 'tr-0ec34f3f-4575-e521-0405-6d19110d5e8a',
                },
                {
                  taskSubjectId: 'TBA932822918000',
                  trackingId: 'TBA932822918000',
                  trId: 'tr-9ec34f34-889d-5017-ce90-61193eec660d',
                },
                {
                  taskSubjectId: 'TBA932806650000',
                  trackingId: 'TBA932806650000',
                  trId: 'tr-e4c34f30-92ee-5744-9fd0-af2f20c235e6',
                },
                {
                  taskSubjectId: 'TBA932820930000',
                  trackingId: 'TBA932820930000',
                  trId: 'tr-18c34f34-08ef-89e7-be70-7af445383882',
                },
                {
                  taskSubjectId: 'TBA932807125000',
                  trackingId: 'TBA932807125000',
                  trId: 'tr-28c34f30-8446-c551-d566-83be657bda7c',
                },
                {
                  taskSubjectId: 'TBA932810761000',
                  trackingId: 'TBA932810761000',
                  trId: 'tr-c6c34f31-70c0-9ab2-02e2-f3efac39e0b6',
                },
                {
                  taskSubjectId: 'TBA930975441000',
                  trackingId: 'TBA930975441000',
                  trId: 'tr-8ec34f32-0403-5288-5b57-e0019cfad221',
                },
              ],
            },
          ],
          totalVolume: 381248.44,
          distance: null,
        },
      },
      transporterGroupAssigned: null,
      transporterAssigned: null,
      departureSetUpdate: null,
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'DispatchPlanningWorkflow',
    systemId: 'arn:aws:sts::475102872062:assumed-role/DispatchPlanningWorkflowLambdaRole/SyncRouteStoreV1',
    timestamp: 1677695371130,
    version: 7,
    routeUpdate: {
      updateType: {
        updateCategory: 'PLAN',
        updateType: 'TRANSPORTER_GROUP',
      },
      routeCreated: null,
      routeStatusChanged: null,
      routeSequenceUpdated: null,
      transporterGroupAssigned: {
        transporterGroup: {
          groupType: 'FLEX',
          groupId: null,
        },
      },
      transporterAssigned: null,
      departureSetUpdate: null,
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'DispatchPlanningWorkflow',
    systemId: 'arn:aws:sts::475102872062:assumed-role/DispatchPlanningWorkflowLambdaRole/SyncRouteStoreV1',
    timestamp: 1677695371061,
    version: 6,
    routeUpdate: {
      updateType: {
        updateCategory: 'GENERAL',
        updateType: 'RESEQUENCED',
      },
      routeCreated: null,
      routeStatusChanged: null,
      routeSequenceUpdated: {
        sequenceSummary: {
          serviceTypeId: 'amzn1.flex.st.v1.PuyOplzlR1idvfPkv5138g',
          maxRouteDuration: 11100,
          startTime: 1677694105,
          duration: 11039,
          taskIds: [
            'Add_TBA932820555000',
            'Add_TBA932816365000',
            'Add_TBA932818357000',
            'Add_TBA932815967000',
            'Add_TBA932824027000',
            'Add_TBA932825775000',
            'Add_TBA932812207000',
            'Add_TBA932822446000',
            'Add_TBA932809510000',
            'Add_TBA932802333000',
            'Add_TBA932820409000',
            'Add_TBA930287797000',
            'Add_TBA932816133000',
            'Add_TBA932818644000',
            'Add_TBA932822918000',
            'Add_TBA932806650000',
            'Add_TBA932820930000',
            'Add_TBA932807125000',
            'Add_TBA932810761000',
            'Add_TBA930975441000',
            'Remove_TBA932816133000',
            'Remove_TBA932802333000',
            'Remove_TBA932812207000',
            'Remove_TBA932818644000',
            'Remove_TBA932820555000',
            'Remove_TBA932820930000',
            'Remove_TBA932818357000',
            'Remove_TBA930287797000',
            'Remove_TBA932825775000',
            'Remove_TBA932820409000',
            'Remove_TBA932824027000',
            'Remove_TBA932806650000',
            'Remove_TBA932816365000',
            'Remove_TBA932810761000',
            'Remove_TBA932822918000',
            'Remove_TBA932822446000',
            'Remove_TBA932809510000',
            'Remove_TBA932807125000',
            'Remove_TBA932815967000',
            'Remove_TBA930975441000',
          ],
          departureSets: [
            {
              departureSetId: 'e6b24bc9-13ea-48a3-98a7-be448fc67bba',
              taskSubjects: [
                {
                  taskSubjectId: 'TBA932820555000',
                  trackingId: 'TBA932820555000',
                  trId: 'tr-16c34f34-0281-7e8f-cfab-1dc845df49c5',
                },
                {
                  taskSubjectId: 'TBA932816365000',
                  trackingId: 'TBA932816365000',
                  trId: 'tr-0cc34f32-e1bb-23e6-63ce-27726a455311',
                },
                {
                  taskSubjectId: 'TBA932818357000',
                  trackingId: 'TBA932818357000',
                  trId: 'tr-06c34f33-62c1-adf9-9dea-69d6465de089',
                },
                {
                  taskSubjectId: 'TBA932815967000',
                  trackingId: 'TBA932815967000',
                  trId: 'tr-30c34f32-c708-974d-3173-700fd1e4081f',
                },
                {
                  taskSubjectId: 'TBA932824027000',
                  trackingId: 'TBA932824027000',
                  trId: 'tr-cac34f34-d21b-c610-9de6-2d7f743119ba',
                },
                {
                  taskSubjectId: 'TBA932825775000',
                  trackingId: 'TBA932825775000',
                  trId: 'tr-c8c34f35-456d-cc92-7e67-c64b70e4f2fc',
                },
                {
                  taskSubjectId: 'TBA932812207000',
                  trackingId: 'TBA932812207000',
                  trId: 'tr-64c34f31-d379-14b4-8bfc-58ed26a90acd',
                },
                {
                  taskSubjectId: 'TBA932822446000',
                  trackingId: 'TBA932822446000',
                  trId: 'tr-sh-82c34f34-6be7-dc8a-9c2b-62d5ee75be54',
                },
                {
                  taskSubjectId: 'TBA932809510000',
                  trackingId: 'TBA932809510000',
                  trId: 'tr-eac34f31-2029-0f89-1ce7-e26710d955ac',
                },
                {
                  taskSubjectId: 'TBA932802333000',
                  trackingId: 'TBA932802333000',
                  trId: 'tr-b6c34f2f-4fbd-ebea-66ac-38f044f64f04',
                },
                {
                  taskSubjectId: 'TBA932820409000',
                  trackingId: 'TBA932820409000',
                  trId: 'tr-e8c34f33-ea04-f3d4-b507-b0f8510a5da9',
                },
                {
                  taskSubjectId: 'TBA930287797000',
                  trackingId: 'TBA930287797000',
                  trId: 'tr-74c34ed0-cbd5-355d-abba-d8e345a893e0',
                },
                {
                  taskSubjectId: 'TBA932816133000',
                  trackingId: 'TBA932816133000',
                  trId: 'tr-a6c34f32-cfed-aa65-5205-08b3657878d8',
                },
                {
                  taskSubjectId: 'TBA932818644000',
                  trackingId: 'TBA932818644000',
                  trId: 'tr-bcc34f33-7100-a629-0b60-33fb83993249',
                },
                {
                  taskSubjectId: 'TBA932822918000',
                  trackingId: 'TBA932822918000',
                  trId: 'tr-9ec34f34-889d-5017-ce90-61193eec660d',
                },
                {
                  taskSubjectId: 'TBA932806650000',
                  trackingId: 'TBA932806650000',
                  trId: 'tr-e4c34f30-92ee-5744-9fd0-af2f20c235e6',
                },
                {
                  taskSubjectId: 'TBA932820930000',
                  trackingId: 'TBA932820930000',
                  trId: 'tr-18c34f34-08ef-89e7-be70-7af445383882',
                },
                {
                  taskSubjectId: 'TBA932807125000',
                  trackingId: 'TBA932807125000',
                  trId: 'tr-28c34f30-8446-c551-d566-83be657bda7c',
                },
                {
                  taskSubjectId: 'TBA932810761000',
                  trackingId: 'TBA932810761000',
                  trId: 'tr-c6c34f31-70c0-9ab2-02e2-f3efac39e0b6',
                },
                {
                  taskSubjectId: 'TBA930975441000',
                  trackingId: 'TBA930975441000',
                  trId: 'tr-8ec34f32-0403-5288-5b57-e0019cfad221',
                },
              ],
            },
          ],
          totalVolume: 337085.25,
          distance: null,
        },
      },
      transporterGroupAssigned: null,
      transporterAssigned: null,
      departureSetUpdate: null,
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'DispatchPlanningWorkflow',
    systemId: 'arn:aws:sts::475102872062:assumed-role/DispatchPlanningWorkflowLambdaRole/SyncRouteStoreV1',
    timestamp: 1677695059080,
    version: 5,
    routeUpdate: {
      updateType: {
        updateCategory: 'PLAN',
        updateType: 'TRANSPORTER_GROUP',
      },
      routeCreated: null,
      routeStatusChanged: null,
      routeSequenceUpdated: null,
      transporterGroupAssigned: {
        transporterGroup: {
          groupType: 'FLEX',
          groupId: null,
        },
      },
      transporterAssigned: null,
      departureSetUpdate: null,
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'DispatchPlanningWorkflow',
    systemId: 'arn:aws:sts::475102872062:assumed-role/DispatchPlanningWorkflowLambdaRole/SyncRouteStoreV1',
    timestamp: 1677695058756,
    version: 4,
    routeUpdate: {
      updateType: {
        updateCategory: 'GENERAL',
        updateType: 'RESEQUENCED',
      },
      routeCreated: null,
      routeStatusChanged: null,
      routeSequenceUpdated: {
        sequenceSummary: {
          serviceTypeId: 'amzn1.flex.st.v1.PuyOplzlR1idvfPkv5138g',
          maxRouteDuration: 11100,
          startTime: 1677693793,
          duration: 10443,
          taskIds: [
            'Add_TBA932820555000',
            'Add_TBA932816365000',
            'Add_TBA932818357000',
            'Add_TBA932815967000',
            'Add_TBA932812207000',
            'Add_TBA932822446000',
            'Add_TBA932809510000',
            'Add_TBA932802333000',
            'Add_TBA932820409000',
            'Add_TBA930287797000',
            'Add_TBA932816133000',
            'Add_TBA932818644000',
            'Add_TBA932822918000',
            'Add_TBA932806650000',
            'Add_TBA932820930000',
            'Add_TBA932807125000',
            'Add_TBA932810761000',
            'Add_TBA930975441000',
            'Remove_TBA932816133000',
            'Remove_TBA932802333000',
            'Remove_TBA932812207000',
            'Remove_TBA932820409000',
            'Remove_TBA932818644000',
            'Remove_TBA932820555000',
            'Remove_TBA932820930000',
            'Remove_TBA932818357000',
            'Remove_TBA930287797000',
            'Remove_TBA932806650000',
            'Remove_TBA932816365000',
            'Remove_TBA932810761000',
            'Remove_TBA932822918000',
            'Remove_TBA932822446000',
            'Remove_TBA932809510000',
            'Remove_TBA932807125000',
            'Remove_TBA932815967000',
            'Remove_TBA930975441000',
          ],
          departureSets: [
            {
              departureSetId: 'e6b24bc9-13ea-48a3-98a7-be448fc67bba',
              taskSubjects: [
                {
                  taskSubjectId: 'TBA932820555000',
                  trackingId: 'TBA932820555000',
                  trId: 'tr-16c34f34-0281-7e8f-cfab-1dc845df49c5',
                },
                {
                  taskSubjectId: 'TBA932816365000',
                  trackingId: 'TBA932816365000',
                  trId: 'tr-0cc34f32-e1bb-23e6-63ce-27726a455311',
                },
                {
                  taskSubjectId: 'TBA932818357000',
                  trackingId: 'TBA932818357000',
                  trId: 'tr-06c34f33-62c1-adf9-9dea-69d6465de089',
                },
                {
                  taskSubjectId: 'TBA932815967000',
                  trackingId: 'TBA932815967000',
                  trId: 'tr-30c34f32-c708-974d-3173-700fd1e4081f',
                },
                {
                  taskSubjectId: 'TBA932812207000',
                  trackingId: 'TBA932812207000',
                  trId: 'tr-64c34f31-d379-14b4-8bfc-58ed26a90acd',
                },
                {
                  taskSubjectId: 'TBA932822446000',
                  trackingId: 'TBA932822446000',
                  trId: 'tr-sh-82c34f34-6be7-dc8a-9c2b-62d5ee75be54',
                },
                {
                  taskSubjectId: 'TBA932809510000',
                  trackingId: 'TBA932809510000',
                  trId: 'tr-eac34f31-2029-0f89-1ce7-e26710d955ac',
                },
                {
                  taskSubjectId: 'TBA932802333000',
                  trackingId: 'TBA932802333000',
                  trId: 'tr-b6c34f2f-4fbd-ebea-66ac-38f044f64f04',
                },
                {
                  taskSubjectId: 'TBA932820409000',
                  trackingId: 'TBA932820409000',
                  trId: 'tr-e8c34f33-ea04-f3d4-b507-b0f8510a5da9',
                },
                {
                  taskSubjectId: 'TBA930287797000',
                  trackingId: 'TBA930287797000',
                  trId: 'tr-74c34ed0-cbd5-355d-abba-d8e345a893e0',
                },
                {
                  taskSubjectId: 'TBA932816133000',
                  trackingId: 'TBA932816133000',
                  trId: 'tr-a6c34f32-cfed-aa65-5205-08b3657878d8',
                },
                {
                  taskSubjectId: 'TBA932818644000',
                  trackingId: 'TBA932818644000',
                  trId: 'tr-bcc34f33-7100-a629-0b60-33fb83993249',
                },
                {
                  taskSubjectId: 'TBA932822918000',
                  trackingId: 'TBA932822918000',
                  trId: 'tr-9ec34f34-889d-5017-ce90-61193eec660d',
                },
                {
                  taskSubjectId: 'TBA932806650000',
                  trackingId: 'TBA932806650000',
                  trId: 'tr-e4c34f30-92ee-5744-9fd0-af2f20c235e6',
                },
                {
                  taskSubjectId: 'TBA932820930000',
                  trackingId: 'TBA932820930000',
                  trId: 'tr-18c34f34-08ef-89e7-be70-7af445383882',
                },
                {
                  taskSubjectId: 'TBA932807125000',
                  trackingId: 'TBA932807125000',
                  trId: 'tr-28c34f30-8446-c551-d566-83be657bda7c',
                },
                {
                  taskSubjectId: 'TBA932810761000',
                  trackingId: 'TBA932810761000',
                  trId: 'tr-c6c34f31-70c0-9ab2-02e2-f3efac39e0b6',
                },
                {
                  taskSubjectId: 'TBA930975441000',
                  trackingId: 'TBA930975441000',
                  trId: 'tr-8ec34f32-0403-5288-5b57-e0019cfad221',
                },
              ],
            },
          ],
          totalVolume: 298379,
          distance: null,
        },
      },
      transporterGroupAssigned: null,
      transporterAssigned: null,
      departureSetUpdate: null,
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'DispatchPlanningWorkflow',
    systemId: 'arn:aws:sts::475102872062:assumed-role/DispatchPlanningWorkflowLambdaRole/SyncRouteStoreV1',
    timestamp: 1677694758460,
    version: 3,
    routeUpdate: {
      updateType: {
        updateCategory: 'PLAN',
        updateType: 'TRANSPORTER_GROUP',
      },
      routeCreated: null,
      routeStatusChanged: null,
      routeSequenceUpdated: null,
      transporterGroupAssigned: {
        transporterGroup: {
          groupType: 'FLEX',
          groupId: null,
        },
      },
      transporterAssigned: null,
      departureSetUpdate: null,
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'DispatchPlanningWorkflow',
    systemId: 'arn:aws:sts::475102872062:assumed-role/DispatchPlanningWorkflowLambdaRole/SyncRouteStoreV1',
    timestamp: 1677694758370,
    version: 2,
    routeUpdate: {
      updateType: {
        updateCategory: 'GENERAL',
        updateType: 'SEQUENCED',
      },
      routeCreated: null,
      routeStatusChanged: null,
      routeSequenceUpdated: {
        sequenceSummary: {
          serviceTypeId: 'amzn1.flex.st.v1.PuyOplzlR1idvfPkv5138g',
          maxRouteDuration: 7500,
          startTime: 1677693492,
          duration: 5820,
          taskIds: [
            'Add_TBA930287797000',
            'Add_TBA932806650000',
            'Add_TBA932807125000',
            'Add_TBA932812207000',
            'Add_TBA932810761000',
            'Add_TBA932809510000',
            'Add_TBA930975441000',
            'Add_TBA932802333000',
            'Remove_TBA932802333000',
            'Remove_TBA932812207000',
            'Remove_TBA930287797000',
            'Remove_TBA932806650000',
            'Remove_TBA932810761000',
            'Remove_TBA932809510000',
            'Remove_TBA932807125000',
            'Remove_TBA930975441000',
          ],
          departureSets: [
            {
              departureSetId: 'e6b24bc9-13ea-48a3-98a7-be448fc67bba',
              taskSubjects: [
                {
                  taskSubjectId: 'TBA930287797000',
                  trackingId: 'TBA930287797000',
                  trId: 'tr-74c34ed0-cbd5-355d-abba-d8e345a893e0',
                },
                {
                  taskSubjectId: 'TBA932806650000',
                  trackingId: 'TBA932806650000',
                  trId: 'tr-e4c34f30-92ee-5744-9fd0-af2f20c235e6',
                },
                {
                  taskSubjectId: 'TBA932807125000',
                  trackingId: 'TBA932807125000',
                  trId: 'tr-28c34f30-8446-c551-d566-83be657bda7c',
                },
                {
                  taskSubjectId: 'TBA932812207000',
                  trackingId: 'TBA932812207000',
                  trId: 'tr-64c34f31-d379-14b4-8bfc-58ed26a90acd',
                },
                {
                  taskSubjectId: 'TBA932810761000',
                  trackingId: 'TBA932810761000',
                  trId: 'tr-c6c34f31-70c0-9ab2-02e2-f3efac39e0b6',
                },
                {
                  taskSubjectId: 'TBA932809510000',
                  trackingId: 'TBA932809510000',
                  trId: 'tr-eac34f31-2029-0f89-1ce7-e26710d955ac',
                },
                {
                  taskSubjectId: 'TBA930975441000',
                  trackingId: 'TBA930975441000',
                  trId: 'tr-8ec34f32-0403-5288-5b57-e0019cfad221',
                },
                {
                  taskSubjectId: 'TBA932802333000',
                  trackingId: 'TBA932802333000',
                  trId: 'tr-b6c34f2f-4fbd-ebea-66ac-38f044f64f04',
                },
              ],
            },
          ],
          totalVolume: 114348.93,
          distance: null,
        },
      },
      transporterGroupAssigned: null,
      transporterAssigned: null,
      departureSetUpdate: null,
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
  {
    updatedBy: 'DispatchPlanningWorkflow',
    systemId: 'arn:aws:sts::475102872062:assumed-role/DispatchPlanningWorkflowLambdaRole/SyncRouteStoreV1',
    timestamp: 1677694457032,
    version: 1,
    routeUpdate: {
      updateType: {
        updateCategory: 'GENERAL',
        updateType: 'CREATED',
      },
      routeCreated: {
        metadata: {
          ROUTE_NAME: 'AK7',
          DISPATCH_DATE: '2023-03-01',
          STATION_TIMEZONE: 'US/Central',
          STATION_CODE: 'VTN1',
          SERVICE_AREA_ID: '479968bb-e253-4c6e-a78a-1629507a8c63',
          CORRELATION_ID: 'decbcbb0-66ff-48c2-8f5f-11c75b73cfc3',
          COUNTRY_CODE: 'US',
        },
      },
      routeStatusChanged: null,
      routeSequenceUpdated: null,
      transporterGroupAssigned: null,
      transporterAssigned: null,
      departureSetUpdate: null,
      dispatchDateUpdate: null,
      referenceTagList: [],
    },
  },
];
