import ufraaVizClient, { FlexRouteAssignmentPlannerInput, FlexRouteAssignmentPlan, ServiceAreaDetails } from '../../../clients';

const DEFAULT_REFRESH_PERIOD = 10_000; // 10 seconds
const MINIMUM_REFRESH_PERIOD = 5_000; // 5 seconds
/**
 * Detect new plan.
 *
 * Implementation is based on pulling mode, pull plans from backend every certain amount of time.
 */
export class PlanDetector {
  readonly serviceAreaId: string;
  readonly period: number;
  private handle?: NodeJS.Timeout;

  private plannerInput?: FlexRouteAssignmentPlannerInput;
  private plan?: FlexRouteAssignmentPlan;

  /**
   * caller provided callback function
   * Invoke the method when there is new planner input or new plan.
   */
  onNewPlanArtifacts?: (plannerInput: FlexRouteAssignmentPlannerInput, plan: FlexRouteAssignmentPlan) => void;

  /**
   *
   * @param serviceAreaId
   * @param period refresh period in milliseconds, default
   */
  constructor(serviceAreaId: string, period?: number) {
    this.serviceAreaId = serviceAreaId;
    if (typeof period === 'number') {
      // minimum refresh period 5 seconds.
      this.period = Math.max(period, MINIMUM_REFRESH_PERIOD);
    } else {
      this.period = DEFAULT_REFRESH_PERIOD;
    }
  }

  start() {
    if (this.handle) {
      throw new Error('PlanDetector is already running');
    }
    this.handle = setInterval(async () => {
      const currentTimestamp = new Date().getTime();

      await this.refresh(currentTimestamp - this.period - 1, currentTimestamp);
    }, this.period);

    const currentTimestamp = new Date().getTime();
    // use a large time window for the first plan loading.
    this.refresh(currentTimestamp - 30 * 60_000, currentTimestamp);
  }

  stop() {
    if (this.handle) {
      clearInterval(this.handle);
      this.handle = undefined;
    }
  }

  private async refresh(startTime: number, endTime: number) {
    // refresh planner input.
    const plannerInputMetadataList = await ufraaVizClient.listArtifactMetadata({
      scope: this.serviceAreaId,
      artifactType: 'FLEX_ROUTE_ASSIGNMENT_PLANNER_INPUT',
      startTime: startTime,
      endTime: endTime,
      timeUnits: 'MILLISECONDS',
    });

    if (plannerInputMetadataList.length > 0) {
      const latestMetadata = plannerInputMetadataList[plannerInputMetadataList.length - 1];
      if (latestMetadata.artifactId) {
        if (this.plannerInput && this.plannerInput.plannerInputId === latestMetadata.artifactId) {
          // do nothing if we already have the latest planner input.
        } else {
          try {
            const plannerInputArtifacts = await ufraaVizClient.getArtifactsFromReferences<FlexRouteAssignmentPlannerInput>({
              artifactIdentifiers: [
                {
                  artifactId: latestMetadata.artifactId,
                  artifactType: 'FLEX_ROUTE_ASSIGNMENT_PLANNER_INPUT',
                },
              ],
            });

            if (plannerInputArtifacts.length !== 1) {
              const message =
                plannerInputArtifacts.length === 0
                  ? `Unable to find the requested planner input ${latestMetadata.artifactId}.`
                  : `Requested one artifact but backend responded ${plannerInputArtifacts.length}.`;
              console.error(message);
              return;
            }

            this.plannerInput = plannerInputArtifacts[0].artifact;
          } catch (err: any) {
            console.error(`Failed to acquire planner input ${latestMetadata.artifactId}`, err?.response?.data);
            return;
          }
        }
      }
    }

    // if we don't have the plan or the plan id is not same as the plannerInput's plan id,
    // then refresh the plan.
    if (this.plannerInput && (!this.plan || this.plan.planId !== this.plannerInput.planId)) {
      try {
        const planArtifacts = await ufraaVizClient.getArtifactsFromReferences<FlexRouteAssignmentPlan>({
          artifactIdentifiers: [
            {
              artifactId: this.plannerInput.planId,
              artifactType: 'FLEX_ROUTE_ASSIGNMENT_PLAN',
            },
          ],
        });

        if (planArtifacts.length === 0) {
          console.warn(`The requested plan ${this.plannerInput.planId} is not generated.`);
          return;
        } else if (planArtifacts.length > 1) {
          console.error(`Requested one artifact but backend responded ${planArtifacts.length}.`);
          return;
        }

        this.plan = planArtifacts[0].artifact;
        if (this.onNewPlanArtifacts) {
          this.onNewPlanArtifacts(this.plannerInput, this.plan);
        }
      } catch (err: any) {
        if (err?.response?.status !== 404) {
          console.error(`Failed to acquire plan ${this.plannerInput.planId}`, err?.response?.data);
        }
      }
    }
  }
}
