import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import Link from '@amzn/awsui-components-react/polaris/link';
import { Notification } from '../../../main-app/global-context';
import './permission-warning-notification.scss';

// Team https://permissions.amazon.com/a/team/ufraaviz-assignment-view;
const UFRAAVIZ_ASSIGNMENT_VIEW_PERMISSION_REQUEST_URL = 'https://issues.amazon.com/issues/create?template=a7f80a3f-70da-4c54-aa9a-243615ec7d8c';

export function buildAssignmentPermissionWarningNotification(): Notification {
  return {
    header: 'Attention',
    content: (
      <Box className={'permission-warning-content'}>
        UfraaViz will start enforcing restricted access on this assignment plan page starting from{' '}
        <Box className={'permission-warning-content'} variant="span" fontWeight="bold">
          October 1, 2022
        </Box>
        . If you wish to continue visiting the page, please request{' '}
        <Box className={'permission-warning-content'} variant="span" fontWeight="bold">
          <Link className={'team-link'} external={true} externalIconAriaLabel="Opens in a new tab" href={UFRAAVIZ_ASSIGNMENT_VIEW_PERMISSION_REQUEST_URL}>
            ufraaviz-assignment-view
          </Link>
        </Box>{' '}
        membership as soon as possible.
      </Box>
    ),
    type: 'warning',
    dismissible: false,
  };
}
