import { ButtonDropdownProps, CollectionPreferencesProps, MultiselectProps } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import * as awsui from '@amzn/awsui-design-tokens';

export const NetworkHealthPagePreferencesStoreKey = 'NetworkHealthPagePreferencesStoreKey';

export const LIVE_INTERVAL_IN_MS = 60000;

export const COLOR_RED = awsui.colorChartsRed300;

export const COLOR_YELLOW = awsui.colorChartsYellow300;

// https://tiny.amazon.com/15x1vy8mr/codeamazpackGSFTblob988cweba
export const TWO_HOURS_IN_MS = 2 * 60 * 60_000;

export const MAXIMUM_QUERY_WINDOW_SIZE_IN_MS = 12 * 60 * 60_000;

export const MAXIMUM_QUERY_WINDOW_SIZE_TEXT = '12 hours';

export const DEFAULT_SERVICE_AREAS_PER_PAGE = 30;

export const BASE_VISIBLE_COLUMN_IDS: Readonly<string[]> = [
  'plan-time',
  'routes',
  'orders',
  'total-unassigned-order-count',
  'unplanned-orders',
  'unassigned-tplus-10-order-count',
  'unassigned-tplus-20-order-count',
  'unassigned-tplus-30-order-count',
  'assigned-block-driver-count',
  'available-block-driver-count',
  'assigned-io-driver-count',
  'unassigned-io-drivers-within-time-radius-count',
];

export const COLUMN_IDS_TO_HEADERS: Readonly<Record<string, string>> = {
  'station-code': 'SA Code',
  'plan-id': 'Plan Id',
  'plan-time': 'Plan Time',
  routes: 'Routes',
  orders: 'Orders',
  'total-unassigned-order-count': 'Unassigned Orders',
  'unplanned-orders': 'Unplanned Orders',
  'unassigned-tplus-10-order-count': 'Unassigned Orders (T+10min)',
  'unassigned-tplus-20-order-count': 'Unassigned Orders (T+20min)',
  'unassigned-tplus-30-order-count': 'Unassigned Orders (T+30min)',
  'assigned-block-driver-count': 'Assigned Block Drivers',
  'available-block-driver-count': 'Block Drivers',
  'assigned-io-driver-count': 'Assigned IO Drivers',
  'unassigned-io-drivers-within-time-radius-count': 'IO Drivers',
};

export const MULTISELECT_STATUS_GROUP: MultiselectProps.OptionGroup = {
  label: 'Status',
  options: [
    {
      label: 'All Statuses',
      value: 'all_statuses',
      disabled: false,
    },
    {
      label: 'Auto Assign Enabled',
      value: 'auto-assign',
      disabled: false,
    },
    {
      label: 'IO Enabled',
      value: 'io',
      disabled: false,
    },
  ],
};

// https://code.amazon.com/packages/LastMileExecutionSnapshotServiceModel/blobs/698d2cdeb0bdc8b8b0482606ada5cd11307d311d/--/model/main.xml#L1164
export const MULTISELECT_PROGRAM_TYPE_GROUP_IDS: ReadonlyArray<string> = ['houdini', 'copperfield', 'other'];

export const MULTISELECT_PROGRAM_TYPE_GROUP: MultiselectProps.OptionGroup = {
  label: 'Program Type',
  options: [
    {
      label: 'All Program Types',
      value: 'all_program_types',
      disabled: false,
    },
    {
      label: 'Prime Now',
      value: 'houdini',
      disabled: false,
    },
    {
      label: '3P / Whole Foods',
      value: 'copperfield',
      disabled: false,
    },
    {
      label: 'Other',
      value: 'other',
      disabled: false,
    },
  ],
};

export const MULTISELECT_OPTIONS_DEFAULT: ReadonlyArray<OptionDefinition> = [MULTISELECT_STATUS_GROUP.options[0], MULTISELECT_PROGRAM_TYPE_GROUP.options[0]];

export const MULTISELECT_OPTIONS_AVAILABLE: ReadonlyArray<MultiselectProps.OptionGroup> = [MULTISELECT_STATUS_GROUP, MULTISELECT_PROGRAM_TYPE_GROUP];

export const ALL_AREAS_PAGE_SIZE_PREFERENCE: CollectionPreferencesProps.PageSizePreference = {
  title: 'Select page size',
  options: [
    { value: 30, label: '30 Service Areas' },
    { value: 50, label: '50 Service Areas' },
    { value: 999, label: 'All Service Areas' },
  ],
};

export const SINGLE_AREA_PAGE_SIZE_PREFERENCE: CollectionPreferencesProps.PageSizePreference = {
  title: 'Select page size',
  options: [
    { value: 30, label: '30 Plans' },
    { value: 50, label: '50 Plans' },
    { value: 999, label: 'All Plans' },
  ],
};

export const BUTTON_DROPDOWN_ITEMS: ReadonlyArray<ButtonDropdownProps.ItemOrGroup> = [
  { text: 'CSV', id: 'csv' },
  { text: 'JSON', id: 'json' },
];
