// reference: https://code.amazon.com/packages/AWS-UI-Examples/blobs/mainline-3.0/--/src/react/commons/copy-text.js

import React from 'react';
import { Box, Button, StatusIndicator, Popover, StatusIndicatorProps } from '@amzn/awsui-components-react/polaris';
import { copyToClipboard } from '../utilities';

const DEFAULT_SUCCESS_TEXT = 'Copied';
const DEFAULT_ERROR_TEXT = 'Failed to copy';

export interface CopyTextProps {
  readonly copyText: string;
  readonly successText?: string;
  readonly errorText?: string;
}

export interface CopyTextState {
  readonly status: StatusIndicatorProps.Type;
  readonly message: string;
}

export default class CopyText extends React.Component<CopyTextProps, CopyTextState> {
  constructor(props: CopyTextProps) {
    super(props);
    this.state = {
      status: 'success',
      message: this.props.successText ? this.props.successText : DEFAULT_SUCCESS_TEXT,
    };
  }

  render() {
    return (
      <div style={{ wordBreak: 'break-all' }}>
        <Box margin={{ right: 'xxs' }} display="inline-block">
          <Popover size="small" position="top" dismissButton={false} triggerType="custom" content={<StatusIndicator type={this.state.status}>{this.state.message}</StatusIndicator>}>
            <Button
              variant="inline-icon"
              iconName="copy"
              ariaLabel={'copy'}
              onClick={async () => {
                try {
                  await copyToClipboard(this.props.copyText);
                  this.setState({
                    status: 'success',
                    message: this.props.successText ? this.props.successText : DEFAULT_SUCCESS_TEXT,
                  });
                } catch (err) {
                  this.setState({
                    status: 'error',
                    message: this.props.errorText ? this.props.errorText : DEFAULT_ERROR_TEXT,
                  });
                }
              }}
            />
          </Popover>
        </Box>
        {this.props.copyText}
      </div>
    );
  }
}
