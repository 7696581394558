import { ServiceAreaSummary } from '../../clients';

export type SummaryField = 'serviceAreaId' | 'stationCode' | 'serviceAreaName' | 'autoDetect';

export const TITLECASE_LOOKUP: Record<SummaryField, string> = {
  serviceAreaId: 'Service Area Id',
  stationCode: 'Station Code',
  serviceAreaName: 'Service Area Name',
  autoDetect: 'Auto Detect',
};

type DROPDOWN_OPTIONS = Array<{ readonly text: string; readonly id: SummaryField; readonly disabled: boolean }>;

function buildDropdownOptions(fields: SummaryField[]): DROPDOWN_OPTIONS {
  const options: DROPDOWN_OPTIONS = [];
  fields.forEach((field) => {
    options.push({
      text: TITLECASE_LOOKUP[field],
      id: field,
      disabled: false,
    });
  });
  return options;
}

export const INPUT_OPTIONS: DROPDOWN_OPTIONS = buildDropdownOptions(['autoDetect', 'serviceAreaId', 'stationCode', 'serviceAreaName']);

export const OUTPUT_OPTIONS: DROPDOWN_OPTIONS = buildDropdownOptions(['serviceAreaId', 'stationCode', 'serviceAreaName']);

export function getValue(summary: ServiceAreaSummary, field: SummaryField): string {
  if (field === 'serviceAreaId') {
    return summary.serviceAreaId;
  } else if (field === 'stationCode') {
    return summary.stationCode;
  } else {
    return summary.serviceAreaName;
  }
}
