import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import ServiceAreaSummary from './service-area-summary';
import AssignmentPlanTable from './assignment-plan-table';
import RoutingPlanTable from './routing-plan-table';
import RouteSnapshotTable from './route-snapshot-table';
import ArtifactTable from './artifact-table';
import DriaSnapshotTable, { DRIA_SNAPSHOT_TYPE } from './dria-snapshot-table';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { StationPageProps, StationPageSettings, StationPageState, COMPONENT_ARTIFACT_TYPE } from './models';
import { QueryParamsHelper } from './query-params-helper';
import { StationPageSettingsModal } from './station-page-settings-modal';
import { PreferencesStore } from '../../utilities/preferences-store';
import { ArtifactType } from '../../clients';
import { withBundle } from '@amzn/react-arb-tools';
import { COMPONENT_ARTIFACT_TYPE_TO_BUNDLE_RESOURCE } from './constants';

const settingsStore = new PreferencesStore();
const SETTING_KEY = 'station-page-settings';

class StationPage extends React.Component<StationPageProps, StationPageState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;
  private queryParamsHelper: QueryParamsHelper;

  constructor(props: StationPageProps) {
    super(props);
    this.state = {
      displaySettings: false,
      settings: settingsStore.get(SETTING_KEY, this.buildDefaultSettings()),
    };
    this.queryParamsHelper = new QueryParamsHelper(props.queryParams);
  }

  componentDidMount() {
    this.context.resetLayout();
    this.context.updateBreadcrumbItems([
      {
        text: 'Service Areas',
        href: '/service-areas',
      },
      {
        text: this.props.serviceAreaId,
        href: `/service-areas/${this.props.serviceAreaId}`,
      },
    ]);
  }

  render() {
    return (
      <React.Fragment>
        {this.state.displaySettings ? (
          <StationPageSettingsModal
            settings={this.state.settings}
            onCancel={() => this.setState({ displaySettings: false })}
            onSave={(settings) => {
              this.setState({ settings, displaySettings: false });
              settingsStore.set(SETTING_KEY, settings);
            }}
            bundle={this.props.bundle}
          />
        ) : undefined}
        {this.renderContent()}
      </React.Fragment>
    );
  }

  private renderContent() {
    return (
      <SpaceBetween direction="vertical" size="xxl">
        <ServiceAreaSummary serviceAreaId={this.props.serviceAreaId} onSettingsClick={() => this.setState({ displaySettings: true })} bundle={this.props.bundle} />

        {this.state.settings.artifactHiddenList.includes('ROUTING_PLAN') ? undefined : (
          <RoutingPlanTable
            serviceAreaId={this.props.serviceAreaId}
            startTime={this.queryParamsHelper.getQueryTime('ROUTING_PLAN', true)}
            endTime={this.queryParamsHelper.getQueryTime('ROUTING_PLAN', false)}
            onQueryWindowChange={(startTime, endTime) => {
              this.queryParamsHelper.updateQueryParameters('ROUTING_PLAN', { startTime, endTime });
              this.props.onQueryParamsChange(this.queryParamsHelper.encodeQueryParams());
            }}
            bundle={this.props.bundle}
          />
        )}

        {this.state.settings.artifactHiddenList.includes('ASSIGNMENT_PLAN') ? undefined : (
          <AssignmentPlanTable
            serviceAreaId={this.props.serviceAreaId}
            startTime={this.queryParamsHelper.getQueryTime('ASSIGNMENT_PLAN', true)}
            endTime={this.queryParamsHelper.getQueryTime('ASSIGNMENT_PLAN', false)}
            onQueryWindowChange={(startTime, endTime) => {
              this.queryParamsHelper.updateQueryParameters('ASSIGNMENT_PLAN', { startTime, endTime });
              this.props.onQueryParamsChange(this.queryParamsHelper.encodeQueryParams());
            }}
            bundle={this.props.bundle}
          />
        )}

        {this.state.settings.artifactHiddenList.includes('SHADOW_ASSIGNMENT_PLAN') ? undefined : (
          <AssignmentPlanTable
            serviceAreaId={this.props.serviceAreaId}
            startTime={this.queryParamsHelper.getQueryTime('SHADOW_ASSIGNMENT_PLAN', true)}
            endTime={this.queryParamsHelper.getQueryTime('SHADOW_ASSIGNMENT_PLAN', false)}
            isShadow={true}
            onQueryWindowChange={(startTime, endTime) => {
              this.queryParamsHelper.updateQueryParameters('SHADOW_ASSIGNMENT_PLAN', { startTime, endTime });
              this.props.onQueryParamsChange(this.queryParamsHelper.encodeQueryParams());
            }}
            bundle={this.props.bundle}
          />
        )}

        {this.state.settings.artifactHiddenList.includes('SNAPSHOT_SERVICE_AREA') ? undefined : this.buildGenericDriaSnapshotTable('SNAPSHOT_SERVICE_AREA')}

        {/** migrate route snapshot to use the generic dria snapshot table */}
        {this.state.settings.artifactHiddenList.includes('SNAPSHOT_ROUTES') ? undefined : (
          <RouteSnapshotTable
            serviceAreaId={this.props.serviceAreaId}
            startTime={this.queryParamsHelper.getQueryTime('SNAPSHOT_ROUTES', true)}
            endTime={this.queryParamsHelper.getQueryTime('SNAPSHOT_ROUTES', false)}
            onQueryWindowChange={(startTime, endTime) => {
              this.queryParamsHelper.updateQueryParameters('SNAPSHOT_ROUTES', { startTime, endTime });
              this.props.onQueryParamsChange(this.queryParamsHelper.encodeQueryParams());
            }}
            bundle={this.props.bundle}
          />
        )}

        {this.state.settings.artifactHiddenList.includes('SNAPSHOT_PROVIDERS') ? undefined : this.buildGenericDriaSnapshotTable('SNAPSHOT_PROVIDERS')}

        {this.state.settings.artifactHiddenList.includes('SNAPSHOT_PACKAGES') ? undefined : this.buildGenericDriaSnapshotTable('SNAPSHOT_PACKAGES')}

        {this.state.settings.artifactHiddenList.includes('UBP_PLAN') ? undefined : this.buildGenericArtifactTable('UBP_PLAN', 'UBP_PLAN', 'UBP Plans')}

        {this.state.settings.artifactHiddenList.includes('PICKUP_READY_PLAN') ? undefined : this.buildGenericArtifactTable('PICKUP_READY_PLAN', 'PICKUP_READY_PLAN', 'Pickup Ready Plans')}

        {this.state.settings.artifactHiddenList.includes('SNAPSHOT_ROUTE_LABOR_ADJUSTMENT')
          ? undefined
          : this.buildGenericArtifactTable('SNAPSHOT_ROUTE_LABOR_ADJUSTMENT', 'SNAPSHOT_ROUTE_LABOR_ADJUSTMENT', 'Route Labor Adjustment Snapshots')}
      </SpaceBetween>
    );
  }

  private buildGenericArtifactTable(artifactType: ArtifactType, componentArtifactType: COMPONENT_ARTIFACT_TYPE, tableName: string) {
    return (
      <ArtifactTable
        artifactType={artifactType}
        serviceAreaId={this.props.serviceAreaId}
        startTime={this.queryParamsHelper.getQueryTime(componentArtifactType, true)}
        endTime={this.queryParamsHelper.getQueryTime(componentArtifactType, false)}
        tableName={this.props.bundle.getMessage(COMPONENT_ARTIFACT_TYPE_TO_BUNDLE_RESOURCE[componentArtifactType])}
        onQueryWindowChange={(startTime, endTime) => {
          this.queryParamsHelper.updateQueryParameters(componentArtifactType, { startTime, endTime });
          this.props.onQueryParamsChange(this.queryParamsHelper.encodeQueryParams());
        }}
        bundle={this.props.bundle}
      />
    );
  }

  private buildGenericDriaSnapshotTable(type: DRIA_SNAPSHOT_TYPE) {
    return (
      <DriaSnapshotTable
        serviceAreaId={this.props.serviceAreaId}
        tableName={this.props.bundle.getMessage(COMPONENT_ARTIFACT_TYPE_TO_BUNDLE_RESOURCE[type])}
        snapshotType={type}
        startTime={this.queryParamsHelper.getQueryTime(type, true)}
        endTime={this.queryParamsHelper.getQueryTime(type, false)}
        onQueryWindowChange={(startTime, endTime) => {
          this.queryParamsHelper.updateQueryParameters(type, { startTime, endTime });
          this.props.onQueryParamsChange(this.queryParamsHelper.encodeQueryParams());
        }}
        onSnapshotClick={(artifactId) => this.handleSnapshotClick(type, artifactId)}
        bundle={this.props.bundle}
      />
    );
  }

  private async handleSnapshotClick(type: DRIA_SNAPSHOT_TYPE, artifactId: string) {
    // todo, e.g. open and render the snapshot
  }

  private buildDefaultSettings(): StationPageSettings {
    return {
      artifactHiddenList: [],
    };
  }
}

export default withBundle('StationPage')(StationPage);
